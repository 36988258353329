import React from 'react';
import 'react-step-progress-bar/styles.css';
import { ProgressBar } from 'react-step-progress-bar';

const progressWrapperStyle = { maxWidth: '520px', margin: '10px auto' };

export default function Progress({ percentage, color }) {
  const filledBackground = color
    ? color
    : 'linear-gradient(to right, #fcbe11, #e05d26)';

  return (
    <div style={progressWrapperStyle}>
      <ProgressBar percent={percentage} filledBackground={filledBackground} />
    </div>
  );
}
