import React from 'react';
import Progress from './Progress';
import { stages } from '../data/stages';
import { getProgressColor } from './utils';

export default function BillCard({
  bill,
  showCategory = true,
  fadeIn = false,
}) {
  const viewBill = () => {
    const link = bill?.link;
    window.open(link, '_blank').focus();
  };
  const stage = bill?.status_updated;
  const billStageIndex = stages.indexOf(stage);
  const billStagePercent = (((billStageIndex + 1) / 16) * 100).toFixed(0);

  const billDescription = () => {
    const desc = bill?.title;
    if (!desc) return 'description not available';
    if (desc.length > 150) return `${desc.substring(0, 150)} ...`;
    return desc;
  };

  return (
    <div className={fadeIn ? 'fade-in-1000 small' : 'small'}>
      <article className="card">
        <div className="card-content">
          <h1 className="card-title">
            <a href={bill?.link}>
              {bill?.state}: {bill?.bill_name}
            </a>
          </h1>
          <p style={{ marginBottom: '0' }}>
            Progress towards becoming law {bill?.status === 'Dead' && '(dead)'}
            {bill?.status === 'Passed' && '(passed)'}{' '}
            {bill.status === 'Postponed' ? '(postponed)' : ''}
            {bill.status === 'Prefiled' ? '(pre-filed)' : ''}
          </p>
          <Progress
            percentage={billStagePercent}
            color={getProgressColor(bill)}
          />
          {showCategory && (
            <p className="card-tags">
              <span className="card-tag">{bill?.category}</span>
            </p>
          )}

          <p className="card-desc">{billDescription()}</p>

          <button
            className="card-view-bill"
            type="button"
            onClick={() => viewBill()}
          >
            View
          </button>
        </div>
      </article>
    </div>
  );
}
