import React, { useState } from 'react';
import Map from './Map';
import SenatorsList from './SenatorsList';

export default function MapSenators({
  allSenators,
  setSelectedRep,
  setSelectedRepImage,
  setSelectedRepEmail,
  setModalIsOpen,
}) {
  const [selectedState, setSelectedState] = useState('All');
  const senatorsToDisplay = allSenators.filter((senator) => {
    if (selectedState === 'all') return senator;
    return senator.state_code === selectedState;
  });

  const [repsVisible, setRepsVisible] = useState(false);

  return (
    <div className="map-section">
      {repsVisible ? (
        <div className="flex-row senator-map-flex-row">
          <div className="flex-column fade-in">
            <div className="map-in-column-action">
              <Map
                senators={allSenators}
                selectedState={selectedState}
                setSelectedState={setSelectedState}
                setRepsVisible={setRepsVisible}
              />
              <button
                id="see-full-map-button"
                className="card-contact-senator"
                type="button"
                style={{ margin: '0px auto' }}
                onClick={() => {
                  setRepsVisible(false);
                  setSelectedState(undefined);
                }}
              >
                see large map
              </button>
            </div>
          </div>
          <div className="flex-column">
            <SenatorsList
              senatorsToDisplay={senatorsToDisplay}
              setSelectedRep={setSelectedRep}
              setSelectedRepEmail={setSelectedRepEmail}
              setSelectedRepImage={setSelectedRepImage}
              setModalIsOpen={setModalIsOpen}
            />
          </div>
        </div>
      ) : (
        <div className="fade-in">
          <Map
            senators={allSenators}
            selectedState={selectedState}
            setSelectedState={setSelectedState}
            setRepsVisible={setRepsVisible}
          />
          <h2 className="light-black">Click to see Senators</h2>
        </div>
      )}
    </div>
  );
}
