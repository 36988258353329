export const stages = [
  'Filed',
  'Introduced',
  '1st reading',
  'Referred to committee',
  'Public hearing',
  '2nd reading',
  '3rd reading',
  'Sent to other chamber',
  '1st reading in other chamber',
  'Referred to committee in other chamber',
  'Public hearing in other chamber',
  '2nd reading in other chamber',
  '3rd reading in other chamber',
  'Sent to house of origin',
  'Sent to governor',
  'Passed',
  'Postponed',
  'Prefiled',
];
