import CTA from '../CTA';

export default function Inflection() {
  return (
    <div className="slim-view">
      <div className="section-wrapper">
        <h1>Inflection point</h1>
        <p>
          Today, trans rights are faced with an urgent legal challenge. The
          efforts to block trans rights and visibility have produced legislation
          guided by the principle that “it’s harmful to be trans, and we should
          stop people from being trans,”{' '}
          <a
            href="https://www.nytimes.com/2021/05/13/opinion/sway-kara-swisher-chase-strangio.html"
            target="_blank"
            rel="noreferrer"
          >
            says
          </a>{' '}
          Chase Strangio. This philosophy explains why trans inclusion is being
          attacked from all angles legislatively. Unfortunately, so far, that
          tactic is working. “It is creating a ton of fear. It is modeling
          itself after the anti-abortion movement,” Strangio points out. “You
          are starting to see protesters outside of gender clinics in the same
          way we do outside of medical health centers that offer abortion
          services.”
        </p>

        <p>
          This year, the anti-trans coalition established a new group called{' '}
          <a
            href="https://promisetoamericaschildren.org/"
            target="_blank"
            rel="noreferrer"
          >
            Promise to America’s Children
          </a>{' '}
          to combat the{' '}
          <a
            href="https://www.hrc.org/resources/the-equality-act"
            target="_blank"
            rel="noreferrer"
          >
            Equality Act
          </a>
          , federal legislation that would explicitly add protections for sexual
          orientation and gender identity to existing civil rights law. Promise
          to America’s Children seeks to{' '}
          <a
            href="https://promisetoamericaschildren.org/resources/"
            target="_blank"
            rel="noreferrer"
          >
            activate
          </a>{' '}
          parents, students, and individuals to fight against trans rights at
          the ground level. In the fight for equality, those voices will need to
          be met at every level with accurate information and true compassion,
          with an understanding that it’s not just about youth athletics, but
          human rights.
        </p>

        <blockquote>
          This moment is a particularly scary one because I think it’s an
          inflection point.
          <p className="callout-text-attribution">Chase Strangio</p>
        </blockquote>

        <p>
          Anti-trans groups have a clear playbook, but the pushback to previous
          bathroom bills provide lessons on ways to respond. In 2016, North
          Carolina’s bathroom bill passed, but public outcry along with
          corporations pulling their business in the state ultimately led to its
          repeal. “A lot of companies pulled out,” Strangio says. “The NBA
          pulled the All-Star game. The NCAA pulled tournament games. That was a
          huge deterrent to other states.”
        </p>

        <p>
          “We will be fighting these fights, and we will be doing it in a
          multitude of ways,” Strangio says. “But this moment is a particularly
          scary one because I think it’s an inflection point. Are we going to be
          sort of on a faster track that looks more like how marriage equality
          was resolved more quickly? Or is it going to be like abortion, where
          you’re defending or holding the line from repeated chipping away at a
          sort of formal equality?”
        </p>
        <CTA />
      </div>
    </div>
  );
}
