import '../Intro.css';

export default function Header() {
  return (
    <div className="header-wrapper">
      <div className="title-box">
        <div className="title">
          <i>
            <span className="">Inflection</span>
          </i>
          <br />
          <span className="color-red">Point</span>
          <br />
        </div>
        <div className="subheader margin-top-50">
          2021 marks a record-breaking year for anti-trans legislation.{' '}
          <span className="color-orange">144</span> bills were introduced in{' '}
          <span className="color-blue">37</span> states.{' '}
          <span className="color-green">18</span> have already passed.
        </div>
        <p className="margin-top-50">
          By{' '}
          <a
            href="https://twitter.com/agbales"
            target="_blank"
            rel="noreferrer"
          >
            Andrew Bales
          </a>
        </p>
      </div>

      <div id="flag">
        <div className="header header-blue" />
        <div className="header header-pink" />
        <div className="header header-white" />
        <div className="header header-pink" />
        <div className="header header-blue" />
      </div>
    </div>
  );
}
