export const groupedBills = [
  [
    'Excluding transgender youth from athletics',
    [
      {
        state: 'Alabama',
        abbreviation: 'AL',
        bill_name: 'HB 391',
        link: 'http://alisondb.legislature.state.al.us/ALISON_LCC/SESSBillStatusResult.ASPX?BILL=HB391&WIN_TYPE=BillResult&SESSIONID=8787',
        category: 'Excluding transgender youth from athletics',
        status_updated: 'Passed',
        last_updated: '4/20/2021',
        status: 'Passed',
        title:
          'Schools, public K-12, athletic events allowing competition by one biological gender against another prohibited unless the event specifically includes both genders',
      },
      {
        state: 'Arkansas',
        abbreviation: 'AR',
        bill_name: 'SB 354',
        link: 'https://www.arkleg.state.ar.us/Bills/Detail?ddBienniumSession=2021%2F2021R&measureno=SB354',
        category: 'Excluding transgender youth from athletics',
        status_updated: 'Passed',
        last_updated: '3/29/2021',
        status: 'Passed',
        title: "To Create The Fairness In Women's Sports Act.",
      },
      {
        state: 'Arkansas',
        abbreviation: 'AR',
        bill_name: 'SB 450',
        link: 'https://www.arkleg.state.ar.us/Bills/Detail?ddBienniumSession=2021%2F2021R&measureno=SB450',
        category: 'Excluding transgender youth from athletics',
        status_updated: '2nd reading in other chamber',
        last_updated: '4/29/2021',
        status: 'Passed',
        title:
          'To Create The Gender Integrity Reinforcement Legislation For Sports (girls) Act; And To Create A Legal Cause Of Action For A Violation Of The Gender Integrity Reinforcement Legislation For Sports (girls) Act.',
      },
      {
        state: 'Arkansas',
        abbreviation: 'AR',
        bill_name: 'SJR 16',
        link: 'https://www.arkleg.state.ar.us/Bills/Detail?ddBienniumSession=2021%2F2021R&measureno=SJR16',
        category: 'Excluding transgender youth from athletics',
        status_updated: '2nd reading',
        last_updated: '2/23/2021',
        status: 'Active',
        title:
          'An Amendment To The Arkansas Constitution Providing That Interscholastic Or Intramural Athletic Teams That Are Sponsored By A Public School Shall Be Expressly Designated Based On Biological Sex.',
      },
      {
        state: 'Arizona',
        abbreviation: 'AZ',
        bill_name: 'SJR 16',
        link: 'https://www.arkleg.state.ar.us/Bills/Detail?ddBienniumSession=2021%2F2021R&measureno=SJR16',
        category: 'Excluding transgender youth from athletics',
        status_updated: '2nd reading',
        last_updated: '2/23/2021',
        status: 'Active',
        title:
          'AN AMENDMENT TO THE ARKANSAS CONSTITUTION PROVIDING THAT INTERSCHOLASTIC OR INTRAMURAL ATHLETIC TEAMS THAT ARE SPONSORED BY A PUBLIC SCHOOL SHALL BE EXPRESSLY DESIGNATED BASED ON BIOLOGICAL SEX.',
      },
      {
        state: 'Arizona',
        abbreviation: 'AZ',
        bill_name: 'SB 1637',
        link: 'https://openstates.org/az/bills/55th-1st-regular/SB1637/',
        category: 'Excluding transgender youth from athletics',
        status_updated: '1st reading',
        last_updated: '6/30/2021',
        status: 'Active',
        title: 'biological sex; interscholastic athletics',
      },
      {
        state: 'Arizona',
        abbreviation: 'AZ',
        bill_name: 'SJR 354',
        link: 'https://www.arkleg.state.ar.us/Bills/Detail?ddBienniumSession=2021%2F2021R&measureno=SB354',
        category: 'Excluding transgender youth from athletics',
        status_updated: 'Passed',
        last_updated: '3/29/2021',
        status: 'Passed',
        title: "To Create The Fairness In Women's Sports Act.",
      },
      {
        state: 'Connecticut',
        abbreviation: 'CT',
        bill_name: 'SB 324',
        link: 'https://www.cga.ct.gov/asp/cgabillstatus/cgabillstatus.asp?selBillType=Bill&bill_num=SB324&which_year=2021',
        category: 'Excluding transgender youth from athletics',
        status_updated: 'Referred to committee',
        last_updated: '1/25/2021',
        status: 'Active',
        title:
          'An Act Concerning Civil Immunity For Interscholastic Athletic Organizations And The Sanctioning Bodies Of Private Youth Organizations.',
      },
      {
        state: 'Connecticut',
        abbreviation: 'CT',
        bill_name: 'HB 6128',
        link: 'https://www.cga.ct.gov/asp/cgabillstatus/cgabillstatus.asp?selBillType=Bill&bill_num=HB6128&which_year=2021',
        category: 'Excluding transgender youth from athletics',
        status_updated: 'Referred to committee',
        last_updated: '1/29/2021',
        status: 'Active',
        title:
          "An Act Prohibiting Student Athletes Who Were Born As Males From Participating In Women's Interscholastic Athletic Events.",
      },
      {
        state: 'Florida',
        abbreviation: 'FL',
        bill_name: 'HB 935',
        link: 'https://www.flsenate.gov/Session/Bill/2021/935/ByVersion',
        category: 'Excluding transgender youth from athletics',
        status_updated: '1st reading',
        last_updated: '4/30/2021',
        status: 'Dead',
        title: 'Youth Gender and Sexual Identity',
      },
      {
        state: 'Florida',
        abbreviation: 'FL',
        bill_name: 'HB 1475',
        link: 'https://www.flsenate.gov/Session/Bill/2021/1475/ByVersion',
        category: 'Excluding transgender youth from athletics',
        status_updated: '2nd reading',
        last_updated: '4/30/2021',
        status: 'Dead',
        title: 'Sex-specific Student Athletic Teams or Sports',
      },
      {
        state: 'Florida',
        abbreviation: 'FL',
        bill_name: 'SB 2012',
        link: 'https://www.flsenate.gov/Session/Bill/2021/2012/ByVersion',
        category: 'Excluding transgender youth from athletics',
        status_updated: 'Referred to committee',
        last_updated: '4/30/2021',
        status: 'Dead',
        title: 'Promoting Equality of Athletic Opportunity',
      },
      {
        state: 'Georgia',
        abbreviation: 'GA',
        bill_name: 'HB 276',
        link: 'https://www.legis.ga.gov/legislation/59286',
        category: 'Excluding transgender youth from athletics',
        status_updated: '2nd reading',
        last_updated: '2/8/2021',
        status: 'Active',
        title:
          'Untitled (description: unlawful for public or private schools whose students compete against a public school to operate athletic programs that permit a person whose gender is male to participate in an athletic program that is designated for females)',
      },
      {
        state: 'Georgia',
        abbreviation: 'GA',
        bill_name: 'HB 372',
        link: 'https://www.legis.ga.gov/legislation/59505',
        category: 'Excluding transgender youth from athletics',
        status_updated: '2nd reading',
        last_updated: '2/11/2021',
        status: 'Active',
        title:
          'Untitled (description: provide a definition for the term "gender")',
      },
      {
        state: 'Georgia',
        abbreviation: 'GA',
        bill_name: 'SB 266',
        link: 'https://www.legis.ga.gov/legislation/60118',
        category: 'Excluding transgender youth from athletics',
        status_updated: '2nd reading',
        last_updated: '3/5/2021',
        status: 'Active',
        title: 'Save Girls Sports Act',
      },
      {
        state: 'Hawaii',
        abbreviation: 'HI',
        bill_name: 'HB 1304',
        link: 'https://www.capitol.hawaii.gov/measure_indiv.aspx?billtype=HB&billnumber=1304&year=2021',
        category: 'Excluding transgender youth from athletics',
        status_updated: 'Referred to committee',
        last_updated: '2/1/2021',
        status: 'Active',
        title: 'Relating To High School Athletics.',
      },
      {
        state: 'Iowa',
        abbreviation: 'IA',
        bill_name: 'HF 184',
        link: 'https://www.legis.iowa.gov/legislation/BillBook?ga=89&ba=HF%20184&v=i',
        category: 'Excluding transgender youth from athletics',
        status_updated: 'Referred to committee',
        last_updated: '1/22/2021',
        status: 'Active',
        title:
          'Student participation in interscholastic or intramural athletic teams or sports based on biological sex',
      },
      {
        state: 'Kansas',
        abbreviation: 'KS',
        bill_name: 'SB 208',
        link: 'http://kslegislature.org/li/b2021_22/measures/SB208/',
        category: 'Excluding transgender youth from athletics',
        status_updated: 'Referred to committee in other chamber',
        last_updated: '12/17/2021',
        status: 'Postponed',
        title:
          "Creating the fairness in women's sports act to require that female student athletic teams only include members who are biologically female.",
      },
      {
        state: 'Kentucky',
        abbreviation: 'KY',
        bill_name: 'SB 106',
        link: 'https://apps.legislature.ky.gov/record/21rs/sb106.html',
        category: 'Excluding transgender youth from athletics',
        status_updated: 'Referred to committee',
        last_updated: '4/2/2021',
        status: 'Dead',
        title: "Save Women's Sports Act.",
      },
      {
        state: 'Kentucky',
        abbreviation: 'KY',
        bill_name: 'HB 471',
        link: 'https://apps.legislature.ky.gov/record/21RS/hb471.html',
        category: 'Excluding transgender youth from athletics',
        status_updated: 'Referred to committee',
        last_updated: '4/2/2021',
        status: 'Dead',
        title: "Save Women's Sports Act",
      },
      {
        state: 'Louisiana',
        abbreviation: 'LA',
        bill_name: 'HB 542',
        link: 'http://www.legis.la.gov/Legis/BillInfo.aspx?s=21RS&b=HB542&sbi=y',
        category: 'Excluding transgender youth from athletics',
        status_updated: 'Referred to committee',
        last_updated: '4/12/2021',
        status: 'Active',
        title:
          "Provides for the Fairness in Women's Sports Act relative to a school's ability to offer opportunities to each student to participate in team sporting events on an equal basis",
      },
      {
        state: 'Louisiana',
        abbreviation: 'LA',
        bill_name: 'SB 156',
        link: 'http://www.legis.la.gov/Legis/BillInfo.aspx?s=21RS&b=SB156&sbi=y',
        category: 'Excluding transgender youth from athletics',
        status_updated: 'Sent to governor',
        last_updated: '7/21/2021',
        status: 'Dead',
        title:
          "Vetoed by governor: 'Provides for the Fairness in Women's Sports Act relative to a school's ability to offer opportunities to each student to participate in team sporting events on an equal basis.'",
      },
      {
        state: 'Maine',
        abbreviation: 'ME',
        bill_name: 'LD 926',
        link: 'http://legislature.maine.gov/LawMakerWeb/summary.asp?paper=HP0682&SessionID=14',
        category: 'Excluding transgender youth from athletics',
        status_updated: 'Referred to committee in other chamber',
        last_updated: '7/19/2021',
        status: 'Dead',
        title:
          "An Act To Ban Biological Males from Participating in Women's Sports",
      },
      {
        state: 'Michigan',
        abbreviation: 'MI',
        bill_name: 'SB 218',
        link: 'http://www.legislature.mi.gov/(S(phd4fehdevtda2c1dmd0mya0))/mileg.aspx?page=GetObject&objectname=2021-SB-0218',
        category: 'Excluding transgender youth from athletics',
        status_updated: 'Referred to committee',
        last_updated: '3/10/2021',
        status: 'Active',
        title:
          "Requirement that only biological males may compete for a position on and compete on a boys' high school team in an interscholastic activity and only biological females may compete for a position on and compete on a girls' high school team in an interscholastic activity",
      },
      {
        state: 'Minnesota',
        abbreviation: 'MN',
        bill_name: 'HF 350',
        link: 'https://www.revisor.mn.gov/bills/bill.php?view=chrono&b=House&f=HF350&ssn=0&y=2021',
        category: 'Excluding transgender youth from athletics',
        status_updated: 'Referred to committee',
        last_updated: '1/28/2021',
        status: 'Active',
        title:
          'A bill for an act relating to education; providing for participation in athletic teams',
      },
      {
        state: 'Minnesota',
        abbreviation: 'MN',
        bill_name: 'HF 352',
        link: 'https://www.revisor.mn.gov/bills/bill.php?view=chrono&b=House&f=HF352&ssn=0&y=2021',
        category: 'Excluding transgender youth from athletics',
        status_updated: 'Referred to committee',
        last_updated: '1/28/2021',
        status: 'Active',
        title:
          'A bill for an act relating to education; restricting female sports team participation to the female sex;',
      },
      {
        state: 'Minnesota',
        abbreviation: 'MN',
        bill_name: 'HF 1657',
        link: 'https://www.revisor.mn.gov/bills/bill.php?view=chrono&b=House&f=HF1657&ssn=0&y=2021',
        category: 'Excluding transgender youth from athletics',
        status_updated: 'Referred to committee',
        last_updated: '2/25/2021',
        status: 'Active',
        title:
          'A bill for an act relating to education; restricting male student participation in female athletics; restricting male access to female changing facilities; providing criminal penalties;',
      },
      {
        state: 'Missouri',
        abbreviation: 'MO',
        bill_name: 'HB 1077',
        link: 'https://www.house.mo.gov/BillContent.aspx?bill=HB1077&year=2021&code=R',
        category: 'Excluding transgender youth from athletics',
        status_updated: 'Referred to committee',
        last_updated: '2/18/2021',
        status: 'Active',
        title:
          'An Act To amend chapter 167, RSMo, by adding thereto one new section relating to public school athletic contests.',
      },
      {
        state: 'Missouri',
        abbreviation: 'MO',
        bill_name: 'HJR 56',
        link: 'https://www.house.mo.gov/BillContent.aspx?bill=HJR56&year=2021&code=R',
        category: 'Excluding transgender youth from athletics',
        status_updated: 'Referred to committee',
        last_updated: '2/18/2021',
        status: 'Active',
        title:
          'Submitting to the qualified voters of Missouri an amendment to Article IX of the Constitution of Missouri, by adopting one new section relating to participation in interscholastic athletic contests.',
      },
      {
        state: 'Missouri',
        abbreviation: 'MO',
        bill_name: 'SB 9',
        link: 'https://www.senate.mo.gov/21info/BTS_Web/Bill.aspx?SessionType=E1&BillID=70525554',
        category: 'Excluding transgender youth from athletics',
        status_updated: '2nd reading in other chamber',
        last_updated: '6/26/2021',
        status: 'Active',
        title: "Save Women's Sports Act",
      },
      {
        state: 'Mississippi',
        abbreviation: 'MS',
        bill_name: 'SB 2536',
        link: 'http://billstatus.ls.state.ms.us/2021/pdf/history/SB/SB2536.xml',
        category: 'Excluding transgender youth from athletics',
        status_updated: 'Passed',
        last_updated: '3/11/2021',
        status: 'Passed',
        title:
          'An Act to Require Any Public School, Public Institution of Higher Learning or Institution of Higher Learning That Is a Member of the NCAA, NAIA, MHSAA or NJCCA to Designate Its Athletic Teams or Sports According to Biological Sex; To Provide Protection for Any School or Institution of Higher Education That Maintains Separate Athletic Teams or Sports for Students of the Female Sex; To Create Private Causes of Action; And for Related Purposes.',
      },
      {
        state: 'Montana',
        abbreviation: 'MT',
        bill_name: 'HB 112',
        link: 'https://laws.leg.mt.gov/legprd/LAW0210W%24BSIV.ActionQuery?P_BILL_NO1=112&P_BLTP_BILL_TYP_CD=HB&Z_ACTION=Find&P_SESS=20211',
        category: 'Excluding transgender youth from athletics',
        status_updated: 'Sent to house of origin',
        last_updated: '5/7/2021',
        status: 'Passed',
        title: "Save Women's Sports Act",
      },
      {
        state: 'North Dakota',
        abbreviation: 'ND',
        bill_name: 'HB 1298',
        link: 'https://www.legis.nd.gov/assembly/67-2021/bill-actions/ba1298.html',
        category: 'Excluding transgender youth from athletics',
        status_updated: 'Sent to governor',
        last_updated: '4/27/2021',
        status: 'Dead',
        title:
          'Relating to participation in athletic events exclusively for males or females.',
      },
      {
        state: 'New Hampshire',
        abbreviation: 'NH',
        bill_name: 'HB 198',
        link: 'http://www.gencourt.state.nh.us/bill_Status/bill_status.aspx?lsr=0397&sy=2021&txtsessionyear=2021&sortoption=',
        category: 'Excluding transgender youth from athletics',
        status_updated: 'Public hearing',
        last_updated: '3/9/2021',
        status: 'Active',
        title:
          'An Act relative to an exception to the opportunity for public education without discrimination.',
      },
      {
        state: 'New Jersey',
        abbreviation: 'NJ',
        bill_name: 'S 3540',
        link: 'https://www.njleg.state.nj.us/bills/BillView.asp?LastSession=20&BillNumber=S3540',
        category: 'Excluding transgender youth from athletics',
        status_updated: 'Referred to committee',
        last_updated: '3/11/2021',
        status: 'Active',
        title: 'Fairness in Women’s Sports Act.',
      },
      {
        state: 'New Mexico',
        abbreviation: 'NM',
        bill_name: 'HB 304',
        link: 'https://www.nmlegis.gov/Legislation/Legislation?chamber=H&legtype=B&legno=304&year=21',
        category: 'Excluding transgender youth from athletics',
        status_updated: 'Referred to committee',
        last_updated: '2/18/2021',
        status: 'Active',
        title: "Women's Sports Protection Act",
      },
      {
        state: 'Ohio',
        abbreviation: 'OH',
        bill_name: 'HB 61',
        link: 'https://www.legislature.ohio.gov/legislation/legislation-summary?id=GA134-HB-61',
        category: 'Excluding transgender youth from athletics',
        status_updated: 'Referred to committee',
        last_updated: '2/4/2021',
        status: 'Active',
        title: "Save Women's Sports Act",
      },
      {
        state: 'Ohio',
        abbreviation: 'OH',
        bill_name: 'SB 132',
        link: 'https://www.legislature.ohio.gov/legislation/legislation-summary?id=GA134-SB-132',
        category: 'Excluding transgender youth from athletics',
        status_updated: 'Referred to committee',
        last_updated: '3/17/2021',
        status: 'Active',
        title: "Save Women's Sports Act",
      },
      {
        state: 'Ohio',
        abbreviation: 'OH',
        bill_name: 'SB 187',
        link: 'https://www.legislature.ohio.gov/legislation/legislation-summary?id=GA134-SB-187',
        category: 'Excluding transgender youth from athletics',
        status_updated: 'Sent to house of origin',
        last_updated: '6/24/2021',
        status: 'Active',
        title:
          "Allow intercollegiate athletes to earn compensation for names AND Save Women's Sports Act",
      },
      {
        state: 'Oklahoma',
        abbreviation: 'OK',
        bill_name: 'SB 2',
        link: 'https://legiscan.com/OK/bill/SB2/2021',
        category: 'Excluding transgender youth from athletics',
        status_updated: 'Sent to governor',
        last_updated: '4/20/2021',
        status: 'Active',
        title:
          "Creating the Save Women's Sports Act; requiring certain athletic teams to be designated based on biological sex.",
      },
      {
        state: 'Pennsylvania',
        abbreviation: 'PA',
        bill_name: 'HB 972',
        link: 'https://www.legis.state.pa.us/cfdocs/billinfo/billinfo.cfm?syear=2021&sind=0&body=H&type=B&bn=972',
        category: 'Excluding transgender youth from athletics',
        status_updated: 'Referred to committee',
        last_updated: '4/5/2021',
        status: 'Active',
        title:
          'An Act providing for sport activities in public institutions of higher education and public school entities to be expressly designated male, female or coed; and creating causes of action for harms suffered by designation.',
      },
      {
        state: 'Rhode Island',
        abbreviation: 'RI',
        bill_name: 'SB 638',
        link: 'https://openstates.org/ri/bills/2021/SB638/',
        category: 'Excluding transgender youth from athletics',
        status_updated: 'Referred to committee',
        last_updated: '8/9/2021',
        status: 'Active',
        title:
          'AN ACT RELATING TO EDUCATION (Categorizes women by their biological identity at birth rather than their gender identity for purpose of organized sports.)',
      },
      {
        state: 'South Carolina',
        abbreviation: 'SC',
        bill_name: 'HB 4608',
        link: 'https://www.scstatehouse.gov/sess124_2021-2022/bills/4608.htm',
        category: 'Excluding transgender youth from athletics',
        status_updated: 'Referred to committee in other chamber',
        last_updated: '11/17/2021',
        status: 'Prefiled',
        title: "Save Women's Sports Act",
      },
      {
        state: 'South Dakota',
        abbreviation: 'SD',
        bill_name: 'HB 1217',
        link: 'https://sdlegislature.gov/Session/Bill/12299',
        category: 'Excluding transgender youth from athletics',
        status_updated: 'Sent to governor',
        last_updated: '3/29/2021',
        status: 'Dead',
        title: "Promote continued fairness in women's sports.",
      },
      {
        state: 'Tennessee',
        abbreviation: 'TN',
        bill_name: 'HB 3',
        link: 'http://wapp.capitol.tn.gov/apps/BillInfo/default.aspx?BillNumber=HB0003&ga=112',
        category: 'Excluding transgender youth from athletics',
        status_updated: 'Passed',
        last_updated: '3/26/2021',
        status: 'Passed',
        title:
          "(Untitled) As enacted, requires that a student's gender for purposes of participation in a public middle school or high school interscholastic athletic activity or event be determined by the student's sex at the time of the student's birth, as indicated on the student's original birth certificate.",
      },
      {
        state: 'Tennessee',
        abbreviation: 'TN',
        bill_name: 'SB 228',
        link: 'http://wapp.capitol.tn.gov/apps/BillInfo/default.aspx?BillNumber=SB0228&ga=112',
        category: 'Excluding transgender youth from athletics',
        status_updated: 'Passed',
        last_updated: '3/26/2021',
        status: 'Passed',
        title:
          "(Untitled) As enacted, requires that a student's gender for purposes of participation in a public middle school or high school interscholastic athletic activity or event be determined by the student's sex at the time of the student's birth, as indicated on the student's original birth certificate.",
      },
      {
        state: 'Texas',
        abbreviation: 'TX',
        bill_name: 'HB 25', // related: HB90, HB10, HB25
        link: 'https://capitol.texas.gov/BillLookup/History.aspx?LegSess=872&Bill=HB25',
        category: 'Excluding transgender youth from athletics',
        status_updated: 'Sent to governor',
        last_updated: '10/14/2021',
        status: 'Active',
        title:
          'Relating to requiring public school students to compete in interscholastic athletic competitions based on biological sex.',
      },
      {
        state: 'Texas',
        abbreviation: 'TX',
        bill_name: 'SB 29',
        link: 'https://capitol.texas.gov/BillLookup/history.aspx?LegSess=87R&Bill=SB29',
        category: 'Excluding transgender youth from athletics',
        status_updated: '2nd reading in other chamber',
        last_updated: '5/25/2021',
        status: 'Active',
        title:
          'Relating to requiring public school students to compete in interscholastic athletic competitions based on biological sex.',
      },
      {
        state: 'Texas',
        abbreviation: 'TX',
        bill_name: 'SB 373',
        link: 'https://capitol.texas.gov/BillLookup/History.aspx?LegSess=87R&Bill=SB373',
        category: 'Excluding transgender youth from athletics',
        status_updated: 'Referred to committee',
        last_updated: '3/9/2021',
        status: 'Active',
        title:
          'Relating to requiring public school students to compete in interscholastic athletic competitions based on biological sex.',
      },
      {
        state: 'Texas',
        abbreviation: 'TX',
        bill_name: 'HB 1458',
        link: 'https://capitol.texas.gov/BillLookup/History.aspx?LegSess=87R&Bill=HB1458',
        category: 'Excluding transgender youth from athletics',
        status_updated: 'Referred to committee',
        last_updated: '3/5/2021',
        status: 'Active',
        title:
          '\tRelating to the separation based on biological sex of athletics teams sponsored by a public school or institution of higher education.',
      },
      {
        state: 'Texas',
        abbreviation: 'TX',
        bill_name: 'HB 3455',
        link: 'https://capitol.texas.gov/BillLookup/History.aspx?LegSess=87R&Bill=HB3455',
        category: 'Excluding transgender youth from athletics',
        status_updated: 'Referred to committee',
        last_updated: '3/22/2021',
        status: 'Active',
        title:
          'Relating to separation of students based on gender for athletics teams sponsored or authorized by a public school.',
      },
      {
        state: 'Texas',
        abbreviation: 'TX',
        bill_name: 'HB 4042',
        link: 'https://capitol.texas.gov/BillLookup/History.aspx?LegSess=87R&Bill=HB4042',
        category: 'Excluding transgender youth from athletics',
        status_updated: 'Referred to committee',
        last_updated: '4/20/2021',
        status: 'Active',
        title:
          'Relating to requiring public school students to participate in interscholastic athletic activities based on biological sex.',
      },
      {
        state: 'Texas',
        abbreviation: 'TX',
        bill_name: 'HB 4043',
        link: 'https://capitol.texas.gov/BillLookup/History.aspx?LegSess=87R&Bill=HB4043',
        category: 'Excluding transgender youth from athletics',
        status_updated: 'Referred to committee',
        last_updated: '3/29/2021',
        status: 'Active',
        title:
          'Relating to requiring public school students to participate in interscholastic athletic activities based on biological sex, including a report regarding university athletic participation based on biological sex.',
      },
      {
        state: 'Texas',
        abbreviation: 'TX',
        bill_name: 'HB 90',
        link: 'https://capitol.texas.gov/BillLookup/History.aspx?LegSess=871&Bill=HB90',
        category: 'Excluding transgender youth from athletics',
        status_updated: 'Filed',
        last_updated: '8/6/2021',
        status: 'Dead',
        title:
          'Relating to requiring public school students to participate in interscholastic athletic activities based on biological sex, including a report regarding university athletic participation based on biological sex.',
      },
      {
        state: 'Texas',
        abbreviation: 'TX',
        bill_name: 'HB 10',
        link: 'https://capitol.texas.gov/BillLookup/History.aspx?LegSess=87R&Bill=HB4042',
        category: 'Excluding transgender youth from athletics',
        status_updated: 'Referred to committee',
        last_updated: '9/27/2021',
        status: 'Active',
        title:
          'Relating to requiring public school students to participate in interscholastic athletic activities based on biological sex.',
      },
      {
        state: 'Texas',
        abbreviation: 'TX',
        bill_name: 'HB 25',
        link: 'https://capitol.texas.gov/BillLookup/History.aspx?LegSess=87R&Bill=HB4042',
        category: 'Excluding transgender youth from athletics',
        status_updated: 'Passed',
        last_updated: '10/25/2021',
        status: 'Passed',
        title:
          'Relating to requiring public school students to compete in interscholastic athletic competitions based on biological sex.',
      },
      {
        state: 'Utah',
        abbreviation: 'UT',
        bill_name: 'HB 302',
        link: 'https://le.utah.gov/~2021/bills/static/HB0302.html',
        category: 'Excluding transgender youth from athletics',
        status_updated: 'Sent to house of origin',
        last_updated: '3/5/2021',
        status: 'Dead',
        title: 'Preserving Sports For Female Athletes',
      },
      {
        state: 'Washington',
        abbreviation: 'WA',
        bill_name: 'HB 1556',
        link: 'https://app.leg.wa.gov/billsummary?BillNumber=1556&Initiative=false&Year=2021',
        category: 'Excluding transgender youth from athletics',
        status_updated: 'Introduced',
        last_updated: '8/9/2021',
        status: 'Active',
        title:
          'Concerning equitable competition between students who participate in school athletic activities',
      },
      {
        state: 'Wisconsin',
        abbreviation: 'WI',
        bill_name: 'AB 195',
        link: 'https://docs.legis.wisconsin.gov/2021/proposals/ab195',
        category: 'Excluding transgender youth from athletics',
        status_updated: 'Referred to committee in other chamber',
        last_updated: '6/21/2021',
        status: 'Active',
        title:
          'Relating to: designating University of Wisconsin and technical college sports and athletic teams based on the sex of the participants.',
      },
      {
        state: 'Wisconsin',
        abbreviation: 'WI',
        bill_name: 'AB 196',
        link: 'https://docs.legis.wisconsin.gov/2021/proposals/ab196',
        category: 'Excluding transgender youth from athletics',
        status_updated: 'Referred to committee in other chamber',
        last_updated: '6/21/2021',
        status: 'Active',
        title:
          'Relating to: designating athletic sports and teams operated or sponsored by public schools or private schools participating in a parental choice program based on the sex of the participants.',
      },
      {
        state: 'Wisconsin',
        abbreviation: 'WI',
        bill_name: 'SB 332',
        link: 'https://docs.legis.wisconsin.gov/2021/proposals/sb322',
        category: 'Excluding transgender youth from athletics',
        status_updated: 'Public hearing in other chamber',
        last_updated: '5/26/2021',
        status: 'Active',
        title:
          'Relating to: designating athletic sports and teams operated or sponsored by public schools or private schools participating in a parental choice program based on the sex of the participants.',
      },
      {
        state: 'Wisconsin',
        abbreviation: 'WI',
        bill_name: 'SB 323',
        link: 'https://docs.legis.wisconsin.gov/2021/proposals/sb323',
        category: 'Excluding transgender youth from athletics',
        status_updated: 'Public hearing in other chamber',
        last_updated: '5/26/2021',
        status: 'Active',
        title:
          'Relating to: designating University of Wisconsin and technical college sports and athletic teams based on the sex of the participants.',
      },
      {
        state: 'West Virginia',
        abbreviation: 'WV',
        bill_name: 'HB 2141',
        link: 'http://www.wvlegislature.gov/Bill_Status/Bills_history.cfm?input=2141&year=2021&sessiontype=RS&btype=bill',
        category: 'Excluding transgender youth from athletics',
        status_updated: 'Referred to committee',
        last_updated: '2/10/2021',
        status: 'Active',
        title: 'Relating to participation in school sports',
      },
      {
        state: 'West Virginia',
        abbreviation: 'WV',
        bill_name: 'HB 2676',
        link: 'http://www.wvlegislature.gov/Bill_Status/Bills_history.cfm?input=2676&year=2021&sessiontype=RS&btype=bill',
        category: 'Excluding transgender youth from athletics',
        status_updated: 'Referred to committee',
        last_updated: '2/23/2021',
        status: 'Active',
        title:
          'Relating to participation in athletic events exclusively for males and females',
      },
      {
        state: 'West Virginia',
        abbreviation: 'WV',
        bill_name: 'HB 2734',
        link: 'http://www.wvlegislature.gov/Bill_Status/Bills_history.cfm?input=2734&year=2021&sessiontype=RS&btype=bill',
        category: 'Excluding transgender youth from athletics',
        status_updated: 'Referred to committee',
        last_updated: '2/24/2021',
        status: 'Active',
        title:
          'Require all athletes to perform in sport of the sex they were born',
      },
      {
        state: 'West Virginia',
        abbreviation: 'WV',
        bill_name: 'HB 3292',
        link: 'http://www.wvlegislature.gov/Bill_Status/Bills_history.cfm?input=3293&year=2021&sessiontype=RS&btype=bill',
        category: 'Excluding transgender youth from athletics',
        status_updated: 'Referred to committee',
        last_updated: '4/28/2021',
        status: 'Passed',
        title:
          'Relating to single-sex participation in interscholastic athletic events',
      },
      {
        state: 'West Virginia',
        abbreviation: 'WV',
        bill_name: 'SB 341',
        link: 'http://www.wvlegislature.gov/Bill_Status/bills_history.cfm?INPUT=341&year=2021&sessiontype=RS',
        category: 'Excluding transgender youth from athletics',
        status_updated: 'Referred to committee',
        last_updated: '2/18/21',
        status: 'Active',
        title:
          "Requiring participation in athletic or sporting events be based on athlete's biological gender",
      },
    ],
  ],
  [
    'Prohibiting healthcare for transgender youth',
    [
      {
        state: 'Alabama',
        abbreviation: 'AL',
        bill_name: 'SB 10',
        link: 'http://alisondb.legislature.state.al.us/ALISON_LCC/SESSBillStatusResult.aspx?BILL=SB10&WIN_TYPE=BillResult',
        category: 'Prohibiting heathcare for transgender youth',
        status_updated: '2nd reading in other chamber',
        last_updated: '6/16/2021',
        status: 'Dead',
        title:
          'Vulnerable Child Compassion and Protection Act, prohibits gender change therapy for minors, prohibits withholding of certain related information from parents',
      },
      {
        state: 'Alabama',
        abbreviation: 'AL',
        bill_name: 'HB 1',
        link: 'http://alisondb.legislature.state.al.us/ALISON_LCC/SESSBillStatusResult.ASPX?BILL=HB1&WIN_TYPE=BillResult&SESSIONID=8787',
        category: 'Prohibiting healthcare for transgender youth',
        status_updated: '2nd reading',
        last_updated: '5/6/21',
        status: 'Postponed',
        title:
          'Vulnerable Child Compassion and Protection Act, prohibits gender change therapy for minors, prohibits withholding of certain related information from parents',
      },
      {
        state: 'Arkansas',
        abbreviation: 'AR',
        bill_name: 'SB 347',
        link: 'https://www.arkleg.state.ar.us/Bills/Detail?id=sb347&ddBienniumSession=2021%2F2021R&Search=',
        category: 'Prohibiting heathcare for transgender youth',
        status_updated: 'Referred to committee',
        last_updated: '10/15/21',
        status: 'Dead',
        title:
          'To Create The Vulnerable Child Protection Act; And To Prohibit Gender Reassignment Services For Minors. Register now for our free OneVote public service or GAITS Pro trial account and you can begin tracking this and other legislation, all driven by the real-time data of the LegiScan API.',
      },
      {
        state: 'Arkansas',
        abbreviation: 'AR',
        bill_name: 'HB 1570',
        link: 'https://www.arkleg.state.ar.us/Bills/Detail?id=HB1570&ddBienniumSession=2021%2F2021R&Search=',
        category: 'Prohibiting heathcare for transgender youth',
        status_updated: 'Passed',
        last_updated: '4/13/2021',
        status: 'Passed',
        title: 'Save Adolescents from Experimentation (SAFE) Act',
      },
      {
        state: 'Arizona',
        abbreviation: 'AZ',
        bill_name: 'SB 1511',
        link: 'https://legiscan.com/AZ/bill/SB1511/2021',
        category: 'Prohibiting heathcare for transgender youth',
        status_updated: '2nd reading',
        last_updated: '2/2/2021',
        status: 'Active',
        title: '(Untitled) Child; vulnerable adult; abuse',
      },
      {
        state: 'Florida',
        abbreviation: 'FL',
        bill_name: 'HB 935',
        link: 'https://www.flsenate.gov/Session/Bill/2021/0935/ByVersion',
        category: 'Prohibiting heathcare for transgender youth',
        status_updated: '1st reading',
        last_updated: '4/30/21',
        status: 'Dead',
        title:
          'Vulnerable Child Protection Act; provides criminal penalties for health care practitioners who engage in or cause specified practices to be performed on minor under certain conditions.',
      },
      {
        state: 'Florida',
        abbreviation: 'FL',
        bill_name: 'HB 211',
        link: 'https://www.flsenate.gov/Session/Bill/2022/0211/ByVersion',
        category: 'Prohibiting heathcare for transgender youth',
        status_updated: 'Filed',
        last_updated: '10/6/21',
        status: 'Prefiled',
        title:
          'Youth Gender and Sexual Identity; Provides criminal penalties for health care practitioners who engage in or cause specified practices to be performed on minor under certain conditions.',
      },
      {
        state: 'Georgia',
        abbreviation: 'GA',
        bill_name: 'HB 401',
        link: 'https://www.legis.ga.gov/legislation/59562',
        category: 'Prohibiting heathcare for transgender youth',
        status_updated: '2nd reading',
        last_updated: '2/16/2021',
        status: 'Active',
        title:
          '(Untitled) prohibit certain licensed, certified, or registered professionals from engaging in certain acts upon minors; to provide for a short title; to provide for definitions; to provide for exceptions; to provide for a penalty and private cause of action; to explicitly waive sovereign immunity, qualified immunity, and official immunity for such a private cause of action; to provide for related matters; to provide for an effective date and applicability; to repeal conflicting laws; and for other purposes.',
      },
      {
        state: 'Iowa',
        abbreviation: 'IA',
        bill_name: 'HF 193',
        link: 'https://www.legis.iowa.gov/legislation/BillBook?ga=89&ba=HF%20193&v=i',
        category: 'Prohibiting heathcare for transgender youth',
        status_updated: 'Referred to committee',
        last_updated: '1/22/2021',
        status: 'Active',
        title:
          "A bill for an act relating to actions relative to treatment or intervention regarding the discordance between a minor's sex and gender identity, and providing civil penalties.",
      },
      {
        state: 'Indiana',
        abbreviation: 'IN',
        bill_name: 'SB 224',
        link: 'http://iga.in.gov/legislative/2021/bills/senate/224',
        category: 'Prohibiting heathcare for transgender youth',
        status_updated: 'Referred to committee',
        last_updated: '2/23/2021',
        status: 'Dead',
        title:
          "Prohibited services relating to care of minors. Prohibits specified health care professionals from: (1) performing certain medical procedures on a minor; or (2) subjecting a minor to certain activities; that purposely attempt to change, reinforce, or affirm a minor's perception of the minor's own sexual attraction or sexual behavior, or attempt to change, reinforce, or affirm a minor's gender identity when the identity is inconsistent with the minor's biological sex.",
      },
      {
        state: 'Kansas',
        abbreviation: 'KS',
        bill_name: 'HB 2210',
        link: 'http://kslegislature.org/li/b2021_22/measures/hb2210/',
        category: 'Prohibiting heathcare for transgender youth',
        status_updated: 'Referred to committee',
        last_updated: '2/3/2021',
        status: 'Active',
        title:
          'Making it a crime for a doctor to perform gender reassignment surgery or hormone replacement therapy on minors.',
      },
      {
        state: 'Kentucky',
        abbreviation: 'KY',
        bill_name: 'HB 336',
        link: 'https://apps.legislature.ky.gov/record/21RS/hb336.html',
        category: 'Prohibiting heathcare for transgender youth',
        status_updated: 'Referred to committee',
        last_updated: '4/2/2021',
        status: 'Dead',
        title:
          'An Act relating to public protection: Create a new section of KRS Chapter 438 to prohibit medical professionals from performing surgeries or prescribing medications to a minor with the intent to alter the sex the child was assigned at birth and create a civil cause of action for violations; create a new section of KRS Chapter 405 regarding parental rights in the area of gender identity or gender dysphoria; create a new section of KRS Chapter 61 to create employee protections for public employees who express opinions regarding gender identity or gender dysphoria. ',
      },
      {
        state: 'Kentucky',
        abbreviation: 'KY',
        bill_name: 'HB 477',
        link: 'https://apps.legislature.ky.gov/record/21rs/hb477.html',
        category: 'Prohibiting heathcare for transgender youth',
        status_updated: 'Referred to committee',
        last_updated: '4/2/2021',
        status: 'Dead',
        title:
          'An Act relating to parental consent for transgender healthcare services: Amend KRS 214.185 to prohibit administering transgender healthcare services on a minor patient without the written consent of the parent or guardian.',
      },
      {
        state: 'Louisiana',
        abbreviation: 'LA',
        bill_name: 'HB 575',
        link: 'http://www.legis.la.gov/Legis/BillInfo.aspx?s=21RS&b=HB575&sbi=y',
        category: 'Prohibiting heathcare for transgender youth',
        status_updated: 'Referred to committee',
        last_updated: '4/12/2021',
        status: 'Active',
        title:
          "Restricts prescribing and dispensing of certain drugs to delay puberty and prohibits withholding of certain information from the minor's parent or legal guardian",
      },
      {
        state: 'Louisiana',
        abbreviation: 'LA',
        bill_name: 'SB 104',
        link: 'https://legis.la.gov/legis/BillInfo.aspx?s=21RS&b=SB104&sbi=y',
        category: 'Prohibiting heathcare for transgender youth',
        status_updated: 'Referred to committee in other chamber',
        last_updated: '4/12/2021',
        status: 'Active',
        title: 'Provides relative to gender therapy and minors.',
      },
      {
        state: 'Missouri',
        abbreviation: 'MO',
        bill_name: 'HB 33',
        link: 'https://www.house.mo.gov/BillContent.aspx?bill=HB33&year=2021&code=R',
        category: 'Prohibiting heathcare for transgender youth',
        status_updated: '2nd reading',
        last_updated: '5/12/2021',
        status: 'Dead',
        title:
          'AN ACT To amend chapter 191, RSMo, by adding thereto one new section relating to transition-related care for children under eighteen years of age, with penalty provisions.',
      },
      {
        state: 'Mississippi',
        abbreviation: 'MS',
        bill_name: 'SB 2171',
        link: 'http://billstatus.ls.state.ms.us/2021/pdf/history/SB/SB2171.xml',
        category: 'Prohibiting heathcare for transgender youth',
        status_updated: 'Referred to committee',
        last_updated: '2/2/2021',
        status: 'Dead',
        title:
          "The Transgender 21 Act: To Prohibit the State, Its Agents, and Political Subdivision from Infringing on a Parent's Right to Withhold Consent for Any Treatment, Activity or Mental Healthcare Services That Are Designed and Intended to Form Their Child's Conceptions of Sex and Gender or to Treat Gender Dysphoria or Gender Nonconformity; To Prohibit Certain Medical Procedures from Being Performed upon a Minor; To Provide a Penalty for Any Medical Profession Who Performs Certain Medical Procedures upon a Minor; To Provide a Good-faith Exception for a Minor Born with a Medically Verifiable Genetic Disorder of Sexual Development; To Prohibit Any State Agency, Political Subdivision or Licensing Organization from Forbidding, Penalizing or Taking Adverse Action Against an Individual Who Gives or Receives Counsel, Advice, Guidance or Other Speech or Communication Consistent with Conscience or Religious Belief; To Provide That No Individual Shall Be Discriminated Against for Providing Information about a Violation of This Act to Certain Authorities; To Provide a Private Cause of Action for Violations of This Act; To Preempt Political Subdivisions from Promulgating Rules or Regulations That Limits the Professional Conduct and Judgment of a Mental Healthcare Professional or Counselor; To Provide That the Provisions of This Act Are Severable; And for Related Purposes.",
      },
      {
        state: 'Montana',
        abbreviation: 'MT',
        bill_name: 'HB 113',
        link: 'https://laws.leg.mt.gov/legprd/LAW0210W%24BSIV.ActionQuery?P_BILL_NO1=113&P_BLTP_BILL_TYP_CD=HB&Z_ACTION=Find&P_SESS=20211',
        category: 'Prohibiting heathcare for transgender youth',
        status_updated: '3rd reading',
        last_updated: '4/29/2021',
        status: 'Dead',
        title:
          'An Act Providing for Youth Health Protection; Providing Definitions; Prohibiting Certain Medications and Medical Procedures for the Treatment of Gender Dysphoria in Minors; And Establishing a Civil Penalty for Health Care Providers Providing Prohibited Treatment',
      },
      {
        state: 'North Carolina',
        abbreviation: 'NC',
        bill_name: 'SB 514',
        link: 'https://www.ncleg.gov/BillLookup/2021/S514',
        category: 'Prohibiting heathcare for transgender youth',
        status_updated: 'Referred to committee',
        last_updated: '4/6/2021',
        status: 'Active',
        title:
          'An Act to Protect Minors from Administration of Puberty Blockers and Cross-sex Hormones and Other Related Actions, Procedures, and Treatments.',
      },
      {
        state: 'Oklahoma',
        abbreviation: 'OK',
        bill_name: 'SB 583',
        link: 'http://www.oklegislature.gov/BillInfo.aspx?Bill=SB583&session=2100',
        category: 'Prohibiting heathcare for transgender youth',
        status_updated: 'Referred to committee',
        last_updated: '2/2/2021',
        status: 'Active',
        title:
          'An Act relating to health services for minors; prohibiting certain medical treatment for minors; providing penalty; defining term; providing for codification; and providing an effective date.',
      },
      {
        state: 'Oklahoma',
        abbreviation: 'OK',
        bill_name: 'SB 676',
        link: 'http://www.oklegislature.gov/BillInfo.aspx?Bill=SB676&session=2100',
        category: 'Prohibiting heathcare for transgender youth',
        status_updated: 'Referred to committee',
        last_updated: '2/2/2021',
        status: 'Active',
        title:
          'An Act relating to crime and punishment; making certain medical treatment unlawful; providing definition; providing for certain penalty; providing for codification; and providing an effective date.',
      },
      {
        state: 'South Carolina',
        abbreviation: 'SC',
        bill_name: 'HB 3477',
        link: 'https://www.scstatehouse.gov/sess124_2021-2022/bills/3477.htm',
        category: 'Prohibiting heathcare for transgender youth',
        status_updated: '1st reading',
        last_updated: '6/7/2021',
        status: 'Active',
        title: "Save Women's Sports Act",
      },
      {
        state: 'South Carolina',
        abbreviation: 'SC',
        bill_name: 'HB 4047',
        link: 'https://www.scstatehouse.gov/sess124_2021-2022/bills/4047.htm',
        category: 'Prohibiting heathcare for transgender youth',
        status_updated: 'Referred to committee',
        last_updated: '3/9/2021',
        status: 'Active',
        title:
          "South Carolina Vulnerable Child Compassion and Protection Act: Prohibit the Performance of a Medical Procedure or the Prescription or Issuance of Medication, upon or to a Minor, That Is Intended to Alter the Appearance of the Minor's Gender or Delay Puberty, with Exceptions; To Create Criminal Penalties; And for Other Purposes.",
      },
      {
        state: 'Tennessee',
        abbreviation: 'TN',
        bill_name: 'SB 657',
        link: 'https://legiscan.com/TN/bill/SB0657/2021',
        category: 'Prohibiting heathcare for transgender youth',
        status_updated: 'Referred to committee',
        last_updated: '2/11/2021',
        status: 'Active',
        title:
          'An Act to amend Tennessee Code Annotated, Title 8; Title 36; Title 37; Title 39; Title 49; Title 53; Title 56; Title 63; Title 68 and Title 71, relative to courses of treatment for children.',
      },
      {
        state: 'Tennessee',
        abbreviation: 'TN',
        bill_name: 'HB 578',
        link: 'https://legiscan.com/TN/bill/HB0578/2021',
        category: 'Prohibiting heathcare for transgender youth',
        status_updated: 'Public hearing',
        last_updated: '4/14/2021',
        status: 'Active',
        title:
          'An Act to amend Tennessee Code Annotated, Title 8; Title 36; Title 37; Title 39; Title 49; Title 53; Title 56; Title 63; Title 68 and Title 71, relative to courses of treatment for children.',
      },
      {
        state: 'Texas',
        abbreviation: 'TX',
        bill_name: 'HB 68',
        link: 'https://capitol.texas.gov/BillLookup/History.aspx?LegSess=87R&Bill=HB68',
        category: 'Prohibiting heathcare for transgender youth',
        status_updated: 'Referred to committee',
        last_updated: '2/25/2021',
        status: 'Active',
        title: 'Relating to the definition of abuse of a child.',
      },
      {
        state: 'Texas',
        abbreviation: 'TX',
        bill_name: 'HB 1339',
        link: 'https://capitol.texas.gov/BillLookup/History.aspx?LegSess=87R&Bill=HB1399',
        category: 'Prohibiting heathcare for transgender youth',
        status_updated: '2nd reading',
        last_updated: '5/12/2021',
        status: 'Active',
        title:
          'Relating to professional liability insurance coverage for and prohibitions on the provision to certain children of procedures and treatments for gender transitioning, gender reassignment, or gender dysphoria.',
      },
      {
        state: 'Texas',
        abbreviation: 'TX',
        bill_name: 'SB 16446',
        link: 'https://capitol.texas.gov/BillLookup/History.aspx?LegSess=87R&Bill=SB1646',
        category: 'Prohibiting heathcare for transgender youth',
        status_updated: 'Sent to other chamber',
        last_updated: '5/3/2021',
        status: 'Active',
        title: 'Relating to the definition of abuse of a child.',
      },
      {
        state: 'Utah',
        abbreviation: 'UT',
        bill_name: 'HB 92',
        link: 'https://le.utah.gov/~2021/bills/static/HB0092.html',
        category: 'Prohibiting heathcare for transgender youth',
        status_updated: 'Public hearing',
        last_updated: '3/5/2021',
        status: 'Dead',
        title:
          'This bill prohibits a physician or surgeon from performing a transgender procedure on a minor.',
      },
      {
        state: 'West Virginia',
        abbreviation: 'WV',
        bill_name: 'HB 2171',
        link: 'http://www.wvlegislature.gov/Bill_Status/Bills_history.cfm?input=2171&year=2021&sessiontype=RS&btype=bill',
        category: 'Prohibiting heathcare for transgender youth',
        status_updated: 'Referred to committee',
        last_updated: '2/10/2021',
        status: 'Active',
        title:
          "Vulnerable Child Protection Act: relating to the prohibition of certain medical treatments and procedures upon a minor, including an emancipated minor, for the purpose of attempting to change or affirm the minor's perception of the minor's sex, if that perception is inconsistent with the minor's genetic sex at birth and making such medical treatments and procedures a felony unless specific exceptions exist as defined.",
      },
    ],
  ],
  [
    'Restrictions on bathroom use',
    [
      {
        state: 'Arkansas',
        abbreviation: 'AR',
        bill_name: 'HB 1882',
        link: 'https://legiscan.com/AR/bill/HB1882/2021',
        category: 'Restrictions on bathroom use',
        status_updated: '2nd reading',
        last_updated: '4/6/2021',
        status: 'Active',
        title: 'Arkansas Privacy Accomodations Act',
      },
      {
        state: 'Arkansas',
        abbreviation: 'AR',
        bill_name: 'HB 1905',
        link: 'https://legiscan.com/AR/bill/HB1905/2021',
        category: 'Restrictions on bathroom use',
        status_updated: '2nd reading',
        last_updated: '4/12/2021',
        status: 'Active',
        title: 'Concerning the offense of indecent exposure',
      },
      {
        state: 'Tennessee',
        abbreviation: 'TN',
        bill_name: 'HB 1223',
        link: 'https://wapp.capitol.tn.gov/apps/BillInfo/default.aspx?BillNumber=HB1233&ga=112',
        category: 'Restrictions on bathroom use',
        status_updated: 'Passed',
        last_updated: '4/12/2021',
        status: 'Passed',
        title: 'Bathroom bill',
      },
      {
        state: 'Tennessee',
        abbreviation: 'TN',
        bill_name: 'HB 1182',
        link: 'https://wapp.capitol.tn.gov/apps/BillInfo/default.aspx?BillNumber=HB1182&ga=112',
        category: 'Restrictions on bathroom use',
        status_updated: 'Passed',
        last_updated: '5/17/2021',
        status: 'Passed',
        title: 'Business bathroom bill',
      },
    ],
  ],
  [
    'Restrictions on government recognition of gender identity',
    [
      {
        state: 'Arkansas',
        abbreviation: 'AR',
        bill_name: 'HB 1749',
        link: 'https://www.arkleg.state.ar.us/Bills/Detail?id=HB1749&ddBienniumSession=2021%2F2021R&Search=',
        category: 'Restrictions on government recognition of gender identity',
        status_updated: '2nd reading in other chamber',
        last_updated: '10/15/2021',
        status: 'Dead',
        title:
          "To Prohibit Requiring Public School and Institution of Higher Education Employees from Addressing a Student by a Term That Identifies a Student as Male or Female and That Is Inconsistent with the Student's Biological Sex.",
      },
      {
        state: 'Iowa',
        abbreviation: 'IA',
        bill_name: 'HF 272',
        link: 'https://www.legis.iowa.gov/legislation/BillBook?ga=89&ba=HF%20272&v=i',
        category: 'Restrictions on government recognition of gender identity',
        status_updated: 'Introduced',
        last_updated: '2/2/2021',
        status: 'Active',
        title:
          'An Act removing gender identity as a protected class under the Iowa Civil Rights Act.',
      },
      {
        state: 'North Dakota',
        abbreviation: 'ND',
        bill_name: 'HB 1476',
        link: 'https://www.legis.nd.gov/assembly/67-2021/bill-actions/ba1476.html',
        category: 'Restrictions on government recognition of gender identity',
        status_updated: 'Referred to committee',
        last_updated: '1/21/2021',
        status: 'Dead',
        title:
          'A Bill for an Act to create and enact a new chapter to title 14 of the North Dakota Century Code, relating to nonsecular self-asserted sex-based identity narratives, to prohibit the state from creating or enforcing policies that directly or symbolically respect nonsecular self-asserted sex-based identity narratives or sexual orientation orthodoxy pursuant to the establishment clause of the First Amendment to the United States Constitution and section 3 of article I of the Constitution of North Dakota; to provide for the continued enforcement of secular marriage policies; to prohibit discrimination for nonsecular beliefs pursuant to the free exercise clause of the First Amendment to the United States Constitution and section 3 of article I of the Constitution of North Dakota. ',
      },
    ],
  ],
  [
    'Restrictions on identification documents',
    [
      {
        state: 'Montana',
        abbreviation: 'MT',
        bill_name: 'LC 2997',
        link: 'http://laws.leg.mt.gov/legprd/LAW0203W%24BSRV.ActionQuery?P_SESS=20211&P_BLTP_BILL_TYP_CD=&P_BILL_NO=&P_BILL_DFT_NO=lc2997&P_CHPT_NO=&Z_ACTION=Find&P_ENTY_ID_SEQ2=&P_SBJT_SBJ_CD=&P_ENTY_ID_SEQ=',
        category: 'Restrictions on identification documents',
        status_updated: 'Passed',
        last_updated: '4/9/2021',
        status: 'Passed',
        title:
          'An Act Revising Vital Statistics Laws Regarding the Amendment of Birth Certificate Sex Designations and the Issuance of Replacement Birth Certificates; Providing That the Department of Public Health and Human Services May Amend a Birth Certificate Sex Designation Only on Receipt of a Court Order Indicating That the Sex of a Person Has Been Changed by Surgical Procedure; Directing the Department of Health and Human Services to Amend Administrative Rules in Conformity with This Act; And Providing an Immediate Effective Date and an Applicability Date.',
      },
      {
        state: 'South Dakota',
        abbreviation: 'SD',
        bill_name: 'HB 1076',
        link: 'https://sdlegislature.gov/Session/Bill/22138',
        category: 'Restrictions on identification documents',
        status_updated: 'Referred to committee in other chamber',
        last_updated: '2/5/2021',
        status: 'Dead',
        title: 'Require that birth certificates reflect biological sex.',
      },
    ],
  ],
  [
    'Restrictions on sex education',
    [
      {
        state: 'Arkansas',
        abbreviation: 'AR',
        bill_name: 'SB 389',
        link: 'https://www.arkleg.state.ar.us/Bills/Detail?ddBienniumSession=2021%2F2021R&measureno=SB389',
        category: 'Restrictions on sex education',
        status_updated: 'Sent to governor',
        last_updated: '4/1/2021',
        status: 'Active',
        title:
          'TO REQUIRE A PUBLIC SCHOOL TO PROVIDE TO PARENTS AND LEGAL GUARDIANS PRIOR WRITTEN NOTIFICATION OF AND AN OPPORTUNITY TO INSPECT MATERIALS RELATED TO SEX EDUCATION, SEXUAL ORIENTATION, AND GENDER IDENTITY',
      },
      {
        state: 'Arizona',
        abbreviation: 'AZ',
        bill_name: 'HB 2710',
        link: 'https://apps.azleg.gov/BillStatus/BillOverview/75488?SessionId=123',
        category: 'Restrictions on sex education',
        status_updated: '2nd reading',
        last_updated: '2/3/2021',
        status: 'Active',
        title: '(Untitled) sex education; child abuse prevention',
      },
      {
        state: 'Indiana',
        abbreviation: 'IN',
        bill_name: 'SB 288',
        link: 'http://iga.in.gov/legislative/2021/bills/senate/288',
        category: 'Restrictions on sex education',
        status_updated: '2nd reading',
        last_updated: '2/22/2021',
        status: 'Active',
        title:
          'Removes schools and certain public libraries from the list of entities eligible for a specified defense to criminal prosecutions alleging: (1) the dissemination of material harmful to minors; or (2) a performance harmful to minors. Adds colleges and universities to the list of entities eligible for a specified defense to criminal prosecutions alleging: (1) the dissemination of material harmful to minors; or (2) a performance harmful to minors.',
      },
      {
        state: 'Iowa',
        abbreviation: 'IA',
        bill_name: 'SF 167',
        link: 'https://www.legis.iowa.gov/legislation/BillBook?ga=89&ba=SF%20167&v=i',
        category: 'Restrictions on sex education',
        status_updated: 'Referred to committee',
        last_updated: '2/16/2021',
        status: 'Active',
        title:
          'An Act Relating to Instruction Relating to Gender Identity in the Curriculum Provided to Students Enrolled in Elementary Education Programs.',
      },
      {
        state: 'Iowa',
        abbreviation: 'IA',
        bill_name: 'HF 326',
        link: 'https://www.legis.iowa.gov/legislation/BillBook?ga=89&ba=HF%20326&v=i',
        category: 'Restrictions on sex education',
        status_updated: 'Referred to committee',
        last_updated: '2/2/2021',
        status: 'Active',
        title:
          'An Act Relating to Curricula Offered by School Districts that Include Instruction Relating to Gender Identity.',
      },
      {
        state: 'Missouri',
        abbreviation: 'MO',
        bill_name: 'HB 786',
        link: 'https://legiscan.com/MO/bill/HB786/2021',
        category: 'Restrictions on sex education',
        status_updated: 'Introduced',
        last_updated: '3/4/2021',
        status: 'Active',
        title: 'Instruction in human sexuality in schools',
      },
      {
        state: 'Oklahoma',
        abbreviation: 'OK',
        bill_name: 'SB 615',
        link: 'http://www.oklegislature.gov/BillInfo.aspx?Bill=SB615&session=2100',
        category: 'Restrictions on sex education',
        status_updated: '2nd reading',
        last_updated: '2/24/2021',
        status: 'Active',
        title:
          'Requiring curriculum and materials used in certain meeting with counselor to be available for inspection.',
      },
    ],
  ],
  [
    'Religious Freedom Restoration Acts',
    [
      {
        state: 'Arkansas',
        abbreviation: 'AR',
        bill_name: 'HJR 1024',
        link: 'https://www.arkleg.state.ar.us/Bills/Detail?ddBienniumSession=2021%2F2021R&measureno=SJR14',
        category: 'Religious Freedom Restoration Acts',
        status_updated: 'Sent to governor',
        last_updated: '2/10/2021',
        status: 'Active',
        title: 'Arkansas Religious Freedom Amendment',
      },
      {
        state: 'Iowa',
        abbreviation: 'IA',
        bill_name: 'HF 170',
        link: 'https://www.legis.iowa.gov/legislation/BillBook?ga=89&ba=HF%20170&v=i',
        category: 'Religious Freedom Restoration Acts',
        status_updated: 'Introduced',
        last_updated: '1/22/2021',
        status: 'Active',
        title:
          'Protecting freedom of conscience from government discrimination act',
      },
      {
        state: 'Iowa',
        abbreviation: 'IA',
        bill_name: 'SF 437',
        link: 'https://www.legis.iowa.gov/legislation/BillBook?ga=89&ba=SF%20436&v=i',
        category: 'Religious Freedom Restoration Acts',
        status_updated: 'Introduced',
        last_updated: '3/2/2021',
        status: 'Active',
        title:
          'Act relating to the standard of judicial review and providing a claim or defense when a state action burdens a person’s exercise of religion,and including effective date provisions.',
      },
      {
        state: 'Indiana',
        abbreviation: 'IN',
        bill_name: 'HB 1503',
        link: 'https://www.legis.iowa.gov/legislation/BillBook?ga=89&ba=SF%20436&v=i',
        category: 'Religious Freedom Restoration Acts',
        status_updated: '1st reading',
        last_updated: '3/2/2021',
        status: 'Dead',
        title: 'Religious freedom restoration',
      },
      {
        state: 'Montana',
        abbreviation: 'MT',
        bill_name: 'SB 215',
        link: 'http://laws.leg.mt.gov/legprd/law0210W%24BSIV.ActionQuery?P_BILL_DFT_NO5=LC2836&Z_ACTION=Find&P_Sess=20211',
        category: 'Religious Freedom Restoration Acts',
        status_updated: 'Passed',
        last_updated: '4/22/2021',
        status: 'Passed',
        title: 'Religious freedom restoration act',
      },
      {
        state: 'New Hampshire',
        abbreviation: 'NH',
        bill_name: 'HB 440',
        link: 'https://legiscan.com/NH/bill/HB440/2021',
        category: 'Religious Freedom Restoration Acts',
        status_updated: 'Referred to committee in other chamber',
        last_updated: '5/25/2021',
        status: 'Active',
        title:
          'Prohibiting the suspension of civil liberties during a state of emergency',
      },
      {
        state: 'South Dakota',
        abbreviation: 'SD',
        bill_name: 'SB 124',
        link: 'https://sdlegislature.gov/Session/Bill/22127',
        category: 'Religious Freedom Restoration Acts',
        status_updated: 'Referred to committee in other chamber',
        last_updated: '3/10/2021',
        status: 'Passed',
        title: 'Provide protections for the exercise of religious freedom',
      },
      {
        state: 'West Virginia',
        abbreviation: 'WV',
        bill_name: 'HB 2545',
        link: 'http://www.wvlegislature.gov/Bill_Status/Bills_history.cfm?input=2545&year=2021&sessiontype=RS&btype=bill',
        category: 'Religious Freedom Restoration Acts',
        status_updated: 'Referred to committee',
        last_updated: '2/16/2021',
        status: 'Active',
        title: 'Religious Freedom Restoration Act',
      },
      {
        state: 'Wyoming',
        abbreviation: 'WY',
        bill_name: 'HB 264',
        link: 'https://wyoleg.gov/Legislation/2021/HB0264',
        category: 'Religious Freedom Restoration Acts',
        status_updated: 'Introduced',
        last_updated: '3/8/2021',
        status: 'Active',
        title: 'Religious freedom restoration act',
      },
    ],
  ],
  [
    'Religious Exemptions in Healthcare Implicating LGBTQ People',
    [
      {
        state: 'Arkansas',
        abbreviation: 'AR',
        bill_name: 'SB 289',
        link: 'https://www.arkleg.state.ar.us/Bills/Detail?ddBienniumSession=2021%2F2021R&measureno=SB289',
        category: 'Religious Exemptions in Healthcare Implicating LGBTQ People',
        status_updated: 'Passed',
        last_updated: '3/29/2021',
        status: 'Passed',
        title: 'Medical Ethics and Diversity Act',
      },
      {
        state: 'Kentucky',
        abbreviation: 'KY',
        bill_name: 'SB 83',
        link: 'https://apps.legislature.ky.gov/record/21rs/sb83.html',
        category: 'Religious Exemptions in Healthcare Implicating LGBTQ People',
        status_updated: '2nd reading',
        last_updated: '4/2/2021',
        status: 'Dead',
        title:
          'AN ACT protecting the exercise of medical ethics and diversity within the medical profession',
      },
      {
        state: 'Montana',
        abbreviation: 'MT',
        bill_name: 'SB 245',
        link: 'https://laws.leg.mt.gov/legprd/LAW0210W%24BSIV.ActionQuery?P_BILL_NO1=245&P_BLTP_BILL_TYP_CD=SB&Z_ACTION=Find&P_SESS=20211',
        category: 'Religious Exemptions in Healthcare Implicating LGBTQ People',
        status_updated: '2nd reading',
        last_updated: '4/29/2021',
        status: 'Dead',
        title: 'Implement medical ethics and diversity act',
      },
      {
        state: 'North Carolina',
        abbreviation: 'NC',
        bill_name: 'SB 515',
        link: 'https://www.ncleg.gov/BillLookup/2021/S515',
        category: 'Religious Exemptions in Healthcare Implicating LGBTQ People',
        status_updated: 'Referred to committee',
        last_updated: '4/6/2021',
        status: 'Active',
        title: 'Health Care Heroes Conscience Protection Act',
      },
      {
        state: 'New Mexico',
        abbreviation: 'NM',
        bill_name: 'SB 323',
        link: 'https://www.nmlegis.gov/Legislation/Legislation?chamber=S&legtype=B&legno=323&year=21',
        category: 'Religious Exemptions in Healthcare Implicating LGBTQ People',
        status_updated: 'Introduced',
        last_updated: '2/1/2021',
        status: 'Active',
        title: 'Health Care Workers Protection Act',
      },
      {
        state: 'South Carolina',
        abbreviation: 'SC',
        bill_name: 'HB 3518',
        link: 'https://www.scstatehouse.gov/sess124_2021-2022/bills/3518.htm',
        category: 'Religious Exemptions in Healthcare Implicating LGBTQ People',
        status_updated: '1st reading',
        last_updated: '1/12/2021',
        status: 'Active',
        title: 'Medical Ethics and Diversity Act',
      },
      {
        state: 'South Dakota',
        abbreviation: 'SD',
        bill_name: 'HB 1247',
        link: 'https://sdlegislature.gov/Session/Bill/22444',
        category: 'Religious Exemptions in Healthcare Implicating LGBTQ People',
        status_updated: '1st reading',
        last_updated: '2/24/2021',
        status: 'Active',
        title:
          'Provide for the protection of the consciences of medical professionals',
      },
      {
        state: 'Texas',
        abbreviation: 'TX',
        bill_name: 'HB 1424',
        link: 'https://capitol.texas.gov/BillLookup/History.aspx?LegSess=87R&Bill=HB1424',
        category: 'Religious Exemptions in Healthcare Implicating LGBTQ People',
        status_updated: 'Introduced',
        last_updated: '5/13/2021',
        status: 'Active',
        title:
          'Protection of persons from participation in a health care service for reasons of conscience; providing a civil remedy; authorizing disciplinary action',
      },
      {
        state: 'Texas',
        abbreviation: 'TX',
        bill_name: 'SB 1674',
        link: 'https://capitol.texas.gov/BillLookup/History.aspx?LegSess=87R&Bill=SB1674',
        category: 'Religious Exemptions in Healthcare Implicating LGBTQ People',
        status_updated: '1st reading',
        last_updated: '3/24/2021',
        status: 'Active',
        title:
          'Protection of individuals from participation in a health care service for reasons of conscience; providing a civil remedy; authorizing disciplinary action',
      },
      {
        state: 'Texas',
        abbreviation: 'TX',
        bill_name: 'SB17',
        link: 'https://capitol.texas.gov/BillLookup/History.aspx?LegSess=871&Bill=SB17',
        category: 'Religious Exemptions in Healthcare Implicating LGBTQ People',
        status_updated: 'Filed',
        last_updated: '7/7/2021',
        status: 'Active',
        title:
          'Relating to protection of individuals from participation in a health care service for reasons of conscience; providing a civil remedy; authorizing disciplinary action.',
      },
    ],
  ],
  [
    'Religious Exemptions in Adoption & Foster Care',
    [
      {
        state: 'Iowa',
        abbreviation: 'IA',
        bill_name: 'HF 170',
        link: 'https://www.legis.iowa.gov/legislation/BillBook?ga=89&ba=HF%20170&v=i',
        category: 'Religious Exemptions in Adoption & Foster Care',
        status_updated: 'Introduced',
        last_updated: '1/22/2021',
        status: 'Active',
        title:
          'Protection freedom of conscience from government discrimination act',
      },
      {
        state: 'Kentucky',
        abbreviation: 'KY',
        bill_name: 'HB 524',
        link: 'https://apps.legislature.ky.gov/record/21rs/hb524.html',
        category: 'Religious Exemptions in Adoption & Foster Care',
        status_updated: 'Introduced',
        last_updated: '4/2/2021',
        status: 'Dead',
        title:
          'Rights of conscience for child-placing agencies and child-caring facilities',
      },
      {
        state: 'South Carolina',
        abbreviation: 'SC',
        bill_name: 'H 3878',
        link: 'https://www.scstatehouse.gov/billsearch.php?billnumbers=3878&session=124&summary=B',
        category: 'Religious Exemptions in Adoption & Foster Care',
        status_updated: 'Referred to committee',
        last_updated: '2/11/2021',
        status: 'Active',
        title: 'Child Welfare Providers Protection Act',
      },
      {
        state: 'West Virginia',
        abbreviation: 'WV',
        bill_name: 'SB 13',
        link: 'http://www.wvlegislature.gov/Bill_Status/bills_history.cfm?year=2021&sessiontype=RS&input=13',
        category: 'Religious Exemptions in Adoption & Foster Care',
        status_updated: 'Referred to committee',
        last_updated: '2/10/2021',
        status: 'Active',
        title:
          'Protecting rights of conscience for child welfare services providers',
      },
    ],
  ],
  [
    'Religious Exemptions in Schools and Student Organizations',
    [
      {
        state: 'Missouri',
        abbreviation: 'MO',
        bill_name: 'SB 332',
        link: 'https://www.senate.mo.gov/21info/BTS_Web/Bill.aspx?SessionType=R&BillID=55156310',
        category: 'Religious Exemptions in Schools and Student Organizations',
        status_updated: '2nd reading',
        last_updated: '2/4/2021',
        status: 'Active',
        title:
          "Prohibits public institutions of higher learning from taking certain adverse actions against belief-based student associations based on such association's belief-related leadership requirements",
      },
    ],
  ],
  [
    'Other Religious Exemption Bills',
    [
      {
        state: 'Iowa',
        abbreviation: 'IA',
        bill_name: 'HF 170',
        link: 'https://www.legis.iowa.gov/legislation/BillBook?ga=89&ba=HF%20170&v=i',
        category: 'Other Religious Exemption Bills',
        status_updated: 'Introduced',
        last_updated: '1/22/2021',
        status: 'Active',
        title:
          'A bill for an act establishing the protecting freedom of conscience from government discrimination Act, and including effective date provisions',
      },
      {
        state: 'Massachusetts',
        abbreviation: 'MA',
        bill_name: 'HD 3468',
        link: 'https://malegislature.gov/Bills/192/HD3468',
        category: 'Other Religious Exemption Bills',
        status_updated: 'Introduced',
        last_updated: '1/22/2021',
        status: 'Active',
        title:
          'Protect the Religious Beliefs and Moral Convictions of Citizens of the Commonwealth',
      },
      {
        state: 'North Dakota',
        abbreviation: 'ND',
        bill_name: 'HB 1476',
        link: 'https://www.legis.nd.gov/assembly/67-2021/bill-actions/ba1476.html',
        category: 'Other Religious Exemption Bills',
        status_updated: 'Introduced',
        last_updated: '1/22/2021',
        status: 'Active',
        title:
          '[...] relating to nonsecular self‑asserted sex‑based identity narratives, to prohibit the state from creating or enforcing policies that directly or symbolically respect nonsecular self‑asserted sex‑based identity narratives or sexual orientation orthodoxy pursuant to the establishment clause of the First Amendment to the United States Constitution and section 3 of article I of the Constitution of North Dakota; to provide for the continued enforcement of secular marriage policies; to prohibit discrimination for nonsecular beliefs pursuant to the free exercise clause of the First Amendment to the United States Constitution and section 3 of article I of the Constitution of North Dakota.',
      },
    ],
  ],
  [
    'Bills Pre-Empting Local Protections',
    [
      {
        state: 'Tennessee',
        abbreviation: 'TN',
        bill_name: 'SB 1208',
        link: 'https://wapp.capitol.tn.gov/apps/BillInfo/default.aspx?BillNumber=SB1208&ga=112',
        category: 'Bills Pre-Empting Local Protections',
        status_updated: '2nd reading',
        last_updated: '2/22/2021',
        status: 'Active',
        title:
          'Religion and Religious Organizations - As introduced, prohibits this state and any political subdivision of this state, or any official of this state or a political subdivision, from creating, enforcing, or endorsing policies that respect or promote non-secular self-asserted sex-based identity narratives, sexual orientation orthodoxy, or non-secular marriage doctrine because the policies fail the Lemon test, as established by the United States Supreme Court in Lemon v. Kurtzman, 403 U.S. 602 (1971). - Amends TCA Title 3; Title 4 and Title 36.',
      },
      {
        state: 'Tennessee',
        abbreviation: 'TN',
        bill_name: 'SB 1229',
        link: 'http://wapp.capitol.tn.gov/apps/BillInfo/default.aspx?BillNumber=SB1229&ga=112',
        category: 'Bills Pre-Empting Local Protections',
        status_updated: 'Passed',
        last_updated: '5/3/2021',
        status: 'Passed',
        title:
          "Education, Curriculum - As enacted, requires an LEA or public charter school to notify a student's parent or guardian prior to commencing instruction of a sexual orientation or gender identity curriculum; permits a parent or guardian to excuse the parent's or guardian's student from a sexual orientation or gender identity curriculum, and prohibits the LEA or charter school from penalizing an excused student. - Amends TCA Title 49, Chapter 6, Part 13.",
      },
      {
        state: 'Wisconsin',
        abbreviation: 'WI',
        bill_name: 'AB 14',
        link: 'https://docs.legis.wisconsin.gov/2021/proposals/ab14',
        category: 'Bills Pre-Empting Local Protections',
        status_updated: 'Referred to committee',
        last_updated: '3/16/2021',
        status: 'Active',
        title:
          'Rules defining unprofessional conduct by marriage and family therapists, professional counselors, and social workers.',
      },
      {
        state: 'Wisconsin',
        abbreviation: 'WI',
        bill_name: 'SB 31',
        link: 'https://docs.legis.wisconsin.gov/2021/proposals/sb31',
        category: 'Bills Pre-Empting Local Protections',
        status_updated: 'Referred to committee',
        last_updated: '3/16/2021',
        status: 'Active',
        title:
          'Rules defining unprofessional conduct by marriage and family therapists, professional counselors, and social workers.',
      },
    ],
  ],
];
