import AllBillsProgress from './AllBillsProgress';
import NivoBillsByYear from './NivoBillsByYear';
import { antiTransBillsByYear } from './../data/hrc-data';

export default function AllBills({ allBills }) {
  const dateDescendingData = antiTransBillsByYear.sort(
    (a, b) => parseInt(a.year) - parseInt(b.year)
  );

  return (
    <div className="slim-view">
      <div className="section-wrapper">
        <div className="section-wrapper">
          <h1>
            <span className="orange-text">{allBills.length}</span> anti-trans
            bills
          </h1>
          <p>
            In 2021, twice as many bills were introduced than in 2020. That’s 6
            times more than 2015, the year that introduced so-called “bathroom
            bills” to the U.S. The first two years of that legislative push
            resulted in the passage of only one bathroom bill, North Carolina’s{' '}
            <a
              href="https://www.ncleg.net/Sessions/2015E2/Bills/House/PDF/H2v1.pdf"
              target="_blank"
              rel="noreferrer"
            >
              HB2
            </a>
            , and the subsequent public outcry and business boycotts led to its{' '}
            <a
              href="https://www.cnn.com/2017/03/30/politics/north-carolina-hb2-agreement/index.html"
              target="_blank"
              rel="noreferrer"
            >
              repeal
            </a>
            .
          </p>
          <div
            style={{
              height: '300px',
              maxHeight: '500px',
              width: '100%',
              padding: '5px',
              marginTop: '30px',
            }}
          >
            <NivoBillsByYear data={dateDescendingData} />
          </div>
          <p className="data-citation">
            <i>
              {' '}
              <a href="https://www.hrc.org/" target="_blank" rel="noreferrer">
                Human Rights Campaign
              </a>{' '}
              data
            </i>
          </p>
          <p>
            Compare this to 2021, which has already seen 16 bills pass. This
            includes a “bathroom bill” in Tennessee{' '}
            <a
              href="https://www.capitol.tn.gov/Bills/112/Bill/HB1182.pdf"
              target="_blank"
              rel="noreferrer"
            >
              (HB 1182)
            </a>{' '}
            and other laws in Arkansas, Montana, West Virginia, and South Dakota
            that block trans access to healthcare, athletics, and government
            identification. Other bills explicitly allow religious organizations
            the right to discriminate against LGBT people or preemptively block
            local governments from creating new anti-discrimination protections.
          </p>
          <AllBillsProgress allBills={allBills} />
        </div>
      </div>
    </div>
  );
}
