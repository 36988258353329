import React, { useState, useRef } from 'react';
import Progress from './Progress';
import { stages } from '../data/stages';
import { getProgressColor } from './utils';

export default function AllBillsProgress({ allBills }) {
  const [isOpen, setIsOpen] = useState(false);
  const topRef = useRef(null);

  const bills = allBills.map((bill) => {
    if (bill.status === 'Passed') return { ...bill, billStagePercent: 100 };

    const stage = bill?.status_updated;
    const billStageIndex = stages.indexOf(stage);
    const billStagePercent = (((billStageIndex + 1) / 16) * 100).toFixed(0);

    return { ...bill, billStagePercent };
  });

  const sortedBills = bills.sort(
    (a, b) => b.billStagePercent - a.billStagePercent
  );

  const classes = isOpen
    ? 'all-bills-container all-bills-container-open'
    : 'all-bills-container all-bills-container-closed';

  return (
    <div ref={topRef}>
      <div className={classes}>
        <div className="progress-bar-chart-label">
          <h2 className="light-black process-bar-chat-description">
            All {allBills.length} bills
          </h2>
          <p>
            <b>progress towards becoming law</b>
          </p>
        </div>
        {sortedBills.map((bill) => (
          <div className="all-bills-row" key={`${bill.name}-${bill.title}`}>
            <div className="all-bills-column all-bills-left">
              <p style={{ textAlign: 'left', minWidth: '350px' }}>
                {bill.state} {bill.bill_name}{' '}
                {bill.status === 'Dead' ? '(died)' : ''}
                {bill.status === 'Passed' ? '(passed)' : ''}
                {bill.status === 'Postponed' ? '(postponed)' : ''}
                {bill.status === 'Prefiled' ? '(pre-filed)' : ''}
              </p>
            </div>
            <div className="all-bills-column all-bills-right">
              <Progress
                percentage={bill.billStagePercent}
                color={getProgressColor(bill)}
              />
            </div>
          </div>
        ))}
      </div>
      <div style={{ width: '100%', textAlign: 'right' }}>
        {isOpen ? (
          <button
            className="all-bills-expand-button"
            onClick={() => {
              setIsOpen(!isOpen);
              topRef.current.scrollIntoView();
            }}
          >
            Collapse bills
          </button>
        ) : (
          <button
            className="all-bills-expand-button"
            onClick={() => setIsOpen(!isOpen)}
          >
            See all {allBills.length} bills
          </button>
        )}
      </div>
      <p className="data-citation">
        <i>
          <a
            href="https://www.aclu.org/legislation-affecting-lgbt-rights-across-country"
            target="_blank"
            rel="noreferrer"
          >
            ACLU
          </a>{' '}
          anti-trans legisltion data
        </i>
      </p>
    </div>
  );
}
