export default function RepsList({
  reps,
  setSelectedRep,
  setSelectedRepEmail,
  setSelectedRepImage,
  setModalIsOpen,
}) {
  const showContactForm = (rep) => {
    setSelectedRep(rep.name);
    setSelectedRepEmail(rep.email);
    setSelectedRepImage(rep.image || '');
    setModalIsOpen(true);
  };

  return (
    <>
      {reps.map((rep) => {
        return (
          <div className="rep-flex-item">
            <img
              src={rep.image}
              className="rep-list-item-image"
              alt={`Profile of ${rep.name}`}
            />
            <div className="rep-list-item-content">
              <h4>
                <a href={rep.openstates_url} target="_blank" rel="noreferrer">
                  {rep.name}
                </a>
              </h4>
              <p className="rep-p">
                {rep?.jurisdiction?.name}, District{' '}
                {rep?.current_role?.district}
              </p>
              {rep.email.includes('@') ? (
                <button
                  className="rep-contact-button"
                  onClick={() => showContactForm(rep)}
                >
                  Contact
                </button>
              ) : (
                <p className="rep-p">No email address available.</p>
              )}
            </div>
          </div>
        );
      })}
    </>
  );
}
