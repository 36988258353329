import '../../Intro.css';

export default function Header() {
  return (
    <div
      className="header-wrapper"
      style={{ marginTop: '20px', marginBottom: '20px' }}
    >
      <div className="title-box-call-to-action">
        <div className="title">
          <span className="">Take</span>{' '}
          <span className="yellow-text">Action</span>
          <br />
        </div>
        <ul className="subheader margin-top-50">
          <li>
            Write your Senator to help pass trans rights protections through the{' '}
            <span className="color-blue">Equality Act</span>.{' '}
          </li>
          <li>
            Tell representatives who signed on with{' '}
            <span className="color-red">Promise to America’s Children</span>{' '}
            that they’re wrong about trans kids.
          </li>
        </ul>
        <p>
          Read the{' '}
          <a href="/" rel="noreferrer">
            full story
          </a>{' '}
          on 2021 trans legislation.
        </p>
      </div>
    </div>
  );
}
