// import ReactPlayer from 'react-player';

export default function Healthcare() {
  return (
    <div className="section-wrapper">
      <h1>
        <span className="orange-text">28 </span>healthcare bills
      </h1>
      <p>
        Healthcare bills seek to ban gender-affirming treatments and establish
        criminal penalties for both doctors and parents. For trans kids, these
        laws would mean discontinuing treatments like hormone blockers. For
        parents, they raise the daunting decision of disrupting their child’s
        treatment or uprooting their family to move out of state. And for
        families seeking medical guidance for the first time, it would mean
        encountering doctors who are legally bound to deny the gender-affirming
        care recommended by{' '}
        <a
          href="https://www.glaad.org/blog/medical-association-statements-supporting-trans-youth-healthcare-and-against-discriminatory"
          target="_blank"
          rel="noreferrer"
        >
          every major medical association
        </a>
        .
      </p>

      <div className="flex-row">
        <div className="flex-column vertical-center">
          <blockquote style={{ maxWidth: '80%', margin: '0' }}>
            The single most extreme anti-trans law to ever pass through a state
            legislature.
            <p className="callout-text-attribution">Chase Strangio</p>
          </blockquote>
        </div>
        <div className="flex-column">
          <p>
            Arkansas’ “Save Adolescents from Experimentation (SAFE) Act”{' '}
            <a
              href="https://www.arkleg.state.ar.us/Bills/FTPDocument?path=%2FBills%2F2021R%2FPublic%2FHB1570.pdf"
              target="_blank"
              rel="noreferrer"
            >
              (HB 1570)
            </a>{' '}
            has been called “the single-most extreme anti-trans law to ever pass
            through a state legislature” by ACLU lawyer Chase Strangio, who
            argued before the Supreme Court in the landmark case{' '}
            <a
              href="https://www.nbcnews.com/feature/nbc-out/supreme-court-s-lgbtq-ruling-could-have-broad-implications-legal-n1231779"
              target="_blank"
              rel="noreferrer"
            >
              <i>Bostock v. Clayton County</i>
            </a>
            . The SAFE Act passed in April despite a veto by Republican Governor
            Asa Hutchinson, who in an op-ed{' '}
            <a
              href="https://www.washingtonpost.com/opinions/asa-hutchinson-veto-transgender-health-bill-youth/2021/04/08/990c43f4-9892-11eb-962b-78c1d8228819_story.html"
              target="_blank"
              rel="noreferrer"
            >
              called the bill
            </a>{' '}
            a “a vast government overreach.” The SAFE Act bans gender-affirming
            care to trans patients under the age of 18, including hormone
            blockers, a reversible measure to temporarily prevent the onset of
            puberty.
          </p>
        </div>

        <p>
          The SAFE Act passed under the guise of “protecting children,” but{' '}
          <a
            href="https://www.thecut.com/2021/04/arkansas-has-passed-the-most-extreme-anti-trans-bill-yet.html"
            target="_blank"
            rel="noreferrer"
          >
            LGBTQ advocates stress
          </a>{' '}
          that denying treatments can have life or death consequences. Dr. Lee
          Beers, president of the American Academy of Pediatrics, warns that the
          law is “dangerous” and “[politicizes] medical care.”
        </p>

        <p>
          In Texas,{' '}
          <a
            href="https://capitol.texas.gov/tlodocs/87R/billtext/html/SB01646E.HTM"
            target="_blank"
            rel="noreferrer"
          >
            SB 1646
          </a>{' '}
          would even redefine “child abuse” to include the “administering or
          supplying, or consenting to or assisting in the administration or
          supply of, a puberty suppression prescription drug or cross-sex
          hormone to a child, other than an intersex child, for the purpose of
          gender transitioning or gender reassignment.” This change could
          potentially allow Child Protective Services to{' '}
          <a
            href="https://www.texasmonthly.com/news-politics/10-year-old-trans-actor-activist-kai-shappley-texas-lege/"
            target="_blank"
            rel="noreferrer"
          >
            remove
          </a>{' '}
          trans kids from their parent’s custody.
        </p>
        <p>
          Taken together, healthcare bills put 45,000 trans kids at risk of
          losing their current gender-affirming care, according to{' '}
          <a
            href="https://williamsinstitute.law.ucla.edu/publications/bans-trans-youth-health-care"
            target="_blank"
            rel="noreferrer"
          >
            the Williams Institute
          </a>
          .
        </p>
      </div>
    </div>
  );
}
