import React, { useState, useRef } from 'react';
import BillCard from './BillCard';
import Athletics from './discussion/Atheltics';
import Healthcare from './discussion/Healthcare';
// import Other from './discussion/Other';

export default function BillsByGroup({ group, bills = [] }) {
  const [isOpen, setIsOpen] = useState(false);
  const topRef = useRef(null);
  const classes = isOpen
    ? 'card-container card-container-open'
    : 'card-container card-container-closed';

  const isHealthcare = group === 'Prohibiting healthcare for transgender youth';
  const isAthletics = group === 'Excluding transgender youth from athletics';

  const getGroupType = () => {
    if (isAthletics) return 'athletics';
    if (isHealthcare) return 'healthcare';
    // if (isOther) return 'other';
    return;
  };

  // Other handled by its own component, for now
  const isOther = [
    'Restrictions on bathroom use',
    'Restrictions on government recognition of gender identity',
    'Restrictions on identification documents',
    'Restrictions on sex education',
    'Religious Freedom Restoration Acts',
    'Religious Exemptions in Healthcare Implicating LGBTQ People',
    'Religious Exemptions in Adoption & Foster Care',
    'Religious Exemptions in Schools and Student Organizations',
    'Other Religious Exemption Bills',
    'Bills Pre-Empting Local Protections',
  ].includes(group);
  if (isOther) return <div></div>;

  return (
    <div>
      <div className="slim-view">
        {isAthletics && <Athletics />}
        {isHealthcare && <Healthcare />}
      </div>
      <div className="section-container" ref={topRef}>
        {isAthletics && (
          <h2 className="light-black">
            {/* <span className="orange-text">
              {bills?.length ? bills.length : '57'}
            </span>{' '} */}
            All youth athletics bills
          </h2>
        )}
        {isHealthcare && (
          <h2 className="light-black">
            {/* <span className="orange-text">
              {bills?.length ? bills.length : '28'}
            </span>{' '} */}
            All youth healthcare bills
          </h2>
        )}
        <div className={classes}>
          {bills.map((bill) => (
            <BillCard bill={bill} fullWidth />
          ))}
        </div>
        {isOpen ? (
          <button
            className="all-bills-expand-button"
            onClick={() => {
              setIsOpen(!isOpen);
              topRef.current.scrollIntoView();
            }}
          >
            Collapse {getGroupType()} bills
          </button>
        ) : (
          <button
            className="all-bills-expand-button"
            onClick={() => setIsOpen(!isOpen)}
          >
            See all {getGroupType()} bills
          </button>
        )}
      </div>
    </div>
  );
}
