import ReactPlayer from 'react-player';

function getWindowWidth() {
  const { innerWidth: width } = window;
  return width;
}

export default function Divide() {
  const isSmallDevice = getWindowWidth() < 800;

  return (
    <div className="slim-view">
      <div className="section-wrapper">
        <h1>Divide and conquer</h1>
        <p>
          In 2017, the Family Research Council sponsored the Values Voter
          Summit. The event hosted a{' '}
          <a
            href="https://www.rightwingwatch.org/post/values-voter-summit-panelist-divide-conquer-to-defeat-totalitarian-trans-inclusion-policies/"
            target="_blank"
            rel="noreferrer"
          >
            breakout session
          </a>{' '}
          titled “transgender ideology in public schools.” Meg Kilgannon,
          Executive Director of Concerned Parents and Educators of Fairfax
          County, took to the podium to warn the audience that “gender identity
          rights only come at the direct expense of others,” and laid out a
          strategic approach to fighting trans rights: divide and conquer.
        </p>
        <div className="flex-row">
          <div className="flex-column">
            <ReactPlayer
              url="https://www.youtube.com/embed/IjY8vuureOc?start=1124&end=1678"
              light="https://cdn.christianpost.com/files/cache/image/12/02/120224_w_400_267.jpg"
              controls={true}
              width={isSmallDevice ? getWindowWidth() : 450}
              style={{ margin: '20px 20px 0 0', maxWidth: '400px' }}
            />
          </div>
          <div className="flex-column">
            <blockquote>
              Focus on gender identity to divide and conquer [...] for all of
              its recent success, the LGBT alliance is actually fragile, and the
              trans activists need the gay rights movement to help legitimize
              them. Gender identity on its own is just a bridge too far. If you
              separate the T from the alphabet soup, we’ll have more success.
              <p className="callout-text-attribution">Meg Kilgannon</p>
            </blockquote>
          </div>
        </div>

        <p>
          The LGBT alliance is fragile enough, Kilgannon argued, that it is
          possible to “separate the T from the alphabet soup.” This could be
          accomplished by narrowly targeting discussions about gender and
          injecting fear and uncertainty into those conversations. “Consider the
          1950s lobotomy fad in psychiatry and compare that to this situation,”
          she offered. “The science is far from settled on gender identity. The
          trans and gender identity movement are a tough sell, so focus on that
          issue: gender identity. Divide and conquer.”
        </p>

        <p>
          Kilgannon also warned against personal attacks on trans kids or their
          parents. “You become the proof they rely on for demanding protection,”
          she acknowledged. The same is true for the persuasive power of
          theological arguments. “If you can help yourself, don’t use religious
          arguments.” For most people, a more convincing “case based on biology
          and reasoning. Secular arguments are perceived as more inclusive.”
        </p>
        <p>
          These tactics are put to action in this year’s anti-trans bills, which
          lean heavily on the authority of scientific language and cite dubious
          academic materials to provide a veneer of legitimacy. This rhetoric
          has been widely embraced by the anti-trans coalition and is
          foundational to the arguments of those like Heritage Foundation’s Ryan
          T. Anderson. In 2018, Anderson published{' '}
          <a
            href="https://www.barnesandnoble.com/w/when-harry-became-sally-ryan-anderson/1125792437"
            target="_blank"
            rel="noreferrer"
          >
            <i>When Harry Became Sally</i>
          </a>
          , which purports to draw “insights from biology, psychology, and
          philosophy” to provide a “nuanced view of human embodiment” but was so
          toxic its was{' '}
          <a
            href="https://s.wsj.net/public/resources/documents/Amazonletter0311.pdf"
            target="_blank"
            rel="noreferrer"
          >
            discontinued on Amazon
          </a>{' '}
          for framing “LGBTQ+ identity as a mental illness.”
        </p>

        <p>
          The quest to divide and conquer has also led to unlikely alliances.
          The Heritage Foundation has platformed the trans-exclusionary radical
          feminist (TERF) organization Women’s Liberation Front (WoLF) in events
          like the 2019{' '}
          <a
            href="https://www.youtube.com/watch?v=Rt9DW4e1Cvw"
            target="_blank"
            rel="noreferrer"
          >
            panel discussion
          </a>{' '}
          “Biology Isn’t Bigotry: Why Sex Matters in the Age of Gender
          Identity.” Ryan T. Anderson moderated the event and applauded the
          partnership as a way to “foster conversation” that could shape U.S.
          policy. WoLF continues to be amplified by the anti-trans coalition,
          even{' '}
          <a
            href="https://www.vox.com/identities/2019/9/5/20840101/terfs-radical-feminists-gender-critical"
            target="_blank"
            rel="noreferrer"
          >
            receiving a grant
          </a>{' '}
          from the ADF, as it continues to promote legislation against trans
          women in prisons, sports, and homeless shelters.
        </p>

        <div className="flex-row">
          <div className="flex-column vertical-center">
            <blockquote>
              It’s a horrible bill being presented really nicely.
              <p className="callout-text-attribution">
                Jason Isaacson, Minnesota Senator
              </p>
            </blockquote>
          </div>
          <div className="flex-column">
            <p>
              At their core, anti-trans bills rely on debunked science and false
              claims of “protecting” children to block trans access to basic
              services and social inclusion. Faced with an anti-trans youth
              athletics bill in Minnesota, Senator Jason Isaacson articulated
              how these bills couch discrimination in positive language. “When
              we say horrible things really nicely, that doesn’t make it any
              better just because it’s polite,” Isaacson said. “And that’s what
              this is: it’s a horrible bill being presented really nicely.”
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
