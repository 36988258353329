import { promiseSignatories } from '../../data/promise-signatories';

export function SelectState({ selectRef, setSelectedState }) {
  const sortedStates = promiseSignatories.sort((a, b) =>
    a.jurisdiction.name > b.jurisdiction.name
      ? 1
      : b.jurisdiction.name > a.jurisdiction.name
      ? -1
      : 0
  );
  return (
    <>
      <label for="state-select">
        <h3 className="state-select-label">Select state</h3>
      </label>
      <select
        name="state"
        id="state"
        className="state-select"
        onChange={(e) => {
          setSelectedState(e.target.value);
          selectRef.current.scrollIntoView();
        }}
      >
        <option value="all" key="all">
          All states
        </option>
        {sortedStates.map((rep) => (
          <option value={rep.jurisdiction.name} key={rep.name}>
            {rep.jurisdiction.name}
          </option>
        ))}
      </select>
    </>
  );
}
