export const promiseSignatories = [
  {
    id: 'ocd-person/c6b03ef1-ab28-4458-9c56-815d5eb5c6b8',
    name: 'Allen Andrews',
    party: 'Republican',
    current_role: {
      title: 'Representative',
      org_classification: 'lower',
      district: '1',
      division_id: 'ocd-division/country:us/state:mo/sldl:1',
    },
    jurisdiction: {
      id: 'ocd-jurisdiction/country:us/state:mo/government',
      name: 'Missouri',
      classification: 'state',
    },
    given_name: 'Allen',
    family_name: 'Andrews',
    image: 'https://house.mo.gov/MemberPhoto.aspx?id=1811',
    email: 'Allen.Andrews@house.mo.gov',
    gender: '',
    birth_date: '',
    death_date: '',
    extras: {
      last_name: 'Andrews',
      first_name: 'Allen',
    },
    created_at: '2018-10-18T14:50:11.322797+00:00',
    updated_at: '2021-05-20T00:12:08.196773+00:00',
    openstates_url:
      'https://openstates.org/person/allen-andrews-62v1ZNebnudYzCgqF9HKkS/',
  },
  {
    id: 'ocd-person/56161a79-480c-4b58-b0a1-a802a8bfd2f4',
    name: 'Jena Powell',
    party: 'Republican',
    current_role: {
      title: 'Representative',
      org_classification: 'lower',
      district: '80',
      division_id: 'ocd-division/country:us/state:oh/sldl:80',
    },
    jurisdiction: {
      id: 'ocd-jurisdiction/country:us/state:oh/government',
      name: 'Ohio',
      classification: 'state',
    },
    given_name: 'Jena',
    family_name: 'Powell',
    image:
      'https://www.legislature.ohio.gov/Assets/HouseMembers/Headshots/Medium/2906.jpg',
    email: 'rep80@ohiohouse.gov',
    gender: '',
    birth_date: '',
    death_date: '',
    extras: {},
    created_at: '2019-01-10T06:15:52.304599+00:00',
    updated_at: '2021-01-06T06:14:15.736310+00:00',
    openstates_url:
      'https://openstates.org/person/jena-powell-2cRRMm5s38teCR1vVdtLbI/',
  },
  {
    id: 'ocd-person/b837abb3-e53b-45f2-85c4-7e4dff974a08',
    name: 'Tim Miller',
    party: 'Republican',
    current_role: {
      title: 'Representative',
      org_classification: 'lower',
      district: '17A',
      division_id: 'ocd-division/country:us/state:mn/sldl:17a',
    },
    jurisdiction: {
      id: 'ocd-jurisdiction/country:us/state:mn/government',
      name: 'Minnesota',
      classification: 'state',
    },
    given_name: 'Tim',
    family_name: 'Miller',
    image: 'https://www.house.leg.state.mn.us/hinfo/memberimgls92/17A.gif',
    email: 'rep.tim.miller@house.mn',
    gender: '',
    birth_date: '',
    death_date: '',
    extras: {},
    created_at: '2018-10-18T14:49:22.041607+00:00',
    updated_at: '2021-01-06T06:09:28.323806+00:00',
    openstates_url:
      'https://openstates.org/person/tim-miller-5bc1qMvqq8KyXm78Op2BgG/',
  },
  {
    id: 'ocd-person/632aa1ac-ea2b-4ff0-8830-b7c7142c87d1',
    name: 'Tom McGillvray',
    party: 'Republican',
    jurisdiction: {
      id: 'ocd-jurisdiction/country:us/state:mt/government',
      name: 'Montana',
      classification: 'state',
    },
    given_name: 'Tom',
    family_name: 'McGillvray',
    image:
      'https://s3.amazonaws.com/ballotpedia-api4/files/thumbs/200/300/TomMcGillvray.jpg',
    email: 'tom.mcgillvray@mtleg.gov',
    gender: '',
    birth_date: '',
    death_date: '',
    extras: {},
    created_at: '2019-11-16T02:04:40.382278+00:00',
    updated_at: '2021-05-10T17:52:43.181837+00:00',
    openstates_url:
      'https://openstates.org/person/tom-mcgillvray-317ipNAZEeJiULTOUZJA9Z/',
  },
  {
    id: 'ocd-person/97943c08-7d1f-400e-b71d-7a20e939b1d9',
    name: 'Mike Thompson',
    party: 'Republican',
    current_role: {
      title: 'Senator',
      org_classification: 'upper',
      district: '10',
      division_id: 'ocd-division/country:us/state:ks/sldu:10',
    },
    jurisdiction: {
      id: 'ocd-jurisdiction/country:us/state:ks/government',
      name: 'Kansas',
      classification: 'state',
    },
    given_name: 'Mike',
    family_name: 'Thompson',
    image: `${process.env.PUBLIC_URL}/images/sen_thompson_mike_1.jpeg`,
    email: 'Mike.Thompson@senate.ks.gov',
    gender: '',
    birth_date: '',
    death_date: '',
    extras: {
      occupation: '',
    },
    created_at: '2020-02-07T06:08:21.903570+00:00',
    updated_at: '2021-01-12T00:09:18.696645+00:00',
    openstates_url:
      'https://openstates.org/person/mike-thompson-4c1aFiDjJxPkV2t4nyjNW5/',
  },
  {
    id: 'ocd-person/e8756722-e9e9-4246-95e3-13019cb3bd50',
    name: 'Sarah Vance',
    party: 'Republican',
    current_role: {
      title: 'Representative',
      org_classification: 'lower',
      district: '31',
      division_id: 'ocd-division/country:us/state:ak/sldl:31',
    },
    jurisdiction: {
      id: 'ocd-jurisdiction/country:us/state:ak/government',
      name: 'Alaska',
      classification: 'state',
    },
    given_name: 'Sarah',
    family_name: 'Vance',
    image: 'http://akleg.gov/images/legislators/van.jpg',
    email: 'Representative.Sarah.Vance@akleg.gov',
    gender: '',
    birth_date: '',
    death_date: '',
    extras: {},
    created_at: '2019-02-07T03:29:08.929268+00:00',
    updated_at: '2021-01-15T00:03:15.224865+00:00',
    openstates_url:
      'https://openstates.org/person/sarah-vance-74ds8IEbCOyAqZk8BtwNFI/',
  },
  {
    id: 'ocd-person/4ab616b7-79d2-4d89-ae25-cd4ea4e8c4c8',
    name: 'Susan Humphries',
    party: 'Republican',
    current_role: {
      title: 'Representative',
      org_classification: 'lower',
      district: '99',
      division_id: 'ocd-division/country:us/state:ks/sldl:99',
    },
    jurisdiction: {
      id: 'ocd-jurisdiction/country:us/state:ks/government',
      name: 'Kansas',
      classification: 'state',
    },
    given_name: 'Susan',
    family_name: 'Humphries',
    image: `${process.env.PUBLIC_URL}/images/rep_humphries_susan_1.jpeg`,
    email: 'Susan.Humphries@house.ks.gov',
    gender: '',
    birth_date: '',
    death_date: '',
    extras: {
      occupation: 'Attorney',
    },
    created_at: '2018-10-18T14:44:31.777514+00:00',
    updated_at: '2021-01-12T00:09:23.878828+00:00',
    openstates_url:
      'https://openstates.org/person/susan-humphries-2Gye702BBQZowGLh04JEuO/',
  },
  {
    id: 'ocd-person/a1b59e61-3b93-4e73-b9ee-03d6dd2c464a',
    name: 'Terry Moore',
    party: 'Republican',
    current_role: {
      title: 'Representative',
      org_classification: 'lower',
      district: '54',
      division_id: 'ocd-division/country:us/state:mt/sldl:54',
    },
    jurisdiction: {
      id: 'ocd-jurisdiction/country:us/state:mt/government',
      name: 'Montana',
      classification: 'state',
    },
    given_name: 'Terry',
    family_name: 'Moore',
    image:
      'https://bloximages.chicago2.vip.townnews.com/mtstandard.com/content/tncms/assets/v3/editorial/1/3f/13f5e06e-855e-5d62-b960-da1be79276ea/5f45b57db0e0c.image.jpg?resize=200%2C250',
    email: 'terry.moore@mtleg.gov',
    gender: '',
    birth_date: '',
    death_date: '',
    extras: {},
    created_at: '2019-01-07T01:54:29.016690+00:00',
    updated_at: '2021-05-10T17:52:30.973935+00:00',
    openstates_url:
      'https://openstates.org/person/terry-moore-4v8lP9rdPaKNvSkzLV5cDa/',
  },
  {
    id: 'ocd-person/948ba2f7-fd71-42ed-a35a-e692b76d2ca2',
    name: 'Rod Montoya',
    party: 'Republican',
    current_role: {
      title: 'Representative',
      org_classification: 'lower',
      district: '1',
      division_id: 'ocd-division/country:us/state:nm/sldl:1',
    },
    jurisdiction: {
      id: 'ocd-jurisdiction/country:us/state:nm/government',
      name: 'New Mexico',
      classification: 'state',
    },
    given_name: 'Rod',
    family_name: 'Montoya',
    image: 'http://www.nmlegis.gov/Images/Legislators/House/HMONR.jpg',
    email: 'roddmontoya@gmail.com',
    gender: '',
    birth_date: '',
    death_date: '',
    extras: {},
    created_at: '2018-10-18T14:55:28.409469+00:00',
    updated_at: '2021-05-10T17:55:12.362197+00:00',
    openstates_url:
      'https://openstates.org/person/rod-montoya-4WIg48pbCBvllxImp0BE0Y/',
  },
  {
    id: 'ocd-person/3e67bde9-4588-42ad-b3e7-2b243e14ab73',
    name: 'Patrick Neville',
    party: 'Republican',
    current_role: {
      title: 'Representative',
      org_classification: 'lower',
      district: '45',
      division_id: 'ocd-division/country:us/state:co/sldl:45',
    },
    jurisdiction: {
      id: 'ocd-jurisdiction/country:us/state:co/government',
      name: 'Colorado',
      classification: 'state',
    },
    given_name: 'Patrick',
    family_name: 'Neville',
    image:
      'http://leg.colorado.gov/sites/default/files/styles/width_300/public/2021a_neville%2C%20patrick.jpg?itok=N_vsKFO9',
    email: 'patrick.neville.house@state.co.us',
    gender: '',
    birth_date: '',
    death_date: '',
    extras: {},
    created_at: '2018-10-18T14:36:10.484572+00:00',
    updated_at: '2021-01-13T00:04:09.382765+00:00',
    openstates_url:
      'https://openstates.org/person/patrick-neville-1tkvvw5WWP4Gjnx8mczUnb/',
  },
  {
    id: 'ocd-person/37a38b6a-84c8-427b-bc6f-2256fffe9062',
    name: 'Paul Schemel',
    party: 'Republican',
    current_role: {
      title: 'Representative',
      org_classification: 'lower',
      district: '90',
      division_id: 'ocd-division/country:us/state:pa/sldl:90',
    },
    jurisdiction: {
      id: 'ocd-jurisdiction/country:us/state:pa/government',
      name: 'Pennsylvania',
      classification: 'state',
    },
    given_name: 'Paul',
    family_name: 'Schemel',
    image: 'https://www.legis.state.pa.us/images/members/200/1705.jpg',
    email: 'pschmel@pahousegop.com',
    gender: '',
    birth_date: '',
    death_date: '',
    extras: {
      youtube: 'http://www.youtube.com/user/RepSchemel',
      facebook: 'https://www.facebook.com/RepSchemel',
    },
    created_at: '2018-10-18T16:11:19.971050+00:00',
    updated_at: '2020-12-30T06:15:40.087750+00:00',
    openstates_url:
      'https://openstates.org/person/paul-schemel-1gzJifcXSuRnOIazVHPWc6/',
  },
  {
    id: 'ocd-person/0f0f626f-b39a-4a94-9d33-0d8166473d26',
    name: 'Eric Smith',
    party: 'Republican',
    current_role: {
      title: 'Representative',
      org_classification: 'lower',
      district: '76',
      division_id: 'ocd-division/country:us/state:ks/sldl:76',
    },
    jurisdiction: {
      id: 'ocd-jurisdiction/country:us/state:ks/government',
      name: 'Kansas',
      classification: 'state',
    },
    given_name: 'Eric',
    family_name: 'Smith',
    image: `${process.env.PUBLIC_URL}/images/rep_smith_eric_1.jpeg`,
    email: 'Eric.Smith@house.ks.gov',
    gender: '',
    birth_date: '',
    death_date: '',
    extras: {
      occupation: 'Deputy Sheriff',
    },
    created_at: '2018-10-18T14:44:52.581483+00:00',
    updated_at: '2021-05-10T17:48:31.992772+00:00',
    openstates_url:
      'https://openstates.org/person/eric-smith-SPulkifZwsBzec09JQiXu/',
  },
  {
    id: 'ocd-person/c26f1f0e-232e-4bf2-99f8-be3e80bfcdcc',
    name: 'Robin Lundstrum',
    party: 'Republican',
    current_role: {
      title: 'Representative',
      org_classification: 'lower',
      district: '87',
      division_id: 'ocd-division/country:us/state:ar/sldl:87',
    },
    jurisdiction: {
      id: 'ocd-jurisdiction/country:us/state:ar/government',
      name: 'Arkansas',
      classification: 'state',
    },
    given_name: 'Robin',
    family_name: 'Lundstrum',
    image:
      'https://www.arkleg.state.ar.us/Content/photos/2021/House/490_sm.jpg',
    email: 'robin.lundstrum@arkansashouse.org',
    gender: '',
    birth_date: '',
    death_date: '',
    extras: {
      occupation: 'Property Management',
    },
    created_at: '2018-10-18T14:33:58.770396+00:00',
    updated_at: '2021-04-08T20:26:51.936298+00:00',
    openstates_url:
      'https://openstates.org/person/robin-lundstrum-5utIECBteQJHYXybSyD1FE/',
  },
  {
    id: 'ocd-person/0e45048c-c1a4-415f-9ee4-5d09ea857e2e',
    name: 'Josh Bonner',
    party: 'Republican',
    current_role: {
      title: 'Representative',
      org_classification: 'lower',
      district: '72',
      division_id: 'ocd-division/country:us/state:ga/sldl:72',
    },
    jurisdiction: {
      id: 'ocd-jurisdiction/country:us/state:ga/government',
      name: 'Georgia',
      classification: 'state',
    },
    given_name: 'Josh',
    family_name: 'Bonner',
    image:
      'https://www.legis.ga.gov/api/images/default-source/portraits/bonner-josh-4893.jpg',
    email: 'josh.bonner@house.ga.gov',
    gender: '',
    birth_date: '',
    death_date: '',
    extras: {
      city: 'Fayetteville',
      guid: 4893,
      residence: 'Fayetteville',
      georgia_id: 4893,
    },
    created_at: '2018-10-18T14:40:02.813486+00:00',
    updated_at: '2021-01-12T00:06:56.600414+00:00',
    openstates_url:
      'https://openstates.org/person/josh-bonner-QvQtTr6Xo3V7Tj9jKlQvG/',
  },
  {
    id: 'ocd-person/2e901960-1c92-47b7-b5e9-690867ee25d6',
    name: 'Sara Walsh',
    party: 'Republican',
    current_role: {
      title: 'Representative',
      org_classification: 'lower',
      district: '50',
      division_id: 'ocd-division/country:us/state:mo/sldl:50',
    },
    jurisdiction: {
      id: 'ocd-jurisdiction/country:us/state:mo/government',
      name: 'Missouri',
      classification: 'state',
    },
    given_name: 'Sara',
    family_name: 'Walsh',
    image: 'https://house.mo.gov/MemberPhoto.aspx?id=1996',
    email: 'Sara.Walsh@house.mo.gov',
    gender: '',
    birth_date: '',
    death_date: '',
    extras: {
      last_name: 'Walsh',
      first_name: 'Sara',
    },
    created_at: '2018-10-18T14:50:03.579546+00:00',
    updated_at: '2021-05-20T00:12:11.996862+00:00',
    openstates_url:
      'https://openstates.org/person/sara-walsh-1PrV21163oVkdfvvVtYH5S/',
  },
  {
    id: 'ocd-person/9335be7e-11ff-4577-af89-e61195143354',
    name: 'Ben Koppelman',
    party: 'Republican',
    current_role: {
      title: 'Representative',
      org_classification: 'lower',
      district: '16',
      division_id: 'ocd-division/country:us/state:nd/sldl:16',
    },
    jurisdiction: {
      id: 'ocd-jurisdiction/country:us/state:nd/government',
      name: 'North Dakota',
      classification: 'state',
    },
    given_name: 'Ben',
    family_name: 'Koppelman',
    image:
      'https://www.legis.nd.gov/files/styles/medium/public/person/photo/bkoppelman.jpg?itok=Vo9eUxbE',
    email: 'bkoppelman@nd.gov',
    gender: '',
    birth_date: '',
    death_date: '',
    extras: {},
    created_at: '2018-10-18T14:53:09.008812+00:00',
    updated_at: '2021-05-10T17:53:17.464666+00:00',
    openstates_url:
      'https://openstates.org/person/ben-koppelman-4TmQrCNR7fxwb4LHWqvqgW/',
  },
  {
    id: 'ocd-person/286af01e-ace3-4de2-b1c3-44756370d932',
    name: 'Larry Hicks',
    party: 'Republican',
    current_role: {
      title: 'Senator',
      org_classification: 'upper',
      district: '11',
      division_id: 'ocd-division/country:us/state:wy/sldu:11',
    },
    jurisdiction: {
      id: 'ocd-jurisdiction/country:us/state:wy/government',
      name: 'Wyoming',
      classification: 'state',
    },
    given_name: 'Larry',
    family_name: 'Hicks',
    image: 'http://wyoleg.gov/LegislatorSummary/Photos/HicksS11.jpg',
    email: 'Larry.Hicks@wyoleg.gov',
    gender: '',
    birth_date: '1958-12-30',
    death_date: '',
    extras: {
      county: 'Albany, Carbon, Sweetwater',
      religion: '',
      education: [
        {
          degree: '',
          gradYear: '',
          schoolName: 'Northwest Community College',
        },
        {
          degree: 'BS-Crop Science',
          gradYear: '1983',
          schoolName: 'University of Wyoming',
        },
        {
          degree: 'MS-Agronomy',
          gradYear: '1986',
          schoolName: 'Montana State University',
        },
        {
          degree: 'Course work complete PhD Agronomy',
          gradYear: '',
          schoolName: 'University of Wyoming',
        },
      ],
      wy_leg_id: 1963,
      occupation: 'Natural Resources Manager',
      place_of_birth: '',
      number_children: '',
      spouse_given_name: 'Roxane "ROX" Hicks',
      civic_organizations: [
        {
          orgname: 'Family Farm Alliance, Advisory Board',
        },
        {
          orgname: 'Savery-Little Snake Water Conservancy Board',
        },
        {
          orgname: 'West Side Canal Co',
        },
        {
          orgname: 'Little Snake Community Church',
        },
        {
          orgname: 'Wyo Wild Sheep Foundation',
        },
        {
          orgname: 'NRA, RMEF, DU, SFW, BOW#, P&Y',
        },
        {
          orgname: 'Boone & Crocket Club',
        },
        {
          orgname: 'North American Pronghorn Foundation',
        },
        {
          orgname: 'MFT Horse Breed Assn',
        },
      ],
    },
    created_at: '2018-10-18T16:14:48.913897+00:00',
    updated_at: '2021-01-12T00:23:00.689601+00:00',
    openstates_url:
      'https://openstates.org/person/larry-hicks-1EGYxVP7hsJcfbocJYhHjW/',
  },
  {
    id: 'ocd-person/019607dd-e8e2-4c2e-b4cc-e95ccddb54a2',
    name: 'Lisa Moser',
    party: 'Republican',
    current_role: {
      title: 'Representative',
      org_classification: 'lower',
      district: '106',
      division_id: 'ocd-division/country:us/state:ks/sldl:106',
    },
    jurisdiction: {
      id: 'ocd-jurisdiction/country:us/state:ks/government',
      name: 'Kansas',
      classification: 'state',
    },
    given_name: 'Lisa',
    family_name: 'Moser',
    image: `${process.env.PUBLIC_URL}/images/rep_moser_lisa_1.jpeg`,
    email: 'lisa.moser@house.ks.gov',
    gender: '',
    birth_date: '',
    death_date: '',
    extras: {
      occupation: 'Rancher',
    },
    created_at: '2021-01-12T00:09:24.295547+00:00',
    updated_at: '2021-01-28T00:07:48.229611+00:00',
    openstates_url:
      'https://openstates.org/person/lisa-moser-2zYVQLJh9pg23gxsrxwcE/',
  },
  {
    id: 'ocd-person/ae46fe13-949c-444e-8dee-af9f9039c1d5',
    name: 'Carrie Ruud',
    party: 'Republican',
    current_role: {
      title: 'Senator',
      org_classification: 'upper',
      district: '10',
      division_id: 'ocd-division/country:us/state:mn/sldu:10',
    },
    jurisdiction: {
      id: 'ocd-jurisdiction/country:us/state:mn/government',
      name: 'Minnesota',
      classification: 'state',
    },
    given_name: 'Carrie',
    family_name: 'Ruud',
    image: 'https://www.senate.mn/img/member_thumbnails/10Ruud.jpg',
    email: 'sen.carrie.ruud@senate.mn',
    gender: '',
    birth_date: '',
    death_date: '',
    extras: {},
    created_at: '2018-10-18T14:49:17.465754+00:00',
    updated_at: '2021-01-06T06:09:28.618253+00:00',
    openstates_url:
      'https://openstates.org/person/carrie-ruud-5Ir6jH7UMBgBHtMkOLlGwn/',
  },
  {
    id: 'ocd-person/acc00fca-50e5-46f7-8676-d502ecfc94ac',
    name: 'Joe Hamm',
    party: 'Republican',
    current_role: {
      title: 'Representative',
      org_classification: 'lower',
      district: '84',
      division_id: 'ocd-division/country:us/state:pa/sldl:84',
    },
    jurisdiction: {
      id: 'ocd-jurisdiction/country:us/state:pa/government',
      name: 'Pennsylvania',
      classification: 'state',
    },
    given_name: 'Joe',
    family_name: 'Hamm',
    image: 'https://www.legis.state.pa.us/images/members/200/1904.jpg',
    email: 'jhamm@pahousegop.​com',
    gender: '',
    birth_date: '',
    death_date: '',
    extras: {},
    created_at: '2020-12-30T06:15:37.099403+00:00',
    updated_at: '2021-04-08T20:40:53.104044+00:00',
    openstates_url:
      'https://openstates.org/person/joe-hamm-5FyS45ASQ1BZd14lVGQBYq/',
  },
  {
    id: 'ocd-person/3439fe0c-fb43-4977-ac8c-1dd1f8f10e96',
    name: 'Stacey Guerin',
    party: 'Republican',
    current_role: {
      title: 'Senator',
      org_classification: 'upper',
      district: '10',
      division_id: 'ocd-division/country:us/state:me/sldu:10',
    },
    jurisdiction: {
      id: 'ocd-jurisdiction/country:us/state:me/government',
      name: 'Maine',
      classification: 'state',
    },
    given_name: 'Stacey',
    family_name: 'Guerin',
    image: 'https://legislature.maine.gov/uploads/visual_edit/guerin-5x7.jpg',
    email: 'Stacey.Guerin@legislature.maine.gov',
    gender: '',
    birth_date: '',
    death_date: '',
    extras: {
      district_name: 'Glenburn',
    },
    created_at: '2018-10-18T14:48:22.770940+00:00',
    updated_at: '2021-05-10T17:50:16.008821+00:00',
    openstates_url:
      'https://openstates.org/person/stacey-guerin-1aY6QECoPKhhWwo5zepubu/',
  },
  {
    id: 'ocd-person/5a29dfb1-14b5-4535-b8ee-da8f432d0727',
    name: 'Bryant L. Richardson',
    party: 'Republican',
    current_role: {
      title: 'Senator',
      org_classification: 'upper',
      district: '21',
      division_id: 'ocd-division/country:us/state:de/sldu:21',
    },
    jurisdiction: {
      id: 'ocd-jurisdiction/country:us/state:de/government',
      name: 'Delaware',
      classification: 'state',
    },
    given_name: 'Bryant L.',
    family_name: 'Richardson',
    image:
      'https://legis.delaware.gov/images/default-source/Legislators/BryantRichardson.jpg?sfvrsn=2',
    email: 'Bryant.Richardson@state.de.us',
    gender: '',
    birth_date: '',
    death_date: '',
    extras: {},
    created_at: '2018-10-18T14:37:47.903873+00:00',
    updated_at: '2020-10-30T22:58:06.821214+00:00',
    openstates_url:
      'https://openstates.org/person/bryant-l-richardson-2k8RdgHS3ubbCaRWPKXDt9/',
  },
  {
    id: 'ocd-person/9799a03d-cd79-4fc3-92bd-655ff5cc55de',
    name: 'Kim Koppelman',
    party: 'Republican',
    current_role: {
      title: 'Representative',
      org_classification: 'lower',
      district: '13',
      division_id: 'ocd-division/country:us/state:nd/sldl:13',
    },
    jurisdiction: {
      id: 'ocd-jurisdiction/country:us/state:nd/government',
      name: 'North Dakota',
      classification: 'state',
    },
    given_name: 'Kim',
    family_name: 'Koppelman',
    image:
      'https://www.legis.nd.gov/files/styles/medium/public/person/photo/kkoppelman.jpg?itok=NcNVr4Oi',
    email: 'kkoppelman@nd.gov',
    gender: '',
    birth_date: '',
    death_date: '',
    extras: {},
    created_at: '2018-10-18T14:53:01.965584+00:00',
    updated_at: '2021-05-10T17:53:19.948764+00:00',
    openstates_url:
      'https://openstates.org/person/kim-koppelman-4c430vDWpMOG86C292bum6/',
  },
  {
    id: 'ocd-person/7aac5ab6-1590-4cfe-8c02-d61f292497c8',
    name: 'Barbara Wasinger',
    party: 'Republican',
    current_role: {
      title: 'Representative',
      org_classification: 'lower',
      district: '111',
      division_id: 'ocd-division/country:us/state:ks/sldl:111',
    },
    jurisdiction: {
      id: 'ocd-jurisdiction/country:us/state:ks/government',
      name: 'Kansas',
      classification: 'state',
    },
    given_name: 'Barbara',
    family_name: 'Wasinger',
    image: `${process.env.PUBLIC_URL}/images/rep_wasinger_barbara_1.jpeg`,
    email: 'Barb.Wasinger@house.ks.gov',
    gender: '',
    birth_date: '',
    death_date: '',
    extras: {
      occupation: 'Business Owner',
    },
    created_at: '2019-01-07T05:01:30.416044+00:00',
    updated_at: '2021-01-12T00:09:14.899830+00:00',
    openstates_url:
      'https://openstates.org/person/barbara-wasinger-3jTpShH62L44rALrqBav0K/',
  },
  {
    id: 'ocd-person/5395223c-9b56-410d-b18f-978d9e2a07a9',
    name: 'Dan Dockstader',
    party: 'Republican',
    current_role: {
      title: 'Senator',
      org_classification: 'upper',
      district: '16',
      division_id: 'ocd-division/country:us/state:wy/sldu:16',
    },
    jurisdiction: {
      id: 'ocd-jurisdiction/country:us/state:wy/government',
      name: 'Wyoming',
      classification: 'state',
    },
    given_name: 'Dan',
    family_name: 'Dockstader',
    image: 'http://wyoleg.gov/LegislatorSummary/Photos/DockstaderDS16.jpg',
    email: 'Dan.Dockstader@wyoleg.gov',
    gender: '',
    birth_date: '1958-02-13',
    death_date: '',
    extras: {
      county: 'Lincoln, Sublette, Teton',
      religion: 'LDS',
      education: [
        {
          degree: 'BA',
          gradYear: '',
          schoolName: 'BYU',
        },
      ],
      wy_leg_id: 1048,
      occupation: 'Publisher/Radio Host',
      place_of_birth: 'Idaho Falls ID',
      number_children: '4',
      spouse_given_name: 'Kim',
      civic_organizations: [
        {
          orgname: 'Chamber of Commerce',
        },
        {
          orgname: 'Arts Council',
        },
        {
          orgname: 'Rotary Club',
        },
        {
          orgname: 'Historical Society',
        },
        {
          orgname: 'Afton Town Council',
        },
        {
          orgname: 'Lincoln County Fair Board',
        },
      ],
    },
    created_at: '2018-10-18T16:14:45.982713+00:00',
    updated_at: '2021-01-12T00:23:04.320272+00:00',
    openstates_url:
      'https://openstates.org/person/dan-dockstader-2XiW54QFRQg3XnFsyvyI93/',
  },
  {
    id: 'ocd-person/e5b4d1e8-40a3-4f75-af3a-36fc7e84a228',
    name: 'Steven K. Howe',
    party: 'Republican',
    current_role: {
      title: 'Representative',
      org_classification: 'lower',
      district: '71',
      division_id: 'ocd-division/country:us/state:ks/sldl:71',
    },
    jurisdiction: {
      id: 'ocd-jurisdiction/country:us/state:ks/government',
      name: 'Kansas',
      classification: 'state',
    },
    given_name: 'Steven',
    family_name: 'Howe',
    image: `${process.env.PUBLIC_URL}/images/rep_howe_steven_1.jpeg`,
    email: 'steven.howe@house.ks.gov',
    gender: '',
    birth_date: '',
    death_date: '',
    extras: {
      occupation: '',
    },
    created_at: '2021-01-12T00:09:21.002687+00:00',
    updated_at: '2021-01-28T00:07:45.165174+00:00',
    openstates_url:
      'https://openstates.org/person/steven-k-howe-6zRsQN1HW22irarYLB41LU/',
  },
  {
    id: '',
    name: 'Sage Dixon',
    party: 'Republican',
    current_role: {
      title: 'Representative',
      org_classification: 'lower',
      district: '1',
      division_id: '',
    },
    jurisdiction: {
      id: '',
      name: 'Idaho',
      classification: 'state',
    },
    given_name: 'Sage',
    family_name: 'Dixon',
    image:
      'https://legislature.idaho.gov/wp-content/uploads/sessioninfo/2019/directory/Dixon3130.jpg',
    email: 'sdixon@house.idaho.gov',
    gender: '',
    birth_date: '',
    death_date: '',
    extras: {
      occupation: '',
    },
    created_at: '',
    updated_at: '',
    openstates_url: '',
  },
  {
    id: '',
    name: 'Charlotte McGuire',
    party: '',
    current_role: {
      title: 'Board of Education, Vice President',
      org_classification: 'lower',
      district: '3',
      division_id: '',
    },
    jurisdiction: {
      id: '',
      name: 'Ohio',
      classification: 'state',
    },
    given_name: 'Charlotte',
    family_name: 'McGuire',
    image:
      'http://education.ohio.gov/getattachment/State-Board/State-Board-Members/District-3/McGuire.png',
    email: 'Charlotte.McGuire@education.ohio.gov',
    gender: '',
    birth_date: '',
    death_date: '',
    extras: {
      occupation: '',
    },
    created_at: '',
    updated_at: '',
    openstates_url: '',
  },
  {
    id: '',
    name: 'Johnathan Hershey',
    party: 'Republican',
    current_role: {
      title: 'Representative',
      org_classification: 'lower',
      district: '82',
      division_id: '',
    },
    jurisdiction: {
      id: '',
      name: 'Pennsylvania',
      classification: 'state',
    },
    given_name: 'Johnathan',
    family_name: 'Hershey',
    image: 'https://www.legis.state.pa.us/images/members/200/1841.jpg',
    email: 'jhershey@pahousegop.com',
    gender: '',
    birth_date: '',
    death_date: '',
    extras: {
      occupation: '',
    },
    created_at: '',
    updated_at: '',
    openstates_url: '',
  },
];

export const usSenators = [
  {
    state_name: 'Alaska',
    state_name_slug: 'alaska',
    state_code: 'AK',
    state_code_slug: 'ak',
    class: 'II',
    bioguide: 'S001198',
    thomas: '2290',
    opensecrets: 'N00035774',
    votesmart: '114964',
    fec: null,
    maplight: null,
    wikidata: null,
    google_entity_id: null,
    title: 'senator',
    party: 'republican',
    name: 'Daniel Sullivan',
    name_slug: 'daniel-sullivan',
    first_name: 'Daniel',
    middle_name: null,
    last_name: 'Sullivan',
    name_suffix: null,
    goes_by: 'Dan',
    pronunciation: 'DAN SUHL-ih-vuhn',
    gender: 'male',
    ethnicity: 'white-american',
    religion: 'roman-catholic',
    openly_lgbtq: 'no',
    date_of_birth: '1964-11-13',
    entered_office: '2015-01-03',
    term_end: '2027-01-03',
    biography:
      'Dan Sullivan is a Senator from Alaska; born in Fairview Park, Cuyahoga County, Ohio, November 13, 1964; graduated Culver Military Academy, Culver, Ind., 1983; B.A., Harvard University, 1987; M.F.S. and J.D., Georgetown University, 1993; United States Marine Corps 1993-1997; United States Marine Corps Reserves 1997-present, attaining the rank of lieutenant colonel; lawyer; White House aide and director on the National Security Council staff during the George W. Bush administration; assistant U.S. secretary of state for economic, energy and business affairs 2006-2009; attorney general of Alaska 2009-2010; Commissioner of Alaska Department of Natural Resources 2010-2013; elected as a Republican to the United States Senate in 2014 for the term ending January 3, 2021.',
    phone: '202-224-3004',
    fax: '202-224-6501',
    latitude: '38.892839',
    longitude: '-77.004356',
    address_complete: '702 Hart Senate Office Building, Washington, DC 20510',
    address_number: null,
    address_prefix: null,
    address_street: '702 Hart Senate Office Building',
    address_sec_unit_type: null,
    address_sec_unit_num: null,
    address_city: 'Washington',
    address_state: 'DC',
    address_zipcode: '20510',
    address_type: 'Building',
    website: 'http://www.sullivan.senate.gov',
    contact_page: 'https://www.sullivan.senate.gov/contact/email',
    facebook_url: 'https://facebook.com/SenDanSullivan',
    twitter_handle: 'SenDanSullivan',
    twitter_url: 'https://twitter.com/SenDanSullivan',
    photo_url:
      'https://cdn.civil.services/us-senate/headshots/512x512/daniel-sullivan.jpg',
  },
  {
    state_name: 'Alaska',
    state_name_slug: 'alaska',
    state_code: 'AK',
    state_code_slug: 'ak',
    class: 'III',
    bioguide: 'M001153',
    thomas: '1694',
    opensecrets: 'N00026050',
    votesmart: '15841',
    fec: null,
    maplight: null,
    wikidata: null,
    google_entity_id: null,
    title: 'senator',
    party: 'republican',
    name: 'Lisa Murkowski',
    name_slug: 'lisa-murkowski',
    first_name: 'Lisa',
    middle_name: null,
    last_name: 'Murkowski',
    name_suffix: null,
    goes_by: null,
    pronunciation: 'LEE-suh mer-COW-skee',
    gender: 'female',
    ethnicity: 'white-american',
    religion: 'roman-catholic',
    openly_lgbtq: 'no',
    date_of_birth: '1957-05-22',
    entered_office: '2002-12-20',
    term_end: '2023-01-03',
    biography:
      "Lisa Murkowski (daughter of Frank Hughes Murkowski), a Senator from Alaska; born in Ketchikan, Alaska, on May 22, 1957; attended public schools in Fairbanks, AK; attended Willamette University in Salem, Oregon, 1975-1977; B.A. in Economics, Georgetown University 1980; J.D., Willamette College of Law 1985; attorney; member, Alaska Bar Association; Anchorage District Court attorney 1987-1989; private practice 1989-1996; Mayor's Task Force on the Homeless 1990-1991; Anchorage Equal Rights Commission 1997-1998; Alaska State house of representatives 1999-2002; appointed to the U.S. Senate on December 20, 2002, to fill the vacancy caused by the resignation of her father, Frank H. Murkowski; elected to the U.S. Senate in 2004; unsuccessful candidate for renomination in 2010, but subsequently elected as a write-in candidate in the November 2, 2010, general election, for the term ending January 3, 2017; Republican Conference vice-chair (2009-2010); chair, Committee on Energy and Natural Resources (One Hundred Fourteenth Congress).",
    phone: '202-224-6665',
    fax: '202-224-5301',
    latitude: '38.892839',
    longitude: '-77.004356',
    address_complete: '709 Hart Senate Office Building, Washington, DC 20510',
    address_number: null,
    address_prefix: null,
    address_street: '709 Hart Senate Office Building',
    address_sec_unit_type: null,
    address_sec_unit_num: null,
    address_city: 'Washington',
    address_state: 'DC',
    address_zipcode: '20510',
    address_type: 'Building',
    website: 'http://www.murkowski.senate.gov',
    contact_page: 'https://www.murkowski.senate.gov/public/index.cfm/contact',
    facebook_url: 'https://facebook.com/SenLisaMurkowski',
    twitter_handle: 'lisamurkowski',
    twitter_url: 'https://twitter.com/lisamurkowski',
    photo_url:
      'https://cdn.civil.services/us-senate/headshots/512x512/lisa-murkowski.jpg',
  },
  {
    state_name: 'Alabama',
    state_name_slug: 'alabama',
    state_code: 'AL',
    state_code_slug: 'al',
    class: 'II',
    bioguide: 'T000278',
    thomas: null,
    opensecrets: null,
    votesmart: '188306',
    fec: 'S0AL00230',
    maplight: null,
    wikidata: null,
    google_entity_id: null,
    title: 'senator',
    party: 'republican',
    name: 'Tommy Tuberville',
    name_slug: 'tommy-tuberville',
    first_name: 'Tommy',
    middle_name: 'Hawley',
    last_name: 'Tuberville',
    name_suffix: null,
    goes_by: null,
    pronunciation: 'taw-mee too-buh-vil',
    gender: 'male',
    ethnicity: 'white-american',
    religion: 'christian',
    openly_lgbtq: 'no',
    date_of_birth: '1954-09-18',
    entered_office: '2021-01-03',
    term_end: '2027-01-03',
    biography:
      'United States Senator Tommy Tuberville was elected to the U.S. Senate in 2020 and is currently serving his first term representing the state of Alabama. His constituents still know him as Coach Tuberville, following his 40-year career coaching college football.',
    phone: '202-224-4124',
    fax: null,
    latitude: '38.892542',
    longitude: '-77.007415',
    address_complete: 'B40A Dirksen Senate Office Building Washington DC 20510',
    address_number: null,
    address_prefix: null,
    address_street: 'B40A Dirksen Senate Office Building Washington DC 20510',
    address_sec_unit_type: null,
    address_sec_unit_num: null,
    address_city: 'Washington',
    address_state: 'DC',
    address_zipcode: '20510',
    address_type: 'Building',
    website: 'https://www.tuberville.senate.gov',
    contact_page: 'https://www.tuberville.senate.gov/',
    facebook_url: 'https://facebook.com/SenatorTuberville/',
    twitter_handle: 'SenTuberville',
    twitter_url: 'https://twitter.com/SenTuberville',
    photo_url:
      'https://cdn.civil.services/us-senate/headshots/512x512/tommy-tuberville.jpg',
  },
  {
    state_name: 'Alabama',
    state_name_slug: 'alabama',
    state_code: 'AL',
    state_code_slug: 'al',
    class: 'III',
    bioguide: 'S000320',
    thomas: '1049',
    opensecrets: 'N00009920',
    votesmart: '53266',
    fec: null,
    maplight: null,
    wikidata: null,
    google_entity_id: null,
    title: 'senator',
    party: 'republican',
    name: 'Richard  Shelby',
    name_slug: 'richard-shelby',
    first_name: 'Richard ',
    middle_name: 'C.',
    last_name: 'Shelby',
    name_suffix: null,
    goes_by: null,
    pronunciation: 'RICH-erd SHELL-bee',
    gender: 'male',
    ethnicity: 'white-american',
    religion: 'presbyterian',
    openly_lgbtq: 'no',
    date_of_birth: '1934-05-06',
    entered_office: '1987-01-03',
    term_end: '2023-01-03',
    biography:
      'Richard Shelby, a Senator and a Representative from Alabama; born in Birmingham, Ala., May 6, 1934; attended the public schools; graduated, University of Alabama 1957; graduated, University of Alabama School of Law 1963; admitted to the Alabama bar in 1961 and commenced practice in Tuscaloosa; city prosecutor, Tuscaloosa 1963-1971; United States Commissioner, Northern District of Alabama 1966-1970; Member, Alabama State Senate 1970-1978; elected as a Democrat to the Ninety-sixth Congress in 1978; reelected to the three succeeding Congresses and served from January 3, 1979, to January 3, 1987; was not a candidate for reelection to the House of Representatives in 1986, but was elected to the United States Senate; reelected in 1992, 1998, 2004, and again in 2010 for the term ending January 3, 2017; changed from the Democratic to the Republican Party in 1994; Chair, Select Committee on Intelligence (One Hundred Fifth and One Hundred Sixth Congresses, One Hundred Seventh Congress [January 20, 2001-June 6, 2001]), Committee on Banking, Housing and Urban Affairs (One Hundred Eighth and One Hundred Ninth Congresses, One Hundred Fourteenth Congress).',
    phone: '202-224-5744',
    fax: '202-224-3416',
    latitude: '38.892542',
    longitude: '-77.007415',
    address_complete:
      '304 Russell Senate Office Building, Washington, DC 20510',
    address_number: null,
    address_prefix: null,
    address_street: '304 Russell Senate Office Building',
    address_sec_unit_type: null,
    address_sec_unit_num: null,
    address_city: 'Washington',
    address_state: 'DC',
    address_zipcode: '20510',
    address_type: 'Building',
    website: 'http://www.shelby.senate.gov',
    contact_page:
      'https://www.shelby.senate.gov/public/index.cfm/emailsenatorshelby',
    facebook_url: 'https://facebook.com/RichardShelby',
    twitter_handle: 'SenShelby',
    twitter_url: 'https://twitter.com/SenShelby',
    photo_url:
      'https://cdn.civil.services/us-senate/headshots/512x512/richard-shelby.jpg',
  },
  {
    state_name: 'Arkansas',
    state_name_slug: 'arkansas',
    state_code: 'AR',
    state_code_slug: 'ar',
    class: 'III',
    bioguide: 'B001236',
    thomas: '1687',
    opensecrets: 'N00013873',
    votesmart: '27958',
    fec: null,
    maplight: null,
    wikidata: null,
    google_entity_id: null,
    title: 'senator',
    party: 'republican',
    name: 'John Boozman',
    name_slug: 'john-boozman',
    first_name: 'John',
    middle_name: null,
    last_name: 'Boozman',
    name_suffix: null,
    goes_by: null,
    pronunciation: 'JAHN BOHZ-muhn',
    gender: 'male',
    ethnicity: 'white-american',
    religion: 'southern-baptist',
    openly_lgbtq: 'no',
    date_of_birth: '1950-12-10',
    entered_office: '2011-01-03',
    term_end: '2023-01-03',
    biography:
      'John Boozman, a Senator and a Representative from Arkansas; born in Shreveport, La., December 10, 1950; graduated from Northside High School, Fort Smith, Ark.; attended the University of Arkansas, Fayetteville, Ark., 1969-1972; graduated Southern College of Optometry, 1977; optometrist; business owner; rancher; member of the Rogers, Ark., school board; elected as a Republican to the One Hundred Seventh Congress by special election to fill the vacancy caused by the resignation of United States Representative Asa Hutchinson; reelected to the four succeeding Congresses (November 20, 2001-January 3, 2011); was not a candidate for reelection to the House of Representatives but was elected as a Republican to the United States Senate in 2010 for the term ending January 3, 2017.',
    phone: '202-224-4843',
    fax: '202-228-1371',
    latitude: '38.892839',
    longitude: '-77.004356',
    address_complete: '141 Hart Senate Office Building, Washington, DC 20510',
    address_number: null,
    address_prefix: null,
    address_street: '141 Hart Senate Office Building',
    address_sec_unit_type: null,
    address_sec_unit_num: null,
    address_city: 'Washington',
    address_state: 'DC',
    address_zipcode: '20510',
    address_type: 'Building',
    website: 'http://www.boozman.senate.gov',
    contact_page: 'https://www.boozman.senate.gov/public/index.cfm/contact',
    facebook_url: 'https://facebook.com/JohnBoozman',
    twitter_handle: 'JohnBoozman',
    twitter_url: 'https://twitter.com/JohnBoozman',
    photo_url:
      'https://cdn.civil.services/us-senate/headshots/512x512/john-boozman.jpg',
  },
  {
    state_name: 'Arkansas',
    state_name_slug: 'arkansas',
    state_code: 'AR',
    state_code_slug: 'ar',
    class: 'II',
    bioguide: 'C001095',
    thomas: '2098',
    opensecrets: 'N00033363',
    votesmart: '135651',
    fec: null,
    maplight: null,
    wikidata: null,
    google_entity_id: null,
    title: 'senator',
    party: 'republican',
    name: 'Tom Cotton',
    name_slug: 'tom-cotton',
    first_name: 'Tom',
    middle_name: null,
    last_name: 'Cotton',
    name_suffix: null,
    goes_by: null,
    pronunciation: 'TAHM KAH-tn',
    gender: 'male',
    ethnicity: 'white-american',
    religion: 'methodist',
    openly_lgbtq: 'no',
    date_of_birth: '1977-05-13',
    entered_office: '2015-01-03',
    term_end: '2027-01-03',
    biography:
      'Tom Cotton, a Senator and a Representative from Arkansas; born in Dardanelle, Yell County, Ark., May 13, 1977; A.B., Harvard University, Cambridge, Mass., 1998; attended Claremont Graduate University, Claremont, Calif., 1998-1999; J.D., Harvard University, Cambridge, Mass., 2002; United States Army, 2004-2009; clerk, U.S. Court of Appeals, 2002-2003; lawyer, private practice; management consultant; farmer; elected as a Republican to the One Hundred Thirteenth Congress (January 3, 2013-January 3, 2015); was not a candidate for reelection to the House of Representatives but was elected as a Republican to the United States Senate in 2014 for the term ending January 3, 2021.',
    phone: '202-224-2353',
    fax: '479-927-1092',
    latitude: '38.892542',
    longitude: '-77.007415',
    address_complete:
      '124 Russell Senate Office Building, Washington, DC 20510',
    address_number: null,
    address_prefix: null,
    address_street: '124 Russell Senate Office Building',
    address_sec_unit_type: null,
    address_sec_unit_num: null,
    address_city: 'Washington',
    address_state: 'DC',
    address_zipcode: '20510',
    address_type: 'Building',
    website: 'http://www.cotton.senate.gov',
    contact_page: 'https://www.cotton.senate.gov/?p=contact',
    facebook_url: 'https://facebook.com/SenatorTomCotton',
    twitter_handle: 'sentomcotton',
    twitter_url: 'https://twitter.com/sentomcotton',
    photo_url:
      'https://cdn.civil.services/us-senate/headshots/512x512/tom-cotton.jpg',
  },
  {
    state_name: 'Arizona',
    state_name_slug: 'arizona',
    state_code: 'AZ',
    state_code_slug: 'az',
    class: 'I',
    bioguide: 'S001191',
    thomas: '2099',
    opensecrets: 'N00033983',
    votesmart: '28338',
    fec: 'S8AZ00197',
    maplight: null,
    wikidata: 'Q1556541',
    google_entity_id: null,
    title: 'senator',
    party: 'democrat',
    name: 'Kyrsten Sinema',
    name_slug: 'kyrsten-sinema',
    first_name: 'Kyrsten',
    middle_name: null,
    last_name: 'Sinema',
    name_suffix: null,
    goes_by: null,
    pronunciation: 'KEERS-ten SI-nuh-muh',
    gender: 'female',
    ethnicity: 'white-american',
    religion: 'unspecified',
    openly_lgbtq: 'bisexual',
    date_of_birth: '1976-07-12',
    entered_office: '2019-01-03',
    term_end: '2025-01-03',
    biography:
      "Kyrsten Sinema is a Democratic member of the U.S. Senate from Arizona. Sinema was elected to the office on November 6, 2018. Sinema is a Democratic member of the U.S. House representing Arizona's 9th Congressional District. She was first elected in 2012 and sworn in on January 3, 2013, becoming the first openly bisexual member of Congress.",
    phone: '202-224-4521',
    fax: null,
    latitude: '38.892839',
    longitude: '-77.004356',
    address_complete: '825B&C Hart Senate Office Building, Washington DC 20510',
    address_number: null,
    address_prefix: null,
    address_street: '825B&C Hart Senate Office Building',
    address_sec_unit_type: null,
    address_sec_unit_num: null,
    address_city: 'Washington',
    address_state: 'DC',
    address_zipcode: '20510',
    address_type: 'Building',
    website: 'https://www.sinema.senate.gov/',
    contact_page:
      'https://www.senate.gov/senators/116thCongress/SinemaKyrsten.htm',
    facebook_url: 'https://facebook.com/KyrstenSinema',
    twitter_handle: 'kyrstensinema',
    twitter_url: 'https://twitter.com/kyrstensinema',
    photo_url:
      'https://cdn.civil.services/us-senate/headshots/512x512/kyrsten-sinema.jpg',
  },
  {
    state_name: 'Arizona',
    state_name_slug: 'arizona',
    state_code: 'AZ',
    state_code_slug: 'az',
    class: 'III',
    bioguide: 'K000377',
    thomas: null,
    opensecrets: 'N00044223',
    votesmart: null,
    fec: 'S0AZ00350',
    maplight: null,
    wikidata: null,
    google_entity_id: null,
    title: 'senator',
    party: 'democrat',
    name: 'Mark Kelly',
    name_slug: 'mark-kelly',
    first_name: 'Mark',
    middle_name: null,
    last_name: 'Kelly',
    name_suffix: null,
    goes_by: null,
    pronunciation: 'marhk kel-ly',
    gender: 'male',
    ethnicity: 'white-american',
    religion: 'unspecified',
    openly_lgbtq: 'no',
    date_of_birth: '1964-02-21',
    entered_office: '2020-12-02',
    term_end: '2023-01-03',
    biography:
      'Senator Kelly is the son of two police officers, and he attended public schools from elementary school through the U.S. Merchant Marine Academy and U.S. Naval Postgraduate School. As a naval aviator, he served on the aircraft carrier the U.S.S. Midway and flew 39 combat missions in Operation Desert Storm.',
    phone: '202-224-2235',
    fax: null,
    latitude: '38.892947',
    longitude: '-77.075686',
    address_complete: 'B40B Dirksen Senate Office Building Washington DC 20510',
    address_number: null,
    address_prefix: null,
    address_street: 'B40B Dirksen Senate Office Building Washington DC 20510',
    address_sec_unit_type: null,
    address_sec_unit_num: null,
    address_city: 'Washington',
    address_state: 'DC',
    address_zipcode: '20510',
    address_type: 'Building',
    website: 'https://www.kelly.senate.gov',
    contact_page: 'https://www.kelly.senate.gov/',
    facebook_url: 'https://facebook.com/SenMarkKelly/',
    twitter_handle: 'SenMarkKelly',
    twitter_url: 'https://twitter.com/SenMarkKelly',
    photo_url:
      'https://cdn.civil.services/us-senate/headshots/512x512/mark-kelly.jpg',
  },
  {
    state_name: 'California',
    state_name_slug: 'california',
    state_code: 'CA',
    state_code_slug: 'ca',
    class: 'I',
    bioguide: 'F000062',
    thomas: '1332',
    opensecrets: 'N00007364',
    votesmart: '53273',
    fec: null,
    maplight: null,
    wikidata: null,
    google_entity_id: null,
    title: 'senator',
    party: 'democrat',
    name: 'Dianne Feinstein',
    name_slug: 'dianne-feinstein',
    first_name: 'Dianne',
    middle_name: null,
    last_name: 'Feinstein',
    name_suffix: null,
    goes_by: null,
    pronunciation: 'DIE-ann FINE-stine',
    gender: 'female',
    ethnicity: 'white-american',
    religion: 'jewish',
    openly_lgbtq: 'no',
    date_of_birth: '1933-06-22',
    entered_office: '1992-11-10',
    term_end: '2019-01-03',
    biography:
      "Dianne Feinstein, a Senator from California; born in San Francisco, Calif., June 22, 1933; attended the San Francisco public schools and graduated from the Convent of the Sacred Heart High School 1951; graduated, Stanford University 1955; Member, California Women's Board of Terms and Parole 1960-1966; Member, San Francisco Board of Supervisors 1970-1978, serving as president 1970-1971, 1974-1975, 1978; mayor of San Francisco 1978-1988; director, Bank of California 1988-1989; co-chair, San Francisco Education Fund's Permanent Fund 1988-1989; unsuccessful candidate for Governor of California 1990; elected in a special election on November 3, 1992, as a Democrat to the United States Senate to fill the term left vacant by the resignation of Pete Wilson; took office on November 4, 1992, and took the oath of office on November 10, 1992; reelected in 1994, 2000, 2006, and again in 2012 for the term ending January 3, 2019; chair, Committee on Rules and Administration (One Hundred Tenth Congress), Select Committee on Intelligence (One Hundred Eleventh to One Hundred Thirteenth Congresses).",
    phone: '202-224-3841',
    fax: '202-228-3954',
    latitude: '38.892839',
    longitude: '-77.004356',
    address_complete: '331 Hart Senate Office Building, Washington, DC 20510',
    address_number: null,
    address_prefix: null,
    address_street: '331 Hart Senate Office Building',
    address_sec_unit_type: null,
    address_sec_unit_num: null,
    address_city: 'Washington',
    address_state: 'DC',
    address_zipcode: '20510',
    address_type: 'Building',
    website: 'http://www.feinstein.senate.gov',
    contact_page: 'https://www.feinstein.senate.gov/public/index.cfm/e-mail-me',
    facebook_url: 'https://facebook.com/SenatorFeinstein',
    twitter_handle: 'SenFeinstein',
    twitter_url: 'https://twitter.com/SenFeinstein',
    photo_url:
      'https://cdn.civil.services/us-senate/headshots/512x512/dianne-feinstein.jpg',
  },
  {
    state_name: 'California',
    state_name_slug: 'california',
    state_code: 'CA',
    state_code_slug: 'ca',
    class: 'III',
    bioguide: 'P000145',
    thomas: null,
    opensecrets: null,
    votesmart: '59742',
    fec: null,
    maplight: null,
    wikidata: null,
    google_entity_id: null,
    title: 'senator',
    party: 'democrat',
    name: 'Alejandro Padilla',
    name_slug: 'alejandro-padilla',
    first_name: 'Alejandro',
    middle_name: null,
    last_name: 'Padilla',
    name_suffix: null,
    goes_by: 'Alex',
    pronunciation: 'ah-lex pa-dil-la',
    gender: 'male',
    ethnicity: 'hispanic-american',
    religion: 'catholic',
    openly_lgbtq: 'no',
    date_of_birth: '1973-03-22',
    entered_office: '2021-01-20',
    term_end: '2027-01-03',
    biography:
      'Senator Alex Padilla is the proud son of immigrants from Mexico, his father a short-order cook and his mother a housekeeper. Padilla attended Los Angeles public schools and is a graduate of the Massachusetts Institute of Technology (MIT) where he earned a Bachelor’s of Science degree in Mechanical Engineering.',
    phone: '202-224-3553',
    fax: null,
    latitude: '38.892839',
    longitude: '-77.004356',
    address_complete: '112 Hart Senate Office Building Washington DC 20510',
    address_number: null,
    address_prefix: null,
    address_street: '112 Hart Senate Office Building Washington DC 20510',
    address_sec_unit_type: null,
    address_sec_unit_num: null,
    address_city: 'Washington',
    address_state: 'DC',
    address_zipcode: '20510',
    address_type: 'Building',
    website: 'https://www.padilla.senate.gov/',
    contact_page: 'https://www.padilla.senate.gov/',
    facebook_url: 'https://facebook.com/SenAlexPadilla/',
    twitter_handle: 'SenAlexPadilla',
    twitter_url: 'https://twitter.com/SenAlexPadilla',
    photo_url:
      'https://cdn.civil.services/us-senate/headshots/512x512/alejandro-padilla.jpg',
  },
  {
    state_name: 'Colorado',
    state_name_slug: 'colorado',
    state_code: 'CO',
    state_code_slug: 'co',
    class: 'II',
    bioguide: 'H000273',
    thomas: null,
    opensecrets: null,
    votesmart: null,
    fec: 'S0CO00575',
    maplight: null,
    wikidata: null,
    google_entity_id: null,
    title: 'senator',
    party: 'democrat',
    name: 'John Hickenlooper',
    name_slug: 'john-hickenlooper',
    first_name: 'John',
    middle_name: 'Wright',
    last_name: 'Hickenlooper',
    name_suffix: 'Jr.',
    goes_by: null,
    pronunciation: 'jawn hih-ken-loo-puh',
    gender: 'male',
    ethnicity: 'white-american',
    religion: 'unspecified',
    openly_lgbtq: 'no',
    date_of_birth: '1952-02-07',
    entered_office: '2021-01-03',
    term_end: '2027-01-03',
    biography:
      'John started his career in Colorado as a geologist. After being laid off in the long recession of the mid-80’s, along with an estimated 10,000 other Earth Science professionals, he opened the state’s first brewpub in a neglected warehouse district in Lower Downtown (LoDo) Denver.',
    phone: '202-224-5941',
    fax: null,
    latitude: '38.892542',
    longitude: '-77.007415',
    address_complete: 'B85 Russell Senate Office Building Washington DC 20510',
    address_number: null,
    address_prefix: null,
    address_street: 'B85 Russell Senate Office Building Washington DC 20510',
    address_sec_unit_type: null,
    address_sec_unit_num: null,
    address_city: 'Washington',
    address_state: 'DC',
    address_zipcode: '20510',
    address_type: 'Building',
    website: 'https://www.hickenlooper.senate.gov/',
    contact_page: 'https://www.hickenlooper.senate.gov',
    facebook_url: null,
    twitter_handle: 'SenatorHick',
    twitter_url: 'https://twitter.com/SenatorHick',
    photo_url:
      'https://cdn.civil.services/us-senate/headshots/512x512/john-hickenlooper.jpg',
  },
  {
    state_name: 'Colorado',
    state_name_slug: 'colorado',
    state_code: 'CO',
    state_code_slug: 'co',
    class: 'III',
    bioguide: 'B001267',
    thomas: '1965',
    opensecrets: 'N00030608',
    votesmart: '110942',
    fec: null,
    maplight: null,
    wikidata: null,
    google_entity_id: null,
    title: 'senator',
    party: 'democrat',
    name: 'Michael  Bennet',
    name_slug: 'michael-bennet',
    first_name: 'Michael ',
    middle_name: 'F.',
    last_name: 'Bennet',
    name_suffix: null,
    goes_by: null,
    pronunciation: 'MAHY-kuhl BEN-ett',
    gender: 'male',
    ethnicity: 'white-american',
    religion: 'unspecified',
    openly_lgbtq: 'no',
    date_of_birth: '1964-11-28',
    entered_office: '2009-01-21',
    term_end: '2023-01-03',
    biography:
      'Michael Bennet, a Senator from Colorado; born in New Delhi, India, November 28, 1964; B.A., Wesleyan University, Middletown, Conn., 1987; J.D., Yale Law School, 1993, editor-in-chief of the Yale Law Journal; counsel to U.S. deputy attorney general 1995-1997; special assistant U.S. attorney, Conn., 1997; managing director, Anschutz Investment Co. 1997-2003; chief of staff to Mayor of Denver, Colo., 2003-2005; Superintendent, Denver Public Schools 2005-2009; appointed January 21, 2009, to the United States Senate for the term ending January 3, 2011, to fill the vacancy caused by the resignation of Kenneth L. Salazar; took the oath of office on January 22, 2009; elected to the U.S. Senate in 2010 for the term ending January 3, 2017; chair, Democratic Senatorial Campaign Committee (2013-2014).',
    phone: '202-224-5852',
    fax: '202-228-5097',
    latitude: '38.892542',
    longitude: '-77.007415',
    address_complete:
      '261 Russell Senate Office Building, Washington, DC 20510',
    address_number: null,
    address_prefix: null,
    address_street: '261 Russell Senate Office Building',
    address_sec_unit_type: null,
    address_sec_unit_num: null,
    address_city: 'Washington',
    address_state: 'DC',
    address_zipcode: '20510',
    address_type: 'Building',
    website: 'http://www.bennet.senate.gov',
    contact_page: 'https://www.bennet.senate.gov/?p=contact',
    facebook_url: 'https://facebook.com/SenatorBennet',
    twitter_handle: 'SenatorBennet',
    twitter_url: 'https://twitter.com/SenatorBennet',
    photo_url:
      'https://cdn.civil.services/us-senate/headshots/512x512/michael-bennet.jpg',
  },
  {
    state_name: 'Connecticut',
    state_name_slug: 'connecticut',
    state_code: 'CT',
    state_code_slug: 'ct',
    class: 'I',
    bioguide: 'M001169',
    thomas: '1837',
    opensecrets: 'N00027566',
    votesmart: '17189',
    fec: null,
    maplight: null,
    wikidata: null,
    google_entity_id: null,
    title: 'senator',
    party: 'democrat',
    name: 'Christopher Murphy',
    name_slug: 'christopher-murphy',
    first_name: 'Christopher',
    middle_name: null,
    last_name: 'Murphy',
    name_suffix: null,
    goes_by: 'Chris',
    pronunciation: 'KRISS MER-fee',
    gender: 'male',
    ethnicity: 'white-american',
    religion: 'protestant',
    openly_lgbtq: 'no',
    date_of_birth: '1973-08-03',
    entered_office: '2013-01-03',
    term_end: '2019-01-03',
    biography:
      'Christopher Murphy, a Senator and a Representative from Connecticut; born in White Plains, Westchester County, N.Y., August 3, 1973; B.A., Williams College, Williamstown, Mass., 1996; attended Exeter College, Oxford, England, 1994-1995; J.D., University of Connecticut, Hartford, Conn., 2002; lawyer, private practice; Southington, Conn., planning and zoning commission, 1997-1999; member of the Connecticut state house of representatives, 1999-2003; member of the Connecticut state senate, 2003-2006; elected as a Democrat to the One Hundred Tenth and to the two succeeding Congresses (January 3, 2007-January 3, 2013); was not a candidate for reelection to the House of Representatives but was elected as a Democrat to the United States Senate in 2012 for the term ending January 3, 2019.',
    phone: '202-224-4041',
    fax: '202-224-9750',
    latitude: '38.892839',
    longitude: '-77.004356',
    address_complete: '136 Hart Senate Office Building, Washington, DC 20510',
    address_number: null,
    address_prefix: null,
    address_street: '136 Hart Senate Office Building',
    address_sec_unit_type: null,
    address_sec_unit_num: null,
    address_city: 'Washington',
    address_state: 'DC',
    address_zipcode: '20510',
    address_type: 'Building',
    website: 'http://www.murphy.senate.gov',
    contact_page: 'https://www.murphy.senate.gov/contact',
    facebook_url: 'https://facebook.com/ChrisMurphyCT',
    twitter_handle: 'ChrisMurphyCT',
    twitter_url: 'https://twitter.com/ChrisMurphyCT',
    photo_url:
      'https://cdn.civil.services/us-senate/headshots/512x512/christopher-murphy.jpg',
  },
  {
    state_name: 'Connecticut',
    state_name_slug: 'connecticut',
    state_code: 'CT',
    state_code_slug: 'ct',
    class: 'III',
    bioguide: 'B001277',
    thomas: '2076',
    opensecrets: 'N00031685',
    votesmart: '1568',
    fec: null,
    maplight: null,
    wikidata: null,
    google_entity_id: null,
    title: 'senator',
    party: 'democrat',
    name: 'Richard Blumenthal',
    name_slug: 'richard-blumenthal',
    first_name: 'Richard',
    middle_name: null,
    last_name: 'Blumenthal',
    name_suffix: null,
    goes_by: 'Dick',
    pronunciation: 'DICK BLOO-mun-thawl',
    gender: 'male',
    ethnicity: 'white-american',
    religion: 'jewish',
    openly_lgbtq: 'no',
    date_of_birth: '1946-02-13',
    entered_office: '2011-01-05',
    term_end: '2023-01-03',
    biography:
      'Richard Blumenthal, a Senator from Connecticut; born in Brooklyn, N.Y., February 13, 1946; graduated Harvard University, B.A., 1967; attended Cambridge University, 1967-1968; graduated Yale University, J.D., 1973; editor-in-chief of the Yale Law Journal; served in the United States Marine Corps Reserves 1970-1976; White House aide 1969-1970; law clerk to United States Supreme Court Justice Harry A. Blackmun 1974-1975; administrative assistant to Senator Abraham Ribicoff 1975-1976; United States Attorney for Connecticut 1977-1981; lawyer; member of the Connecticut state house of representatives 1984-1987; member of the Connecticut state senate 1987-1990; attorney general of Connecticut 1991-2010; elected as a Democrat to the United States Senate in 2010 for the term ending January 3, 2017.',
    phone: '202-224-2823',
    fax: '202-224-9673',
    latitude: '38.892839',
    longitude: '-77.004356',
    address_complete: '706 Hart Senate Office Building, Washington, DC 20510',
    address_number: null,
    address_prefix: null,
    address_street: '706 Hart Senate Office Building',
    address_sec_unit_type: null,
    address_sec_unit_num: null,
    address_city: 'Washington',
    address_state: 'DC',
    address_zipcode: '20510',
    address_type: 'Building',
    website: 'http://www.blumenthal.senate.gov',
    contact_page: 'https://www.blumenthal.senate.gov/contact/',
    facebook_url: 'https://facebook.com/SenBlumenthal',
    twitter_handle: 'SenBlumenthal',
    twitter_url: 'https://twitter.com/SenBlumenthal',
    photo_url:
      'https://cdn.civil.services/us-senate/headshots/512x512/richard-blumenthal.jpg',
  },
  {
    state_name: 'Delaware',
    state_name_slug: 'delaware',
    state_code: 'DE',
    state_code_slug: 'de',
    class: 'II',
    bioguide: 'C001088',
    thomas: '1984',
    opensecrets: 'N00031820',
    votesmart: '122834',
    fec: null,
    maplight: null,
    wikidata: null,
    google_entity_id: null,
    title: 'senator',
    party: 'democrat',
    name: 'Christopher Coons',
    name_slug: 'christopher-coons',
    first_name: 'Christopher',
    middle_name: 'A.',
    last_name: 'Coons',
    name_suffix: null,
    goes_by: 'Chris',
    pronunciation: 'KRISS KOONZ',
    gender: 'male',
    ethnicity: 'white-american',
    religion: 'presbyterian',
    openly_lgbtq: 'no',
    date_of_birth: '1963-09-09',
    entered_office: '2010-11-15',
    term_end: '2027-01-03',
    biography:
      'Chris Coons, a Senator from Delaware; born on September 9, 1963, in Greenwich, Conn.; B.A., Amherst College, 1985; J.D., Yale University Law School, 1992; M.A.R., Yale Divinity School, 1992; worked in the non-profit sector; attorney; served as president of the New Castle County Council 2001-2005; County Executive of New Castle County 2005-2010; elected as a Democrat to the United States Senate in the November 2, 2010, special election to the term ending January 3, 2015, a seat previously held by appointed Senator Edward E. Kaufman, and took the oath of office on November 15, 2010; reelected in 2014 for the term ending January 3, 2021.',
    phone: '202-224-5042',
    fax: null,
    latitude: '38.892542',
    longitude: '-77.007415',
    address_complete:
      '127A Russell Senate Office Building, Washington, DC 20510',
    address_number: null,
    address_prefix: null,
    address_street: '127A Russell Senate Office Building',
    address_sec_unit_type: null,
    address_sec_unit_num: null,
    address_city: 'Washington',
    address_state: 'DC',
    address_zipcode: '20510',
    address_type: 'Building',
    website: 'http://www.coons.senate.gov',
    contact_page: 'https://www.coons.senate.gov/contact',
    facebook_url: 'https://facebook.com/senatorchriscoons',
    twitter_handle: 'ChrisCoons',
    twitter_url: 'https://twitter.com/ChrisCoons',
    photo_url:
      'https://cdn.civil.services/us-senate/headshots/512x512/christopher-coons.jpg',
  },
  {
    state_name: 'Delaware',
    state_name_slug: 'delaware',
    state_code: 'DE',
    state_code_slug: 'de',
    class: 'I',
    bioguide: 'C000174',
    thomas: '179',
    opensecrets: 'N00012508',
    votesmart: '22421',
    fec: null,
    maplight: null,
    wikidata: null,
    google_entity_id: null,
    title: 'senator',
    party: 'democrat',
    name: 'Thomas Carper',
    name_slug: 'thomas-carper',
    first_name: 'Thomas',
    middle_name: 'R.',
    last_name: 'Carper',
    name_suffix: null,
    goes_by: 'Tom',
    pronunciation: 'TAHM KAR-per',
    gender: 'male',
    ethnicity: 'white-american',
    religion: 'presbyterian',
    openly_lgbtq: 'no',
    date_of_birth: '1947-01-23',
    entered_office: '2001-01-03',
    term_end: '2019-01-03',
    biography:
      'Thomas Carper, a Senator and a Representative from Delaware; born in Beckley, Raleigh County, W.Va., January 23, 1947; attended public schools; graduated, Whetstone High School, Columbus, Ohio 1964; B.A., Ohio State University, Columbus 1968; University of Delaware, Newark, M.B.A. 1975; served in the United States Navy 1968-1973; Naval Reserve, commander, 1973-1991; Delaware State Treasurer 1976-1983; elected as a Democrat to the Ninety-eighth and to the four succeeding Congresses (January 3, 1983-January 3, 1993); Governor of Delaware 1993-2001; chairman of the National Governors Association 1998-1999; elected to the United States Senate in 2000 for term commencing January 3, 2001; reelected in 2006 and again in 2012 for the term ending January 3, 2019; chair, Committee on Homeland Security and Governmental Affairs (One Hundred Thirteenth Congress).',
    phone: '202-224-2441',
    fax: '202-228-2190',
    latitude: '38.892839',
    longitude: '-77.004356',
    address_complete: '513 Hart Senate Office Building, Washington, DC 20510',
    address_number: null,
    address_prefix: null,
    address_street: '513 Hart Senate Office Building',
    address_sec_unit_type: null,
    address_sec_unit_num: null,
    address_city: 'Washington',
    address_state: 'DC',
    address_zipcode: '20510',
    address_type: 'Building',
    website: 'http://www.carper.senate.gov',
    contact_page:
      'https://www.carper.senate.gov/public/index.cfm/email-senator-carper',
    facebook_url: 'https://facebook.com/tomcarper',
    twitter_handle: 'SenatorCarper',
    twitter_url: 'https://twitter.com/SenatorCarper',
    photo_url:
      'https://cdn.civil.services/us-senate/headshots/512x512/thomas-carper.jpg',
  },
  {
    state_name: 'Florida',
    state_name_slug: 'florida',
    state_code: 'FL',
    state_code_slug: 'fl',
    class: 'I',
    bioguide: 'S001217',
    thomas: null,
    opensecrets: 'N00043290',
    votesmart: '124204',
    fec: 'S8FL00273',
    maplight: null,
    wikidata: 'Q439729',
    google_entity_id: null,
    title: 'senator',
    party: 'republican',
    name: 'Rick Scott',
    name_slug: 'rick-scott',
    first_name: 'Rick',
    middle_name: null,
    last_name: 'Scott',
    name_suffix: null,
    goes_by: null,
    pronunciation: 'RIK SKAHT',
    gender: 'male',
    ethnicity: 'white-american',
    religion: 'unspecified',
    openly_lgbtq: 'no',
    date_of_birth: '1952-12-01',
    entered_office: '2019-01-03',
    term_end: '2025-01-03',
    biography:
      'Rick Scott is a Republican member of the U.S. Senate from Florida. Scott was elected to the office on November 6, 2018. Scott was elected governor of Florida in 2010 and 2014. Prior to serving in public office, Scott owned an investment firm and a business that acquired healthcare organizations',
    phone: '202-224-5274',
    fax: null,
    latitude: '38.892839',
    longitude: '-77.004356',
    address_complete: '716 Hart Senate Office Building, Washington, DC 20510',
    address_number: null,
    address_prefix: null,
    address_street: '716 Hart Senate Office Building',
    address_sec_unit_type: null,
    address_sec_unit_num: null,
    address_city: 'Washington',
    address_state: 'DC',
    address_zipcode: '20510',
    address_type: 'Building',
    website: 'https://www.rickscott.senate.gov',
    contact_page: 'https://www.rickscott.senate.gov/contact_rick',
    facebook_url: 'https://facebook.com/RickScottSenOffice',
    twitter_handle: 'SenRickScott',
    twitter_url: 'https://twitter.com/SenRickScott',
    photo_url:
      'https://cdn.civil.services/us-senate/headshots/512x512/rick-scott.jpg',
  },
  {
    state_name: 'Florida',
    state_name_slug: 'florida',
    state_code: 'FL',
    state_code_slug: 'fl',
    class: 'III',
    bioguide: 'R000595',
    thomas: '2084',
    opensecrets: 'N00030612',
    votesmart: '1601',
    fec: null,
    maplight: null,
    wikidata: null,
    google_entity_id: null,
    title: 'senator',
    party: 'republican',
    name: 'Marco Rubio',
    name_slug: 'marco-rubio',
    first_name: 'Marco',
    middle_name: null,
    last_name: 'Rubio',
    name_suffix: null,
    goes_by: null,
    pronunciation: 'MAR-koh ROO-bee-oh',
    gender: 'male',
    ethnicity: 'hispanic-american',
    religion: 'roman-catholic',
    openly_lgbtq: 'no',
    date_of_birth: '1971-05-28',
    entered_office: '2011-01-03',
    term_end: '2023-01-03',
    biography:
      'Marco Rubio, a Senator from Florida; born in Miami, Fla., May 28, 1971; graduated University of Florida, B.S., 1993; graduated University of Miami, J.D., 1996; lawyer; West Miami City Commission 1998-2000; member of the Florida house of representatives 2000-2008; majority leader 2003-2006; speaker 2006-2008; elected as a Republican to the United States Senate for the term ending January 3, 2017.',
    phone: '202-224-3041',
    fax: null,
    latitude: '38.892542',
    longitude: '-77.007415',
    address_complete:
      '284 Russell Senate Office Building, Washington, DC 20510',
    address_number: null,
    address_prefix: null,
    address_street: '284 Russell Senate Office Building',
    address_sec_unit_type: null,
    address_sec_unit_num: null,
    address_city: 'Washington',
    address_state: 'DC',
    address_zipcode: '20510',
    address_type: 'Building',
    website: 'http://www.rubio.senate.gov',
    contact_page: 'https://www.rubio.senate.gov/public/index.cfm/contact',
    facebook_url: 'https://facebook.com/SenatorMarcoRubio',
    twitter_handle: 'marcorubio',
    twitter_url: 'https://twitter.com/marcorubio',
    photo_url:
      'https://cdn.civil.services/us-senate/headshots/512x512/marco-rubio.jpg',
  },
  {
    state_name: 'Georgia',
    state_name_slug: 'georgia',
    state_code: 'GA',
    state_code_slug: 'ga',
    class: 'II',
    bioguide: 'O000174',
    thomas: null,
    opensecrets: null,
    votesmart: '176134',
    fec: 'S8GA00180',
    maplight: null,
    wikidata: null,
    google_entity_id: null,
    title: 'senator',
    party: 'democrat',
    name: 'Jon Ossoff',
    name_slug: 'jon-ossoff',
    first_name: 'Jon',
    middle_name: null,
    last_name: 'Ossoff',
    name_suffix: null,
    goes_by: null,
    pronunciation: 'john os off',
    gender: 'male',
    ethnicity: 'white-american',
    religion: 'jewish',
    openly_lgbtq: 'no',
    date_of_birth: '1987-02-16',
    entered_office: '2021-01-20',
    term_end: '2027-01-03',
    biography:
      'Before his election to the Senate, Jon led a team that exposed ISIS war crimes, atrocities committed by peacekeeping troops, human trafficking, fraud, theft, corruption, corporate abuse, and murder as the CEO of a 30-year-old company that produces investigations of official corruption, organized crime, and war crimes for international news organizations.',
    phone: '202-224-3521',
    fax: null,
    latitude: '38.892542',
    longitude: '-77.007415',
    address_complete: '825 B&C Hart Senate Office Building Washington DC 20510',
    address_number: null,
    address_prefix: null,
    address_street: '825 B&C Hart Senate Office Building Washington DC 20510',
    address_sec_unit_type: null,
    address_sec_unit_num: null,
    address_city: 'Washington',
    address_state: 'DC',
    address_zipcode: '20510',
    address_type: 'Building',
    website: 'https://www.ossoff.senate.gov/',
    contact_page: 'https://www.ossoff.senate.gov/contact/',
    facebook_url: 'https://facebook.com/SenOssoff',
    twitter_handle: 'SenOssoff',
    twitter_url: 'https://twitter.com/SenOssoff',
    photo_url:
      'https://cdn.civil.services/us-senate/headshots/512x512/jon-ossoff.jpg',
  },
  {
    state_name: 'Georgia',
    state_name_slug: 'georgia',
    state_code: 'GA',
    state_code_slug: 'ga',
    class: 'III',
    bioguide: 'W000790',
    thomas: null,
    opensecrets: null,
    votesmart: '189794',
    fec: 'S0GA00559',
    maplight: null,
    wikidata: null,
    google_entity_id: null,
    title: 'senator',
    party: 'democrat',
    name: 'Raphael Warnock',
    name_slug: 'raphael-warnock',
    first_name: 'Raphael',
    middle_name: 'Gamaliel',
    last_name: 'Warnock',
    name_suffix: null,
    goes_by: null,
    pronunciation: 'rap-hael war-nock',
    gender: 'male',
    ethnicity: 'african-american',
    religion: 'baptist',
    openly_lgbtq: 'no',
    date_of_birth: '1969-07-23',
    entered_office: '2021-01-20',
    term_end: '2027-01-03',
    biography:
      'Senator Reverend Raphael Warnock grew up in Kayton Homes public housing in Savannah, born one of twelve brothers and sisters raised in coastal Georgia. His father, a veteran, small businessman, and preacher, grew up in Burke and Screven County, GA.',
    phone: '202-224-3643',
    fax: null,
    latitude: '38.892542',
    longitude: '-77.007415',
    address_complete: 'B40D Dirksen Senate Office Building Washington DC 20510',
    address_number: null,
    address_prefix: null,
    address_street: 'B40D Dirksen Senate Office Building Washington DC 20510',
    address_sec_unit_type: null,
    address_sec_unit_num: null,
    address_city: 'Washington',
    address_state: 'DC',
    address_zipcode: '20510',
    address_type: 'Building',
    website: 'https://www.warnock.senate.gov/',
    contact_page: 'https://www.warnock.senate.gov/',
    facebook_url: 'https://facebook.com/SenatorWarnock',
    twitter_handle: 'SenatorWarnock',
    twitter_url: 'https://twitter.com/SenatorWarnock',
    photo_url:
      'https://cdn.civil.services/us-senate/headshots/512x512/raphael-warnock.jpg',
  },
  {
    state_name: 'Hawaii',
    state_name_slug: 'hawaii',
    state_code: 'HI',
    state_code_slug: 'hi',
    class: 'III',
    bioguide: 'S001194',
    thomas: '2173',
    opensecrets: 'N00028138',
    votesmart: '17852',
    fec: null,
    maplight: null,
    wikidata: null,
    google_entity_id: null,
    title: 'senator',
    party: 'democrat',
    name: 'Brian Schatz',
    name_slug: 'brian-schatz',
    first_name: 'Brian',
    middle_name: null,
    last_name: 'Schatz',
    name_suffix: null,
    goes_by: null,
    pronunciation: 'BRAHY-uhn SHAHTS [Rhymes with shots, not cats]',
    gender: 'male',
    ethnicity: 'white-american',
    religion: 'jewish',
    openly_lgbtq: 'no',
    date_of_birth: '1972-10-20',
    entered_office: '2012-12-26',
    term_end: '2023-01-03',
    biography:
      'Brian Schatz, a Senator from Hawaii; born in Ann Arbor, Washtenaw County, Mich., October 20, 1972; attended Punahou School in Honolulu, Hawaii; B.A. Pomona College 1994; teacher; member of the Hawaii state house of representatives, 1998-2006; CEO, Helping Hands Hawaii, 2002-2010; Chairman, Democratic Party of Hawaii, 2008-2011; Hawaii lieutenant governor, 2010-2012; appointed as a Democrat to the United States Senate on December 26, 2012, to fill the vacancy caused by the death of Daniel Inouye, and took the oath of office on December 27, 2012; elected in 2014 in a special election for the term ending January 3, 2017.',
    phone: '202-224-3934',
    fax: '202-228-1153',
    latitude: '38.892839',
    longitude: '-77.004356',
    address_complete: '722 Hart Senate Office Building, Washington, DC 20510',
    address_number: null,
    address_prefix: null,
    address_street: '722 Hart Senate Office Building',
    address_sec_unit_type: null,
    address_sec_unit_num: null,
    address_city: 'Washington',
    address_state: 'DC',
    address_zipcode: '20510',
    address_type: 'Building',
    website: 'http://www.schatz.senate.gov',
    contact_page: 'https://www.schatz.senate.gov/contact',
    facebook_url: 'https://facebook.com/SenBrianSchatz',
    twitter_handle: 'brianschatz',
    twitter_url: 'https://twitter.com/brianschatz',
    photo_url:
      'https://cdn.civil.services/us-senate/headshots/512x512/brian-schatz.jpg',
  },
  {
    state_name: 'Hawaii',
    state_name_slug: 'hawaii',
    state_code: 'HI',
    state_code_slug: 'hi',
    class: 'I',
    bioguide: 'H001042',
    thomas: '1844',
    opensecrets: 'N00028139',
    votesmart: '1677',
    fec: null,
    maplight: null,
    wikidata: null,
    google_entity_id: null,
    title: 'senator',
    party: 'democrat',
    name: 'Mazie Hirono',
    name_slug: 'mazie-hirono',
    first_name: 'Mazie',
    middle_name: 'K.',
    last_name: 'Hirono',
    name_suffix: null,
    goes_by: null,
    pronunciation: "MAY-zee hear-OH-noh [O's unrounded]",
    gender: 'female',
    ethnicity: 'asian-american',
    religion: 'buddhism',
    openly_lgbtq: 'no',
    date_of_birth: '1947-11-03',
    entered_office: '2013-01-03',
    term_end: '2019-01-03',
    biography:
      'Mazie Hirono, a Senator and a Representative from Hawaii; born in Fukushima, Japan, November 3, 1947; graduated from Kaimuki High School, Honolulu, Hawaii; B.A., University of Hawaii, Manoa, Hawaii, 1970; J.D., Georgetown University, Washington, D.C., 1978; lawyer, private practice; member of the Hawaii state house of representatives, 1981-1994; Hawaii lieutenant governor, 1994-2002; elected as a Democrat to the One Hundred Tenth and to the two succeeding Congresses (January 3, 2007-January 3, 2013); was not a candidate for reelection to the House of Representatives but was elected as a Democrat to the United States Senate in 2012 for the term ending January 3, 2019.',
    phone: '202-224-6361',
    fax: '202-224-2126',
    latitude: '38.892839',
    longitude: '-77.004356',
    address_complete: '330 Hart Senate Office Building, Washington, DC 20510',
    address_number: null,
    address_prefix: null,
    address_street: '330 Hart Senate Office Building',
    address_sec_unit_type: null,
    address_sec_unit_num: null,
    address_city: 'Washington',
    address_state: 'DC',
    address_zipcode: '20510',
    address_type: 'Building',
    website: 'http://www.hirono.senate.gov',
    contact_page: 'https://www.hirono.senate.gov/contact',
    facebook_url: 'https://facebook.com/senatorhirono',
    twitter_handle: 'maziehirono',
    twitter_url: 'https://twitter.com/maziehirono',
    photo_url:
      'https://cdn.civil.services/us-senate/headshots/512x512/mazie-hirono.jpg',
  },
  {
    state_name: 'Iowa',
    state_name_slug: 'iowa',
    state_code: 'IA',
    state_code_slug: 'ia',
    class: 'III',
    bioguide: 'G000386',
    thomas: '457',
    opensecrets: 'N00001758',
    votesmart: '53293',
    fec: null,
    maplight: null,
    wikidata: null,
    google_entity_id: null,
    title: 'senator',
    party: 'republican',
    name: 'Charles Grassley',
    name_slug: 'charles-grassley',
    first_name: 'Charles',
    middle_name: null,
    last_name: 'Grassley',
    name_suffix: null,
    goes_by: 'Chuck',
    pronunciation: 'CHUHK GRASS-lee',
    gender: 'male',
    ethnicity: 'white-american',
    religion: 'baptist',
    openly_lgbtq: 'no',
    date_of_birth: '1933-09-17',
    entered_office: '1981-01-03',
    term_end: '2023-01-03',
    biography:
      'Charles Grassley, a Senator and a Representative from Iowa; born in New Hartford, Butler County, Iowa, September 17, 1933; attended the public schools; graduated, University of Northern Iowa, Cedar Falls 1955; received a graduate degree from the same university in 1956; pursued graduate work, University of Iowa, Iowa City 1957-1958; engaged in agriculture; part-time university instructor; member, Iowa house of representatives 1959-1974; elected in 1974 as a Republican to the Ninety-fourth Congress; reelected to the Ninety-fifth and Ninety-sixth Congresses (January 3, 1975-January 3, 1981); was not a candidate in 1980 for reelection to the House of Representatives, but was elected to the Senate in 1980; reelected in 1986, 1992, 1998, 2004 and again in 2010 for the term ending January 3, 2017; Chair, Special Committee on Aging (One Hundred Fifth Congress), Committee on Finance (One Hundred Seventh Congress [January 20 to June 6, 2001], One Hundred Eighth and One Hundred Ninth Congresses), Committee on the Judiciary (One Hundred Fourteenth Congress).',
    phone: '202-224-3744',
    fax: '202-224-6020',
    latitude: '38.892839',
    longitude: '-77.004356',
    address_complete: '135 Hart Senate Office Building, Washington, DC 20510',
    address_number: null,
    address_prefix: null,
    address_street: '135 Hart Senate Office Building',
    address_sec_unit_type: null,
    address_sec_unit_num: null,
    address_city: 'Washington',
    address_state: 'DC',
    address_zipcode: '20510',
    address_type: 'Building',
    website: 'http://www.grassley.senate.gov',
    contact_page: 'https://www.grassley.senate.gov/contact',
    facebook_url: 'https://facebook.com/grassley',
    twitter_handle: 'ChuckGrassley',
    twitter_url: 'https://twitter.com/ChuckGrassley',
    photo_url:
      'https://cdn.civil.services/us-senate/headshots/512x512/charles-grassley.jpg',
  },
  {
    state_name: 'Iowa',
    state_name_slug: 'iowa',
    state_code: 'IA',
    state_code_slug: 'ia',
    class: 'II',
    bioguide: 'E000295',
    thomas: '2283',
    opensecrets: 'N00035483',
    votesmart: '128583',
    fec: null,
    maplight: null,
    wikidata: null,
    google_entity_id: null,
    title: 'senator',
    party: 'republican',
    name: 'Joni Ernst',
    name_slug: 'joni-ernst',
    first_name: 'Joni',
    middle_name: null,
    last_name: 'Ernst',
    name_suffix: null,
    goes_by: null,
    pronunciation: 'JOH-nee URNST',
    gender: 'female',
    ethnicity: 'white-american',
    religion: 'lutheran',
    openly_lgbtq: 'no',
    date_of_birth: '1970-07-01',
    entered_office: '2015-01-03',
    term_end: '2027-01-03',
    biography:
      'Joni Ernst, a Senator from Iowa; born in Red Oak, Montgomery County, Iowa, July 1, 1970; B.A., Iowa State University, 1992; M.P.A., Columbus College (now Columbus State University), Columbus, Ga., 1995; United States Army Reserves, 1993-2001; Iowa Army National Guard, 2001-present, attaining the rank of lieutenant colonel; auditor of Montgomery County, Iowa, 2005-2011; member of the Iowa state Senate 2011-2014; elected as a Republican to the United States Senate in 2014 for the term ending January 3, 2021.',
    phone: '202-224-3254',
    fax: '202-224-9369',
    latitude: '38.892542',
    longitude: '-77.007415',
    address_complete:
      '111 Russell Senate Office Building, Washington, DC 20510',
    address_number: null,
    address_prefix: null,
    address_street: '111 Russell Senate Office Building',
    address_sec_unit_type: null,
    address_sec_unit_num: null,
    address_city: 'Washington',
    address_state: 'DC',
    address_zipcode: '20510',
    address_type: 'Building',
    website: 'http://www.ernst.senate.gov',
    contact_page: 'https://www.ernst.senate.gov/public/index.cfm/contact',
    facebook_url: 'https://facebook.com/joniforiowa',
    twitter_handle: 'joniernst',
    twitter_url: 'https://twitter.com/joniernst',
    photo_url:
      'https://cdn.civil.services/us-senate/headshots/512x512/joni-ernst.jpg',
  },
  {
    state_name: 'Idaho',
    state_name_slug: 'idaho',
    state_code: 'ID',
    state_code_slug: 'id',
    class: 'II',
    bioguide: 'R000584',
    thomas: '1896',
    opensecrets: 'N00029441',
    votesmart: '2919',
    fec: null,
    maplight: null,
    wikidata: null,
    google_entity_id: null,
    title: 'senator',
    party: 'republican',
    name: 'James Risch',
    name_slug: 'james-risch',
    first_name: 'James',
    middle_name: 'E.',
    last_name: 'Risch',
    name_suffix: null,
    goes_by: 'Jim',
    pronunciation: 'JIM RESH [Rhymes with mesh]',
    gender: 'male',
    ethnicity: 'white-american',
    religion: 'roman-catholic',
    openly_lgbtq: 'no',
    date_of_birth: '1943-05-03',
    entered_office: '2009-01-03',
    term_end: '2027-01-03',
    biography:
      'James Risch, a Senator from Idaho; born in Milwaukee, Wis., May 3, 1943; B.A., attended the University of Wisconsin, Milwaukee, 1961-1963; B.S. in Forestry, University of Idaho, 1965; J.D., University of Idaho College of Law, 1968; Ada County, Idaho, prosecuting attorney, 1970-1974; Idaho State Senator, 1974-1989 and 1995-2003, serving as majority leader (1976-1982), and as president pro tempore (1982-1989); lieutenant governor of Idaho, 2003-2006 and 2007-2009; governor of Idaho, 2006; elected as a Republican to the United States Senate in 2008; reelected in 2014 for the term ending January 3, 2021.',
    phone: '202-224-2752',
    fax: '202-224-2573',
    latitude: '38.892542',
    longitude: '-77.007415',
    address_complete:
      '483 Russell Senate Office Building, Washington, DC 20510',
    address_number: null,
    address_prefix: null,
    address_street: '483 Russell Senate Office Building',
    address_sec_unit_type: null,
    address_sec_unit_num: null,
    address_city: 'Washington',
    address_state: 'DC',
    address_zipcode: '20510',
    address_type: 'Building',
    website: 'http://www.risch.senate.gov',
    contact_page: 'https://www.risch.senate.gov/public/index.cfm?p=Email',
    facebook_url: 'https://facebook.com/SenatorRisch',
    twitter_handle: 'SenatorRisch',
    twitter_url: 'https://twitter.com/SenatorRisch',
    photo_url:
      'https://cdn.civil.services/us-senate/headshots/512x512/james-risch.jpg',
  },
  {
    state_name: 'Idaho',
    state_name_slug: 'idaho',
    state_code: 'ID',
    state_code_slug: 'id',
    class: 'III',
    bioguide: 'C000880',
    thomas: '250',
    opensecrets: 'N00006267',
    votesmart: '26830',
    fec: null,
    maplight: null,
    wikidata: null,
    google_entity_id: null,
    title: 'senator',
    party: 'republican',
    name: 'Mike Crapo',
    name_slug: 'mike-crapo',
    first_name: 'Mike',
    middle_name: null,
    last_name: 'Crapo',
    name_suffix: null,
    goes_by: null,
    pronunciation: 'MIKE CRAY-poh',
    gender: 'male',
    ethnicity: 'white-american',
    religion: 'mormon',
    openly_lgbtq: 'no',
    date_of_birth: '1951-05-20',
    entered_office: '1999-01-03',
    term_end: '2023-01-03',
    biography:
      'Michael Crapo, a Senator and a Representative from Idaho; born in Idaho Falls, Bonneville County, Idaho, May 20, 1951; graduated Idaho Falls High School 1969; B.A., Brigham Young University 1973; J.D., Harvard University School of Law 1977; admitted to the bar in 1977; law clerk to Judge James M. Carter, United States Court of Appeals, Ninth Circuit 1977-1978; practiced law in San Diego 1978-1979, and in Idaho Falls 1979-1992; vice chair, Bonneville County Republican Committee 1979-1981; vice chair, Legislative District 29 Republican Committee 1982-1984; Idaho State Senator 1985-1992; elected as a Republican to the One Hundred Third and to the two succeeding Congresses (January 3, 1993-January 3, 1999); was not a candidate for reelection to the U.S. House of Representatives, but was elected to the United States Senate in 1998; reelected in 2004 and again in 2010 for the term ending January 3, 2017.',
    phone: '202-224-6142',
    fax: '202-228-1375',
    latitude: '38.892947',
    longitude: '-77.075686',
    address_complete:
      '239 Dirksen Senate Office Building, Washington, DC 20510',
    address_number: null,
    address_prefix: null,
    address_street: '239 Dirksen Senate Office Building',
    address_sec_unit_type: null,
    address_sec_unit_num: null,
    address_city: 'Washington',
    address_state: 'DC',
    address_zipcode: '20510',
    address_type: 'Building',
    website: 'http://www.crapo.senate.gov',
    contact_page: 'https://www.crapo.senate.gov/contact/email.cfm',
    facebook_url: 'https://facebook.com/mikecrapo',
    twitter_handle: 'MikeCrapo',
    twitter_url: 'https://twitter.com/MikeCrapo',
    photo_url:
      'https://cdn.civil.services/us-senate/headshots/512x512/mike-crapo.jpg',
  },
  {
    state_name: 'Illinois',
    state_name_slug: 'illinois',
    state_code: 'IL',
    state_code_slug: 'il',
    class: 'II',
    bioguide: 'D000563',
    thomas: '326',
    opensecrets: 'N00004981',
    votesmart: '26847',
    fec: null,
    maplight: null,
    wikidata: null,
    google_entity_id: null,
    title: 'senate-minority-whip',
    party: 'democrat',
    name: 'Richard Durbin',
    name_slug: 'richard-durbin',
    first_name: 'Richard',
    middle_name: 'J.',
    last_name: 'Durbin',
    name_suffix: null,
    goes_by: 'Dick',
    pronunciation: 'DICK DER-buhn',
    gender: 'male',
    ethnicity: 'white-american',
    religion: 'roman-catholic',
    openly_lgbtq: 'no',
    date_of_birth: '1944-11-21',
    entered_office: '1997-01-03',
    term_end: '2027-01-03',
    biography:
      'Richard Durbin, a Senator and a Representative from Illinois; born in East St. Louis, St. Clair County, Ill., November 21, 1944; graduated from Assumption High School, East St. Louis 1962; B.S., Georgetown University School of Foreign Service Senate Office Building, Washington, DC 1966; intern in the office of Illinois Senator Paul Douglas during his senior year in College; J.D., Georgetown University Law Center 1969; admitted to the Illinois bar 1969, and commenced practice in Springfield; legal counsel to Illinois Lieutenant Governor Paul Simon 1969-1972; legal counsel, Illinois State Senate Judiciary Committee 1972-1982; Democratic nominee for lieutenant governor of Illinois 1978; associate professor, Southern Illinois University School of Medicine, Springfield 1978-1983; delegate, Democratic National Conventions 1984, 1988, 1992 and 1996; chairman of Illinois state delegation in 1996; elected as a Democrat to the Ninety-eighth and to the six succeeding Congresses (January 3, 1983-January 2, 1997); elected as a Democrat to the United States Senate in 1996; reelected in 2002, 2008, and again in 2014 for the term ending January 3, 2021; Democratic party whip (2005-).',
    phone: '202-224-2152',
    fax: '202-228-0400',
    latitude: '38.892839',
    longitude: '-77.004356',
    address_complete: '711 Hart Senate Office Building, Washington, DC 20510',
    address_number: null,
    address_prefix: null,
    address_street: '711 Hart Senate Office Building',
    address_sec_unit_type: null,
    address_sec_unit_num: null,
    address_city: 'Washington',
    address_state: 'DC',
    address_zipcode: '20510',
    address_type: 'Building',
    website: 'http://www.durbin.senate.gov',
    contact_page: 'https://www.durbin.senate.gov/contact/',
    facebook_url: 'https://facebook.com/SenatorDurbin',
    twitter_handle: 'SenatorDurbin',
    twitter_url: 'https://twitter.com/SenatorDurbin',
    photo_url:
      'https://cdn.civil.services/us-senate/headshots/512x512/richard-durbin.jpg',
  },
  {
    state_name: 'Illinois',
    state_name_slug: 'illinois',
    state_code: 'IL',
    state_code_slug: 'il',
    class: 'III',
    bioguide: 'D000622',
    thomas: '2123',
    opensecrets: 'N00027860',
    votesmart: '57442',
    fec: null,
    maplight: null,
    wikidata: null,
    google_entity_id: null,
    title: 'senator',
    party: 'democrat',
    name: 'Tammy Duckworth',
    name_slug: 'tammy-duckworth',
    first_name: 'Tammy',
    middle_name: null,
    last_name: 'Duckworth',
    name_suffix: null,
    goes_by: null,
    pronunciation: 'TAM-ee DUCK-werth',
    gender: 'female',
    ethnicity: 'asian-american',
    religion: 'deist',
    openly_lgbtq: 'no',
    date_of_birth: '1968-03-12',
    entered_office: '2017-01-03',
    term_end: '2023-01-03',
    biography:
      "Tammy Duckworth, a Senator and a Representative from Illinois; born in Bangkok, Thailand, March 12, 1968; graduated from President William McKinley High School, Honolulu, Hawaii, 1985; B.A., University of Hawaii, Manoa, Hawaii, 1989; M.A., The George Washington University, Washington, D.C., 1992; attended Northern Illinois University, DeKalb, Ill.; Illinois Army National Guard, 1996-present; unsuccessful candidate for election to the United States House of Representatives in 2006; director, Ill. department of veterans' affairs, 2006-2009; assistant secretary, United States Department of Veterans' Affairs, 2009-2011; delegate to the Democratic National Convention, 2012; elected as a Democrat to the One Hundred Thirteenth Congress and to the succeeding Congress (January 3, 2013-January 3, 2017); was not a candidate for reelection to the House of Representatives but was elected as a Democrat to the United States Senate in 2016 for the term ending January 3, 2023.",
    phone: '202-224-2854',
    fax: '202-228-0618',
    latitude: '38.892839',
    longitude: '-77.004356',
    address_complete: '524 Hart Senate Office Building, Washington, DC 20510',
    address_number: null,
    address_prefix: null,
    address_street: '524 Hart Senate Office Building',
    address_sec_unit_type: null,
    address_sec_unit_num: null,
    address_city: 'Washington',
    address_state: 'DC',
    address_zipcode: '20510',
    address_type: 'Building',
    website: 'http://www.duckworth.senate.gov',
    contact_page: 'https://www.duckworth.senate.gov/content/contact-senator',
    facebook_url: 'https://facebook.com/CongresswomanTammyDuckworth',
    twitter_handle: 'RepDuckworth',
    twitter_url: 'https://twitter.com/RepDuckworth',
    photo_url:
      'https://cdn.civil.services/us-senate/headshots/512x512/tammy-duckworth.jpg',
  },
  {
    state_name: 'Indiana',
    state_name_slug: 'indiana',
    state_code: 'IN',
    state_code_slug: 'in',
    class: 'I',
    bioguide: 'B001310',
    thomas: null,
    opensecrets: 'N00041731',
    votesmart: '148564',
    fec: 'S8IN00171',
    maplight: null,
    wikidata: 'Q42804470',
    google_entity_id: null,
    title: 'senator',
    party: 'republican',
    name: 'Mike Braun',
    name_slug: 'mike-braun',
    first_name: 'Mike',
    middle_name: null,
    last_name: 'Braun',
    name_suffix: null,
    goes_by: null,
    pronunciation: 'MYK BRA-un',
    gender: 'male',
    ethnicity: 'white-american',
    religion: 'unspecified',
    openly_lgbtq: 'no',
    date_of_birth: '1954-03-24',
    entered_office: '2019-01-03',
    term_end: '2025-01-03',
    biography:
      'Mike Braun is a Republican member of the U.S. Senate from Indiana. Braun was elected to the office on November 6, 2018.',
    phone: '202-224-4814',
    fax: null,
    latitude: '38.892542',
    longitude: '-77.007415',
    address_complete: 'B85 Russell Senate Office Building Washington DC 20510',
    address_number: null,
    address_prefix: null,
    address_street: 'B85 Russell Senate Office Building Washington DC 20510',
    address_sec_unit_type: null,
    address_sec_unit_num: null,
    address_city: 'Washington',
    address_state: 'DC',
    address_zipcode: '20510',
    address_type: 'Building',
    website: 'https://www.braun.senate.gov/',
    contact_page: 'https://www.braun.senate.gov/',
    facebook_url: 'https://facebook.com/SenatorBraun',
    twitter_handle: 'SenatorBraun',
    twitter_url: 'https://twitter.com/SenatorBraun',
    photo_url:
      'https://cdn.civil.services/us-senate/headshots/512x512/mike-braun.jpg',
  },
  {
    state_name: 'Indiana',
    state_name_slug: 'indiana',
    state_code: 'IN',
    state_code_slug: 'in',
    class: 'III',
    bioguide: 'Y000064',
    thomas: '2019',
    opensecrets: 'N00030670',
    votesmart: '120345',
    fec: null,
    maplight: null,
    wikidata: null,
    google_entity_id: null,
    title: 'senator',
    party: 'republican',
    name: 'Todd Young',
    name_slug: 'todd-young',
    first_name: 'Todd',
    middle_name: null,
    last_name: 'Young',
    name_suffix: null,
    goes_by: null,
    pronunciation: 'TAHD YUHNG',
    gender: 'male',
    ethnicity: 'white-american',
    religion: 'christian',
    openly_lgbtq: 'no',
    date_of_birth: '1972-08-24',
    entered_office: '2017-01-03',
    term_end: '2023-01-03',
    biography:
      'Todd Young, a Senator and a Representative from Indiana; born in Lancaster, Lancaster County, Pa., August 24, 1972; graduated from Carmel High School, Carmel, Ind., 1990; B.S., United States Naval Academy, Annapolis, Md., 1995; M.B.A., University of Chicago, Chicago, Ill., 2000; M.A., University of London, London, England, 2001; J.D., Robert H. McKinney School of Law, Indiana University, Indianapolis, Ind., 2006; United States Navy, 1990-1991; United States Marine Corps, 1995-2000; staff, United States Senator Richard Lugar of Indiana, 2001-2003; Orange County, Ind., deputy prosecutor, 2007-2010; consultant; lawyer, private practice; elected as a Republican to the One Hundred Twelfth and to the two succeeding Congresses (January 3, 2011-January 3, 2017); was not a candidate for reelection to the House of Representatives but was elected as a Republican to the United States Senate in 2016 for the term ending January 3, 2023.',
    phone: '202-224-5623',
    fax: null,
    latitude: '38.892542',
    longitude: '-77.007415',
    address_complete:
      'B33 Russell Senate Office Building, Washington, DC 20510',
    address_number: null,
    address_prefix: null,
    address_street: 'B33 Russell Senate Office Building',
    address_sec_unit_type: null,
    address_sec_unit_num: null,
    address_city: 'Washington',
    address_state: 'DC',
    address_zipcode: '20510',
    address_type: 'Building',
    website: 'http://www.young.senate.gov',
    contact_page: 'https://www.young.senate.gov/content/contact-senator',
    facebook_url: 'https://facebook.com/ToddYoungIndiana',
    twitter_handle: 'ToddYoungIN',
    twitter_url: 'https://twitter.com/ToddYoungIN',
    photo_url:
      'https://cdn.civil.services/us-senate/headshots/512x512/todd-young.jpg',
  },
  {
    state_name: 'Kansas',
    state_name_slug: 'kansas',
    state_code: 'KS',
    state_code_slug: 'ks',
    class: 'III',
    bioguide: 'M000934',
    thomas: '1507',
    opensecrets: 'N00005282',
    votesmart: '542',
    fec: null,
    maplight: null,
    wikidata: null,
    google_entity_id: null,
    title: 'senator',
    party: 'republican',
    name: 'Jerry Moran',
    name_slug: 'jerry-moran',
    first_name: 'Jerry',
    middle_name: null,
    last_name: 'Moran',
    name_suffix: null,
    goes_by: null,
    pronunciation: 'JAIR-ee more-ANN',
    gender: 'male',
    ethnicity: 'white-american',
    religion: 'methodist',
    openly_lgbtq: 'no',
    date_of_birth: '1954-05-29',
    entered_office: '2011-01-03',
    term_end: '2023-01-03',
    biography:
      'Jerry Moran, a Senator and a Representative from Kansas; born in Great Bend, Barton County, Kans., May 29, 1954; attended Plainville High School, Plainville, Kans.; graduated University of Kansas, Lawrence, Kans., B.S., 1976; graduated Kansas University School of Law, Lawrence, Kans., J.D., 1981; M.B.A. candidate, Fort Hays State University, Hays, Kans.; bank officer; instructor, Fort Hays State University, Hays, Kans.; member of the Kansas state senate 1989-1997, serving as vice president 1993-1995, majority leader 1995-1997; Kansas state special assistant attorney general 1982-1985; deputy attorney, Rooks County, Kans. 1987-1995; University of Kansas School of Law, Lawrence, Kans., board of governors, serving as vice president 1993-1994, and as President 1994-1995; board of directors, Kansas chamber of commerce and industry 1996-1997; elected as a Republican to the One Hundred Fifth Congress and to the six succeeding Congresses (January 3, 1997-January 3, 2011); was not a candidate for reelection to the House of Representatives but was elected as a Republican to the United States Senate in 2010 for the term ending January 3, 2017; chair, National Republican Senatorial Committee (2012-2014).',
    phone: '202-224-6521',
    fax: '202-228-6966',
    latitude: '38.892947',
    longitude: '-77.075686',
    address_complete:
      '521 Dirksen Senate Office Building, Washington, DC 20510',
    address_number: null,
    address_prefix: null,
    address_street: '521 Dirksen Senate Office Building',
    address_sec_unit_type: null,
    address_sec_unit_num: null,
    address_city: 'Washington',
    address_state: 'DC',
    address_zipcode: '20510',
    address_type: 'Building',
    website: 'http://www.moran.senate.gov',
    contact_page: 'https://www.moran.senate.gov/public/index.cfm/e-mail-jerry',
    facebook_url: 'https://facebook.com/jerrymoran',
    twitter_handle: 'JerryMoran',
    twitter_url: 'https://twitter.com/JerryMoran',
    photo_url:
      'https://cdn.civil.services/us-senate/headshots/512x512/jerry-moran.jpg',
  },
  {
    state_name: 'Kansas',
    state_name_slug: 'kansas',
    state_code: 'KS',
    state_code_slug: 'ks',
    class: 'II',
    bioguide: 'M001198',
    thomas: null,
    opensecrets: 'N00037034',
    votesmart: '172080',
    fec: 'S0KS00315',
    maplight: null,
    wikidata: null,
    google_entity_id: null,
    title: 'senator',
    party: 'republican',
    name: 'Roger Marshall',
    name_slug: 'roger-marshall',
    first_name: 'Roger',
    middle_name: 'W.',
    last_name: 'Marshall',
    name_suffix: null,
    goes_by: null,
    pronunciation: 'roh-gehr maw-shul',
    gender: 'male',
    ethnicity: 'white-american',
    religion: 'unspecified',
    openly_lgbtq: 'no',
    date_of_birth: '1960-08-09',
    entered_office: '2021-01-03',
    term_end: '2027-01-03',
    biography:
      'Senator Marshall is a former Congressman for Kansas’ Big 1st District, a physician, devoted father, grandfather, and husband. As a 5th generation farm kid growing up in Butler County, Dr. Marshall became the first in his family to attend college.',
    phone: '202-224-4774',
    fax: null,
    latitude: '38.892839',
    longitude: '-77.004356',
    address_complete: 'B33 Russell Senate Office Building Washington DC 20510',
    address_number: null,
    address_prefix: null,
    address_street: 'B33 Russell Senate Office Building Washington DC 20510',
    address_sec_unit_type: null,
    address_sec_unit_num: null,
    address_city: 'Washington',
    address_state: 'DC',
    address_zipcode: '20510',
    address_type: 'Building',
    website: 'https://www.marshall.senate.gov',
    contact_page: 'https://www.marshall.senate.gov',
    facebook_url: null,
    twitter_handle: 'SenatorMarshall',
    twitter_url: 'https://twitter.com/SenatorMarshall',
    photo_url:
      'https://cdn.civil.services/us-senate/headshots/512x512/roger-marshall.jpg',
  },
  {
    state_name: 'Kentucky',
    state_name_slug: 'kentucky',
    state_code: 'KY',
    state_code_slug: 'ky',
    class: 'II',
    bioguide: 'M000355',
    thomas: '1395',
    opensecrets: 'N00003389',
    votesmart: '53298',
    fec: null,
    maplight: null,
    wikidata: null,
    google_entity_id: null,
    title: 'senate-majority-leader',
    party: 'republican',
    name: 'Mitch McConnell',
    name_slug: 'mitch-mcconnell',
    first_name: 'Mitch',
    middle_name: null,
    last_name: 'McConnell',
    name_suffix: null,
    goes_by: null,
    pronunciation: 'MITCH muh-KAH-nuhl',
    gender: 'male',
    ethnicity: 'white-american',
    religion: 'southern-baptist',
    openly_lgbtq: 'no',
    date_of_birth: '1942-02-20',
    entered_office: '1985-01-03',
    term_end: '2027-01-03',
    biography:
      'Mitch McConnell, a Senator from Kentucky; born in Tuscumbia, Colbert County, Ala., February 20, 1942; attended Louisville, Ky. public schools; graduated, University of Louisville 1964; graduated, University of Kentucky Law School, Lexington 1967; admitted to the Kentucky bar in 1967; legislative assistant to Senator Marlow Cook 1968-1970; Deputy Assistant United States Attorney for Legislative Affairs 1974-1975; Acting Assistant Attorney General 1975; elected judge-executive of Jefferson County, Ky., 1978-1985; elected as a Republican to the United States Senate in 1984; reelected in 1990, 1996, 2002, 2008, and again in 2014 for the term ending January 3, 2021; Republican party whip (2003-2007); minority leader (2007-2015); majority leader (2015-); chair, Select Committee on Ethics (One Hundred Fourth Congress), National Republican Senatorial Campaign Committee (One Hundred Fifth through One Hundred Sixth Congresses), Committee on Rules and Administration (One Hundred Sixth Congress, One Hundred Seventh Congress [January 20, 2001-June 6, 2001]).',
    phone: '202-224-2541',
    fax: '202-224-2499',
    latitude: '38.892542',
    longitude: '-77.007415',
    address_complete:
      '317 Russell Senate Office Building, Washington, DC 20510',
    address_number: null,
    address_prefix: null,
    address_street: '317 Russell Senate Office Building',
    address_sec_unit_type: null,
    address_sec_unit_num: null,
    address_city: 'Washington',
    address_state: 'DC',
    address_zipcode: '20510',
    address_type: 'Building',
    website: 'http://www.mcconnell.senate.gov',
    contact_page: 'https://www.mcconnell.senate.gov/public/index.cfm?p=contact',
    facebook_url: 'https://facebook.com/McConnellForSenate',
    twitter_handle: 'McConnellPress',
    twitter_url: 'https://twitter.com/McConnellPress',
    photo_url:
      'https://cdn.civil.services/us-senate/headshots/512x512/mitch-mcconnell.jpg',
  },
  {
    state_name: 'Kentucky',
    state_name_slug: 'kentucky',
    state_code: 'KY',
    state_code_slug: 'ky',
    class: 'III',
    bioguide: 'P000603',
    thomas: '2082',
    opensecrets: 'N00030836',
    votesmart: '117285',
    fec: null,
    maplight: null,
    wikidata: null,
    google_entity_id: null,
    title: 'senator',
    party: 'republican',
    name: 'Rand Paul',
    name_slug: 'rand-paul',
    first_name: 'Rand',
    middle_name: null,
    last_name: 'Paul',
    name_suffix: null,
    goes_by: null,
    pronunciation: 'RANND PAWL',
    gender: 'male',
    ethnicity: 'white-american',
    religion: 'presbyterian',
    openly_lgbtq: 'no',
    date_of_birth: '1963-01-07',
    entered_office: '2011-01-03',
    term_end: '2023-01-03',
    biography:
      'Rand Paul (son of Ronald Ernest Paul), a Senator from Kentucky; born in Pittsburgh, Pa., January 7, 1963; attended Baylor University, 1981-1984; graduated Duke University, M.D., 1988; ophthalmologist; elected as a Republican to the United States Senate for the term ending January 3, 2017.',
    phone: '202-224-4343',
    fax: null,
    latitude: '38.892542',
    longitude: '-77.007415',
    address_complete:
      '167 Russell Senate Office Building, Washington, DC 20510',
    address_number: null,
    address_prefix: null,
    address_street: '167 Russell Senate Office Building',
    address_sec_unit_type: null,
    address_sec_unit_num: null,
    address_city: 'Washington',
    address_state: 'DC',
    address_zipcode: '20510',
    address_type: 'Building',
    website: 'http://www.paul.senate.gov',
    contact_page: 'https://www.paul.senate.gov/connect/email-rand',
    facebook_url: 'https://facebook.com/SenatorRandPaul',
    twitter_handle: 'RandPaul',
    twitter_url: 'https://twitter.com/RandPaul',
    photo_url:
      'https://cdn.civil.services/us-senate/headshots/512x512/rand-paul.jpg',
  },
  {
    state_name: 'Louisiana',
    state_name_slug: 'louisiana',
    state_code: 'LA',
    state_code_slug: 'la',
    class: 'II',
    bioguide: 'C001075',
    thomas: '1925',
    opensecrets: 'N00030245',
    votesmart: '69494',
    fec: null,
    maplight: null,
    wikidata: null,
    google_entity_id: null,
    title: 'senator',
    party: 'republican',
    name: 'Bill Cassidy',
    name_slug: 'bill-cassidy',
    first_name: 'Bill',
    middle_name: null,
    last_name: 'Cassidy',
    name_suffix: null,
    goes_by: null,
    pronunciation: 'BILL CASS-ih-dee',
    gender: 'male',
    ethnicity: 'white-american',
    religion: 'christian',
    openly_lgbtq: 'no',
    date_of_birth: '1957-09-28',
    entered_office: '2015-01-03',
    term_end: '2027-01-03',
    biography:
      'Bill Cassidy, a Senator and a Representative from Louisiana; born in Highland Park, Lake County, Ill., September 28, 1957; B.S., Louisiana State University, Baton Rouge, La., 1979; M.D., Louisiana State University Medical School, New Orleans, La., 1983; physician; member of the Louisiana state senate, 2006-2008; elected as a Republican to the One Hundred Eleventh and to the two succeeding Congresses (January 3, 2009-January 3, 2015); was not a candidate for reelection to the House of Representatives but was elected as a Republican to the United States Senate in 2014 for the term ending January 3, 2021.',
    phone: '202-224-5824',
    fax: '202-224-9735',
    latitude: '38.892839',
    longitude: '-77.004356',
    address_complete: '703 Hart Senate Office Building, Washington, DC 20510',
    address_number: null,
    address_prefix: null,
    address_street: '703 Hart Senate Office Building',
    address_sec_unit_type: null,
    address_sec_unit_num: null,
    address_city: 'Washington',
    address_state: 'DC',
    address_zipcode: '20510',
    address_type: 'Building',
    website: 'http://www.cassidy.senate.gov',
    contact_page: 'https://www.cassidy.senate.gov/contact',
    facebook_url: 'https://facebook.com/billcassidy',
    twitter_handle: 'SenatorCassidy',
    twitter_url: 'https://twitter.com/SenatorCassidy',
    photo_url:
      'https://cdn.civil.services/us-senate/headshots/512x512/bill-cassidy.jpg',
  },
  {
    state_name: 'Louisiana',
    state_name_slug: 'louisiana',
    state_code: 'LA',
    state_code_slug: 'la',
    class: 'III',
    bioguide: 'K000393',
    thomas: null,
    opensecrets: 'N00026823',
    votesmart: '35496',
    fec: null,
    maplight: null,
    wikidata: null,
    google_entity_id: null,
    title: 'senator',
    party: 'republican',
    name: 'John Kennedy',
    name_slug: 'john-kennedy',
    first_name: 'John',
    middle_name: 'Neely',
    last_name: 'Kennedy',
    name_suffix: null,
    goes_by: null,
    pronunciation: 'JAHN KEN-uh-dee',
    gender: 'male',
    ethnicity: 'white-american',
    religion: 'methodist',
    openly_lgbtq: 'no',
    date_of_birth: '1951-11-21',
    entered_office: '2017-01-03',
    term_end: '2023-01-03',
    biography:
      "John Kennedy, a Senator from Louisiana; born in Centreville, Miss., November 21, 1951; B.A., Vanderbilt University, 1973; J.D., University of Virginia School of Law, 1977; B.C.L., Oxford University, Oxford, England, 1979; lawyer; special counsel to Louisiana Governor Buddy Roemer; secretary to Governor Roemer's Cabinet; Secretary of the state Department of Revenue in the Cabinet of Louisiana Governor Mike Foster 1996-1999; Louisiana state Treasurer 1999-2016; elected as a Republican to the United States Senate in 2016 for the term ending January 3, 2023.",
    phone: '202-224-4623',
    fax: null,
    latitude: '38.892839',
    longitude: '-77.004356',
    address_complete:
      'SRB11 Russell Senate Office Building, Washington, DC 20510',
    address_number: null,
    address_prefix: null,
    address_street: 'SRB11 Russell Senate Office Building',
    address_sec_unit_type: null,
    address_sec_unit_num: null,
    address_city: 'Washington',
    address_state: 'DC',
    address_zipcode: '20510',
    address_type: 'Building',
    website: 'http://www.kennedy.senate.gov',
    contact_page: 'https://www.kennedy.senate.gov/content/contact-senator',
    facebook_url: 'https://facebook.com/JohnKennedyLouisiana',
    twitter_handle: 'JohnKennedyLA',
    twitter_url: 'https://twitter.com/JohnKennedyLA',
    photo_url:
      'https://cdn.civil.services/us-senate/headshots/512x512/john-kennedy.jpg',
  },
  {
    state_name: 'Massachusetts',
    state_name_slug: 'massachusetts',
    state_code: 'MA',
    state_code_slug: 'ma',
    class: 'II',
    bioguide: 'M000133',
    thomas: '735',
    opensecrets: 'N00000270',
    votesmart: '26900',
    fec: null,
    maplight: null,
    wikidata: null,
    google_entity_id: null,
    title: 'senator',
    party: 'democrat',
    name: 'Edward Markey',
    name_slug: 'edward-markey',
    first_name: 'Edward',
    middle_name: 'J.',
    last_name: 'Markey',
    name_suffix: null,
    goes_by: 'Ed',
    pronunciation: 'ED MAR-kee',
    gender: 'male',
    ethnicity: 'white-american',
    religion: 'roman-catholic',
    openly_lgbtq: 'no',
    date_of_birth: '1946-11-11',
    entered_office: '2013-07-16',
    term_end: '2027-01-03',
    biography:
      'Edward Markey, a Senator and a Representative from Massachusetts; born in Malden, Middlesex County, Mass., July 11, 1946; attended Immaculate Conception Grammar School, Malden, Mass.; graduated from Malden Catholic High School, Malden, Mass., 1964; B.A., Boston College, Chestnut Hill, Mass., 1968; J.D., Boston College Law School, Chestnut Hill, Mass., 1972; lawyer, private practice; United States Army Reserve, 1968-1973; member of the Massachusetts state house of representatives, 1973-1976; elected as a Democrat to the Ninety-fourth Congress, by special election to fill the vacancy caused by the death of United States Representative Torbert H. Macdonald; reelected to the nineteen succeeding Congresses, and served from November 2, 1976, to July 15, 2013, when he resigned to become a U.S. Senator; elected as a Democrat to the U.S. Senate in a special election on June 25, 2013, to fill the vacancy caused by the resignation of John F. Kerry, a seat subsequently held by appointed Senator William Cowan, and took the oath of office on July 16, 2013; reelected in 2014 for the term ending January 3, 2021.',
    phone: '202-224-2742',
    fax: null,
    latitude: '38.892947',
    longitude: '-77.075686',
    address_complete:
      '255 Dirksen Senate Office Building, Washington, DC 20510',
    address_number: null,
    address_prefix: null,
    address_street: '255 Dirksen Senate Office Building',
    address_sec_unit_type: null,
    address_sec_unit_num: null,
    address_city: 'Washington',
    address_state: 'DC',
    address_zipcode: '20510',
    address_type: 'Building',
    website: 'http://www.markey.senate.gov',
    contact_page: 'https://www.markey.senate.gov/contact',
    facebook_url: 'https://facebook.com/EdJMarkey',
    twitter_handle: 'SenMarkey',
    twitter_url: 'https://twitter.com/SenMarkey',
    photo_url:
      'https://cdn.civil.services/us-senate/headshots/512x512/edward-markey.jpg',
  },
  {
    state_name: 'Massachusetts',
    state_name_slug: 'massachusetts',
    state_code: 'MA',
    state_code_slug: 'ma',
    class: 'I',
    bioguide: 'W000817',
    thomas: '2182',
    opensecrets: 'N00033492',
    votesmart: '141272',
    fec: null,
    maplight: null,
    wikidata: null,
    google_entity_id: null,
    title: 'senator',
    party: 'democrat',
    name: 'Elizabeth Warren',
    name_slug: 'elizabeth-warren',
    first_name: 'Elizabeth',
    middle_name: null,
    last_name: 'Warren',
    name_suffix: null,
    goes_by: null,
    pronunciation: 'uh-LIZ-uh-bith WAR-en',
    gender: 'female',
    ethnicity: 'white-american',
    religion: 'methodist',
    openly_lgbtq: 'no',
    date_of_birth: '1949-06-22',
    entered_office: '2013-01-03',
    term_end: '2019-01-03',
    biography:
      'Elizabeth Warren, a Senator from Massachusetts; born in Oklahoma City, Okla., June 22, 1949; attended George Washington University, 1966-1968; graduated University of Houston, B.S., 1970; graduated Rutgers University, J.D., 1976; elementary school teacher; lawyer; law professor; bankruptcy analyst; Chair, Congressional Oversight Panel for the Troubled Asset Relief Program 2008-2010; special assistant to President Barack Obama for the Consumer Financial Protection Bureau 2010-2011; elected as a Democrat to the United States Senate in 2012 for the term ending January 3, 2019.',
    phone: '202-224-4543',
    fax: null,
    latitude: '38.892839',
    longitude: '-77.004356',
    address_complete: '317 Hart Senate Office Building, Washington, DC 20510',
    address_number: null,
    address_prefix: null,
    address_street: '317 Hart Senate Office Building',
    address_sec_unit_type: null,
    address_sec_unit_num: null,
    address_city: 'Washington',
    address_state: 'DC',
    address_zipcode: '20510',
    address_type: 'Building',
    website: 'http://www.warren.senate.gov',
    contact_page: 'https://www.warren.senate.gov/?p=email_senator',
    facebook_url: 'https://facebook.com/senatorelizabethwarren',
    twitter_handle: 'SenWarren',
    twitter_url: 'https://twitter.com/SenWarren',
    photo_url:
      'https://cdn.civil.services/us-senate/headshots/512x512/elizabeth-warren.jpg',
  },
  {
    state_name: 'Maryland',
    state_name_slug: 'maryland',
    state_code: 'MD',
    state_code_slug: 'md',
    class: 'I',
    bioguide: 'C000141',
    thomas: '174',
    opensecrets: 'N00001955',
    votesmart: '26888',
    fec: null,
    maplight: null,
    wikidata: null,
    google_entity_id: null,
    title: 'senator',
    party: 'democrat',
    name: 'Benjamin Cardin',
    name_slug: 'benjamin-cardin',
    first_name: 'Benjamin',
    middle_name: null,
    last_name: 'Cardin',
    name_suffix: null,
    goes_by: 'Ben',
    pronunciation: 'BEHN KAHR-dn',
    gender: 'male',
    ethnicity: 'white-american',
    religion: 'jewish',
    openly_lgbtq: 'no',
    date_of_birth: '1943-10-05',
    entered_office: '2007-01-03',
    term_end: '2019-01-03',
    biography:
      'Benjamin Cardin, a Senator and a Representative from Maryland; born in Baltimore, Md., October 5, 1943; attended public schools; graduated from Baltimore City College, Baltimore, Md., 1961; B.A., University of Pittsburgh, Pittsburgh, Pa., 1964; J.D., University of Maryland, Baltimore, Md., 1967; lawyer, private practice; member of the Maryland state house of delegates, 1966-1986, speaker, 1979-1986; elected as a Democrat to the One Hundredth and to the nine succeeding Congresses (January 3, 1987-January 3, 2007); one of the managers appointed by the House of Representatives in 1989 to conduct the impeachment proceedings against Walter L. Nixon, judge of the United States District Court for the District Court of Mississippi; not a candidate for reelection to the House of Representatives but was elected as a Democrat to the United States Senate in 2006 for the term commencing January 3, 2007; reelected in 2012 for the term ending January 3, 2019.',
    phone: '202-224-4524',
    fax: '202-224-1651',
    latitude: '38.892839',
    longitude: '-77.004356',
    address_complete: '509 Hart Senate Office Building, Washington, DC 20510',
    address_number: null,
    address_prefix: null,
    address_street: '509 Hart Senate Office Building',
    address_sec_unit_type: null,
    address_sec_unit_num: null,
    address_city: 'Washington',
    address_state: 'DC',
    address_zipcode: '20510',
    address_type: 'Building',
    website: 'http://www.cardin.senate.gov',
    contact_page: 'https://www.cardin.senate.gov/contact/',
    facebook_url: 'https://facebook.com/senatorbencardin',
    twitter_handle: 'SenatorCardin',
    twitter_url: 'https://twitter.com/SenatorCardin',
    photo_url:
      'https://cdn.civil.services/us-senate/headshots/512x512/benjamin-cardin.jpg',
  },
  {
    state_name: 'Maryland',
    state_name_slug: 'maryland',
    state_code: 'MD',
    state_code_slug: 'md',
    class: 'III',
    bioguide: 'V000128',
    thomas: '1729',
    opensecrets: 'N00013820',
    votesmart: '6098',
    fec: null,
    maplight: null,
    wikidata: null,
    google_entity_id: null,
    title: 'senator',
    party: 'democrat',
    name: 'Chris Van Hollen',
    name_slug: 'chris-van-hollen',
    first_name: 'Chris',
    middle_name: null,
    last_name: 'Van Hollen',
    name_suffix: null,
    goes_by: null,
    pronunciation: 'KRISS vann HAH-lenn',
    gender: 'male',
    ethnicity: 'white-american',
    religion: 'episcopalian',
    openly_lgbtq: 'no',
    date_of_birth: '1959-01-10',
    entered_office: '2017-01-03',
    term_end: '2023-01-03',
    biography:
      'Christopher Van Hollen, a Senator and a Representative from Maryland; born in Karachi, Pakistan, January 10, 1959; B.A., Swarthmore College, Swarthmore, Pa., 1982; M.P.P., Harvard University, Cambridge, Mass., 1985; J.D., Georgetown University, Washington, D.C., 1990; lawyer, private practice; member of the Maryland state house of representatives, 1990-1994; member of the Maryland state senate, 1994-2002; elected as a Democrat to the One Hundred Eighth and to the six succeeding Congresses (January 3, 2003-January 3, 2017); was not a candidate for reelection to the House of Representatives but was elected as a Democrat to the United States Senate in 2016 for the term ending January 3, 2023.',
    phone: '202-224-4654',
    fax: '202-228-3398',
    latitude: '38.892839',
    longitude: '-77.004356',
    address_complete: '503 Hart Senate Office Building, Washington, DC 20510',
    address_number: null,
    address_prefix: null,
    address_street: '503 Hart Senate Office Building',
    address_sec_unit_type: null,
    address_sec_unit_num: null,
    address_city: 'Washington',
    address_state: 'DC',
    address_zipcode: '20510',
    address_type: 'Building',
    website: 'http://www.vanhollen.senate.gov',
    contact_page: 'https://www.vanhollen.senate.gov/content/contact-senator',
    facebook_url: 'https://facebook.com/chrisvanhollen',
    twitter_handle: 'ChrisVanHollen',
    twitter_url: 'https://twitter.com/ChrisVanHollen',
    photo_url:
      'https://cdn.civil.services/us-senate/headshots/512x512/chris-van-hollen.jpg',
  },
  {
    state_name: 'Maine',
    state_name_slug: 'maine',
    state_code: 'ME',
    state_code_slug: 'me',
    class: 'I',
    bioguide: 'K000383',
    thomas: '2185',
    opensecrets: 'N00034580',
    votesmart: '22381',
    fec: null,
    maplight: null,
    wikidata: null,
    google_entity_id: null,
    title: 'senator',
    party: 'independent',
    name: 'Angus King',
    name_slug: 'angus-king',
    first_name: 'Angus',
    middle_name: 'S.',
    last_name: 'King',
    name_suffix: 'Jr.',
    goes_by: null,
    pronunciation: 'AYN-guhs KEENG',
    gender: 'male',
    ethnicity: 'white-american',
    religion: 'episcopalian',
    openly_lgbtq: 'no',
    date_of_birth: '1944-03-31',
    entered_office: '2013-01-03',
    term_end: '2019-01-03',
    biography:
      'Angus King, a Senator from Maine; born in Alexandria, Va., March 31, 1944; graduated Dartmouth College, B.A., 1966; graduated University of Virginia, J.D., 1969; lawyer; Counsel, U.S. Senate Subcommittee on Alcoholism and Narcotics; television program host and co-producer; company executive; energy conservation company owner; governor of Maine 1995-2003; elected as an Independent to the United States Senate in 2012 for the term ending January 3, 2019.',
    phone: '202-224-5344',
    fax: null,
    latitude: '38.892839',
    longitude: '-77.004356',
    address_complete: '133 Hart Senate Office Building, Washington, DC 20510',
    address_number: null,
    address_prefix: null,
    address_street: '133 Hart Senate Office Building',
    address_sec_unit_type: null,
    address_sec_unit_num: null,
    address_city: 'Washington',
    address_state: 'DC',
    address_zipcode: '20510',
    address_type: 'Building',
    website: 'http://www.king.senate.gov',
    contact_page: 'https://www.king.senate.gov/contact',
    facebook_url: 'https://facebook.com/SenatorAngusSKingJr',
    twitter_handle: 'SenAngusKing',
    twitter_url: 'https://twitter.com/SenAngusKing',
    photo_url:
      'https://cdn.civil.services/us-senate/headshots/512x512/angus-king.jpg',
  },
  {
    state_name: 'Maine',
    state_name_slug: 'maine',
    state_code: 'ME',
    state_code_slug: 'me',
    class: 'II',
    bioguide: 'C001035',
    thomas: '1541',
    opensecrets: 'N00000491',
    votesmart: '379',
    fec: null,
    maplight: null,
    wikidata: null,
    google_entity_id: null,
    title: 'senator',
    party: 'republican',
    name: 'Susan Collins',
    name_slug: 'susan-collins',
    first_name: 'Susan',
    middle_name: 'M.',
    last_name: 'Collins',
    name_suffix: null,
    goes_by: null,
    pronunciation: 'SOO-zuhn KAH-lunz',
    gender: 'female',
    ethnicity: 'white-american',
    religion: 'roman-catholic',
    openly_lgbtq: 'no',
    date_of_birth: '1952-12-07',
    entered_office: '1997-01-03',
    term_end: '2027-01-03',
    biography:
      'Susan Collins, a Senator from Maine; born in Caribou, Aroostook County, Maine, December 7, 1952; graduated from St. Lawrence University, Canton, NY 1975; worked for United States Senator William S. Cohen 1975-1987, serving as staff director of the Senate Governmental Affairs Subcommittee on the Oversight of Government Management 1981-1987; commissioner of the Maine Department of Professional and Financial Regulation 1987-1992; New England regional director, United States Small Business Administration 1992; served as deputy state treasurer of Massachusetts, 1993; won an eight-way Republican primary to become the first woman nominated for governor of Maine in 1994, but lost general election; elected as a Republican to the United States Senate in 1996; reelected in 2002, 2008, and again in 2014 for the term ending January 3, 2021; chair, Committee on Governmental Affairs (One Hundred Eighth Congress), Committee on Homeland Security and Governmental Affairs (One Hundred Ninth Congress); Special Committee on Aging (One Hundred Fourteenth Congress).',
    phone: '202-224-2523',
    fax: '202-224-2693',
    latitude: '38.892947',
    longitude: '-77.075686',
    address_complete:
      '413 Dirksen Senate Office Building, Washington, DC 20510',
    address_number: null,
    address_prefix: null,
    address_street: '413 Dirksen Senate Office Building',
    address_sec_unit_type: null,
    address_sec_unit_num: null,
    address_city: 'Washington',
    address_state: 'DC',
    address_zipcode: '20510',
    address_type: 'Building',
    website: 'http://www.collins.senate.gov',
    contact_page: 'https://www.collins.senate.gov/contact',
    facebook_url: 'https://facebook.com/susancollins',
    twitter_handle: 'senatorcollins',
    twitter_url: 'https://twitter.com/senatorcollins',
    photo_url:
      'https://cdn.civil.services/us-senate/headshots/512x512/susan-collins.jpg',
  },
  {
    state_name: 'Michigan',
    state_name_slug: 'michigan',
    state_code: 'MI',
    state_code_slug: 'mi',
    class: 'I',
    bioguide: 'S000770',
    thomas: '1531',
    opensecrets: 'N00004118',
    votesmart: '515',
    fec: null,
    maplight: null,
    wikidata: null,
    google_entity_id: null,
    title: 'senator',
    party: 'democrat',
    name: 'Debbie Stabenow',
    name_slug: 'debbie-stabenow',
    first_name: 'Debbie',
    middle_name: null,
    last_name: 'Stabenow',
    name_suffix: null,
    goes_by: null,
    pronunciation: 'DEH-bee STAB-en-oh',
    gender: 'female',
    ethnicity: 'white-american',
    religion: 'methodist',
    openly_lgbtq: 'no',
    date_of_birth: '1950-04-29',
    entered_office: '2001-01-03',
    term_end: '2019-01-03',
    biography:
      'Debbie Stabenow, a Senator and a Representative from Michigan; born in Gladwin, Mich., April 29, 1950; graduated, Clare High School; B.A., Michigan State University, East Lansing 1972; M.S.W. magna cum laude, Michigan State University, 1975; social worker; leadership training consultant; Ingham County commissioner 1975-1978; member, State house of representatives 1979-1990; member, State senate 1991-1994; elected as a Democrat to the One Hundred Fifth and One Hundred Sixth Congresses (January 3, 1997-January 3, 2001); was not a candidate for reelection to the House of Representatives in 2000, but was elected to the United States Senate for the term commencing January 3, 2001; reelected in 2006 and again in 2012 for the term ending January 3, 2019; Democratic Conference secretary (2005-2007); vice-chair, Democratic Policy and Communications Committee (2011-); chair, Committee on Agriculture, Nutrition, and Forestry (One Hundred Twelfth and One Hundred Thirteenth Congresses).',
    phone: '202-224-4822',
    fax: null,
    latitude: '38.892839',
    longitude: '-77.004356',
    address_complete: '731 Hart Senate Office Building, Washington, DC 20510',
    address_number: null,
    address_prefix: null,
    address_street: '731 Hart Senate Office Building',
    address_sec_unit_type: null,
    address_sec_unit_num: null,
    address_city: 'Washington',
    address_state: 'DC',
    address_zipcode: '20510',
    address_type: 'Building',
    website: 'http://www.stabenow.senate.gov',
    contact_page: 'https://www.stabenow.senate.gov/?p=contact',
    facebook_url: 'https://facebook.com/stabenow',
    twitter_handle: 'SenStabenow',
    twitter_url: 'https://twitter.com/SenStabenow',
    photo_url:
      'https://cdn.civil.services/us-senate/headshots/512x512/debbie-stabenow.jpg',
  },
  {
    state_name: 'Michigan',
    state_name_slug: 'michigan',
    state_code: 'MI',
    state_code_slug: 'mi',
    class: 'II',
    bioguide: 'P000595',
    thomas: '1929',
    opensecrets: 'N00029277',
    votesmart: '8749',
    fec: null,
    maplight: null,
    wikidata: null,
    google_entity_id: null,
    title: 'senator',
    party: 'democrat',
    name: 'Gary Peters',
    name_slug: 'gary-peters',
    first_name: 'Gary',
    middle_name: 'C.',
    last_name: 'Peters',
    name_suffix: null,
    goes_by: null,
    pronunciation: 'GAIR-ee PEE-terz',
    gender: 'male',
    ethnicity: 'white-american',
    religion: 'episcopalian',
    openly_lgbtq: 'no',
    date_of_birth: '1958-01-01',
    entered_office: '2015-01-03',
    term_end: '2027-01-03',
    biography:
      'Gary Peters, a Senator and a Representative from Michigan; born in Pontiac, Oakland County, Mich., December 1, 1958; B.A., Alma College, Alma, Mich., 1980; M.B.A., Finance, University of Detroit, Detroit, Mich., 1984; J.D., Wayne State University, Detroit, Mich., 1989; M.A., Philosophy, Michigan State University, East Lansing, Mich., 2007; United States Navy Reserve, 1993-2000, 2001-2005; assistant vice president, Merrill Lynch, 1980-1989; vice president, UBS/Paine Webber, 1989-2003; Arbitrator, Financial Industry Regulatory Authority; instructor, Oakland University, Rochester, Mich., and Wayne State University, Detroit, Mich.; Professor, Central Michigan University; member, Rochester Hills, Mich., city council, 1991-1993; member of the Michigan state senate, 1995-2002; unsuccessful candidate for Michigan state attorney general in 2002; chief administrative officer for the Bureau of investments, state of Michigan, 2003; lottery commissioner, state of Michigan, 2003-2007; elected as a Democrat to the One Hundred Eleventh and to the two succeeding Congresses (January 3, 2009-January 3, 2015); was not a candidate for reelection to the House of Representatives but was elected as a Democrat to the United States Senate in 2014 for the term ending January 3, 2021.',
    phone: '202-224-6221',
    fax: null,
    latitude: '38.892839',
    longitude: '-77.004356',
    address_complete: '724 Hart Senate Office Building, Washington, DC 20510',
    address_number: null,
    address_prefix: null,
    address_street: '724 Hart Senate Office Building',
    address_sec_unit_type: null,
    address_sec_unit_num: null,
    address_city: 'Washington',
    address_state: 'DC',
    address_zipcode: '20510',
    address_type: 'Building',
    website: 'http://www.peters.senate.gov',
    contact_page: 'https://www.peters.senate.gov/contact/email-gary',
    facebook_url: 'https://facebook.com/SenGaryPeters',
    twitter_handle: 'SenGaryPeters',
    twitter_url: 'https://twitter.com/SenGaryPeters',
    photo_url:
      'https://cdn.civil.services/us-senate/headshots/512x512/gary-peters.jpg',
  },
  {
    state_name: 'Minnesota',
    state_name_slug: 'minnesota',
    state_code: 'MN',
    state_code_slug: 'mn',
    class: 'II',
    bioguide: 'S001203',
    thomas: null,
    opensecrets: 'N00042353',
    votesmart: '152968',
    fec: null,
    maplight: null,
    wikidata: null,
    google_entity_id: null,
    title: 'senator',
    party: 'democrat',
    name: 'Tina Smith',
    name_slug: 'tina-smith',
    first_name: 'Tina',
    middle_name: 'Flint',
    last_name: 'Smith',
    name_suffix: null,
    goes_by: null,
    pronunciation: 'TEE-nuh SMITH',
    gender: 'female',
    ethnicity: 'white-american',
    religion: 'unspecified',
    openly_lgbtq: 'no',
    date_of_birth: '1958-03-04',
    entered_office: '2018-01-02',
    term_end: '2019-01-03',
    biography:
      'Tina Smith, a Senator from Minnesota; born in Albuquerque, N.Mex., March 4, 1958; B.A., Stanford University, 1980; M.B.A., Dartmouth College, Tuck School of Business, 1984; marketing consultant and executive; chief of staff to Minneapolis mayor R.T. Rybak; chief of staff to Minnesota governor Mark Dayton; lieutenant governor of Minnesota 2015-2018; appointed as a Democrat to the United States Senate to fill the vacancy caused by the resignation of Al Franken, for the term ending January 3, 2021, and took the oath of office on January 3, 2018.',
    phone: '202-224-5641',
    fax: null,
    latitude: '38.892839',
    longitude: '-77.004356',
    address_complete: '309 Hart Senate Office Building, Washington, DC 20510',
    address_number: null,
    address_prefix: null,
    address_street: '309 Hart Senate Office Building',
    address_sec_unit_type: null,
    address_sec_unit_num: null,
    address_city: 'Washington',
    address_state: 'DC',
    address_zipcode: '20510',
    address_type: 'Building',
    website: 'https://www.smith.senate.gov/',
    contact_page: 'https://www.smith.senate.gov/content/contact-senator',
    facebook_url: 'https://facebook.com/USSenTinaSmith',
    twitter_handle: 'SenTinaSmith',
    twitter_url: 'https://twitter.com/SenTinaSmith',
    photo_url:
      'https://cdn.civil.services/us-senate/headshots/512x512/tina-smith.jpg',
  },
  {
    state_name: 'Minnesota',
    state_name_slug: 'minnesota',
    state_code: 'MN',
    state_code_slug: 'mn',
    class: 'I',
    bioguide: 'K000367',
    thomas: '1826',
    opensecrets: 'N00027500',
    votesmart: '65092',
    fec: null,
    maplight: null,
    wikidata: null,
    google_entity_id: null,
    title: 'senator',
    party: 'democrat',
    name: 'Amy Klobuchar',
    name_slug: 'amy-klobuchar',
    first_name: 'Amy',
    middle_name: null,
    last_name: 'Klobuchar',
    name_suffix: null,
    goes_by: null,
    pronunciation: 'AY-mee KLOH-buh-shar',
    gender: 'female',
    ethnicity: 'white-american',
    religion: 'congregationalist',
    openly_lgbtq: 'no',
    date_of_birth: '1960-05-25',
    entered_office: '2007-01-03',
    term_end: '2019-01-03',
    biography:
      'Amy Klobuchar, a Senator from Minnesota; born in Plymouth, Minn., May 25, 1960; attended the public schools in Plymouth; graduated magna cum laude Yale University 1982; J.D., University of Chicago 1985; practiced law in Minnesota, becoming partner at the law firms of Dorsey &amp; Whitney and Gray Plant Mooty; Hennepin County Attorney 1999-2006; elected as a Democrat to the United States Senate in 2006 for the term commencing January 3, 2007; reelected in 2012 for the term ending January 3, 2019.',
    phone: '202-224-3244',
    fax: '202-228-2186',
    latitude: '38.892839',
    longitude: '-77.004356',
    address_complete: '302 Hart Senate Office Building, Washington, DC 20510',
    address_number: null,
    address_prefix: null,
    address_street: '302 Hart Senate Office Building',
    address_sec_unit_type: null,
    address_sec_unit_num: null,
    address_city: 'Washington',
    address_state: 'DC',
    address_zipcode: '20510',
    address_type: 'Building',
    website: 'http://www.klobuchar.senate.gov',
    contact_page: 'https://www.klobuchar.senate.gov/public/index.cfm/email-amy',
    facebook_url: 'https://facebook.com/amyklobuchar',
    twitter_handle: 'amyklobuchar',
    twitter_url: 'https://twitter.com/amyklobuchar',
    photo_url:
      'https://cdn.civil.services/us-senate/headshots/512x512/amy-klobuchar.jpg',
  },
  {
    state_name: 'Missouri',
    state_name_slug: 'missouri',
    state_code: 'MO',
    state_code_slug: 'mo',
    class: 'I',
    bioguide: 'H001089',
    thomas: null,
    opensecrets: 'N00041620',
    votesmart: '169716',
    fec: 'S8MO00160',
    maplight: null,
    wikidata: 'Q23020745',
    google_entity_id: null,
    title: 'senator',
    party: 'republican',
    name: 'Josh Hawley',
    name_slug: 'josh-hawley',
    first_name: 'Josh',
    middle_name: null,
    last_name: 'Hawley',
    name_suffix: null,
    goes_by: null,
    pronunciation: 'JAHSH HAW-lee',
    gender: 'male',
    ethnicity: 'white-american',
    religion: 'unspecified',
    openly_lgbtq: 'no',
    date_of_birth: '1979-12-31',
    entered_office: '2019-01-03',
    term_end: '2025-01-03',
    biography:
      "Josh Hawley is a Republican member of the U.S. Senate from Missouri. Hawley was elected to the office on November 6, 2018. Hawley is the current attorney general of Missouri. He was the Republican candidate for the office in the state's 2016 election. He won the general election on November 8, 2016.",
    phone: '202-224-6154',
    fax: null,
    latitude: '38.892947',
    longitude: '-77.075686',
    address_complete: 'B40A Dirksen Senate Office Building Washington DC 20510',
    address_number: null,
    address_prefix: null,
    address_street: 'B40A Dirksen Senate Office Building Washington DC 20510',
    address_sec_unit_type: null,
    address_sec_unit_num: null,
    address_city: 'Washington',
    address_state: 'DC',
    address_zipcode: '20510',
    address_type: 'Building',
    website: 'https://www.hawley.senate.gov',
    contact_page: 'https://www.hawley.senate.gov/contact-senator-hawley',
    facebook_url: 'https://facebook.com/SenatorHawley',
    twitter_handle: 'HawleyMO',
    twitter_url: 'https://twitter.com/HawleyMO',
    photo_url:
      'https://cdn.civil.services/us-senate/headshots/512x512/josh-hawley.jpg',
  },
  {
    state_name: 'Missouri',
    state_name_slug: 'missouri',
    state_code: 'MO',
    state_code_slug: 'mo',
    class: 'III',
    bioguide: 'B000575',
    thomas: '1464',
    opensecrets: 'N00005195',
    votesmart: '418',
    fec: null,
    maplight: null,
    wikidata: null,
    google_entity_id: null,
    title: 'senator',
    party: 'republican',
    name: 'Roy Blunt',
    name_slug: 'roy-blunt',
    first_name: 'Roy',
    middle_name: null,
    last_name: 'Blunt',
    name_suffix: null,
    goes_by: null,
    pronunciation: 'ROY BLUHNT',
    gender: 'male',
    ethnicity: 'white-american',
    religion: 'southern-baptist',
    openly_lgbtq: 'no',
    date_of_birth: '1950-01-10',
    entered_office: '2011-01-03',
    term_end: '2023-01-03',
    biography:
      'Roy Blunt, a Senator and a Representative from Missouri; born in Niangua, Webster County, Mo., January 10, 1950; graduated Southwest Baptist University, Bolivar, Mo., B.A., 1970; graduated Missouri State University, Springfield, Mo., M.A., 1972; high school history teacher; clerk and chief election officer, Greene County, Mo. 1973-1984; Missouri state secretary of state 1984-1993; President, Southwest Baptist University, Bolivar, Mo. 1993-1996; elected as a Republican to the One Hundred Fifth and to the six succeeding Congresses (January 3, 1997-January 3, 2011); majority whip (One Hundred Eighth through One Hundred Ninth Congresses); acting majority leader (One Hundred Ninth Congress, September 28, 2005-February 2, 2006); minority whip (One Hundred Tenth Congress); was not a candidate for reelection to the House of Representatives but was elected as a Republican to the United States Senate in 2010 for the term ending January 3, 2017; vice-chair, Republican Conference (2012-); chair, Committee on Rules and Administration (One Hundred Fourteenth Congress).',
    phone: '202-224-5721',
    fax: '202-224-8149',
    latitude: '38.892542',
    longitude: '-77.007415',
    address_complete:
      '260 Russell Senate Office Building, Washington, DC 20510',
    address_number: null,
    address_prefix: null,
    address_street: '260 Russell Senate Office Building',
    address_sec_unit_type: null,
    address_sec_unit_num: null,
    address_city: 'Washington',
    address_state: 'DC',
    address_zipcode: '20510',
    address_type: 'Building',
    website: 'http://www.blunt.senate.gov',
    contact_page: 'https://www.blunt.senate.gov/public/index.cfm/contact-roy',
    facebook_url: 'https://facebook.com/RoyForMissouri',
    twitter_handle: 'RoyBlunt',
    twitter_url: 'https://twitter.com/RoyBlunt',
    photo_url:
      'https://cdn.civil.services/us-senate/headshots/512x512/roy-blunt.jpg',
  },
  {
    state_name: 'Mississippi',
    state_name_slug: 'mississippi',
    state_code: 'MS',
    state_code_slug: 'ms',
    class: 'I',
    bioguide: 'W000437',
    thomas: '1226',
    opensecrets: 'N00003280',
    votesmart: '21926',
    fec: null,
    maplight: null,
    wikidata: null,
    google_entity_id: null,
    title: 'senator',
    party: 'republican',
    name: 'Roger Wicker',
    name_slug: 'roger-wicker',
    first_name: 'Roger',
    middle_name: 'F.',
    last_name: 'Wicker',
    name_suffix: null,
    goes_by: null,
    pronunciation: 'RAH-jer WIK-er',
    gender: 'male',
    ethnicity: 'white-american',
    religion: 'southern-baptist',
    openly_lgbtq: 'no',
    date_of_birth: '1951-07-05',
    entered_office: '2007-12-31',
    term_end: '2019-01-03',
    biography:
      'Roger Wicker, a Senator and a Representative from Mississippi; born in Pontotoc, Pontotoc County, Miss., July 5, 1951; graduated Pontotoc High School, Pontotoc, Miss., 1969; B.A., University of Mississippi, University, Miss., 1973; J.D., University of Mississippi, University, Miss. 1975; Air Force Reserve Officer Training Corps; United States Air Force 1976-1980; United States Air Force Reserve 1980-2003; staff for United States Representative Trent Lott of Mississippi, House Committee on Rules 1980-1982; Tupelo, Miss., judge pro tem; Lee County, Miss., public defender 1984-1987; member of the Mississippi state Senate 1988-1994; elected as a Republican to the One Hundred Fourth and to the six succeeding Congresses (January 3, 1995-December 31, 2007); resigned on December 31, 2007, to accept appointment to the Senate; appointed to the U.S. Senate on December 31, 2007, to fill the seat left vacant by the resignation of Chester Trent Lott; took oath of office on December 31, 2007; elected in 2008 in a special election for the term ending January 3, 2013; reelected in 2012 for the term ending January 3, 2019; chair, National Republican Senatorial Committee (2015-).',
    phone: '202-224-6253',
    fax: '202-228-0378',
    latitude: '38.892947',
    longitude: '-77.075686',
    address_complete:
      '555 Dirksen Senate Office Building, Washington, DC 20510',
    address_number: null,
    address_prefix: null,
    address_street: '555 Dirksen Senate Office Building',
    address_sec_unit_type: null,
    address_sec_unit_num: null,
    address_city: 'Washington',
    address_state: 'DC',
    address_zipcode: '20510',
    address_type: 'Building',
    website: 'http://www.wicker.senate.gov',
    contact_page: 'https://www.wicker.senate.gov/public/index.cfm/contact',
    facebook_url: 'https://facebook.com/wicker',
    twitter_handle: 'SenatorWicker',
    twitter_url: 'https://twitter.com/SenatorWicker',
    photo_url:
      'https://cdn.civil.services/us-senate/headshots/512x512/roger-wicker.jpg',
  },
  {
    state_name: 'Mississippi',
    state_name_slug: 'mississippi',
    state_code: 'MS',
    state_code_slug: 'ms',
    class: 'II',
    bioguide: 'H001079',
    thomas: null,
    opensecrets: 'N00043298',
    votesmart: '20784',
    fec: null,
    maplight: null,
    wikidata: null,
    google_entity_id: null,
    title: 'senator',
    party: 'republican',
    name: 'Cindy Hyde-Smith',
    name_slug: 'cindy-hyde-smith',
    first_name: 'Cindy',
    middle_name: null,
    last_name: 'Hyde-Smith',
    name_suffix: null,
    goes_by: null,
    pronunciation: 'SIN-dee HIDE-smith',
    gender: 'female',
    ethnicity: 'white-american',
    religion: 'christian',
    openly_lgbtq: 'no',
    date_of_birth: '1959-05-10',
    entered_office: '2018-04-09',
    term_end: '2027-01-03',
    biography:
      'Cindy Hyde-Smith is a Senator from Mississippi; born in Brookhaven, Miss., May 10, 1959; graduated Copiah-Lincoln Community College; B.A., University of Southern Mississippi, 1981; cattle farmer; member of the Mississippi state senate 2000-2012; Mississippi state commissioner of agriculture and commerce 2012-2018; appointed as a Republican to the United States Senate to fill the vacancy caused by the resignation of Thad Cochran; took the oath of office on April 9, 2018; subsequently elected in a special election on November 27, 2018, for the remainder of the term ending January 3, 2021.',
    phone: '202-224-5054',
    fax: null,
    latitude: '38.892947',
    longitude: '-77.075686',
    address_complete:
      '113 Dirksen Senate Office Building, Washington, DC 20510',
    address_number: null,
    address_prefix: null,
    address_street: '113 Dirksen Senate Office Building',
    address_sec_unit_type: null,
    address_sec_unit_num: null,
    address_city: 'Washington',
    address_state: 'DC',
    address_zipcode: '20510',
    address_type: 'Building',
    website: 'http://www.hydesmith.senate.gov',
    contact_page: 'https://www.hydesmith.senate.gov/content/contact-senator',
    facebook_url: 'https://facebook.com/SenatorCindyHydeSmith',
    twitter_handle: 'SenHydeSmith',
    twitter_url: 'https://twitter.com/SenHydeSmith',
    photo_url:
      'https://cdn.civil.services/us-senate/headshots/512x512/cindy-hyde-smith.jpg',
  },
  {
    state_name: 'Montana',
    state_name_slug: 'montana',
    state_code: 'MT',
    state_code_slug: 'mt',
    class: 'I',
    bioguide: 'T000464',
    thomas: '1829',
    opensecrets: 'N00027605',
    votesmart: '20928',
    fec: null,
    maplight: null,
    wikidata: null,
    google_entity_id: null,
    title: 'senator',
    party: 'democrat',
    name: 'Jon Tester',
    name_slug: 'jon-tester',
    first_name: 'Jon',
    middle_name: null,
    last_name: 'Tester',
    name_suffix: null,
    goes_by: null,
    pronunciation: 'JAHN TEH-ster',
    gender: 'male',
    ethnicity: 'white-american',
    religion: 'church-of-god',
    openly_lgbtq: 'no',
    date_of_birth: '1956-08-21',
    entered_office: '2007-01-03',
    term_end: '2019-01-03',
    biography:
      'Jon Tester, a Senator from Montana; born in Havre, Mont., on August 21, 1956; graduated College of Great Falls 1978, farmer; teacher; member, Big Sandy school board 1983-1992 (chairman 1986-1991); Montana State Senate 1999-2006, serving as minority whip 2001-2003, minority leader 2003-2005, president 2005-2006; elected as a Democrat to the United States Senate in 2006 for the term commencing January 3, 2007; reelected in 2012 for the term ending January 3, 2019; chair, Committee on Indian Affairs (One Hundred Thirteenth Congress [February 12, 2014-January 3, 2015]); chair, Democratic Senatorial Campaign Committee (2015-).',
    phone: '202-224-2644',
    fax: '202-224-8594',
    latitude: '38.892839',
    longitude: '-77.004356',
    address_complete: '311 Hart Senate Office Building, Washington, DC 20510',
    address_number: null,
    address_prefix: null,
    address_street: '311 Hart Senate Office Building',
    address_sec_unit_type: null,
    address_sec_unit_num: null,
    address_city: 'Washington',
    address_state: 'DC',
    address_zipcode: '20510',
    address_type: 'Building',
    website: 'http://www.tester.senate.gov',
    contact_page: 'https://www.tester.senate.gov/?p=email_senator',
    facebook_url: 'https://facebook.com/senatortester',
    twitter_handle: 'SenatorTester',
    twitter_url: 'https://twitter.com/SenatorTester',
    photo_url:
      'https://cdn.civil.services/us-senate/headshots/512x512/jon-tester.jpg',
  },
  {
    state_name: 'Montana',
    state_name_slug: 'montana',
    state_code: 'MT',
    state_code_slug: 'mt',
    class: 'II',
    bioguide: 'D000618',
    thomas: '2138',
    opensecrets: 'N00033054',
    votesmart: '135720',
    fec: null,
    maplight: null,
    wikidata: null,
    google_entity_id: null,
    title: 'senator',
    party: 'republican',
    name: 'Steve Daines',
    name_slug: 'steve-daines',
    first_name: 'Steve',
    middle_name: null,
    last_name: 'Daines',
    name_suffix: null,
    goes_by: null,
    pronunciation: 'STEEV DAYNZ',
    gender: 'male',
    ethnicity: 'white-american',
    religion: 'presbyterian',
    openly_lgbtq: 'no',
    date_of_birth: '1962-08-20',
    entered_office: '2015-01-03',
    term_end: '2027-01-03',
    biography:
      'Steve Daines, a Senator and a Representative from Montana; born in Van Nuys, Los Angeles County, Calif., August 20, 1962; graduated from Bozeman High School, Bozeman, Mont.; B.S., Montana State University, Bozeman, Mont., 1984; businessman; delegate, Republican National Convention, 1984; unsuccessful candidate for lieutenant governor of Montana in 2008; elected as a Republican to the One Hundred Thirteenth Congress (January 3, 2013-January 3, 2015); was not a candidate for reelection to the House of Representatives but was elected as a Republican to the United States Senate in 2014 for the term ending January 3, 2021.',
    phone: '202-224-2651',
    fax: null,
    latitude: '38.892839',
    longitude: '-77.004356',
    address_complete: '320 Hart Senate Office Building, Washington, DC 20510',
    address_number: null,
    address_prefix: null,
    address_street: '320 Hart Senate Office Building',
    address_sec_unit_type: null,
    address_sec_unit_num: null,
    address_city: 'Washington',
    address_state: 'DC',
    address_zipcode: '20510',
    address_type: 'Building',
    website: 'http://www.daines.senate.gov',
    contact_page: 'https://www.daines.senate.gov/connect/email-steve',
    facebook_url: 'https://facebook.com/SteveDainesMT',
    twitter_handle: 'SteveDaines',
    twitter_url: 'https://twitter.com/SteveDaines',
    photo_url:
      'https://cdn.civil.services/us-senate/headshots/512x512/steve-daines.jpg',
  },
  {
    state_name: 'North Carolina',
    state_name_slug: 'north-carolina',
    state_code: 'NC',
    state_code_slug: 'nc',
    class: 'III',
    bioguide: 'B001135',
    thomas: '153',
    opensecrets: 'N00002221',
    votesmart: '21787',
    fec: null,
    maplight: null,
    wikidata: null,
    google_entity_id: null,
    title: 'senator',
    party: 'republican',
    name: 'Richard Burr',
    name_slug: 'richard-burr',
    first_name: 'Richard',
    middle_name: null,
    last_name: 'Burr',
    name_suffix: null,
    goes_by: null,
    pronunciation: 'RICH-erd BUR',
    gender: 'male',
    ethnicity: 'white-american',
    religion: 'methodist',
    openly_lgbtq: 'no',
    date_of_birth: '1955-11-30',
    entered_office: '2005-01-03',
    term_end: '2023-01-03',
    biography:
      'Richard Burr, a Senator and a Representative from North Carolina; born in Charlottesville, Albemarle County, Va., November 30, 1955; graduated from Reynolds High School, Winston-Salem, N.C., 1974; B.A., Wake Forest University, Winston-Salem, N.C., 1978; unsuccessful candidate for election to the One Hundred Third Congress in 1992; elected as a Republican to the One Hundred Fourth and to the four succeeding Congresses (January 3, 1995-January 3, 2005); was not a candidate for reelection to the House of Representatives, but was elected to the United States Senate in 2004; reelected in 2010 for the term ending January 3, 2017; Chair, Select Committee on Intelligence (One Hundred Fourteenth Congress).',
    phone: '202-224-3154',
    fax: '202-228-2981',
    latitude: '38.892542',
    longitude: '-77.007415',
    address_complete:
      '217 Russell Senate Office Building, Washington, DC 20510',
    address_number: null,
    address_prefix: null,
    address_street: '217 Russell Senate Office Building',
    address_sec_unit_type: null,
    address_sec_unit_num: null,
    address_city: 'Washington',
    address_state: 'DC',
    address_zipcode: '20510',
    address_type: 'Building',
    website: 'http://www.burr.senate.gov',
    contact_page: 'https://www.burr.senate.gov/contact/email',
    facebook_url: 'https://facebook.com/SenatorRichardBurr',
    twitter_handle: 'SenatorBurr',
    twitter_url: 'https://twitter.com/SenatorBurr',
    photo_url:
      'https://cdn.civil.services/us-senate/headshots/512x512/richard-burr.jpg',
  },
  {
    state_name: 'North Carolina',
    state_name_slug: 'north-carolina',
    state_code: 'NC',
    state_code_slug: 'nc',
    class: 'II',
    bioguide: 'T000476',
    thomas: '2291',
    opensecrets: 'N00035492',
    votesmart: '57717',
    fec: null,
    maplight: null,
    wikidata: null,
    google_entity_id: null,
    title: 'senator',
    party: 'republican',
    name: 'Thom Tillis',
    name_slug: 'thom-tillis',
    first_name: 'Thom',
    middle_name: null,
    last_name: 'Tillis',
    name_suffix: null,
    goes_by: null,
    pronunciation: 'TAHM TIL-iss',
    gender: 'male',
    ethnicity: 'white-american',
    religion: 'roman-catholic',
    openly_lgbtq: 'no',
    date_of_birth: '1960-08-30',
    entered_office: '2015-01-03',
    term_end: '2027-01-03',
    biography:
      'Thom Tillis, a Senator from North Carolina; born in Jacksonville, Duval County, Fla., August 30, 1960; B.A., University of Maryland University College, 1997; management consultant; member of the Cornelius (N.C.) board of commissioners 2003-2005; Member of the North Carolina house of representatives 2007-2014, serving as speaker of the House 2011-2014; elected as a Republican to the United States Senate in 2014 for the term ending January 3, 2021.',
    phone: '202-224-6342',
    fax: '202-228-2563',
    latitude: '38.892947',
    longitude: '-77.075686',
    address_complete:
      '185 Dirksen Senate Office Building, Washington, DC 20510',
    address_number: null,
    address_prefix: null,
    address_street: '185 Dirksen Senate Office Building',
    address_sec_unit_type: null,
    address_sec_unit_num: null,
    address_city: 'Washington',
    address_state: 'DC',
    address_zipcode: '20510',
    address_type: 'Building',
    website: 'http://www.tillis.senate.gov',
    contact_page: 'https://www.tillis.senate.gov/public/index.cfm/email-me',
    facebook_url: 'https://facebook.com/SenatorThomTillis',
    twitter_handle: 'SenThomTillis',
    twitter_url: 'https://twitter.com/SenThomTillis',
    photo_url:
      'https://cdn.civil.services/us-senate/headshots/512x512/thom-tillis.jpg',
  },
  {
    state_name: 'North Dakota',
    state_name_slug: 'north-dakota',
    state_code: 'ND',
    state_code_slug: 'nd',
    class: 'I',
    bioguide: 'C001096',
    thomas: null,
    opensecrets: 'N00004614',
    votesmart: '444',
    fec: 'S8ND00120',
    maplight: null,
    wikidata: 'Q3957020',
    google_entity_id: null,
    title: 'senator',
    party: 'republican',
    name: 'Kevin Cramer',
    name_slug: 'kevin-cramer',
    first_name: 'Kevin',
    middle_name: null,
    last_name: 'Cramer',
    name_suffix: null,
    goes_by: null,
    pronunciation: 'KE-vin KRAY-mer',
    gender: 'male',
    ethnicity: 'white-american',
    religion: 'unspecified',
    openly_lgbtq: 'no',
    date_of_birth: '1961-01-21',
    entered_office: '2019-01-03',
    term_end: '2025-01-03',
    biography:
      'Kevin Cramer is a Republican member-elect for the U.S. Senate from North Dakota. Cramer won the general election on November 6, 2018, after advancing from the primary on June 12, 2018. Cramer served as a member of the North Dakota Public Service Commission from 2003-2013. He was elected to the U.S. House in 2012.',
    phone: '202-224-2043',
    fax: null,
    latitude: '38.892947',
    longitude: '-77.075686',
    address_complete: 'B40C Dirksen Senate Office Building Washington DC 20510',
    address_number: null,
    address_prefix: null,
    address_street: 'B40C Dirksen Senate Office Building Washington DC 20510',
    address_sec_unit_type: null,
    address_sec_unit_num: null,
    address_city: 'Washington',
    address_state: 'DC',
    address_zipcode: '20510',
    address_type: 'Building',
    website: 'https://www.cramer.senate.gov/',
    contact_page: 'https://www.cramer.senate.gov/',
    facebook_url: 'https://facebook.com/SenatorKevinCramer',
    twitter_handle: 'SenKevinCramer',
    twitter_url: 'https://twitter.com/SenKevinCramer',
    photo_url:
      'https://cdn.civil.services/us-senate/headshots/512x512/kevin-cramer.jpg',
  },
  {
    state_name: 'North Dakota',
    state_name_slug: 'north-dakota',
    state_code: 'ND',
    state_code_slug: 'nd',
    class: 'III',
    bioguide: 'H001061',
    thomas: '2079',
    opensecrets: 'N00031688',
    votesmart: '41788',
    fec: null,
    maplight: null,
    wikidata: null,
    google_entity_id: null,
    title: 'senator',
    party: 'republican',
    name: 'John Hoeven',
    name_slug: 'john-hoeven',
    first_name: 'John',
    middle_name: null,
    last_name: 'Hoeven',
    name_suffix: null,
    goes_by: null,
    pronunciation: 'JAHN HOH-vuhn',
    gender: 'male',
    ethnicity: 'white-american',
    religion: 'roman-catholic',
    openly_lgbtq: 'no',
    date_of_birth: '1957-03-13',
    entered_office: '2011-01-03',
    term_end: '2023-01-03',
    biography:
      'John Hoeven, a Senator from North Dakota; born in Bismarck, N.D., March 13, 1957; graduated Dartmouth College, B.A., 1979; graduated Northwestern University, M.B.A., 1981; bank president and CEO; governor of North Dakota 2000-2010; elected as a Republican to the United States Senate in 2010 for the term ending January 3, 2017.',
    phone: '202-224-2551',
    fax: '701- 250-4484',
    latitude: '38.892542',
    longitude: '-77.007415',
    address_complete:
      '338 Russell Senate Office Building, Washington, DC 20510',
    address_number: null,
    address_prefix: null,
    address_street: '338 Russell Senate Office Building',
    address_sec_unit_type: null,
    address_sec_unit_num: null,
    address_city: 'Washington',
    address_state: 'DC',
    address_zipcode: '20510',
    address_type: 'Building',
    website: 'http://www.hoeven.senate.gov',
    contact_page:
      'https://www.hoeven.senate.gov/public/index.cfm/email-the-senator',
    facebook_url: 'https://facebook.com/SenatorJohnHoeven',
    twitter_handle: 'SenJohnHoeven',
    twitter_url: 'https://twitter.com/SenJohnHoeven',
    photo_url:
      'https://cdn.civil.services/us-senate/headshots/512x512/john-hoeven.jpg',
  },
  {
    state_name: 'Nebraska',
    state_name_slug: 'nebraska',
    state_code: 'NE',
    state_code_slug: 'ne',
    class: 'II',
    bioguide: 'S001197',
    thomas: '2289',
    opensecrets: 'N00035544',
    votesmart: '150182',
    fec: null,
    maplight: null,
    wikidata: null,
    google_entity_id: null,
    title: 'senator',
    party: 'republican',
    name: 'Ben Sasse',
    name_slug: 'ben-sasse',
    first_name: 'Ben',
    middle_name: null,
    last_name: 'Sasse',
    name_suffix: null,
    goes_by: null,
    pronunciation: 'BEN SASS',
    gender: 'male',
    ethnicity: 'white-american',
    religion: 'evangelical-lutheran',
    openly_lgbtq: 'no',
    date_of_birth: '1972-02-22',
    entered_office: '2015-01-03',
    term_end: '2027-01-03',
    biography:
      "Benjamin Sasse, a Senator from Nebraska; born in Plainview, Pierce County, Nebr., February 22, 1972; B.A., Harvard University, 1994; M.A., St. John's College, Annapolis, Md., 1998; Ph.D., Yale University, 2004; management consultant; University professor; chief of staff to U.S. Representative Jeff Fortenberry; counselor to the secretary, U.S. Department of Health and Human Services; assistant secretary for planning and evaluation, U.S. Department of Health and Human Services; president of Midland University, Fremont, Nebr.; elected as a Republican to the United States Senate in 2014 for the term ending January 3, 2021.",
    phone: '202-224-4224',
    fax: null,
    latitude: '38.892542',
    longitude: '-77.007415',
    address_complete:
      '386A Russell Senate Office Building, Washington, DC 20510',
    address_number: null,
    address_prefix: null,
    address_street: '386A Russell Senate Office Building',
    address_sec_unit_type: null,
    address_sec_unit_num: null,
    address_city: 'Washington',
    address_state: 'DC',
    address_zipcode: '20510',
    address_type: 'Building',
    website: 'http://www.sasse.senate.gov',
    contact_page: 'https://www.sasse.senate.gov/public/index.cfm/email-ben',
    facebook_url: 'https://facebook.com/SenatorSasse',
    twitter_handle: 'SenSasse',
    twitter_url: 'https://twitter.com/SenSasse',
    photo_url:
      'https://cdn.civil.services/us-senate/headshots/512x512/ben-sasse.jpg',
  },
  {
    state_name: 'Nebraska',
    state_name_slug: 'nebraska',
    state_code: 'NE',
    state_code_slug: 'ne',
    class: 'I',
    bioguide: 'F000463',
    thomas: '2179',
    opensecrets: 'N00033443',
    votesmart: '41963',
    fec: null,
    maplight: null,
    wikidata: null,
    google_entity_id: null,
    title: 'senator',
    party: 'republican',
    name: 'Deb Fischer',
    name_slug: 'deb-fischer',
    first_name: 'Deb',
    middle_name: null,
    last_name: 'Fischer',
    name_suffix: null,
    goes_by: null,
    pronunciation: 'DEBB FISH-er',
    gender: 'female',
    ethnicity: 'white-american',
    religion: 'presbyterian',
    openly_lgbtq: 'no',
    date_of_birth: '1951-03-01',
    entered_office: '2013-01-03',
    term_end: '2019-01-03',
    biography:
      'Deb Fischer, a Senator from Nebraska; born in Lincoln, Neb., March 1, 1951; graduated University of Nebraska, B.S., 1988; rancher; Valentine Rural High School Board of Education 1990-2004; Nebraska Coordinating Commission for Postsecondary Education 2000-2004; Nebraska Unicameral Legislature 2005-2012; elected as a Republican to the United States Senate in 2012 for the term ending January 3, 2019.',
    phone: '202-224-6551',
    fax: '202-228-1325',
    latitude: '38.892542',
    longitude: '-77.007415',
    address_complete:
      '454 Russell Senate Office Building, Washington, DC 20510',
    address_number: null,
    address_prefix: null,
    address_street: '454 Russell Senate Office Building',
    address_sec_unit_type: null,
    address_sec_unit_num: null,
    address_city: 'Washington',
    address_state: 'DC',
    address_zipcode: '20510',
    address_type: 'Building',
    website: 'http://www.fischer.senate.gov',
    contact_page: 'https://www.fischer.senate.gov/public/index.cfm/contact',
    facebook_url: 'https://facebook.com/senatordebfischer',
    twitter_handle: 'SenatorFischer',
    twitter_url: 'https://twitter.com/SenatorFischer',
    photo_url:
      'https://cdn.civil.services/us-senate/headshots/512x512/deb-fischer.jpg',
  },
  {
    state_name: 'New Hampshire',
    state_name_slug: 'new-hampshire',
    state_code: 'NH',
    state_code_slug: 'nh',
    class: 'II',
    bioguide: 'S001181',
    thomas: '1901',
    opensecrets: 'N00024790',
    votesmart: '1663',
    fec: null,
    maplight: null,
    wikidata: null,
    google_entity_id: null,
    title: 'senator',
    party: 'democrat',
    name: 'Jeanne Shaheen',
    name_slug: 'jeanne-shaheen',
    first_name: 'Jeanne',
    middle_name: null,
    last_name: 'Shaheen',
    name_suffix: null,
    goes_by: null,
    pronunciation: 'JEEN shuh-HEEN',
    gender: 'female',
    ethnicity: 'white-american',
    religion: 'protestant',
    openly_lgbtq: 'no',
    date_of_birth: '1947-01-28',
    entered_office: '2009-01-03',
    term_end: '2021-01-13',
    biography:
      'Jeanne Shaheen, a Senator from New Hampshire; born in St. Charles, Mo., January 28, 1947; B.A., Shippensburg State College, Shippensburg, Penn., 1969; M.S.S., University of Mississippi, 1973; New Hampshire State Senator, 1990-1996; governor of New Hampshire, 1997-2003; unsuccessful candidate for election to the United States Senate in 2002; elected as a Democrat to the United States Senate in 2008; reelected in 2014 for the term ending January 3, 2021.',
    phone: '202-224-2841',
    fax: null,
    latitude: '38.892839',
    longitude: '-77.004356',
    address_complete: '506 Hart Senate Office Building, Washington, DC 20510',
    address_number: null,
    address_prefix: null,
    address_street: '506 Hart Senate Office Building',
    address_sec_unit_type: null,
    address_sec_unit_num: null,
    address_city: 'Washington',
    address_state: 'DC',
    address_zipcode: '20510',
    address_type: 'Building',
    website: 'http://www.shaheen.senate.gov',
    contact_page: 'https://www.shaheen.senate.gov/contact/contact-jeanne',
    facebook_url: 'https://facebook.com/SenatorShaheen',
    twitter_handle: 'SenatorShaheen',
    twitter_url: 'https://twitter.com/SenatorShaheen',
    photo_url:
      'https://cdn.civil.services/us-senate/headshots/512x512/jeanne-shaheen.jpg',
  },
  {
    state_name: 'New Hampshire',
    state_name_slug: 'new-hampshire',
    state_code: 'NH',
    state_code_slug: 'nh',
    class: 'III',
    bioguide: 'H001076',
    thomas: null,
    opensecrets: 'N00038397',
    votesmart: '42552',
    fec: null,
    maplight: null,
    wikidata: null,
    google_entity_id: null,
    title: 'senator',
    party: 'democrat',
    name: 'Margaret Hassan',
    name_slug: 'margaret-hassan',
    first_name: 'Margaret',
    middle_name: null,
    last_name: 'Hassan',
    name_suffix: null,
    goes_by: 'Maggie',
    pronunciation: 'MAG-ee HASS-uhn',
    gender: 'female',
    ethnicity: 'white-american',
    religion: 'united-church-of-christ',
    openly_lgbtq: 'no',
    date_of_birth: '1958-02-27',
    entered_office: '2017-01-03',
    term_end: '2023-01-03',
    biography:
      'Maggie Hassan, a Senator from New Hampshire; born in Boston, Mass., February 27, 1958; B.A., Brown University, 1980; J.D., Northeastern University School of Law, 1985; lawyer; member of the New Hampshire Advisory Committee to the Adequacy in Education and Finance Commission; member of the New Hampshire state Senate 2005-2010, serving as majority leader 2008-2010; governor of New Hampshire 2013-2016; elected as a Democrat to the United States Senate in 2016 for the term ending January 3, 2023.',
    phone: '202-224-3324',
    fax: null,
    latitude: '38.892542',
    longitude: '-77.007415',
    address_complete:
      'B85 Russell Senate Office Building, Washington, DC 20510',
    address_number: null,
    address_prefix: null,
    address_street: 'B85 Russell Senate Office Building',
    address_sec_unit_type: null,
    address_sec_unit_num: null,
    address_city: 'Washington',
    address_state: 'DC',
    address_zipcode: '20510',
    address_type: 'Building',
    website: 'http://www.hassan.senate.gov',
    contact_page: 'https://www.hassan.senate.gov/content/contact-senator',
    facebook_url: 'https://facebook.com/maggiefornh',
    twitter_handle: 'Maggie_Hassan',
    twitter_url: 'https://twitter.com/Maggie_Hassan',
    photo_url:
      'https://cdn.civil.services/us-senate/headshots/512x512/margaret-hassan.jpg',
  },
  {
    state_name: 'New Jersey',
    state_name_slug: 'new-jersey',
    state_code: 'NJ',
    state_code_slug: 'nj',
    class: 'II',
    bioguide: 'B001288',
    thomas: '2194',
    opensecrets: 'N00035267',
    votesmart: '76151',
    fec: null,
    maplight: null,
    wikidata: null,
    google_entity_id: null,
    title: 'senator',
    party: 'democrat',
    name: 'Cory Booker',
    name_slug: 'cory-booker',
    first_name: 'Cory',
    middle_name: null,
    last_name: 'Booker',
    name_suffix: null,
    goes_by: null,
    pronunciation: 'KOR-ee BOOK-er',
    gender: 'male',
    ethnicity: 'african-american',
    religion: 'roman-catholic',
    openly_lgbtq: 'no',
    date_of_birth: '1969-04-27',
    entered_office: '2013-10-31',
    term_end: '2027-01-03',
    biography:
      "Cory Booker, a Senator from New Jersey; born on April 27, 1969, in Washington, D.C.; graduated Northern Valley Regional High School, Old Tappan, N.J., 1987; B.A., Stanford University, 1991; M.A., Stanford University, 1992; attended The Queen's College, University of Oxford, Oxford, England, as a Rhodes Scholar and received a graduate degree in 1994; J.D., Yale Law School, 1997; worked as an attorney in the non-profit sector; Newark City Council 1998-2002; Mayor of Newark, N.J. 2006-2013; elected as a Democrat to the United States Senate in a special election on October 16, 2013, to fill the vacancy caused by the death of Frank Lautenberg, a seat subsequently held by appointed Senator Jeffrey Chiesa, and took the oath of office on October 31, 2013; reelected in 2014 for the term ending January 3, 2021.",
    phone: '202-224-3224',
    fax: '202-224-8378',
    latitude: '38.892947',
    longitude: '-77.075686',
    address_complete:
      '359 Dirksen Senate Office Building, Washington, DC 20510',
    address_number: null,
    address_prefix: null,
    address_street: '359 Dirksen Senate Office Building',
    address_sec_unit_type: null,
    address_sec_unit_num: null,
    address_city: 'Washington',
    address_state: 'DC',
    address_zipcode: '20510',
    address_type: 'Building',
    website: 'http://www.booker.senate.gov',
    contact_page: 'https://www.booker.senate.gov/?p=contact',
    facebook_url: 'https://facebook.com/corybooker',
    twitter_handle: 'CoryBooker',
    twitter_url: 'https://twitter.com/CoryBooker',
    photo_url:
      'https://cdn.civil.services/us-senate/headshots/512x512/cory-booker.jpg',
  },
  {
    state_name: 'New Jersey',
    state_name_slug: 'new-jersey',
    state_code: 'NJ',
    state_code_slug: 'nj',
    class: 'I',
    bioguide: 'M000639',
    thomas: '791',
    opensecrets: 'N00000699',
    votesmart: '26961',
    fec: null,
    maplight: null,
    wikidata: null,
    google_entity_id: null,
    title: 'senator',
    party: 'democrat',
    name: 'Robert Menendez',
    name_slug: 'robert-menendez',
    first_name: 'Robert',
    middle_name: null,
    last_name: 'Menendez',
    name_suffix: null,
    goes_by: null,
    pronunciation: 'BAHB men-EN-dez',
    gender: 'male',
    ethnicity: 'hispanic-american',
    religion: 'christian',
    openly_lgbtq: 'no',
    date_of_birth: '1954-01-01',
    entered_office: '2006-01-18',
    term_end: '2019-01-03',
    biography:
      "Robert Menendez, a Senator and a Representative from New Jersey; born in New York, N.Y., on January 1, 1954; graduated from Union Hill High School, Union City, N.J., 1972; B.A., Saint Peter's College, Jersey City, N.J., 1976; J.D., Rutgers University School of Law, Newark, N.J., 1979; lawyer, private practice; member of the Union City, N.J., board of education 1974-1978, and chief financial officer 1978-1982; mayor of Union City, N.J., 1986-1992; member of the New Jersey state general assembly 1987-1991; Member of the New Jersey state Senate 1991-1993; elected as a Democrat to the One Hundred Third and to the six succeeding Congresses, and served from January 3, 1993, to January 16, 2006, when he resigned to become a U.S. senator; chair, House Democratic Caucus (One Hundred Eighth through One Hundred Ninth Congresses); appointed on January 17, 2006, to the United States Senate to fill vacancy caused by resignation of Jon S. Corzine; service began on January 17, 2006, and took the oath of office on January 18, 2006; elected to the U.S. Senate in 2006 for the term commencing January 3, 2007; reelected in 2012 for the term ending January 3, 2019; chair, Democratic Senatorial Campaign Committee (2008-2011), Committee on Foreign Relations (One Hundred Thirteenth Congress [February 1, 2013-January 3, 2015]).",
    phone: '202-224-4744',
    fax: '202-228-2197',
    latitude: '38.892839',
    longitude: '-77.004356',
    address_complete: '528 Hart Senate Office Building, Washington, DC 20510',
    address_number: null,
    address_prefix: null,
    address_street: '528 Hart Senate Office Building',
    address_sec_unit_type: null,
    address_sec_unit_num: null,
    address_city: 'Washington',
    address_state: 'DC',
    address_zipcode: '20510',
    address_type: 'Building',
    website: 'http://menendez.senate.gov',
    contact_page: 'https://www.menendez.senate.gov/contact',
    facebook_url: 'https://facebook.com/senatormenendez',
    twitter_handle: 'SenatorMenendez',
    twitter_url: 'https://twitter.com/SenatorMenendez',
    photo_url:
      'https://cdn.civil.services/us-senate/headshots/512x512/robert-menendez.jpg',
  },
  {
    state_name: 'New Mexico',
    state_name_slug: 'new-mexico',
    state_code: 'NM',
    state_code_slug: 'nm',
    class: 'I',
    bioguide: 'H001046',
    thomas: '1937',
    opensecrets: 'N00029835',
    votesmart: '74517',
    fec: null,
    maplight: null,
    wikidata: null,
    google_entity_id: null,
    title: 'senator',
    party: 'democrat',
    name: 'Martin Heinrich',
    name_slug: 'martin-heinrich',
    first_name: 'Martin',
    middle_name: null,
    last_name: 'Heinrich',
    name_suffix: null,
    goes_by: null,
    pronunciation: 'MAR-tn HINE-rehk',
    gender: 'male',
    ethnicity: 'white-american',
    religion: 'lutheran',
    openly_lgbtq: 'no',
    date_of_birth: '1971-10-17',
    entered_office: '2013-01-03',
    term_end: '2019-01-03',
    biography:
      'Martin Heinrich, a Senator and a Representative from New Mexico; born in Fallon, Churchill County, Nev.; October 17, 1971; B. S., University of Missouri, Columbia, Mo., 1995; attended University of New Mexico, Albuquerque, N. Mex., 2001-2002; engineer; business owner; member, Albuquerque, N. Mex., city council, 2003-2007; President, Albuquerque, N. Mex., city council, 2005-2006; trustee, New Mexico state natural resources, 2006; elected as a Democrat to the One Hundred Eleventh and to the succeeding Congress (January 3, 2009-January 3, 2013); was not a candidate for reelection to the House of Representatives but was elected as a Democrat to the United States Senate in 2012 for the term ending January 3, 2019.',
    phone: '202-224-5521',
    fax: '202-228-2841',
    latitude: '38.892839',
    longitude: '-77.004356',
    address_complete: '303 Hart Senate Office Building, Washington, DC 20510',
    address_number: null,
    address_prefix: null,
    address_street: '303 Hart Senate Office Building',
    address_sec_unit_type: null,
    address_sec_unit_num: null,
    address_city: 'Washington',
    address_state: 'DC',
    address_zipcode: '20510',
    address_type: 'Building',
    website: 'http://www.heinrich.senate.gov',
    contact_page: 'https://www.heinrich.senate.gov/contact',
    facebook_url: 'https://facebook.com/MartinHeinrich',
    twitter_handle: 'MartinHeinrich',
    twitter_url: 'https://twitter.com/MartinHeinrich',
    photo_url:
      'https://cdn.civil.services/us-senate/headshots/512x512/martin-heinrich.jpg',
  },
  {
    state_name: 'New Mexico',
    state_name_slug: 'new-mexico',
    state_code: 'NM',
    state_code_slug: 'nm',
    class: 'II',
    bioguide: 'L000570',
    thomas: '1939',
    opensecrets: 'N00029562',
    votesmart: '102842',
    fec: 'S0NM00058',
    maplight: null,
    wikidata: null,
    google_entity_id: null,
    title: 'senator',
    party: 'democrat',
    name: 'Ben Luján',
    name_slug: 'ben-lujan',
    first_name: 'Ben',
    middle_name: 'Ray',
    last_name: 'Luján',
    name_suffix: null,
    goes_by: null,
    pronunciation: 'behn lu-khan',
    gender: 'male',
    ethnicity: 'hispanic-american',
    religion: 'christian',
    openly_lgbtq: 'no',
    date_of_birth: '1972-06-07',
    entered_office: '2021-01-03',
    term_end: '2027-01-03',
    biography:
      'Senator Luján grew up in Nambé, a small farming community north of Santa Fe, bordered by the Nambé and Pojoaque Pueblos. It was there that he learned New Mexico values, including hard work, dedication, and perseverance, from his family and community.',
    phone: '202-224-6621',
    fax: null,
    latitude: '38.892839',
    longitude: '-77.004356',
    address_complete: 'B40C Dirksen Senate Office Building Washington DC 20510',
    address_number: null,
    address_prefix: null,
    address_street: 'B40C Dirksen Senate Office Building Washington DC 20510',
    address_sec_unit_type: null,
    address_sec_unit_num: null,
    address_city: 'Washington',
    address_state: 'DC',
    address_zipcode: '20510',
    address_type: 'Building',
    website: 'https://www.lujan.senate.gov',
    contact_page: 'https://www.lujan.senate.gov/',
    facebook_url: 'https://www.facebook.com/SenatorLujan/',
    twitter_handle: 'SenatorLujan',
    twitter_url: 'https://twitter.com/SenatorLujan',
    photo_url:
      'https://cdn.civil.services/us-senate/headshots/512x512/ben-lujan.jpg',
  },
  {
    state_name: 'Nevada',
    state_name_slug: 'nevada',
    state_code: 'NV',
    state_code_slug: 'nv',
    class: 'III',
    bioguide: 'C001113',
    thomas: null,
    opensecrets: 'N00037161',
    votesmart: '69579',
    fec: null,
    maplight: null,
    wikidata: null,
    google_entity_id: null,
    title: 'senator',
    party: 'democrat',
    name: 'Catherine Cortez-Masto',
    name_slug: 'catherine-cortez-masto',
    first_name: 'Catherine',
    middle_name: null,
    last_name: 'Cortez-Masto',
    name_suffix: null,
    goes_by: null,
    pronunciation: 'KATH-rin KOR-tez MASS-toh',
    gender: 'female',
    ethnicity: 'hispanic-american',
    religion: 'roman-catholic',
    openly_lgbtq: 'no',
    date_of_birth: '1964-03-29',
    entered_office: '2017-01-03',
    term_end: '2023-01-03',
    biography:
      'Catherine Cortez Masto, a Senator from Nevada; born in Las Vegas, Nev., March 29, 1964; B.A., University of Nevada, 1986; J.D., Gonzaga University School of Law, 1990; admitted to the Nevada bar in 1990; assistant county manager, Clark County, Nev.; chief of staff to Nevada Governor Bob Miller; federal prosecutor; attorney general of Nevada 2007-2015; executive vice chancellor for the Nevada System of Higher Education; elected as a Democrat to the United States Senate in 2016 for the term ending January 3, 2023.',
    phone: '202-224-3542',
    fax: '202--228-6753',
    latitude: '38.892839',
    longitude: '-77.004356',
    address_complete:
      'B40A Dirksen Senate Office Building, Washington, DC 20510',
    address_number: null,
    address_prefix: null,
    address_street: 'B40A Dirksen Senate Office Building',
    address_sec_unit_type: null,
    address_sec_unit_num: null,
    address_city: 'Washington',
    address_state: 'DC',
    address_zipcode: '20510',
    address_type: 'Building',
    website: 'http://www.masto.senate.gov',
    contact_page: 'https://www.cortezmasto.senate.gov/content/contact-senator',
    facebook_url: 'https://facebook.com/catherinecortezmasto',
    twitter_handle: 'CatherineForNV',
    twitter_url: 'https://twitter.com/CatherineForNV',
    photo_url:
      'https://cdn.civil.services/us-senate/headshots/512x512/catherine-cortez-masto.jpg',
  },
  {
    state_name: 'Nevada',
    state_name_slug: 'nevada',
    state_code: 'NV',
    state_code_slug: 'nv',
    class: 'I',
    bioguide: 'R000608',
    thomas: null,
    opensecrets: 'N00038734',
    votesmart: '169471',
    fec: 'S8NV00156',
    maplight: null,
    wikidata: 'Q27825033',
    google_entity_id: null,
    title: 'senator',
    party: 'democrat',
    name: 'Jacky Rosen',
    name_slug: 'jacky-rosen',
    first_name: 'Jacky',
    middle_name: null,
    last_name: 'Rosen',
    name_suffix: null,
    goes_by: null,
    pronunciation: 'JA-kee ROH-zen',
    gender: 'female',
    ethnicity: 'white-american',
    religion: 'unspecified',
    openly_lgbtq: 'no',
    date_of_birth: '1957-08-02',
    entered_office: '2019-01-03',
    term_end: '2025-01-03',
    biography:
      'Jacky Rosen is a Democratic member of the U.S. Senate from Nevada. Rosen was elected to the office on November 6, 2018. Rosen was previously a Democratic member of the U.S. House representing the 3rd Congressional District of Nevada. Rosen was first elected to the House in 2016.',
    phone: '202-224-6244',
    fax: null,
    latitude: '38.892947',
    longitude: '-77.075686',
    address_complete: 'G12 Dirksen Senate Office Building Washington DC 20510',
    address_number: null,
    address_prefix: null,
    address_street: 'G12 Dirksen Senate Office Building Washington DC 20510',
    address_sec_unit_type: null,
    address_sec_unit_num: null,
    address_city: 'Washington',
    address_state: 'DC',
    address_zipcode: '20510',
    address_type: 'Building',
    website: 'https://www.rosen.senate.gov/',
    contact_page: 'https://www.rosen.senate.gov/',
    facebook_url: 'https://facebook.com/SenJackyRosen',
    twitter_handle: 'SenJackyRosen',
    twitter_url: 'https://twitter.com/SenJackyRosen',
    photo_url:
      'https://cdn.civil.services/us-senate/headshots/512x512/jacky-rosen.jpg',
  },
  {
    state_name: 'New York',
    state_name_slug: 'new-york',
    state_code: 'NY',
    state_code_slug: 'ny',
    class: 'III',
    bioguide: 'S000148',
    thomas: '1036',
    opensecrets: 'N00001093',
    votesmart: '26976',
    fec: null,
    maplight: null,
    wikidata: null,
    google_entity_id: null,
    title: 'senate-minority-leader',
    party: 'democrat',
    name: 'Charles Schumer',
    name_slug: 'charles-schumer',
    first_name: 'Charles',
    middle_name: 'E.',
    last_name: 'Schumer',
    name_suffix: null,
    goes_by: 'Chuck',
    pronunciation: 'CHUHK SHOO-mer',
    gender: 'male',
    ethnicity: 'white-american',
    religion: 'jewish',
    openly_lgbtq: 'no',
    date_of_birth: '1950-11-23',
    entered_office: '1999-01-03',
    term_end: '2023-01-03',
    biography:
      'Charles Schumer, a Senator and a Representative from New York; born in Brooklyn, N.Y., November 23, 1950; attended the public schools; graduated from Madison High School, Brooklyn 1967; A.B., Harvard University 1971; J.D., Harvard Law School 1974; admitted to the New York bar in 1975; served in the New York State Assembly 1975-1980; elected as a Democrat to the Ninety-seventh and to the eight succeeding Congresses (January 3, 1981-January 3, 1999); was not a candidate in 1998 for reelection to the U.S. House of Representatives, but was elected as a Democrat to the United States Senate in 1998; reelected in 2004 and again in 2010 for the term ending January 3, 2017; chair, Democratic Senatorial Campaign Committee (2005-2008); chair, Democratic Policy and Communications Committee (2011-); chair, Joint Economic Committee (One Hundred Tenth and One Hundred Eleventh Congresses), Committee on Rules and Administration (One Hundred Eleventh to One Hundred Thirteenth Congresses).',
    phone: '202-224-6542',
    fax: '202-228-3027',
    latitude: '38.892839',
    longitude: '-77.004356',
    address_complete: '322 Hart Senate Office Building, Washington, DC 20510',
    address_number: null,
    address_prefix: null,
    address_street: '322 Hart Senate Office Building',
    address_sec_unit_type: null,
    address_sec_unit_num: null,
    address_city: 'Washington',
    address_state: 'DC',
    address_zipcode: '20510',
    address_type: 'Building',
    website: 'http://www.schumer.senate.gov',
    contact_page: 'https://www.schumer.senate.gov/contact/email-chuck',
    facebook_url: 'https://facebook.com/senschumer',
    twitter_handle: 'SenSchumer',
    twitter_url: 'https://twitter.com/SenSchumer',
    photo_url:
      'https://cdn.civil.services/us-senate/headshots/512x512/charles-schumer.jpg',
  },
  {
    state_name: 'New York',
    state_name_slug: 'new-york',
    state_code: 'NY',
    state_code_slug: 'ny',
    class: 'I',
    bioguide: 'G000555',
    thomas: '1866',
    opensecrets: 'N00027658',
    votesmart: '65147',
    fec: null,
    maplight: null,
    wikidata: null,
    google_entity_id: null,
    title: 'senator',
    party: 'democrat',
    name: 'Kirsten Gillibrand',
    name_slug: 'kirsten-gillibrand',
    first_name: 'Kirsten',
    middle_name: 'E.',
    last_name: 'Gillibrand',
    name_suffix: null,
    goes_by: null,
    pronunciation: 'KEER-stin JILL-uh-brand',
    gender: 'female',
    ethnicity: 'white-american',
    religion: 'roman-catholic',
    openly_lgbtq: 'no',
    date_of_birth: '1966-12-09',
    entered_office: '2009-01-26',
    term_end: '2019-01-03',
    biography:
      'Kirsten Gillibrand, a Senator and a Representative from New York; born in Albany, Albany County, N.Y., December 9, 1966; A.B., Dartmouth College, Hanover, N.H., 1988; J.D., University of California Los Angeles, Los Angeles, Calif., 1991; lawyer, private practice; special counsel, United States Housing and Urban Development; elected as a Democrat to the One Hundred Tenth Congress and to the succeeding Congress, and served from January 3, 2007, to January 26, 2009, when she resigned to become a U.S. Senator; appointed January 23, 2009, to fill the vacancy caused by the resignation of Hillary Clinton; appointment took effect upon her resignation from the House of Representatives on January 26, 2009; took the oath of office on January 27, 2009; elected in 2010 in a special election for the term ending January 3, 2013; reelected in 2012 for the term ending January 3, 2019.',
    phone: '202-224-4451',
    fax: '202-228-0282',
    latitude: '38.892542',
    longitude: '-77.007415',
    address_complete:
      '478 Russell Senate Office Building, Washington, DC 20510',
    address_number: null,
    address_prefix: null,
    address_street: '478 Russell Senate Office Building',
    address_sec_unit_type: null,
    address_sec_unit_num: null,
    address_city: 'Washington',
    address_state: 'DC',
    address_zipcode: '20510',
    address_type: 'Building',
    website: 'http://www.gillibrand.senate.gov',
    contact_page: 'https://www.gillibrand.senate.gov/contact/',
    facebook_url: 'https://facebook.com/KirstenGillibrand',
    twitter_handle: 'SenGillibrand',
    twitter_url: 'https://twitter.com/SenGillibrand',
    photo_url:
      'https://cdn.civil.services/us-senate/headshots/512x512/kirsten-gillibrand.jpg',
  },
  {
    state_name: 'Ohio',
    state_name_slug: 'ohio',
    state_code: 'OH',
    state_code_slug: 'oh',
    class: 'III',
    bioguide: 'P000449',
    thomas: '924',
    opensecrets: 'N00003682',
    votesmart: '27008',
    fec: null,
    maplight: null,
    wikidata: null,
    google_entity_id: null,
    title: 'senator',
    party: 'republican',
    name: 'Rob Portman',
    name_slug: 'rob-portman',
    first_name: 'Rob',
    middle_name: null,
    last_name: 'Portman',
    name_suffix: null,
    goes_by: null,
    pronunciation: 'RAHB PORT-min',
    gender: 'male',
    ethnicity: 'white-american',
    religion: 'methodist',
    openly_lgbtq: 'no',
    date_of_birth: '1955-12-19',
    entered_office: '2011-01-03',
    term_end: '2023-01-03',
    biography:
      'Robert Portman, a Senator and a Representative from Ohio; born in Cincinnati, Hamilton County, Ohio, December 19, 1955; graduated from the Cincinnati Country Day School, Cincinnati, Ohio; graduated Dartmouth College, Hanover, N.H., B.A., 1979; graduated University of Michigan School of Law, Ann Arbor, Mich., J.D., 1984; associate counsel to President George H. W. Bush 1989; deputy assistant and director, Office of Legislative Affairs, White House Office 1989-1991; elected as a Republican to the One Hundred Third Congress, by special election, to fill the vacancy caused by the resignation of United States Representative Willis D. Gradison, Jr.; reelected to the six succeeding Congresses until his resignation on April 29, 2005 (May 4, 1993-April 29, 2005); U.S. Trade Representative, in the cabinet of President of George W. Bush 2005-2006; Director, Office of Management and Budget, in the cabinet of President George W. Bush 2006-2007; elected as a Republican to the United States Senate in 2010 for the term ending January 3, 2017.',
    phone: '202-224-3353',
    fax: null,
    latitude: '38.892542',
    longitude: '-77.007415',
    address_complete:
      '448 Russell Senate Office Building, Washington, DC 20510',
    address_number: null,
    address_prefix: null,
    address_street: '448 Russell Senate Office Building',
    address_sec_unit_type: null,
    address_sec_unit_num: null,
    address_city: 'Washington',
    address_state: 'DC',
    address_zipcode: '20510',
    address_type: 'Building',
    website: 'http://www.portman.senate.gov',
    contact_page:
      'https://www.portman.senate.gov/public/index.cfm/contact?p=contact...',
    facebook_url: 'https://facebook.com/senrobportman',
    twitter_handle: 'robportman',
    twitter_url: 'https://twitter.com/robportman',
    photo_url:
      'https://cdn.civil.services/us-senate/headshots/512x512/rob-portman.jpg',
  },
  {
    state_name: 'Ohio',
    state_name_slug: 'ohio',
    state_code: 'OH',
    state_code_slug: 'oh',
    class: 'I',
    bioguide: 'B000944',
    thomas: '136',
    opensecrets: 'N00003535',
    votesmart: '27018',
    fec: null,
    maplight: null,
    wikidata: null,
    google_entity_id: null,
    title: 'senator',
    party: 'democrat',
    name: 'Sherrod Brown',
    name_slug: 'sherrod-brown',
    first_name: 'Sherrod',
    middle_name: null,
    last_name: 'Brown',
    name_suffix: null,
    goes_by: null,
    pronunciation: 'SHAIR-uhd BROWN',
    gender: 'male',
    ethnicity: 'white-american',
    religion: 'lutheran',
    openly_lgbtq: 'no',
    date_of_birth: '1952-11-09',
    entered_office: '2007-01-03',
    term_end: '2019-01-03',
    biography:
      'Sherrod Brown, a Senator and a Representative from Ohio; born in Mansfield, Richland County, Ohio, November 9, 1952; B.A., Yale University, New Haven, Conn., 1974; M.A., Ohio State University, Ohio, 1981; member of the faculty, Ohio State University, Mansfield, Ohio, 1979-1981; member of the Ohio State house of representatives, 1975-1982; Ohio secretary of state, 1983-1991; elected as a Democrat to the One Hundred Third and to the six succeeding Congresses (January 3, 1993-January 3, 2007); not a candidate for reelection to the House of Representatives but was elected as a Democrat to the United States Senate in 2006; reelected in 2012 for the term ending January 3, 2019.',
    phone: '202-224-2315',
    fax: '202-228-6321',
    latitude: '38.892839',
    longitude: '-77.004356',
    address_complete: '713 Hart Senate Office Building, Washington, DC 20510',
    address_number: null,
    address_prefix: null,
    address_street: '713 Hart Senate Office Building',
    address_sec_unit_type: null,
    address_sec_unit_num: null,
    address_city: 'Washington',
    address_state: 'DC',
    address_zipcode: '20510',
    address_type: 'Building',
    website: 'http://www.brown.senate.gov',
    contact_page: 'https://www.brown.senate.gov/contact/',
    facebook_url: 'https://facebook.com/sherrod',
    twitter_handle: 'SenSherrodBrown',
    twitter_url: 'https://twitter.com/SenSherrodBrown',
    photo_url:
      'https://cdn.civil.services/us-senate/headshots/512x512/sherrod-brown.jpg',
  },
  {
    state_name: 'Oklahoma',
    state_name_slug: 'oklahoma',
    state_code: 'OK',
    state_code_slug: 'ok',
    class: 'II',
    bioguide: 'I000024',
    thomas: '583',
    opensecrets: 'N00005582',
    votesmart: '27027',
    fec: null,
    maplight: null,
    wikidata: null,
    google_entity_id: null,
    title: 'senator',
    party: 'republican',
    name: 'James Inhofe',
    name_slug: 'james-inhofe',
    first_name: 'James',
    middle_name: 'M.',
    last_name: 'Inhofe',
    name_suffix: null,
    goes_by: 'Jim',
    pronunciation: 'JIM IN-hawf',
    gender: 'male',
    ethnicity: 'white-american',
    religion: 'southern-baptist',
    openly_lgbtq: 'no',
    date_of_birth: '1934-11-17',
    entered_office: '1994-11-17',
    term_end: '2027-01-03',
    biography:
      'James Inhofe, a Senator and a Representative from Oklahoma; born in Des Moines, Iowa, November 17, 1934; attended public schools in Tulsa, Okla.; B.A., University of Tulsa 1973; served in the U.S. Army 1957-1958; president, Quaker Life Insurance Company; member, Oklahoma State house of representatives 1967-1969; State Senate 1969-1977; unsuccessful candidate for Governor of Oklahoma 1974, and for the U. S. House of Representatives 1976; mayor of Tulsa 1978-1984; elected as a Republican to the One Hundredth and to the three succeeding Congresses and served from January 3, 1987, to November 15, 1994, when he resigned, having been elected to the United States Senate in the November 8, 1994, special election to the unexpired portion of the term ending January 3, 1997, left vacant by the resignation of David L. Boren; took the oath of office on November 17, 1994; elected to a full term in 1996; reelected in 2002, 2008, and again 2014 for the term ending January 3, 2021; chair, Committee on Environment and Public Works (One Hundred Eighth, One Hundred Ninth, and One Hundred Fourteenth Congresses).',
    phone: '202-224-4721',
    fax: '202-228-0380',
    latitude: '38.892542',
    longitude: '-77.007415',
    address_complete:
      '205 Russell Senate Office Building, Washington, DC 20510',
    address_number: null,
    address_prefix: null,
    address_street: '205 Russell Senate Office Building',
    address_sec_unit_type: null,
    address_sec_unit_num: null,
    address_city: 'Washington',
    address_state: 'DC',
    address_zipcode: '20510',
    address_type: 'Building',
    website: 'http://www.inhofe.senate.gov',
    contact_page: 'https://www.inhofe.senate.gov/contact',
    facebook_url: 'https://facebook.com/jiminhofe',
    twitter_handle: 'jiminhofe',
    twitter_url: 'https://twitter.com/jiminhofe',
    photo_url:
      'https://cdn.civil.services/us-senate/headshots/512x512/james-inhofe.jpg',
  },
  {
    state_name: 'Oklahoma',
    state_name_slug: 'oklahoma',
    state_code: 'OK',
    state_code_slug: 'ok',
    class: 'III',
    bioguide: 'L000575',
    thomas: '2050',
    opensecrets: 'N00031129',
    votesmart: '124938',
    fec: null,
    maplight: null,
    wikidata: null,
    google_entity_id: null,
    title: 'senator',
    party: 'republican',
    name: 'James Lankford',
    name_slug: 'james-lankford',
    first_name: 'James',
    middle_name: null,
    last_name: 'Lankford',
    name_suffix: null,
    goes_by: null,
    pronunciation: 'JAYMZ LAYNK-ferd',
    gender: 'male',
    ethnicity: 'white-american',
    religion: 'presbyterian',
    openly_lgbtq: 'no',
    date_of_birth: '1968-03-04',
    entered_office: '2015-01-03',
    term_end: '2023-01-03',
    biography:
      'James Lankford, a Senator and a Representative from Oklahoma; born in Dallas, Dallas County, Tex., March 4, 1968; B.S., University of Texas, Austin, Tex., 1990; M.Div., Southwestern Theological Baptist Seminary, Ft. Worth, Tex., 1994; youth camp director, 1996-2009; elected as a Republican to the One Hundred Twelfth and to the succeeding Congress (January 3, 2011-January 3, 2015); was not a candidate for reelection to the House of Representatives but was elected as a Republican to the U.S. Senate in the November 4, 2014, special election to the unexpired portion of the term ending January 3, 2017, left vacant by the resignation of Thomas Coburn; took the oath of office on January 3, 2015.',
    phone: '202-224-5754',
    fax: '202-228-1015',
    latitude: '38.892839',
    longitude: '-77.004356',
    address_complete: '316 Hart Senate Office Building, Washington, DC 20510',
    address_number: null,
    address_prefix: null,
    address_street: '316 Hart Senate Office Building',
    address_sec_unit_type: null,
    address_sec_unit_num: null,
    address_city: 'Washington',
    address_state: 'DC',
    address_zipcode: '20510',
    address_type: 'Building',
    website: 'http://www.lankford.senate.gov',
    contact_page: 'https://www.lankford.senate.gov/contact/email',
    facebook_url: 'https://facebook.com/SenatorLankford',
    twitter_handle: 'SenatorLankford',
    twitter_url: 'https://twitter.com/SenatorLankford',
    photo_url:
      'https://cdn.civil.services/us-senate/headshots/512x512/james-lankford.jpg',
  },
  {
    state_name: 'Oregon',
    state_name_slug: 'oregon',
    state_code: 'OR',
    state_code_slug: 'or',
    class: 'II',
    bioguide: 'M001176',
    thomas: '1900',
    opensecrets: 'N00029303',
    votesmart: '23644',
    fec: null,
    maplight: null,
    wikidata: null,
    google_entity_id: null,
    title: 'senator',
    party: 'democrat',
    name: 'Jeff Merkley',
    name_slug: 'jeff-merkley',
    first_name: 'Jeff',
    middle_name: null,
    last_name: 'Merkley',
    name_suffix: null,
    goes_by: null,
    pronunciation: 'JEFF MER-klee',
    gender: 'male',
    ethnicity: 'white-american',
    religion: 'lutheran',
    openly_lgbtq: 'no',
    date_of_birth: '1956-10-24',
    entered_office: '2009-01-03',
    term_end: '2027-01-03',
    biography:
      'Jeff Merkley, a Senator from Oregon; born in Myrtle Creek, Ore., October 24, 1956; B.A., Stanford University, 1979; M.P.A., Princeton University, 1982; government analyst; nonprofit executive; Oregon State Representative, 1999-2008; speaker of the Oregon State House of Representative, 2007-2008; elected as a Democrat to the United States Senate in 2008; reelected in 2014 for the term ending January 3, 2021.',
    phone: '202-224-3753',
    fax: '202-228-3997',
    latitude: '38.892839',
    longitude: '-77.004356',
    address_complete: '313 Hart Senate Office Building, Washington, DC 20510',
    address_number: null,
    address_prefix: null,
    address_street: '313 Hart Senate Office Building',
    address_sec_unit_type: null,
    address_sec_unit_num: null,
    address_city: 'Washington',
    address_state: 'DC',
    address_zipcode: '20510',
    address_type: 'Building',
    website: 'http://www.merkley.senate.gov',
    contact_page: 'https://www.merkley.senate.gov/contact/',
    facebook_url: 'https://facebook.com/jeffmerkley',
    twitter_handle: 'SenJeffMerkley',
    twitter_url: 'https://twitter.com/SenJeffMerkley',
    photo_url:
      'https://cdn.civil.services/us-senate/headshots/512x512/jeff-merkley.jpg',
  },
  {
    state_name: 'Oregon',
    state_name_slug: 'oregon',
    state_code: 'OR',
    state_code_slug: 'or',
    class: 'III',
    bioguide: 'W000779',
    thomas: '1247',
    opensecrets: 'N00007724',
    votesmart: '27036',
    fec: null,
    maplight: null,
    wikidata: null,
    google_entity_id: null,
    title: 'senator',
    party: 'democrat',
    name: 'Ron Wyden',
    name_slug: 'ron-wyden',
    first_name: 'Ron',
    middle_name: null,
    last_name: 'Wyden',
    name_suffix: null,
    goes_by: null,
    pronunciation: 'RAHN WAHY-dn',
    gender: 'male',
    ethnicity: 'white-american',
    religion: 'jewish',
    openly_lgbtq: 'no',
    date_of_birth: '1949-05-03',
    entered_office: '1996-02-06',
    term_end: '2023-01-03',
    biography:
      'Ron Wyden, a Senator and a Representative from Oregon; born in Wichita, Sedgwick County, Kans., May 3, 1949; attended the public schools of Palo Alto, Calif.; A.B., Stanford University 1971; J.D., University of Oregon Law School in Eugene 1974; director, Oregon Legal Services for the Elderly 1977-1979; public member, Oregon State Board of Examiners of Nursing Home Administrators 1977-1979; elected as a Democrat to the Ninety-seventh and to the seven succeeding Congresses, and served from January 3, 1981, to February 5, 1996, when he resigned, having been elected to the Senate; elected to the Senate in a special election on January 30, 1996, to fill the unexpired portion of the term ending January 3, 1999, left vacant by the resignation of Robert W. Packwood, and began service on February 5, 1996; reelected in 1998, 2004 and again in 2010 for the term ending January 3, 2017; chair, Committee on Energy and Natural Resources (One Hundred Thirteenth Congress [January 3, 2013-February 12, 2014]), Committee on Finance (One Hundred Thirteenth Congress [February 12, 2014-January 3, 2015]).',
    phone: '202-224-5244',
    fax: '202-228-2717',
    latitude: '38.892947',
    longitude: '-77.075686',
    address_complete:
      '221 Dirksen Senate Office Building, Washington, DC 20510',
    address_number: null,
    address_prefix: null,
    address_street: '221 Dirksen Senate Office Building',
    address_sec_unit_type: null,
    address_sec_unit_num: null,
    address_city: 'Washington',
    address_state: 'DC',
    address_zipcode: '20510',
    address_type: 'Building',
    website: 'http://www.wyden.senate.gov',
    contact_page: 'https://www.wyden.senate.gov/contact/',
    facebook_url: 'https://facebook.com/wyden',
    twitter_handle: 'RonWyden',
    twitter_url: 'https://twitter.com/RonWyden',
    photo_url:
      'https://cdn.civil.services/us-senate/headshots/512x512/ron-wyden.jpg',
  },
  {
    state_name: 'Pennsylvania',
    state_name_slug: 'pennsylvania',
    state_code: 'PA',
    state_code_slug: 'pa',
    class: 'III',
    bioguide: 'T000461',
    thomas: '2085',
    opensecrets: 'N00001489',
    votesmart: '24096',
    fec: null,
    maplight: null,
    wikidata: null,
    google_entity_id: null,
    title: 'senator',
    party: 'republican',
    name: 'Patrick Toomey',
    name_slug: 'patrick-toomey',
    first_name: 'Patrick',
    middle_name: 'J.',
    last_name: 'Toomey',
    name_suffix: null,
    goes_by: 'Pat',
    pronunciation: 'PATT TOO-mee',
    gender: 'male',
    ethnicity: 'white-american',
    religion: 'roman-catholic',
    openly_lgbtq: 'no',
    date_of_birth: '1961-11-17',
    entered_office: '2011-01-03',
    term_end: '2023-01-03',
    biography:
      'Patrick Toomey, a Senator and a Representative from Pennsylvania; born in Providence, Providence County, R.I., November 17, 1961; graduated from LaSalle Academy, Providence, R.I.; graduated Harvard University, Cambridge, Mass., B.A., 1984; elected to Allentown, Pa., Government Study Commission 1994; elected as a Republican to the One Hundred Sixth and to the two succeeding Congresses (January 3, 1999-January 3, 2005); was not a candidate for reelection in 2004, but was an unsuccessful candidate for nomination to the United States Senate; elected as a Republican to the United States Senate in 2010 for the term ending January 3, 2017.',
    phone: '202-224-4254',
    fax: '202-228-0284',
    latitude: '38.892542',
    longitude: '-77.007415',
    address_complete:
      '248 Russell Senate Office Building, Washington, DC 20510',
    address_number: null,
    address_prefix: null,
    address_street: '248 Russell Senate Office Building',
    address_sec_unit_type: null,
    address_sec_unit_num: null,
    address_city: 'Washington',
    address_state: 'DC',
    address_zipcode: '20510',
    address_type: 'Building',
    website: 'http://www.toomey.senate.gov',
    contact_page: 'https://www.toomey.senate.gov/?p=contact',
    facebook_url: 'https://facebook.com/senatortoomey',
    twitter_handle: 'SenToomey',
    twitter_url: 'https://twitter.com/SenToomey',
    photo_url:
      'https://cdn.civil.services/us-senate/headshots/512x512/patrick-toomey.jpg',
  },
  {
    state_name: 'Pennsylvania',
    state_name_slug: 'pennsylvania',
    state_code: 'PA',
    state_code_slug: 'pa',
    class: 'I',
    bioguide: 'C001070',
    thomas: '1828',
    opensecrets: 'N00027503',
    votesmart: '2541',
    fec: null,
    maplight: null,
    wikidata: null,
    google_entity_id: null,
    title: 'senator',
    party: 'democrat',
    name: 'Robert Casey',
    name_slug: 'robert-casey',
    first_name: 'Robert',
    middle_name: 'P.',
    last_name: 'Casey',
    name_suffix: 'Jr.',
    goes_by: 'Bob',
    pronunciation: 'BAHB KAY-see',
    gender: 'male',
    ethnicity: 'white-american',
    religion: 'roman-catholic',
    openly_lgbtq: 'no',
    date_of_birth: '1960-04-13',
    entered_office: '2007-01-03',
    term_end: '2019-01-03',
    biography:
      'Robert Casey, a Senator from Pennsylvania; born in Scranton, Penn., April 13, 1960; graduated College of the Holy Cross 1982; J.D., Catholic University of America 1988; practiced law in Scranton; Pennsylvania auditor general 1997-2005; unsuccessful candidate for Governor 2002; Pennsylvania State Treasurer 2005-2006; elected as a Democrat to the United States Senate in 2006 for the term commencing January 3, 2007; reelected in 2012 for the term ending January 3, 2019; chair, Joint Economic Committee (One Hundred Twelfth Congress); vice chair, Joint Economic Committee (One Hundred Thirteenth Congress).',
    phone: '202-224-6324',
    fax: '202-228-0604',
    latitude: '38.892542',
    longitude: '-77.007415',
    address_complete:
      '393 Russell Senate Office Building, Washington, DC 20510',
    address_number: null,
    address_prefix: null,
    address_street: '393 Russell Senate Office Building',
    address_sec_unit_type: null,
    address_sec_unit_num: null,
    address_city: 'Washington',
    address_state: 'DC',
    address_zipcode: '20510',
    address_type: 'Building',
    website: 'http://www.casey.senate.gov',
    contact_page: 'https://www.casey.senate.gov/contact/',
    facebook_url: 'https://facebook.com/SenatorBobCasey',
    twitter_handle: 'SenBobCasey',
    twitter_url: 'https://twitter.com/SenBobCasey',
    photo_url:
      'https://cdn.civil.services/us-senate/headshots/512x512/robert-casey.jpg',
  },
  {
    state_name: 'Rhode Island',
    state_name_slug: 'rhode-island',
    state_code: 'RI',
    state_code_slug: 'ri',
    class: 'II',
    bioguide: 'R000122',
    thomas: '949',
    opensecrets: 'N00000362',
    votesmart: '27060',
    fec: null,
    maplight: null,
    wikidata: null,
    google_entity_id: null,
    title: 'senator',
    party: 'democrat',
    name: 'Jack Reed',
    name_slug: 'jack-reed',
    first_name: 'Jack',
    middle_name: null,
    last_name: 'Reed',
    name_suffix: null,
    goes_by: null,
    pronunciation: 'JACK REED',
    gender: 'male',
    ethnicity: 'white-american',
    religion: 'roman-catholic',
    openly_lgbtq: 'no',
    date_of_birth: '1949-11-12',
    entered_office: '1997-01-03',
    term_end: '2027-01-03',
    biography:
      "Jack Reed, a Senator and a Representative from Rhode Island; born in Providence, R.I., November 12, 1949; attended St. Matthew's Elementary School, Cranston, R.I.; graduated, LaSalle Academy, Providence, R.I., 1967; B.S., United States Military Academy, West Point 1971; M.P.P., Kennedy School of Government, Harvard University 1973; J.D., Harvard Law School 1982; served in the U.S. Army 1971-1979 and Army Reserve 1979-1991; associate professor, Department of Social Sciences, U.S. Military Academy 1977-1979; admitted to the Washington, D.C. bar 1983; lawyer; member, Rhode Island State Senate 1985-1990; elected as a Democrat to the One Hundred Second and to the two succeeding Congresses (January 3, 1991-January 2, 1997); was not a candidate for reelection to the House of Representatives in 1996, but was elected to the United States Senate in 1996; reelected in 2002, 2008, and again in 2014 for the term ending January 3, 2021.",
    phone: '202-224-4642',
    fax: '202-224-4680',
    latitude: '38.892839',
    longitude: '-77.004356',
    address_complete: '728 Hart Senate Office Building, Washington, DC 20510',
    address_number: null,
    address_prefix: null,
    address_street: '728 Hart Senate Office Building',
    address_sec_unit_type: null,
    address_sec_unit_num: null,
    address_city: 'Washington',
    address_state: 'DC',
    address_zipcode: '20510',
    address_type: 'Building',
    website: 'http://www.reed.senate.gov',
    contact_page: 'https://www.reed.senate.gov/contact/',
    facebook_url: 'https://facebook.com/SenJackReed',
    twitter_handle: 'SenJackReed',
    twitter_url: 'https://twitter.com/SenJackReed',
    photo_url:
      'https://cdn.civil.services/us-senate/headshots/512x512/jack-reed.jpg',
  },
  {
    state_name: 'Rhode Island',
    state_name_slug: 'rhode-island',
    state_code: 'RI',
    state_code_slug: 'ri',
    class: 'I',
    bioguide: 'W000802',
    thomas: '1823',
    opensecrets: 'N00027533',
    votesmart: '2572',
    fec: null,
    maplight: null,
    wikidata: null,
    google_entity_id: null,
    title: 'senator',
    party: 'democrat',
    name: 'Sheldon Whitehouse',
    name_slug: 'sheldon-whitehouse',
    first_name: 'Sheldon',
    middle_name: null,
    last_name: 'Whitehouse',
    name_suffix: null,
    goes_by: null,
    pronunciation: 'SHELL-duhn WHITE-house',
    gender: 'male',
    ethnicity: 'white-american',
    religion: 'episcopalian',
    openly_lgbtq: 'no',
    date_of_birth: '1955-10-20',
    entered_office: '2007-01-03',
    term_end: '2019-01-03',
    biography:
      'Sheldon Whitehouse, a Senator from Rhode Island; born in Manhattan, N.Y., October 20, 1955; graduated Yale University 1978; J.D., University of Virginia 1982; director, Rhode Island Department of Business Regulation 1992-1994; United States attorney 1994-1998; Rhode Island State attorney general 1999-2003; unsuccessful candidate for Governor 2002; elected as a Democrat to the United States Senate in 2006 for the term commencing January 3, 2007; reelected in 2012 for the term ending January 3, 2019.',
    phone: '202-224-2921',
    fax: '202-228-6362',
    latitude: '38.892839',
    longitude: '-77.004356',
    address_complete: '530 Hart Senate Office Building, Washington, DC 20510',
    address_number: null,
    address_prefix: null,
    address_street: '530 Hart Senate Office Building',
    address_sec_unit_type: null,
    address_sec_unit_num: null,
    address_city: 'Washington',
    address_state: 'DC',
    address_zipcode: '20510',
    address_type: 'Building',
    website: 'http://www.whitehouse.senate.gov',
    contact_page: 'https://www.whitehouse.senate.gov/contact/email-sheldon',
    facebook_url: 'https://facebook.com/SenatorWhitehouse',
    twitter_handle: 'SenWhitehouse',
    twitter_url: 'https://twitter.com/SenWhitehouse',
    photo_url:
      'https://cdn.civil.services/us-senate/headshots/512x512/sheldon-whitehouse.jpg',
  },
  {
    state_name: 'South Carolina',
    state_name_slug: 'south-carolina',
    state_code: 'SC',
    state_code_slug: 'sc',
    class: 'II',
    bioguide: 'G000359',
    thomas: '452',
    opensecrets: 'N00009975',
    votesmart: '21992',
    fec: null,
    maplight: null,
    wikidata: null,
    google_entity_id: null,
    title: 'senator',
    party: 'republican',
    name: 'Lindsey Graham',
    name_slug: 'lindsey-graham',
    first_name: 'Lindsey',
    middle_name: null,
    last_name: 'Graham',
    name_suffix: null,
    goes_by: null,
    pronunciation: 'LIN-zee GRAM',
    gender: 'male',
    ethnicity: 'white-american',
    religion: 'southern-baptist',
    openly_lgbtq: 'no',
    date_of_birth: '1955-07-09',
    entered_office: '2003-01-03',
    term_end: '2027-01-03',
    biography:
      'Lindsey Graham, a Senator and a Representative from South Carolina; born in Central, Pickens County, S.C., July 9, 1955; graduated from Daniel High School, Central, S.C. 1973; B.A., University of South Carolina, Columbia 1977; J.D., University of South Carolina School of Law 1981; United States Air Force 1982-1988; South Carolina Air National Guard 1989-1995; United States Air Force Reserves 1995-present; lawyer, private practice; assistant county attorney, Oconee County, S.C. 1988-1992; city attorney for Central, S.C. 1990-1994; Member of the South Carolina state house of representatives 1992-1994; elected as a Republican to the House of Representatives for the One Hundred Fourth and to the three succeeding Congresses (January 3, 1995-January 3, 2003); one of the managers appointed by the House of Representatives in 1998 to conduct the impeachment proceedings against President William Clinton; was not a candidate for reelection in 2002, but was elected as a Republican to the U.S. Senate in 2002; reelected in 2008, and again in 2014 for the term ending January 3, 2021.',
    phone: '202-224-5972',
    fax: '202-224-3808',
    latitude: '38.892542',
    longitude: '-77.007415',
    address_complete:
      '290 Russell Senate Office Building, Washington, DC 20510',
    address_number: null,
    address_prefix: null,
    address_street: '290 Russell Senate Office Building',
    address_sec_unit_type: null,
    address_sec_unit_num: null,
    address_city: 'Washington',
    address_state: 'DC',
    address_zipcode: '20510',
    address_type: 'Building',
    website: 'http://www.lgraham.senate.gov',
    contact_page:
      'https://www.lgraham.senate.gov/public/index.cfm/e-mail-senator-gr...',
    facebook_url: 'https://facebook.com/USSenatorLindseyGraham',
    twitter_handle: 'LindseyGrahamSC',
    twitter_url: 'https://twitter.com/LindseyGrahamSC',
    photo_url:
      'https://cdn.civil.services/us-senate/headshots/512x512/lindsey-graham.jpg',
  },
  {
    state_name: 'South Carolina',
    state_name_slug: 'south-carolina',
    state_code: 'SC',
    state_code_slug: 'sc',
    class: 'III',
    bioguide: 'S001184',
    thomas: '2056',
    opensecrets: 'N00031782',
    votesmart: '11940',
    fec: null,
    maplight: null,
    wikidata: null,
    google_entity_id: null,
    title: 'senator',
    party: 'republican',
    name: 'Tim Scott',
    name_slug: 'tim-scott',
    first_name: 'Tim',
    middle_name: null,
    last_name: 'Scott',
    name_suffix: null,
    goes_by: null,
    pronunciation: 'TIMM SKAHT',
    gender: 'male',
    ethnicity: 'african-american',
    religion: 'evangelical',
    openly_lgbtq: 'no',
    date_of_birth: '1965-09-19',
    entered_office: '2013-01-02',
    term_end: '2023-01-03',
    biography:
      'Tim Scott, a Senator and a Representative from South Carolina; born in North Charleston, Charleston County, S.C., September 19, 1965; attended Presbyterian College, Clinton, S.C., 1983-1984; B.S., Charleston Southern University, Charleston, S.C., 1988; entrepreneur; Charleston County, S.C. council, 1995-2008; unsuccessful candidate for the South Carolina state senate in 1996; Member of the South Carolina house of representatives, 2009-2010; elected as a Republican to the One Hundred Twelfth Congress, and served from January 3, 2011, to January 2, 2013, when he resigned to become a U.S. Senator; appointed January 2, 2013, to fill the vacancy caused by the resignation of James DeMint; appointment took effect upon his resignation from the House of Representatives on January 2, 2013; took the oath of office on January 3, 2013; elected in 2014 in a special election for the term ending January 3, 2017.',
    phone: '202-224-6121',
    fax: '202-228-5143',
    latitude: '38.892839',
    longitude: '-77.004356',
    address_complete: '520 Hart Senate Office Building, Washington, DC 20510',
    address_number: null,
    address_prefix: null,
    address_street: '520 Hart Senate Office Building',
    address_sec_unit_type: null,
    address_sec_unit_num: null,
    address_city: 'Washington',
    address_state: 'DC',
    address_zipcode: '20510',
    address_type: 'Building',
    website: 'http://www.scott.senate.gov',
    contact_page: 'https://www.scott.senate.gov/contact/email-me',
    facebook_url: 'https://facebook.com/SenatorTimScott',
    twitter_handle: 'SenatorTimScott',
    twitter_url: 'https://twitter.com/SenatorTimScott',
    photo_url:
      'https://cdn.civil.services/us-senate/headshots/512x512/tim-scott.jpg',
  },
  {
    state_name: 'South Dakota',
    state_name_slug: 'south-dakota',
    state_code: 'SD',
    state_code_slug: 'sd',
    class: 'III',
    bioguide: 'T000250',
    thomas: '1534',
    opensecrets: 'N00004572',
    votesmart: '398',
    fec: null,
    maplight: null,
    wikidata: null,
    google_entity_id: null,
    title: 'senate-majority-whip',
    party: 'republican',
    name: 'John Thune',
    name_slug: 'john-thune',
    first_name: 'John',
    middle_name: null,
    last_name: 'Thune',
    name_suffix: null,
    goes_by: null,
    pronunciation: 'JAHN THOON',
    gender: 'male',
    ethnicity: 'white-american',
    religion: 'evangelical',
    openly_lgbtq: 'no',
    date_of_birth: '1961-01-07',
    entered_office: '2005-01-03',
    term_end: '2023-01-03',
    biography:
      'John Thune, a Senator and a Representative from South Dakota; born in Pierre, Hughes County, S. Dak., January 7, 1961; graduated from Jones County High School, S. Dak., 1979; B.S., Biola University, La Mirada, Calif., 1983; M.B.A., University of South Dakota, Vermillion, S. Dak., 1984; executive director, South Dakota state municipal league; board of directors, National League of Cities; staff, United States Senator James Abdnor of South Dakota 1985-1986; South Dakota state Republican party executive director 1989-1991; director, South Dakota state railroad division 1991-1993; director, South Dakota state municipal league 1993-1996; elected as a Republican to the One Hundred Fifth and to the two succeeding Congresses (January 3, 1997-January 3, 2003); was not a candidate for reelection to the One Hundred Eighth Congress in 2002, but was an unsuccessful candidate for the United States Senate; elected as a Republican to the U.S. Senate in 2004; reelected in 2010 for the term ending January 3, 2017; vice-chair, Republican Conference (2009); chair, Republican Policy Committee (2009-2012); chair, Republican Conference (2012-); chair, Committee on Commerce, Science, and Transportation (One Hundred Fourteenth Congress).',
    phone: '202-224-2321',
    fax: '202-228-5429',
    latitude: '38.892947',
    longitude: '-77.075686',
    address_complete:
      '511 Dirksen Senate Office Building, Washington, DC 20510',
    address_number: null,
    address_prefix: null,
    address_street: '511 Dirksen Senate Office Building',
    address_sec_unit_type: null,
    address_sec_unit_num: null,
    address_city: 'Washington',
    address_state: 'DC',
    address_zipcode: '20510',
    address_type: 'Building',
    website: 'http://www.thune.senate.gov',
    contact_page: 'https://www.thune.senate.gov/public/index.cfm/contact',
    facebook_url: 'https://facebook.com/johnthune',
    twitter_handle: 'SenJohnThune',
    twitter_url: 'https://twitter.com/SenJohnThune',
    photo_url:
      'https://cdn.civil.services/us-senate/headshots/512x512/john-thune.jpg',
  },
  {
    state_name: 'South Dakota',
    state_name_slug: 'south-dakota',
    state_code: 'SD',
    state_code_slug: 'sd',
    class: 'II',
    bioguide: 'R000605',
    thomas: '2288',
    opensecrets: 'N00035187',
    votesmart: '7455',
    fec: null,
    maplight: null,
    wikidata: null,
    google_entity_id: null,
    title: 'senator',
    party: 'republican',
    name: 'Mike Rounds',
    name_slug: 'mike-rounds',
    first_name: 'Mike',
    middle_name: null,
    last_name: 'Rounds',
    name_suffix: null,
    goes_by: null,
    pronunciation: 'MIKE ROWNDZ',
    gender: 'male',
    ethnicity: 'white-american',
    religion: 'roman-catholic',
    openly_lgbtq: 'no',
    date_of_birth: '1954-10-24',
    entered_office: '2015-01-03',
    term_end: '2027-01-03',
    biography:
      'Mike Rounds, a Senator from South Dakota; born in Huron, Beadle County, S. Dak., October 24, 1954; B.S., South Dakota State University, 1977; insurance and real estate executive; member of the South Dakota state Senate 1991-2000, serving as majority leader 1995-2000; governor of South Dakota 2003-2011; elected as a Republican to the United States Senate in 2014 for the term ending January 3, 2021.',
    phone: '202-224-5842',
    fax: null,
    latitude: '38.892839',
    longitude: '-77.004356',
    address_complete: '502 Hart Senate Office Building, Washington, DC 20510',
    address_number: null,
    address_prefix: null,
    address_street: '502 Hart Senate Office Building',
    address_sec_unit_type: null,
    address_sec_unit_num: null,
    address_city: 'Washington',
    address_state: 'DC',
    address_zipcode: '20510',
    address_type: 'Building',
    website: 'http://www.rounds.senate.gov',
    contact_page: 'https://www.rounds.senate.gov/contact/email-mike',
    facebook_url: 'https://facebook.com/SenatorMikeRounds',
    twitter_handle: 'SenatorRounds',
    twitter_url: 'https://twitter.com/SenatorRounds',
    photo_url:
      'https://cdn.civil.services/us-senate/headshots/512x512/mike-rounds.jpg',
  },
  {
    state_name: 'Tennessee',
    state_name_slug: 'tennessee',
    state_code: 'TN',
    state_code_slug: 'tn',
    class: 'I',
    bioguide: 'B001243',
    thomas: null,
    opensecrets: 'N00003105',
    votesmart: '25186',
    fec: 'S8TN00337',
    maplight: null,
    wikidata: 'Q458971',
    google_entity_id: null,
    title: 'senator',
    party: 'republican',
    name: 'Marsha Blackburn',
    name_slug: 'marsha-blackburn',
    first_name: 'Marsha',
    middle_name: null,
    last_name: 'Blackburn',
    name_suffix: null,
    goes_by: null,
    pronunciation: 'MARR-shuh BLAK-bern',
    gender: 'female',
    ethnicity: 'white-american',
    religion: 'unspecified',
    openly_lgbtq: 'no',
    date_of_birth: '1952-06-06',
    entered_office: '2019-01-03',
    term_end: '2025-01-03',
    biography:
      'Marsha Blackburn is a Republican member of the U.S. Senate from Tennessee. Blackburn was elected to the office on November 6, 2018. Blackburn is a member of the U.S. House of Representatives from the state of Tennessee, representing the 7th Congressional District. Blackburn was first elected in 2002.',
    phone: '202-224-3344',
    fax: null,
    latitude: '38.892947',
    longitude: '-77.075686',
    address_complete: 'B40B Dirksen Senate Office Building Washington DC 20510',
    address_number: null,
    address_prefix: null,
    address_street: 'B40B Dirksen Senate Office Building Washington DC 20510',
    address_sec_unit_type: null,
    address_sec_unit_num: null,
    address_city: 'Washington',
    address_state: 'DC',
    address_zipcode: '20510',
    address_type: 'Building',
    website: 'https://www.blackburn.senate.gov/',
    contact_page: 'https://www.blackburn.senate.gov/',
    facebook_url: 'https://facebook.com/marshablackburn',
    twitter_handle: 'MarshaBlackburn',
    twitter_url: 'https://twitter.com/MarshaBlackburn',
    photo_url:
      'https://cdn.civil.services/us-senate/headshots/512x512/marsha-blackburn.jpg',
  },
  {
    state_name: 'Tennessee',
    state_name_slug: 'tennessee',
    state_code: 'TN',
    state_code_slug: 'tn',
    class: 'II',
    bioguide: 'H000601',
    thomas: null,
    opensecrets: null,
    votesmart: '128466',
    fec: 'S0TN00169',
    maplight: null,
    wikidata: null,
    google_entity_id: null,
    title: 'senator',
    party: 'republican',
    name: 'Bill Hagerty',
    name_slug: 'bill-hagerty',
    first_name: 'Bill',
    middle_name: 'Francis',
    last_name: 'Hagerty',
    name_suffix: 'IV',
    goes_by: null,
    pronunciation: 'bille ha-ger-ty',
    gender: 'male',
    ethnicity: 'white-american',
    religion: 'unspecified',
    openly_lgbtq: 'no',
    date_of_birth: '1959-08-14',
    entered_office: '2021-01-03',
    term_end: '2027-01-03',
    biography:
      'Bill is a life-long businessman. He started his business career with the Boston Consulting Group, where his work took him to five continents, including three years based in Tokyo, Japan. He later became a venture capital and private equity investor where he invested in and served as an executive and board member of a wide range of companies.',
    phone: '202-224-4944',
    fax: null,
    latitude: '38.892947',
    longitude: '-77.075686',
    address_complete: 'B11 Russell Senate Office Building Washington DC 20510',
    address_number: null,
    address_prefix: null,
    address_street: 'B11 Russell Senate Office Building Washington DC 20510',
    address_sec_unit_type: null,
    address_sec_unit_num: null,
    address_city: 'Washington',
    address_state: 'DC',
    address_zipcode: '20510',
    address_type: 'Building',
    website: 'https://www.hagerty.senate.gov/',
    contact_page: 'https://www.hagerty.senate.gov/',
    facebook_url: null,
    twitter_handle: null,
    twitter_url: null,
    photo_url:
      'https://cdn.civil.services/us-senate/headshots/512x512/bill-hagerty.jpg',
  },
  {
    state_name: 'Texas',
    state_name_slug: 'texas',
    state_code: 'TX',
    state_code_slug: 'tx',
    class: 'II',
    bioguide: 'C001056',
    thomas: '1692',
    opensecrets: 'N00024852',
    votesmart: '15375',
    fec: null,
    maplight: null,
    wikidata: null,
    google_entity_id: null,
    title: 'senator',
    party: 'republican',
    name: 'John Cornyn',
    name_slug: 'john-cornyn',
    first_name: 'John',
    middle_name: null,
    last_name: 'Cornyn',
    name_suffix: null,
    goes_by: null,
    pronunciation: 'JAHN KOR-nuhn',
    gender: 'male',
    ethnicity: 'white-american',
    religion: 'church-of-christ',
    openly_lgbtq: 'no',
    date_of_birth: '1952-02-02',
    entered_office: '2002-12-01',
    term_end: '2027-01-03',
    biography:
      "John Cornyn, a Senator from Texas; born in Houston, Texas, on February 2, 1952; B.A., Trinity University 1973; J.D., St. Mary's School of Law 1977; LLM, University of Virginia 1995; attorney; Bexar County district court judge 1984-1990; Texas Supreme Court 1990-1997; Texas state attorney general 1999-2002; elected as a Republican to the U.S. Senate in 2002; subsequently appointed to the remainder of the term left vacant by the resignation of William Philip Gramm, and took the oath of office on December 2, 2002; reelected in 2008, and again in 2014 for the term ending January 3, 2021; Republican Conference vice-chair (2007-2009); chair, National Republican Senatorial Committee (2009-2012); Republican party whip (2013-).",
    phone: '202-224-2934',
    fax: '713- 572-3777',
    latitude: '38.892839',
    longitude: '-77.004356',
    address_complete: '517 Hart Senate Office Building, Washington, DC 20510',
    address_number: null,
    address_prefix: null,
    address_street: '517 Hart Senate Office Building',
    address_sec_unit_type: null,
    address_sec_unit_num: null,
    address_city: 'Washington',
    address_state: 'DC',
    address_zipcode: '20510',
    address_type: 'Building',
    website: 'http://www.cornyn.senate.gov',
    contact_page: 'https://www.cornyn.senate.gov/contact',
    facebook_url: 'https://facebook.com/Sen.JohnCornyn',
    twitter_handle: 'JohnCornyn',
    twitter_url: 'https://twitter.com/JohnCornyn',
    photo_url:
      'https://cdn.civil.services/us-senate/headshots/512x512/john-cornyn.jpg',
  },
  {
    state_name: 'Texas',
    state_name_slug: 'texas',
    state_code: 'TX',
    state_code_slug: 'tx',
    class: 'I',
    bioguide: 'C001098',
    thomas: '2175',
    opensecrets: 'N00033085',
    votesmart: '135705',
    fec: null,
    maplight: null,
    wikidata: null,
    google_entity_id: null,
    title: 'senator',
    party: 'republican',
    name: 'Ted Cruz',
    name_slug: 'ted-cruz',
    first_name: 'Ted',
    middle_name: null,
    last_name: 'Cruz',
    name_suffix: null,
    goes_by: null,
    pronunciation: 'TED KROOZ',
    gender: 'male',
    ethnicity: 'hispanic-american',
    religion: 'southern-baptist',
    openly_lgbtq: 'no',
    date_of_birth: '1970-12-22',
    entered_office: '2013-01-03',
    term_end: '2019-01-03',
    biography:
      'Ted Cruz, a Senator from Texas; born in Calgary, Alberta, Canada, December 22, 1970; graduated Princeton University, B.A., 1992; graduated Harvard University, J.D., 1995; law clerk to Chief Justice of the United States William Rehnquist; associate deputy attorney general, U.S. Department of Justice; Director of the Office of Policy Planning, Federal Trade Commission; solicitor general of Texas 2003-2008; lawyer; elected as a Republican to the United States Senate in 2012 for the term ending January 3, 2019.',
    phone: '202-224-5922',
    fax: null,
    latitude: '38.892542',
    longitude: '-77.007415',
    address_complete:
      '404 Russell Senate Office Building, Washington, DC 20510',
    address_number: null,
    address_prefix: null,
    address_street: '404 Russell Senate Office Building',
    address_sec_unit_type: null,
    address_sec_unit_num: null,
    address_city: 'Washington',
    address_state: 'DC',
    address_zipcode: '20510',
    address_type: 'Building',
    website: 'http://www.cruz.senate.gov',
    contact_page: 'https://www.cruz.senate.gov/?p=email_senator',
    facebook_url: 'https://facebook.com/SenatorTedCruz',
    twitter_handle: 'SenTedCruz',
    twitter_url: 'https://twitter.com/SenTedCruz',
    photo_url:
      'https://cdn.civil.services/us-senate/headshots/512x512/ted-cruz.jpg',
  },
  {
    state_name: 'Utah',
    state_name_slug: 'utah',
    state_code: 'UT',
    state_code_slug: 'ut',
    class: 'III',
    bioguide: 'L000577',
    thomas: '2080',
    opensecrets: 'N00031696',
    votesmart: '66395',
    fec: null,
    maplight: null,
    wikidata: null,
    google_entity_id: null,
    title: 'senator',
    party: 'republican',
    name: 'Mike Lee',
    name_slug: 'mike-lee',
    first_name: 'Mike',
    middle_name: null,
    last_name: 'Lee',
    name_suffix: null,
    goes_by: null,
    pronunciation: 'MIKE LEE',
    gender: 'male',
    ethnicity: 'white-american',
    religion: 'mormon',
    openly_lgbtq: 'no',
    date_of_birth: '1971-06-04',
    entered_office: '2011-01-03',
    term_end: '2023-01-03',
    biography:
      'Mike Lee, a Senator from Utah; born in Mesa, Ariz., June 4, 1971; graduated Brigham Young University, B.A., 1994; graduated Brigham Young University, J.D., 1997; law clerk to 3rd Circuit Court of Appeals Justice Samuel Alito 1998; lawyer; assistant U.S. attorney 2002-2005; counsel to Utah Governor John Huntsman 2005-2006; law clerk to United States Supreme Court Justice Samuel Alito 2006-2007; lawyer; elected as a Republican to the United States Senate in 2010 for the term ending January 3, 2017.',
    phone: '202-224-5444',
    fax: null,
    latitude: '38.892542',
    longitude: '-77.007415',
    address_complete:
      '361A Russell Senate Office Building, Washington, DC 20510',
    address_number: null,
    address_prefix: null,
    address_street: '361A Russell Senate Office Building',
    address_sec_unit_type: null,
    address_sec_unit_num: null,
    address_city: 'Washington',
    address_state: 'DC',
    address_zipcode: '20510',
    address_type: 'Building',
    website: 'http://www.lee.senate.gov',
    contact_page: 'https://www.lee.senate.gov/public/index.cfm/contact',
    facebook_url: 'https://facebook.com/senatormikelee',
    twitter_handle: 'SenMikeLee',
    twitter_url: 'https://twitter.com/SenMikeLee',
    photo_url:
      'https://cdn.civil.services/us-senate/headshots/512x512/mike-lee.jpg',
  },
  {
    state_name: 'Utah',
    state_name_slug: 'utah',
    state_code: 'UT',
    state_code_slug: 'ut',
    class: 'I',
    bioguide: 'R000615',
    thomas: null,
    opensecrets: 'N00000286',
    votesmart: '21942',
    fec: 'S4MA00143',
    maplight: null,
    wikidata: 'Q4496',
    google_entity_id: null,
    title: 'senator',
    party: 'republican',
    name: 'Mitt Romney',
    name_slug: 'mitt-romney',
    first_name: 'Mitt',
    middle_name: null,
    last_name: 'Romney',
    name_suffix: null,
    goes_by: null,
    pronunciation: 'MIT RAWM-nee',
    gender: 'male',
    ethnicity: 'white-american',
    religion: 'mormon',
    openly_lgbtq: 'no',
    date_of_birth: '1947-03-12',
    entered_office: '2019-01-03',
    term_end: '2025-01-03',
    biography:
      'Mitt Romney is a Republican member of the U.S. Senate from Utah. Romney was elected to the office on November 6, 2018. Romney was the 2012 Republican nominee for President of the United States. He served as Governor of Massachusetts from January 2, 2003 to January 4, 2007.',
    phone: '202-224-5251',
    fax: null,
    latitude: '38.892542',
    longitude: '-77.007415',
    address_complete: 'B33 Russell Senate Office Building Washington DC 20510',
    address_number: null,
    address_prefix: null,
    address_street: 'B33 Russell Senate Office Building Washington DC 20510',
    address_sec_unit_type: null,
    address_sec_unit_num: null,
    address_city: 'Washington',
    address_state: 'DC',
    address_zipcode: '20510',
    address_type: 'Building',
    website: 'https://www.romney.senate.gov/',
    contact_page: 'https://www.romney.senate.gov/',
    facebook_url: 'https://facebook.com/senatorromney',
    twitter_handle: 'SenatorRomney',
    twitter_url: 'https://twitter.com/SenatorRomney',
    photo_url:
      'https://cdn.civil.services/us-senate/headshots/512x512/mitt-romney.jpg',
  },
  {
    state_name: 'Virginia',
    state_name_slug: 'virginia',
    state_code: 'VA',
    state_code_slug: 'va',
    class: 'II',
    bioguide: 'W000805',
    thomas: '1897',
    opensecrets: 'N00002097',
    votesmart: '535',
    fec: null,
    maplight: null,
    wikidata: null,
    google_entity_id: null,
    title: 'senator',
    party: 'democrat',
    name: 'Mark Warner',
    name_slug: 'mark-warner',
    first_name: 'Mark',
    middle_name: null,
    last_name: 'Warner',
    name_suffix: null,
    goes_by: null,
    pronunciation: 'MARK WAR-ner',
    gender: 'male',
    ethnicity: 'white-american',
    religion: 'presbyterian',
    openly_lgbtq: 'no',
    date_of_birth: '1954-12-15',
    entered_office: '2009-01-03',
    term_end: '2027-01-03',
    biography:
      'Mark Warner, a Senator from Virginia; born in Indianapolis, Ind., December 15, 1954; B.A., George Washington University, 1977; J.D., Harvard Law School, 1980; worked on the staff of Senator Christopher Dodd; engaged in the high-technology business; co-founded the company that became Nextel; Virginia Democratic Party Chairman, 1993-1995; unsuccessful candidate for election to the United States Senate in 1996; Governor of Virginia, 2002-2006; chairman of the National Governors Association 2004-2005; elected as a Democrat to the United States Senate in 2008; reelected in 2014 for the term ending January 3, 2021.',
    phone: '202-224-2023',
    fax: null,
    latitude: '38.892542',
    longitude: '-77.007415',
    address_complete:
      '475 Russell Senate Office Building, Washington, DC 20510',
    address_number: null,
    address_prefix: null,
    address_street: '475 Russell Senate Office Building',
    address_sec_unit_type: null,
    address_sec_unit_num: null,
    address_city: 'Washington',
    address_state: 'DC',
    address_zipcode: '20510',
    address_type: 'Building',
    website: 'http://www.warner.senate.gov',
    contact_page: 'https://www.warner.senate.gov/public/index.cfm?p=Contact',
    facebook_url: 'https://facebook.com/MarkRWarner',
    twitter_handle: 'MarkWarner',
    twitter_url: 'https://twitter.com/MarkWarner',
    photo_url:
      'https://cdn.civil.services/us-senate/headshots/512x512/mark-warner.jpg',
  },
  {
    state_name: 'Virginia',
    state_name_slug: 'virginia',
    state_code: 'VA',
    state_code_slug: 'va',
    class: 'I',
    bioguide: 'K000384',
    thomas: '2176',
    opensecrets: 'N00033177',
    votesmart: '50772',
    fec: null,
    maplight: null,
    wikidata: null,
    google_entity_id: null,
    title: 'senator',
    party: 'democrat',
    name: 'Tim Kaine',
    name_slug: 'tim-kaine',
    first_name: 'Tim',
    middle_name: null,
    last_name: 'Kaine',
    name_suffix: null,
    goes_by: null,
    pronunciation: 'TIM KANE',
    gender: 'male',
    ethnicity: 'white-american',
    religion: 'roman-catholic',
    openly_lgbtq: 'no',
    date_of_birth: '1958-02-26',
    entered_office: '2013-01-03',
    term_end: '2019-01-03',
    biography:
      'Timothy Kaine, a Senator from Virginia; born in St. Paul, Minn., February 26, 1958; graduated University of Missouri, B.A., 1979; graduated Harvard University, J.D., 1983; lawyer; Richmond City Council 1994-1998; mayor of Richmond, Va., 1998-2001; lieutenant governor of Virginia 2002-2006; governor of Virginia 2006-2010; chairman of the Democratic National Committee 2009-2011; elected as a Democrat to the United States Senate in 2012 for the term ending January 3, 2019.',
    phone: '202-224-4024',
    fax: '202-228-6363',
    latitude: '38.892542',
    longitude: '-77.007415',
    address_complete:
      '231 Russell Senate Office Building, Washington, DC 20510',
    address_number: null,
    address_prefix: null,
    address_street: '231 Russell Senate Office Building',
    address_sec_unit_type: null,
    address_sec_unit_num: null,
    address_city: 'Washington',
    address_state: 'DC',
    address_zipcode: '20510',
    address_type: 'Building',
    website: 'http://www.kaine.senate.gov',
    contact_page: 'https://www.kaine.senate.gov/contact',
    facebook_url: 'https://facebook.com/SenatorKaine',
    twitter_handle: 'timkaine',
    twitter_url: 'https://twitter.com/timkaine',
    photo_url:
      'https://cdn.civil.services/us-senate/headshots/512x512/tim-kaine.jpg',
  },
  {
    state_name: 'Vermont',
    state_name_slug: 'vermont',
    state_code: 'VT',
    state_code_slug: 'vt',
    class: 'I',
    bioguide: 'S000033',
    thomas: '1010',
    opensecrets: 'N00000528',
    votesmart: '27110',
    fec: null,
    maplight: null,
    wikidata: null,
    google_entity_id: null,
    title: 'senator',
    party: 'independent',
    name: 'Bernard Sanders',
    name_slug: 'bernard-sanders',
    first_name: 'Bernard',
    middle_name: null,
    last_name: 'Sanders',
    name_suffix: null,
    goes_by: 'Bernie',
    pronunciation: 'BER-nee SAN-durz',
    gender: 'male',
    ethnicity: 'white-american',
    religion: 'jewish',
    openly_lgbtq: 'no',
    date_of_birth: '1941-09-08',
    entered_office: '2007-01-03',
    term_end: '2019-01-03',
    biography:
      'Bernard Sanders, a Senator and a Representative from Vermont; born in Brooklyn, Kings County, N.Y., September 8, 1941; graduated from Madison High School, Brooklyn, N.Y.; B.S., University of Chicago, Chicago, Ill., 1964; Faculty, Harvard University, Cambridge, Mass., 1989; faculty, Hamilton College, Clinton, N.Y., 1990; carpenter; journalist; unsuccessful independent candidate for election to the United States Senate in 1972 and 1974; unsuccessful independent candidate for election for governor of Vermont in 1972, 1976 and 1986; mayor of Burlington, Vt., 1981-1989; unsuccessful independent candidate to the One Hundred First Congress in 1988; elected as an Independent to the One Hundred Second and to the seven succeeding Congresses (January 3, 1991-January 3, 2007); was not a candidate for reelection to the House of Representatives but was elected as an Independent to the United States Senate in 2006 for the term commencing January 3, 2007; reelected in 2012 for the term ending January 3, 2019; chair, Committee on Veterans Affairs (One Hundred Thirteenth Congress).',
    phone: '202-224-5141',
    fax: '202-228-0776',
    latitude: '38.892542',
    longitude: '-77.007415',
    address_complete:
      '332 Dirksen Senate Office Building, Washington, DC 20510',
    address_number: null,
    address_prefix: null,
    address_street: '332 Dirksen Senate Office Building',
    address_sec_unit_type: null,
    address_sec_unit_num: null,
    address_city: 'Washington',
    address_state: 'DC',
    address_zipcode: '20510',
    address_type: 'Building',
    website: 'http://www.sanders.senate.gov',
    contact_page: 'https://www.sanders.senate.gov/contact/',
    facebook_url: 'https://facebook.com/senatorsanders',
    twitter_handle: 'SenSanders',
    twitter_url: 'https://twitter.com/SenSanders',
    photo_url:
      'https://cdn.civil.services/us-senate/headshots/512x512/bernard-sanders.jpg',
  },
  {
    state_name: 'Vermont',
    state_name_slug: 'vermont',
    state_code: 'VT',
    state_code_slug: 'vt',
    class: 'III',
    bioguide: 'L000174',
    thomas: '1383',
    opensecrets: 'N00009918',
    votesmart: '53353',
    fec: null,
    maplight: null,
    wikidata: null,
    google_entity_id: null,
    title: 'senator',
    party: 'democrat',
    name: 'Patrick Leahy',
    name_slug: 'patrick-leahy',
    first_name: 'Patrick',
    middle_name: 'J.',
    last_name: 'Leahy',
    name_suffix: null,
    goes_by: null,
    pronunciation: 'PAT-truck LAY-hee',
    gender: 'male',
    ethnicity: 'white-american',
    religion: 'roman-catholic',
    openly_lgbtq: 'no',
    date_of_birth: '1940-03-31',
    entered_office: '1975-01-03',
    term_end: '2023-01-03',
    biography:
      "Patrick Leahy, a Senator from Vermont; born in Montpelier, Washington County, Vt., March 31, 1940; graduated, St. Michael's College, Winooski, Vt., 1961; received J.D. degree from Georgetown University 1964; admitted to the Vermont bar in 1964 and commenced practice in Burlington; State's attorney, Chittenden County, Vt., 1966-1974; elected as a Democrat to the United States Senate in 1974; reelected in 1980, 1986, 1992,1998, 2004 and again in 2010 for the term ending January 3, 2017; President pro tempore (December 17, 2012-January 3, 2015); chair, Committee on Agriculture, Nutrition and Forestry (One Hundredth through One Hundred Third Congresses), Committee on the Judiciary (One Hundred Seventh Congress [January 3-20, 2001; June 6, 2001-January 3, 2003], One Hundred Tenth to One Hundred Thirteenth Congresses).",
    phone: '202-224-4242',
    fax: null,
    latitude: '38.892542',
    longitude: '-77.007415',
    address_complete:
      '437 Russell Senate Office Building, Washington, DC 20510',
    address_number: null,
    address_prefix: null,
    address_street: '437 Russell Senate Office Building',
    address_sec_unit_type: null,
    address_sec_unit_num: null,
    address_city: 'Washington',
    address_state: 'DC',
    address_zipcode: '20510',
    address_type: 'Building',
    website: 'http://www.leahy.senate.gov',
    contact_page: 'https://www.leahy.senate.gov/contact/',
    facebook_url: 'https://facebook.com/SenatorPatrickLeahy',
    twitter_handle: 'SenatorLeahy',
    twitter_url: 'https://twitter.com/SenatorLeahy',
    photo_url:
      'https://cdn.civil.services/us-senate/headshots/512x512/patrick-leahy.jpg',
  },
  {
    state_name: 'Washington',
    state_name_slug: 'washington',
    state_code: 'WA',
    state_code_slug: 'wa',
    class: 'I',
    bioguide: 'C000127',
    thomas: '172',
    opensecrets: 'N00007836',
    votesmart: '27122',
    fec: null,
    maplight: null,
    wikidata: null,
    google_entity_id: null,
    title: 'senator',
    party: 'democrat',
    name: 'Maria Cantwell',
    name_slug: 'maria-cantwell',
    first_name: 'Maria',
    middle_name: null,
    last_name: 'Cantwell',
    name_suffix: null,
    goes_by: null,
    pronunciation: 'muh-REE-uh KANT-well',
    gender: 'female',
    ethnicity: 'white-american',
    religion: 'roman-catholic',
    openly_lgbtq: 'no',
    date_of_birth: '1958-10-13',
    entered_office: '2001-01-03',
    term_end: '2019-01-03',
    biography:
      'Maria Cantwell, a Senator and a Representative from Washington; born in Indianapolis, Ind., October 13, 1958; attended public schools in Indianapolis; B.A., Miami University of Ohio 1980; pursued an academic course at the Miami University European Center, Luxembourg; public relations consultant; Washington State Representative 1987-1993; elected as a Democrat to the 103rd Congress (January 3, 1993-January 3, 1995); unsuccessful candidate for reelection to the 104th Congress; elected as a Democrat to the U.S. Senate on November 7, 2000, for the term commencing January 3, 2001; reelected in 2006 and again in 2012 for the term ending January 3, 2019; chair, Committee on Indian Affairs (One Hundred Thirteenth Congress [January 3, 2013-February 12, 2014]), Committee on Small Business and Entrepreneurship (One Hundred Thirteenth Congress [February 12, 2014-January 3, 2015]).',
    phone: '202-224-3441',
    fax: '202-228-0514',
    latitude: '38.892839',
    longitude: '-77.004356',
    address_complete: '511 Hart Senate Office Building, Washington, DC 20510',
    address_number: null,
    address_prefix: null,
    address_street: '511 Hart Senate Office Building',
    address_sec_unit_type: null,
    address_sec_unit_num: null,
    address_city: 'Washington',
    address_state: 'DC',
    address_zipcode: '20510',
    address_type: 'Building',
    website: 'http://www.cantwell.senate.gov',
    contact_page:
      'https://www.cantwell.senate.gov/public/index.cfm/email-maria',
    facebook_url: 'https://facebook.com/senatorcantwell',
    twitter_handle: 'SenatorCantwell',
    twitter_url: 'https://twitter.com/SenatorCantwell',
    photo_url:
      'https://cdn.civil.services/us-senate/headshots/512x512/maria-cantwell.jpg',
  },
  {
    state_name: 'Washington',
    state_name_slug: 'washington',
    state_code: 'WA',
    state_code_slug: 'wa',
    class: 'III',
    bioguide: 'M001111',
    thomas: '1409',
    opensecrets: 'N00007876',
    votesmart: '53358',
    fec: null,
    maplight: null,
    wikidata: null,
    google_entity_id: null,
    title: 'senator',
    party: 'democrat',
    name: 'Patty Murray',
    name_slug: 'patty-murray',
    first_name: 'Patty',
    middle_name: null,
    last_name: 'Murray',
    name_suffix: null,
    goes_by: null,
    pronunciation: 'PATT-ee MER-ee',
    gender: 'female',
    ethnicity: 'white-american',
    religion: 'roman-catholic',
    openly_lgbtq: 'no',
    date_of_birth: '1950-10-11',
    entered_office: '1993-01-03',
    term_end: '2023-01-03',
    biography:
      'Patty Murray, a Senator from Washington; born in Seattle, King County, Wash., October 11, 1950; graduated, Washington State University 1972; education volunteer 1977-1984; instructor, Shoreline Community College, Shoreline, Wash., 1984-1987; citizen lobbyist for environmental and educational issues 1983-1988; Member, board of directors, Shoreline School District 1985-1989, serving as president and legislative representative for two terms; member, Washington state Senate 1988-1992, Democratic whip 1990-1992; elected as a Democrat to the United States Senate in 1992; reelected in 1998, 2004 and again in 2010 for the term ending January 3, 2017; chairwoman, Democratic Senatorial Campaign Committee (2001-2003, 2011-2013), Committee on Veterans Affairs (One Hundred Twelfth Congress), Committee on the Budget (One Hundred Thirteenth Congress); Democratic Conference secretary (2007-).',
    phone: '202-224-2621',
    fax: '202-224-0238',
    latitude: '38.892542',
    longitude: '-77.007415',
    address_complete:
      '154 Russell Senate Office Building, Washington, DC 20510',
    address_number: null,
    address_prefix: null,
    address_street: '154 Russell Senate Office Building',
    address_sec_unit_type: null,
    address_sec_unit_num: null,
    address_city: 'Washington',
    address_state: 'DC',
    address_zipcode: '20510',
    address_type: 'Building',
    website: 'http://www.murray.senate.gov',
    contact_page: 'https://www.murray.senate.gov/public/index.cfm/contactme',
    facebook_url: 'https://facebook.com/pattymurray',
    twitter_handle: 'PattyMurray',
    twitter_url: 'https://twitter.com/PattyMurray',
    photo_url:
      'https://cdn.civil.services/us-senate/headshots/512x512/patty-murray.jpg',
  },
  {
    state_name: 'Wisconsin',
    state_name_slug: 'wisconsin',
    state_code: 'WI',
    state_code_slug: 'wi',
    class: 'III',
    bioguide: 'J000293',
    thomas: '2086',
    opensecrets: 'N00032546',
    votesmart: '126217',
    fec: null,
    maplight: null,
    wikidata: null,
    google_entity_id: null,
    title: 'senator',
    party: 'republican',
    name: 'Ron Johnson',
    name_slug: 'ron-johnson',
    first_name: 'Ron',
    middle_name: null,
    last_name: 'Johnson',
    name_suffix: null,
    goes_by: null,
    pronunciation: 'RAHN JAHN-suhn',
    gender: 'male',
    ethnicity: 'white-american',
    religion: 'lutheran',
    openly_lgbtq: 'no',
    date_of_birth: '1955-04-08',
    entered_office: '2011-01-03',
    term_end: '2023-01-03',
    biography:
      'Ron Johnson, a Senator from Wisconsin; born on April 8, 1955, in Mankato, Minn.; graduated University of Minnesota, B.S., 1977; studied business administration, University of Minnesota, 1977-1979; plastics manufacturer; accountant; elected as a Republican to the United States Senate in 2010 for the term ending January 3, 2017; chair, Committee on Homeland Security and Governmental Affairs (One Hundred Fourteenth Congress).',
    phone: '202-224-5323',
    fax: '202-228-6965',
    latitude: '38.892839',
    longitude: '-77.004356',
    address_complete: '328 Hart Senate Office Building, Washington, DC 20510',
    address_number: null,
    address_prefix: null,
    address_street: '328 Hart Senate Office Building',
    address_sec_unit_type: null,
    address_sec_unit_num: null,
    address_city: 'Washington',
    address_state: 'DC',
    address_zipcode: '20510',
    address_type: 'Building',
    website: 'http://www.ronjohnson.senate.gov',
    contact_page:
      'https://www.ronjohnson.senate.gov/public/index.cfm/email-the-senator',
    facebook_url: 'https://facebook.com/senronjohnson',
    twitter_handle: 'SenRonJohnson',
    twitter_url: 'https://twitter.com/SenRonJohnson',
    photo_url:
      'https://cdn.civil.services/us-senate/headshots/512x512/ron-johnson.jpg',
  },
  {
    state_name: 'Wisconsin',
    state_name_slug: 'wisconsin',
    state_code: 'WI',
    state_code_slug: 'wi',
    class: 'I',
    bioguide: 'B001230',
    thomas: '1558',
    opensecrets: 'N00004367',
    votesmart: '3470',
    fec: null,
    maplight: null,
    wikidata: null,
    google_entity_id: null,
    title: 'senator',
    party: 'democrat',
    name: 'Tammy Baldwin',
    name_slug: 'tammy-baldwin',
    first_name: 'Tammy',
    middle_name: null,
    last_name: 'Baldwin',
    name_suffix: null,
    goes_by: null,
    pronunciation: 'TAMM-ee BAWLD-wuhn',
    gender: 'female',
    ethnicity: 'white-american',
    religion: 'unspecified',
    openly_lgbtq: 'no',
    date_of_birth: '1962-02-11',
    entered_office: '2013-01-03',
    term_end: '2019-01-03',
    biography:
      'Tammy Baldwin, a Senator and a Representative from Wisconsin; born in Madison, Dane County, Wis., February 11, 1962; graduated from Madison West High School, Madison, Wis., 1980; A.B., Smith College, Northampton, Mass., 1984; J.D., University of Wisconsin Law School, Madison, Wis., 1989; lawyer, private practice; Dane County, Wis., board of supervisors, 1986-1994; member of the Wisconsin state assembly, 1993-1999; elected as a Democrat to the One Hundred Sixth and to the six succeeding Congresses (January 3, 1999-January 3, 2013); was not a candidate for reelection to the House of Representatives but was elected as a Democrat to the United States Senate in 2012 for the term ending January 3, 2019.',
    phone: '202-224-5653',
    fax: null,
    latitude: '38.892839',
    longitude: '-77.004356',
    address_complete: '717 Hart Senate Office Building, Washington, DC 20510',
    address_number: null,
    address_prefix: null,
    address_street: '717 Hart Senate Office Building',
    address_sec_unit_type: null,
    address_sec_unit_num: null,
    address_city: 'Washington',
    address_state: 'DC',
    address_zipcode: '20510',
    address_type: 'Building',
    website: 'http://www.baldwin.senate.gov',
    contact_page: 'https://www.baldwin.senate.gov/feedback',
    facebook_url: 'https://facebook.com/senatortammybaldwin',
    twitter_handle: 'tammybaldwin',
    twitter_url: 'https://twitter.com/tammybaldwin',
    photo_url:
      'https://cdn.civil.services/us-senate/headshots/512x512/tammy-baldwin.jpg',
  },
  {
    state_name: 'West Virginia',
    state_name_slug: 'west-virginia',
    state_code: 'WV',
    state_code_slug: 'wv',
    class: 'I',
    bioguide: 'M001183',
    thomas: '1983',
    opensecrets: 'N00032838',
    votesmart: '7547',
    fec: null,
    maplight: null,
    wikidata: null,
    google_entity_id: null,
    title: 'senator',
    party: 'democrat',
    name: 'Joe Manchin',
    name_slug: 'joe-manchin',
    first_name: 'Joe',
    middle_name: null,
    last_name: 'Manchin',
    name_suffix: 'III',
    goes_by: null,
    pronunciation: 'JOH MAN-chuhn',
    gender: 'male',
    ethnicity: 'white-american',
    religion: 'roman-catholic',
    openly_lgbtq: 'no',
    date_of_birth: '1947-08-24',
    entered_office: '2010-11-15',
    term_end: '2019-01-03',
    biography:
      'Joe Manchin, a Senator from West Virginia; born August 24, 1947, in Farmington, Marion County, West Virginia; graduated Farmington High School, Farmington, WV, 1965; B.A., West Virginia University, 1970; businessman; member of the West Virginia house of delegates 1982-1986; member of the West Virginia state Senate 1986-1996; unsuccessful candidate for the Democratic gubernatorial nomination in 1996; Secretary of State, West Virginia 2000-2004; elected governor of West Virginia in 2004 and reelected in 2008; chairman of the National Governors Association 2010; elected as a Democrat to the U.S. Senate in a special election on November 2, 2010, to fill the vacancy caused by the death of Robert C. Byrd, a seat subsequently held by appointed Senator Carte Goodwin, and took the oath of office on November 15, 2010; reelected in 2012 for the term ending January 3, 2019.',
    phone: '202-224-3954',
    fax: '202-228-0002',
    latitude: '38.892839',
    longitude: '-77.004356',
    address_complete: '306 Hart Senate Office Building, Washington, DC 20510',
    address_number: null,
    address_prefix: null,
    address_street: '306 Hart Senate Office Building',
    address_sec_unit_type: null,
    address_sec_unit_num: null,
    address_city: 'Washington',
    address_state: 'DC',
    address_zipcode: '20510',
    address_type: 'Building',
    website: 'http://www.manchin.senate.gov',
    contact_page:
      'https://www.manchin.senate.gov/public/index.cfm/contact-form',
    facebook_url: 'https://facebook.com/JoeManchinIII',
    twitter_handle: 'Sen_JoeManchin',
    twitter_url: 'https://twitter.com/Sen_JoeManchin',
    photo_url:
      'https://cdn.civil.services/us-senate/headshots/512x512/joe-manchin.jpg',
  },
  {
    state_name: 'West Virginia',
    state_name_slug: 'west-virginia',
    state_code: 'WV',
    state_code_slug: 'wv',
    class: 'II',
    bioguide: 'C001047',
    thomas: '1676',
    opensecrets: 'N00009771',
    votesmart: '11701',
    fec: null,
    maplight: null,
    wikidata: null,
    google_entity_id: null,
    title: 'senator',
    party: 'republican',
    name: 'Shelley Capito',
    name_slug: 'shelley-capito',
    first_name: 'Shelley',
    middle_name: 'Moore',
    last_name: 'Capito',
    name_suffix: null,
    goes_by: null,
    pronunciation: 'SHELL-ee MORE CAP-it-oh',
    gender: 'female',
    ethnicity: 'white-american',
    religion: 'presbyterian',
    openly_lgbtq: 'no',
    date_of_birth: '1953-11-26',
    entered_office: '2015-01-03',
    term_end: '2027-01-03',
    biography:
      'Shelley Capito (daughter of Arch Alfred Moore, Jr.), a Senator and a Representative from West Virginia; born in Glendale, Marshall County, W.Va., November 26, 1953; B.S., Duke University, Durham, N.C., 1975; M.Ed., University of Virginia, Charlottesville, Va., 1976; college counselor; Member, West Virginia state house of representatives, 1997-2001; elected as a Republican to the One Hundred Seventh and to the six succeeding Congresses (January 3, 2001-January 3, 2015); was not a candidate for reelection to the House of Representatives but was elected as a Republican to the United States Senate in 2014 for the term ending January 3, 2021.',
    phone: '202-224-6472',
    fax: null,
    latitude: '38.892542',
    longitude: '-77.007415',
    address_complete:
      '172 Russell Senate Office Building, Washington, DC 20510',
    address_number: null,
    address_prefix: null,
    address_street: '172 Russell Senate Office Building',
    address_sec_unit_type: null,
    address_sec_unit_num: null,
    address_city: 'Washington',
    address_state: 'DC',
    address_zipcode: '20510',
    address_type: 'Building',
    website: 'http://www.capito.senate.gov',
    contact_page: 'https://www.capito.senate.gov/contact/contact-shelley',
    facebook_url: 'https://facebook.com/senshelley',
    twitter_handle: 'SenCapito',
    twitter_url: 'https://twitter.com/SenCapito',
    photo_url:
      'https://cdn.civil.services/us-senate/headshots/512x512/shelley-capito.jpg',
  },
  {
    state_name: 'Wyoming',
    state_name_slug: 'wyoming',
    state_code: 'WY',
    state_code_slug: 'wy',
    class: 'I',
    bioguide: 'B001261',
    thomas: '1881',
    opensecrets: 'N00006236',
    votesmart: '52662',
    fec: null,
    maplight: null,
    wikidata: null,
    google_entity_id: null,
    title: 'senator',
    party: 'republican',
    name: 'John Barrasso',
    name_slug: 'john-barrasso',
    first_name: 'John',
    middle_name: null,
    last_name: 'Barrasso',
    name_suffix: null,
    goes_by: null,
    pronunciation: 'JAHN buh-RASS-oh',
    gender: 'male',
    ethnicity: 'white-american',
    religion: 'presbyterian',
    openly_lgbtq: 'no',
    date_of_birth: '1952-07-21',
    entered_office: '2007-06-25',
    term_end: '2019-01-03',
    biography:
      'John Barrasso, a Senator from Wyoming; born on July 21, 1952, in Reading, Penn.; graduated Georgetown University, Washington, D.C., B.S. 1974; graduated Georgetown University School of Medicine, M.D. 1978; residency at Yale Medical School, New Haven, Conn.; orthopedic surgeon, private practice; chief of staff of the Wyoming Medical Center, Casper, Wyo.; Wyoming State Senate 2002-2007; appointed to the United States Senate on June 22, 2007, to fill the vacancy caused by the death of Craig L. Thomas; took the oath of office on June 25, 2007; elected in 2008 in a special election for the term ending January 3, 2013; reelected in 2012 for the term ending January 3, 2019; vice-chair, Republican Conference (2010-2012); chair, Republican Policy Committee (2012-); chair, Committee on Indian Affairs (One Hundred Fourteenth Congress).',
    phone: '202-224-6441',
    fax: '202-224-1724',
    latitude: '38.892542',
    longitude: '-77.007415',
    address_complete:
      '307 Dirksen Senate Office Building, Washington, DC 20510',
    address_number: null,
    address_prefix: null,
    address_street: '307 Dirksen Senate Office Building',
    address_sec_unit_type: null,
    address_sec_unit_num: null,
    address_city: 'Washington',
    address_state: 'DC',
    address_zipcode: '20510',
    address_type: 'Building',
    website: 'http://www.barrasso.senate.gov',
    contact_page:
      'https://www.barrasso.senate.gov/public/index.cfm/contact-form',
    facebook_url: 'https://facebook.com/johnbarrasso/',
    twitter_handle: 'SenJohnBarrasso',
    twitter_url: 'https://twitter.com/SenJohnBarrasso',
    photo_url:
      'https://cdn.civil.services/us-senate/headshots/512x512/john-barrasso.jpg',
  },
  {
    state_name: 'Wyoming',
    state_name_slug: 'wyoming',
    state_code: 'WY',
    state_code_slug: 'wy',
    class: 'II',
    bioguide: 'L000571',
    thomas: '01960',
    opensecrets: 'N00029788',
    votesmart: '15546',
    fec: 'S0WY00137',
    maplight: null,
    wikidata: null,
    google_entity_id: null,
    title: 'senator',
    party: 'republican',
    name: 'Cynthia Lummis',
    name_slug: 'cynthia-lummis',
    first_name: 'Cynthia',
    middle_name: 'M.',
    last_name: 'Lummis',
    name_suffix: null,
    goes_by: null,
    pronunciation: 'cyn-thee-ah luh-mis',
    gender: 'female',
    ethnicity: 'white-american',
    religion: 'unspecified',
    openly_lgbtq: 'no',
    date_of_birth: '1954-09-10',
    entered_office: '2021-01-03',
    term_end: '2027-01-03',
    biography:
      'Born on a cattle ranch in Laramie County, Senator Lummis has spent her entire career fighting for Wyoming families, communities, businesses and values. From the halls of the Wyoming House to the halls of the U.S. House, her time in public service has always been focused on advocating for Wyoming’s future.',
    phone: '202-224-3424',
    fax: null,
    latitude: '38.892542',
    longitude: '-77.007415',
    address_complete: 'G12 Dirksen Senate Office Building Washington DC 20510',
    address_number: null,
    address_prefix: null,
    address_street: 'G12 Dirksen Senate Office Building Washington DC 20510',
    address_sec_unit_type: null,
    address_sec_unit_num: null,
    address_city: 'Washington',
    address_state: 'DC',
    address_zipcode: '20510',
    address_type: 'Building',
    website: 'https://www.lummis.senate.gov',
    contact_page: 'https://www.lummis.senate.gov',
    facebook_url: 'https://www.facebook.com/sencynthialummis/',
    twitter_handle: 'SenLummis',
    twitter_url: 'https://twitter.com/SenLummis',
    photo_url:
      'https://cdn.civil.services/us-senate/headshots/512x512/cynthia-lummis.jpg',
  },
];
