export default function CTA() {
  return (
    <div className="flex-row call-to-action-row">
      <div className="call-to-action-section-wrapper"></div>
      <div className="call-to-action-section-wrapper">
        <h1 className="green-text">
          Show your support for trans rights.{' '}
          <a
            href="./take-action"
            rel="noreferrer"
            className="call-to-action-link"
          >
            <span className="blue-text">Take Action</span>
          </a>
        </h1>
      </div>
    </div>
  );
}
