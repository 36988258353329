export default function Healthcare() {
  return (
    <div className="slim-view">
      <div className="section-wrapper">
        <h1>How did we get here?</h1>
        <div className="flex-row">
          <div className="flex-column">
            <p>
              In 2014, Time Magazine published{' '}
              <a
                href="https://time.com/135480/transgender-tipping-point/"
                target="_blank"
                rel="noreferrer"
              >
                “The Transgender Tipping Point,”
              </a>{' '}
              a profile of actress Laverne Cox. The cover story considered a
              changing culture amid major court victories for same-sex marriage.
              Cox described an emerging space for trans people to tell their
              stories. “More of us are living visibly and pursuing our dreams
              visibly,” she said. “When people have points of reference that are
              humanizing, that demystifies difference.” Yet within this
              optimism, the article recognized the looming legal battle they
              called “America’s next civil rights frontier.”
            </p>
          </div>
          <div className="flex-column">
            <blockquote className="frontier-blockquote">
              America’s next civil rights frontier
              <p className="callout-text-attribution">Time Magazine, 2014</p>
            </blockquote>
          </div>
        </div>
        <p>
          By the summer of 2015, the Supreme Court ruling on{' '}
          <a
            href="https://www.supremecourt.gov/opinions/14pdf/14-556_3204.pdf"
            target="_blank"
            rel="noreferrer"
          >
            <i>Obergefell v. Hodges</i>
          </a>{' '}
          meant that every state would be required to grant and recognize
          same-sex marriages. As Time Magazine predicted, the cultural and legal
          battlegrounds were redrawn. That year saw the introduction of
          anti-trans legislation at the state level and a mobilization for the
          fight to come in the years ahead.
        </p>
        <p>
          The same coalition that spent decades opposing abortion and same-sex
          marriage had shifted its focus to transgender rights. The Family
          Research Council, which has long opposed the “homosexual movement,”
          calls trans identity a{' '}
          <a href="https://frc.org/cult" target="_blank" rel="noreferrer">
            “cult”
          </a>{' '}
          and gender-affirming treatment “the lobotomies of our time.”
          Similarly, the Family Policy Alliance, which continues to argue that
          marriage is a “God-given” gift between “one man and one woman”, now
          stresses their conviction that “God created mankind male and female
          […] which cannot be changed.” The Alliance Defending Freedom, which
          has been designated a{' '}
          <a
            href="https://www.splcenter.org/fighting-hate/extremist-files/group/alliance-defending-freedom"
            target="_blank"
            rel="noreferrer"
          >
            hate group
          </a>{' '}
          by the Southern Poverty Law Center, has provided legal muscle to
          oppose what it calls the{' '}
          <a
            href="https://archive.org/stream/TheHomosexualAgenda-ExposingThePrincipalThreatToReligiousFreedomToday/The-Homosexual-Agenda-Exposing-the-Principle-Threat-Alan-Sears_djvu.txt"
            target="_blank"
            rel="noreferrer"
          >
            “homosexual agenda”
          </a>{' '}
          for decades and remains a key force against trans rights, even{' '}
          <a
            href="https://www.nbcnews.com/feature/nbc-out/state-anti-transgender-bills-represent-coordinated-attack-advocates-say-n1258124"
            target="_blank"
            rel="noreferrer"
          >
            ghostwriting
          </a>{' '}
          legislation introduced this year.
        </p>

        <div className="flex-row">
          <div className="flex-column vertical-center">
            <blockquote style={{ marginRight: '40px' }}>
              It’s the latest generation of what’s catching people’s eyes and
              pocketbooks.
              <p className="callout-text-attribution">Heron Greenesmith</p>
            </blockquote>
          </div>
          <div className="flex-column">
            <p>
              The shift from same-sex marriage opposition to anti-trans
              legislation shouldn’t be seen as a signal of defeat, explains
              Heron Greenesmith, Senior Research Analyst at Political Research
              Associates. “It’s an entrenchment,” they say. Religious right
              organizations including the Heritage Foundation, Alliance
              Defending Freedom, Family Policy Alliance, and the Family Research
              Council “never lost power.” Those organizations simply pivoted to
              “the latest generation of what’s catching people’s eyes and
              pocketbooks.”
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
