export const hrc_opposed_bills = [
  {
    count: 66,
    category: 'anti-trans sports bills',
    bills: [
      'AR SJR 16',
      'AR SJR 16',
      'CT SB 324',
      'FL SB 2012',
      'FL HB 1475',
      'KS SB 208',
      'MI SB 218',
      'MN HF 350',
      'MN SF 96',
      'MN HF 350',
      'MO HB 1045',
      'MO HJR 53',
      'MO SB 503',
      'NH HB 198',
      'OH HB 61',
      'OK SB 2',
      'SC HB 3477',
      'SC SB 531',
      'TN HB 003',
      'TN HB 1458',
      'TN SB 288',
      'TX SB 29',
      'TX SB 373',
      'TX HB 1458',
    ],
  },
  {
    count: 33,
    category: 'anti-trans medical bills',
    bills: [
      'AL HB 1',
      'AL SB 10',
      'FL HB 935',
      'IA HF 193',
      'IA HF 327',
      'IN SB 0224',
      'KS SB 214',
      'KS HB 2210',
      'MO HB 33',
      'MO SB 422',
      'NH HB 68',
      'SC HB 4047',
      'TN SB 657',
      'TN SB 578',
      'TX HB 1311',
      'TX HB 1399',
      'TX HB 68',
    ],
  },
  {
    count: 14,
    category: 'bathroom/locker room bills',
    bills: [
      'IA HF 187',
      'IA SF 224',
      'MN HF 1657',
      'TN HB 1233',
      'TN SB 1367',
      'TN HB 1182',
      'TN HB 1177',
      'TN SB 1224',
      'TN SB 1238',
    ],
  },
  {
    count: 5,
    category: 'other',
    bills: ['TX HB 68'],
  },
];

// HRC email
export const antiTransBillsByYear = [
  {
    year: 2015,
    total: 19,
    bills: [
      { type: 'bathroom / locker room / sports', count: 16 },
      { type: 'other', count: 3 },
    ],
  },
  {
    year: 2016,
    total: 55,
    bills: [
      { type: 'bathroom / locker room / sports', count: 25 },
      { type: 'other', count: 30 },
    ],
  },
  {
    year: 2017,
    total: 45,
    bills: [
      { type: 'bathroom / locker room / sports', count: 28 },
      { type: 'other', count: 17 },
    ],
  },
  {
    year: 2018,
    total: 26,
    bills: [
      { type: 'bathroom / locker room / sports', count: 20 },
      { type: 'health', count: 2 },
      { type: 'other', count: 4 },
    ],
  },
  {
    year: 2019,
    total: 19,
    bills: [
      { type: 'bathroom / locker room / sports', count: 12 },
      { type: 'health', count: 4 },
      { type: 'other', count: 3 },
    ],
  },
  {
    year: 2020,
    total: 66,
    bills: [
      { type: 'bathroom / locker room / sports', count: 25 },
      { type: 'health', count: 24 },
      { type: 'other', count: 17 },
    ],
  },
  {
    year: 2021,
    total: 118,
    bills: [
      { type: 'bathroom / locker room / sports', count: 80 },
      { type: 'health', count: 33 },
      { type: 'other', count: 5 },
    ],
  },
];
