export default function Intro() {
  return (
    <div className="slim-view">
      <div className="section-wrapper" id="section-one">
        <h1>A record-breaking year</h1>
        <p>
          144 anti-trans bills are moving through 37 states, opposing
          transgender equality across nearly every aspect of public life. If
          passed, Texas would{' '}
          <a
            href="https://www.texastribune.org/2021/04/27/texas-senate-transgender-child-abuse/"
            target="_blank"
            rel="noreferrer"
          >
            redefine
          </a>{' '}
          “child abuse” to criminalize parents and doctors who put their child
          on hormone blockers. Iowa would mandate educators teach “the potential
          harm and adverse outcomes of social and medical gender interventions.”
          A New Hampshire bill comes right out and says “civil rights shall not
          apply” to transgender girls in school sports.
        </p>

        <div className="flex-row">
          <div className="flex-column vertical-center">
            <div
              className="callout-text callout-orange"
              style={{ margin: '0' }}
            >
              “civil rights shall not apply”
              <p className="callout-text-attribution">New Hampshire HB 198</p>
            </div>
          </div>
          <div className="flex-column">
            <p>
              The titles of the bills mislead the public about their intent. The
              many iterations of “Save women’s sports” bills would not shield
              cis-gender girls from losing trophies, but rather codify the
              meaning of “girls” to exclude trans girls. Arkansas’ “Save
              Adolescents From Experimentation (SAFE) Act”{' '}
              <a
                href="https://www.arkleg.state.ar.us/Bills/Detail?id=hb1570&ddBienniumSession=2021%2F2021R&Search="
                target="_blank"
                rel="noreferrer"
              >
                (HB 1570)
              </a>{' '}
              is not about experimental drugs or treatments, but instead blocks
              doctors from providing basic gender-affirming healthcare. The goal
              across these bills is simple: to keep transgender people out of
              public life.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
