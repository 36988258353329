import React from 'react';
import './App.css';
import { groupedBills } from './data/groupedBills';
import Header from './components/Header';
import Intro from './components/Intro';
import AllBills from './components/AllBills';
import BillsByGroup from './components/BillsByGroup';
import Divide from './components/discussion/Divide';
import Frontier from './components/discussion/Frontier';
import Inflection from './components/discussion/Inflection';
import Other from './components/discussion/Other';
import MapSection from './components/MapSection';
import CallToAction from './components/action/CallToAction';

import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';

function App() {
  const allBills2D = groupedBills.map((entry) => entry[1]);
  const allBills = allBills2D.reduce((prev, next) => prev.concat(next));

  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <>
            <Header />
            <Intro />
            <MapSection allBills={allBills} />
            <AllBills allBills={allBills} />
            <Frontier />
            {groupedBills.map((group) => (
              <BillsByGroup
                group={group[0]}
                bills={group[1]}
                key={`${group?.state}-${group?.category}-${group?.title}`}
              />
            ))}
            <Other groupedBills={groupedBills} />
            <Divide />
            <Inflection />
          </>
        </Route>
        <Route exact path="/take-action">
          <CallToAction />
        </Route>
        <Route render={() => <Redirect to="/" />} />
      </Switch>
    </Router>
  );
}

export default App;
