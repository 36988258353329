import React, { useState, useEffect } from 'react';
import USAMap from 'react-usa-map';

export default function Map({
  allBills,
  setSelectedState,
  selectedState,
  setBillsVisible,
}) {
  const [mapData, setMapData] = useState();
  const [states, setStates] = useState([]);

  useEffect(() => {
    // Set States Array
    const onlyUnique = (value, index, self) => self.indexOf(value) === index;
    const states = allBills.map((b) => b.abbreviation).filter(onlyUnique);
    setStates(states);

    // Map data [ {"AR": { count: 1, bills: [{...}]}, ... ]
    const mData = states
      .map((state) => {
        return allBills.filter((b) => b.abbreviation === state);
      })
      .reduce((map, entry) => {
        const state = entry[0].abbreviation;

        map[state] = {
          count: entry.length,
          bills: entry,
        };
        return map;
      }, {});

    setMapData(mData);
  }, [allBills]);

  const statesConfig = () => {
    let config = {};
    const selectedColor = '#5f92e8'; // f38823 or 4382eb

    const colors = {
      severity1: '#FC9930',
      severity2: '#EF832B',
      severity3: '#E26C25',
      severity4: '#D45620',
      severity5: '#C73F1A',
    };

    const heatmapColor = (state) => {
      const stateCount = mapData[state].count;

      if (stateCount === 1) return colors.severity1;
      if (stateCount === 2) return colors.severity2;
      if (stateCount === 3) return colors.severity3;
      if (stateCount === 4) return colors.severity4;

      return colors.severity5;
    };

    states.forEach((state) => {
      const fillColor =
        selectedState === state ? selectedColor : heatmapColor(state);
      config[state] = {
        fill: fillColor,
        clickHandler: (event) => {
          setSelectedState(event.target.dataset.name);
          setBillsVisible(true);
        },
      };
    });

    return config;
  };

  return <USAMap customize={statesConfig()} />;
}
