import React, { useState, useRef } from 'react';
import BillCard from '../BillCard';

export default function Other({ groupedBills }) {
  const [isOpen, setIsOpen] = useState(false);
  const topRef = useRef(null);
  const classes = isOpen
    ? 'card-container card-container-open'
    : 'card-container card-container-closed';

  const isOther = [
    'Restrictions on bathroom use',
    'Restrictions on government recognition of gender identity',
    'Restrictions on identification documents',
    'Restrictions on sex education',
    'Religious Freedom Restoration Acts',
    'Religious Exemptions in Healthcare Implicating LGBTQ People',
    'Religious Exemptions in Adoption & Foster Care',
    'Religious Exemptions in Schools and Student Organizations',
    'Other Religious Exemption Bills',
    'Bills Pre-Empting Local Protections',
  ];

  const bills = groupedBills
    .filter((set) => {
      const category = set[0];
      return isOther.includes(category);
    })
    .map((set) => set[1])
    .flat();

  return (
    <>
      <div className="slim-view">
        <div className="section-wrapper">
          <h1>
            <span className="orange-text">46</span> more discriminatory bills
          </h1>
          <p>
            The implications of anti-trans legislation goes beyond doctors
            offices and sports teams. Other bills would make it harder to obtain
            government IDs, use single-sex facilities, teach gender identity in
            sex-education classes, or enforce non-discrimination laws. Seven
            states even introduced “pre-emptive local protections" bills that
            would bar cities or local governments from passing their own
            nondiscrimination protections.
          </p>
          <div className="flex-row">
            <div className="flex-column vertical-center">
              <div
                className="callout-text callout-blue"
                style={{ fontSize: '2.1rem', lineHeight: '2.1rem' }}
              >
                Curricula must include “the existence of transition regret and
                detransition, and the potential harm and adverse outcomes of
                social and medical gender interventions.”
                <p className="callout-text-attribution">Iowa HF 326</p>
              </div>
            </div>
            <div className="flex-column">
              <p>
                Religious Freedom Restoration Acts (RFRAs) are among the most
                prominent legislation types in this group. These bills carve out
                exemptions that allow those with religious objections to
                discriminate with impunity. Exemptions would allow providers to
                deny gender-affirming care, deny trans parents the ability to
                adopt a child, and legalize other discrimination on the grounds
                of religious conviction.
              </p>
              <p>
                In schools, sex education materials are another focus. Bills
                targeting the classroom range from opt-in signatures that would
                limit the number of children who learn about gender to{' '}
                <a
                  href="https://legiscan.com/IA/bill/HF326/2021"
                  target="_blank"
                  rel="noreferrer"
                >
                  requiring
                </a>{' '}
                teachers to cover “the existence of transition regret and
                detransition, and the potential harm and adverse outcomes of
                social and medical gender interventions.”
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="section-container" ref={topRef}>
        <h2 className="light-black">
          {/* <span className="orange-text">
            {bills?.length ? bills.length : '57'}
          </span>{' '} */}
          All other discriminatory bills
        </h2>
        <div className={classes}>
          {bills.map((bill) => (
            <BillCard bill={bill} fullWidth />
          ))}
        </div>
        {isOpen ? (
          <button
            className="all-bills-expand-button"
            onClick={() => {
              setIsOpen(!isOpen);
              topRef.current.scrollIntoView();
            }}
          >
            Collapse bills
          </button>
        ) : (
          <button
            className="all-bills-expand-button"
            onClick={() => setIsOpen(!isOpen)}
          >
            See more bills
          </button>
        )}
      </div>
    </>
  );
}
