import React, { useState } from 'react';
import Map from './Map';
import BillsByState from './BillsByState';

function getWindowWidth() {
  const { innerWidth: width } = window;
  return width;
}

export default function MapSection({ allBills }) {
  const [selectedState, setSelectedState] = useState();
  const [billsVisible, setBillsVisible] = useState(false);
  const isSmallDevice = getWindowWidth() < 800;

  const hideBills = () => {
    setSelectedState();
    setBillsVisible(false);
  };

  return (
    <div className="map-section">
      {billsVisible && !isSmallDevice ? (
        <div className="flex-row map-flex-row">
          <div className="flex-column fade-in">
            <div className="map-in-column sticky-column">
              <Map
                allBills={allBills}
                selectedState={selectedState}
                setSelectedState={setSelectedState}
                setBillsVisible={setBillsVisible}
              />
            </div>
          </div>
          <div className="flex-column">
            <BillsByState
              state={selectedState}
              bills={allBills}
              billsVisible={billsVisible}
              setBillsVisible={setBillsVisible}
              hideBills={hideBills}
            />
          </div>
        </div>
      ) : (
        <div className="fade-in">
          <Map
            allBills={allBills}
            selectedState={isSmallDevice ? undefined : selectedState}
            setSelectedState={setSelectedState}
            setBillsVisible={setBillsVisible}
          />
          {!isSmallDevice && (
            <h2 className="light-black">(click state to see bills)</h2>
          )}
        </div>
      )}
    </div>
  );
}
