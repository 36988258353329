import ReactPlayer from 'react-player';

function getWindowWidth() {
  const { innerWidth: width } = window;
  return width;
}

export default function Atheletics() {
  const isSmallDevice = getWindowWidth() < 800;

  return (
    <div className="section-wrapper">
      <h1>
        <span className="orange-text">57 </span>youth athletics bills
      </h1>

      <p>
        Numerous bills excluding trans girls from sports passed this year,
        including Arkansas’ “Fairness in Women’s Sports Act”{' '}
        <a
          href="https://www.arkleg.state.ar.us/Bills/Detail?id=SB354&ddBienniumSession=2021/2021R"
          target="_blank"
          rel="noreferrer"
        >
          (SB 354)
        </a>
        , Montana’s “Save Women’s Sports Act”{' '}
        <a
          href="https://leg.mt.gov/bills/2021/HB0199/HB0112_1.pdf"
          target="_blank"
          rel="noreferrer"
        >
          (HB 112)
        </a>
        , and West Virginia’s single-sex participation bill{' '}
        <a
          href="https://legiscan.com/WV/bill/HB3293/2021"
          target="_blank"
          rel="noreferrer"
        >
          (HB 3293)
        </a>
        . Like bathroom bills, these laws divide students by “biological sex”
        while rejecting gender identity, sometimes referring to trans girls as
        “students of the male sex.”
      </p>
      <p>
        Tennessee’s{' '}
        <a
          href="https://legiscan.com/TN/bill/SB0228/2021"
          target="_blank"
          rel="noreferrer"
        >
          SB 228
        </a>{' '}
        explicitly proposes redefining gender to become synonymous to “the
        student’s sex at the time of the student’s birth, as indicated on the
        student’s original birth certificate.” The bill would invalidate trans
        identity and exclude trans kids from being visible to their classmates.
        It also blocks future recognition of amended birth certificates,
        permanently barring trans kids from school sports.
      </p>
      <p>
        The law also impacts cis girls who have gender presentations that don’t
        conform with what parents or coaches might expect. The Washington Post{' '}
        <a
          href="https://www.washingtonpost.com/lifestyle/style/bathroom-bills-focus-on-the-trans-community-but-could-affect-another-group-more/2016/04/24/c5026920-0a26-11e6-bfa1-4efa856caf2a_story.html"
          target="_blank"
          rel="noreferrer"
        >
          points out
        </a>{' '}
        that if a child is thought to be transgender, they may have to “provide
        evidence” of their sex through genital exams or genetic testing. Beyond
        its invasiveness, the{' '}
        <a
          href="https://www.scientificamerican.com/article/trans-girls-belong-on-girls-sports-teams"
          target="_blank"
          rel="noreferrer"
        >
          Scientific American
        </a>{' '}
        points out that such laws entirely ignore the existence of those with XY
        chromosomes who also have vaginas. “This is just one of several
        conditions that would make such sex policing impossible.”
      </p>

      <blockquote>
        It wasn’t about bathrooms, and it’s not about women’s sports
      </blockquote>

      <p>
        The misinformation underpinning these laws has long been circulating in
        American society. Anti-trans organizations promote the baseless fear
        that trans women make public facilities unsafe. They operate out of a
        suspicion of trans women, painting them as not real women, but deceptive
        predators. During the first wave of bathroom bills in 2015, for
        instance, presidential candidate Mike Huckabee offered this hypothetical
        to the National Religious Broadcasters Convention audience:
      </p>

      <div className="flex-row">
        <div className="flex-column vertical-center">
          <ReactPlayer
            url="https://www.youtube.com/embed/NaVVDQziV9M"
            controls={true}
            width={isSmallDevice ? getWindowWidth() : 400}
            style={{ marginRight: '20px', maxWidth: '400px' }}
          />
        </div>
        <div className="flex-column">
          <blockquote>
            I wish that someone told me that when I was in high school that I
            could have felt like a woman when it came time to take showers in
            PE. I’m pretty sure that I would have found my feminine side and
            said, “Coach, I think I’d rather shower with the girls today.”
          </blockquote>
        </div>
      </div>

      <p>
        It’s important to understand that “it wasn't about bathrooms, and it’s
        not about women’s sports,” as Mark Joseph Stern{' '}
        <a
          href="https://slate.com/news-and-politics/2021/04/transgender-rights-bathrooms-sports-alliance-defending-freedom.html"
          target="_blank"
          rel="noreferrer"
        >
          explains
        </a>{' '}
        for Slate. Anti-trans bills are legal footholds for the larger mission
        of defining what gender expressions are acceptable.
      </p>
    </div>
  );
}
