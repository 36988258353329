import { ResponsiveBar } from '@nivo/bar';

const getWindowWidth = () => {
  const { innerWidth: width } = window;
  return width;
};

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
export default function NivoBillsByYear({ data }) {
  const isSmallDevice = getWindowWidth() < 800;

  const themeStyles = {
    textColor: '#333333',
    fontSize: 16,
  };

  const sortedData = data.sort((a, b) => parseInt(b.year) - parseInt(a.year));
  const chartData = sortedData.map((entry) => {
    let d = { ...entry };
    entry.bills.forEach((b) => (d[b.type] = b.count));
    return d;
  });

  return (
    <ResponsiveBar
      data={chartData}
      keys={['bathroom / locker room / sports', 'health', 'other']}
      margin={{ left: 55 }}
      enableLabel={false} // until HRC data replaced by ACLU
      indexBy="year"
      labelBy="year"
      layout="horizontal"
      animate={true}
      theme={themeStyles}
      padding={0.4}
      valueScale={{ type: 'linear' }}
      indexScale={{ type: 'band', round: true }}
      colors={['#4382eb', '#008683', '#fcbe11']} // red-ish ['#c73f1a', '#f38923', '#fcbe11']
      borderColor={{ from: 'color', modifiers: [['brighter', 1.6]] }}
      axisTop={null}
      axisRight={null}
      axisBottom={null}
      axisLeft={{
        tickSize: 0,
        tickPadding: 20,
        tickRotation: 0,
        legend: '',
        legendPosition: 'middle',
        legendOffset: -49,
      }}
      enableGridY={false}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
      legends={[
        {
          dataFrom: 'keys',
          anchor: 'top-right',
          direction: 'column',
          justify: false,
          translateX: isSmallDevice ? 0 : -150,
          // translateY: -100,
          itemsSpacing: 2,
          itemWidth: 100,
          itemHeight: 20,
          itemDirection: 'left-to-right',
          itemOpacity: 0.85,
          symbolSize: 20,
          effects: [
            {
              on: 'hover',
              style: {
                itemOpacity: 0.8,
              },
            },
          ],
        },
      ]}
      motionStiffness={250}
      motionDamping={30}
    />
  );
}
