import React from 'react';
import { useEffect } from 'react';
import BillCard from './BillCard';

export default function BillsByState({
  state,
  bills = [],
  billsVisible,
  setBillsVisible,
  hideBills,
}) {
  useEffect(() => {
    // View bills if new state selected after clear
    setBillsVisible(true);
  }, [state, setBillsVisible]);

  const stateBills = bills.filter((entry) => entry.abbreviation === state);

  return (
    <>
      <div>
        <h2
          style={{
            display: 'inline-block',
            textAlign: 'center',
            marginRight: '10px',
          }}
          className="light-black"
        >
          {`${state} (${stateBills.length})`}
        </h2>
        {state && billsVisible && (
          <button
            className="clear-bills-button"
            onClick={() => hideBills()}
            style={{ display: 'inline-block', textAlign: 'center' }}
          >
            hide bills
          </button>
        )}
      </div>

      {billsVisible && (
        <div className="card-container">
          {stateBills.map((bill) => (
            <BillCard bill={bill} fullWidth fadeIn />
          ))}
        </div>
      )}
    </>
  );
}
