import React, { useState, useEffect } from 'react';
import USAMap from 'react-usa-map';

export default function Map({
  senators,
  setSelectedState,
  selectedState,
  setRepsVisible,
}) {
  const [states, setStates] = useState([]);

  useEffect(() => {
    // Get all states array
    const onlyUnique = (value, index, self) => self.indexOf(value) === index;
    const states = senators?.map((s) => s.state_code).filter(onlyUnique);
    setStates(states);
  }, [senators]);

  const statesConfig = () => {
    let config = {};
    const selectedColor = '#fcbe11';
    const primaryColor = '#4382eb';
    states.forEach((state) => {
      const fillColor = selectedState === state ? selectedColor : primaryColor;
      config[state] = {
        fill: fillColor,
        clickHandler: (event) => {
          setSelectedState(event.target.dataset.name);
          setRepsVisible(true);
        },
      };
    });

    return config;
  };

  return <USAMap customize={statesConfig()} />;
}
