import React from 'react';
import Modal from 'react-modal';

export default function SenatorModal({
  modalIsOpen,
  closeModal,
  modalStyles,
  selectedRep,
  selectedRepEmail,
  selectedRepImage,
  copy,
  isCopied,
}) {
  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={modalStyles}
      contentLabel="Contact Senator modal"
    >
      <img
        src={selectedRepImage}
        className="rep-template-image"
        alt={`Profile of ${selectedRep}`}
      />
      <h2>{selectedRep}</h2>
      <p>{selectedRepEmail}</p>
      <div className="letter-template" id="letter-template">
        <p>Dear {selectedRep},</p>
        <p>
          I am a concerned citizen writing today to urge you to please support
          adding protections for sexual orientation and gender identity to the
          Equality Act. This historic bill, as passed by the House of
          Representatives, will protect lesbian, gay, bisexual, and transgender
          Americans from discrimination and strengthen protections against
          discrimination based on race, color, national origin, religion, and
          sex in businesses that serve the public.
        </p>
        <p>
          The Equality Act is a vital next step in ensuring the promise of equal
          opportunity reaches all Americans. The nonpartisan Public Religion
          Research Institute (PRRI) found that nationally, support for a bill
          like the Equality Act topped 70 percent, which includes a majority of
          Democrats, Republicans, and Independents.
        </p>
        <p>
          In 2021, there are 131 anti-trans bills currently introduced into
          state houses. At their core, the bills rely on debunked science and
          false claims of “protecting” children to block trans access to basic
          services and inclusion. Minnesota Senator Jason Isaacson characterized
          the state’s bill as clearly anti-trans legislation couched in positive
          language. “When we say horrible things really nicely, that doesn’t
          make it any better just because it’s polite,” Isaacson said. “And
          that’s what this is: it’s a horrible bill being presented really
          nicely.”
        </p>
        <p>Thank you for your consideration of this critical bill.</p>
        <p>Sincerely,</p>
      </div>
      <button
        className={
          isCopied
            ? 'rep-contact-button rep-btn-copy rep-btn-copy-sustained'
            : 'rep-contact-button rep-btn-copy'
        }
        onClick={() => copy()}
      >
        {isCopied ? 'Copied to clipboard!' : 'Copy template'}
      </button>
      <button className="rep-contact-button rep-btn-close" onClick={closeModal}>
        close
      </button>
    </Modal>
  );
}
