export default function SenatorsList({
  senatorsToDisplay,
  setSelectedRep,
  setSelectedRepEmail,
  setSelectedRepImage,
  setModalIsOpen,
}) {
  const showContactForm = (senator) => {
    setSelectedRep(senator.name);
    setSelectedRepEmail(senator.website);
    setSelectedRepImage(senator.photo_url);
    setModalIsOpen(true);
  };

  return (
    <div className="senators-list">
      {senatorsToDisplay?.map((senator) => {
        return (
          <div className={'fade-in-1000 small'}>
            <article className="card">
              <div className="senator-card-content">
                <img
                  src={senator.photo_url}
                  className="senator-image"
                  alt={`Profile of ${senator.name}`}
                />
                <h1 className="card-title">
                  <a href={senator.site_name}>{senator.name}</a>
                </h1>
                <p className="senator-party-state">
                  <span
                    className="card-tag"
                    style={{
                      margin: '0px 0px 5px 0px',
                    }}
                  >
                    {senator.party.toUpperCase()}
                  </span>
                  , {senator.state_name}
                </p>

                {senator?.twitter_handle && (
                  <a
                    href={senator?.twitter_url}
                    target="_blank"
                    rel="noreferrer"
                    style={{
                      margin: '0 auto 5 auto',
                      color: '#4382eb',
                      textDecoration: 'none',
                    }}
                  >
                    @{senator.twitter_handle}
                  </a>
                )}
                {senator?.phone && (
                  <p style={{ margin: '0 auto 5 auto' }}>
                    Phone: {senator.phone}
                  </p>
                )}
                {senator?.fax && (
                  <p style={{ margin: '0 auto 5 auto' }}>Fax: {senator.fax}</p>
                )}
                {senator.address_complete && (
                  <p style={{ margin: '0 auto 5 auto' }}>
                    {senator.address_complete}
                  </p>
                )}

                <button
                  className="card-contact-senator"
                  type="button"
                  onClick={() => showContactForm(senator)}
                >
                  Write your Senator
                </button>
              </div>
            </article>
          </div>
        );
      })}
    </div>
  );
}
