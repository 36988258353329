import React, { useState, useRef } from 'react';
import './promise.css';
import PromiseModal from './PromiseModal';
import PromiseRepsList from './PromiseRepsList';
import MapSenators from './MapSenators';
import { SelectState } from './SelectState';
import { copyToClip } from './utils';
import { promiseSignatories, usSenators } from '../../data/promise-signatories';
import SenatorModal from './SenatorModal';
import Header from './Header';

const getWindowWidth = () => {
  const { innerWidth: width } = window;
  return width;
};

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '80vw',
    paddingTop: '5vh',
    paddingBottom: '5vh',
    maxHeight: '80vh',
  },
};

const modalStylesSmallDevice = {
  content: {
    width: '80vw',
    margin: '0',
    padding: '10px 0 0 0',
    transform: 'translateX(-5vw)',
  },
};

export default function CallToAction() {
  const isSmallDevice = getWindowWidth() < 800;

  const [selectedStatePromise, setSelectedStatePromise] = useState('all');
  const [selectedRep, setSelectedRep] = useState();
  const [selectedRepEmail, setSelectedRepEmail] = useState();
  const [selectedRepImage, setSelectedRepImage] = useState();

  const [modalPromiseIsOpen, setModalPromiseIsOpen] = useState(false);
  const [modalSenatorIsOpen, setModalSenatorIsOpen] = useState(false);

  const [isCopied, setIsCopied] = useState(false);

  const selectRefPromise = useRef(null);

  const copy = () => {
    setIsCopied(true);
    copyToClip(document.getElementById('letter-template').innerHTML);
  };

  const closeModalPromise = () => {
    setModalPromiseIsOpen(false);
    setIsCopied(false);
  };

  const closeModalSenator = () => {
    setModalSenatorIsOpen(false);
    setIsCopied(false);
  };

  const repsToDisplay = promiseSignatories.filter((rep) => {
    if (selectedStatePromise === 'all') return rep;
    return rep.jurisdiction.name === selectedStatePromise;
  });

  return (
    <>
      <Header />
      <div className="slim-view">
        <div className="section-wrapper">
          <h1>
            <span className="blue-text">Support</span> the Equality Act
          </h1>
          <p>
            <a
              href="https://www.congress.gov/117/bills/hr5/BILLS-117hr5pcs.pdf"
              target="_blank"
              rel="noreferrer"
            >
              The Equality Act
            </a>{' '}
            would provide federal protections for sexual orientation and gender
            identity. It has already passed the House, and is now stalled in the
            Senate. The Equality Act simply amends the Civil Rights Act of 1964
            to uphold the Supreme Court ruling in{' '}
            <a
              href="https://www.nbcnews.com/feature/nbc-out/supreme-court-s-lgbtq-ruling-could-have-broad-implications-legal-n1231779"
              target="_blank"
              rel="noreferrer"
            >
              <i>Bostock v. Clayton County</i>
            </a>
            .
          </p>
          <p>
            During Pride Month 2019, Joe Biden called the Equality Act his{' '}
            <a
              href="https://apnews.com/article/ab96c4d9b3a84ef9b10598781ff38e93"
              target="_bank"
            >
              top priority
            </a>
            . “Just like with racial justice and women’s rights,” he said, “we
            are seeing pushback against all the progress we’ve made toward
            equality.” It is necessary to urge Senators to support these core
            LGBT protections.
          </p>
          <p>
            Contact your Senator to tell them you support trans rights, and they
            should too. Select your state and click "write your senator" to see
            a model email that you can customize and send.
          </p>
          <SenatorModal
            modalIsOpen={modalSenatorIsOpen}
            closeModal={closeModalSenator}
            modalStyles={isSmallDevice ? modalStylesSmallDevice : modalStyles}
            selectedRep={selectedRep}
            selectedRepEmail={selectedRepEmail}
            selectedRepImage={selectedRepImage}
            copy={copy}
            isCopied={isCopied}
          />
        </div>
      </div>
      <MapSenators
        allSenators={usSenators}
        setSelectedRep={setSelectedRep}
        setSelectedRepImage={setSelectedRepImage}
        setSelectedRepEmail={setSelectedRepEmail}
        setModalIsOpen={setModalSenatorIsOpen}
      />
      <div className="slim-view">
        <div className="section-wrapper">
          <h1>
            <span className="orange-text">Reject</span> Promise to America’s
            Children
          </h1>
          <p>
            Conservative political and religious organizations have spun into
            action at the threat of legislation like the Equality Act.{' '}
            <a
              href="https://promisetoamericaschildren.org/"
              target="_blank"
              rel="noreferrer"
            >
              Promise to America’s Children
            </a>{' '}
            was created earlier this year by The Heritage Foundation, Family
            Policy Alliance, and Alliance Defending Freedom to serve as a
            full-court press that targets policymakers, school boards, and
            parents. Their website urges readers to #LetKidsBeKids, but the
            group does not include trans kids in that message. They publish
            transphobic resource guides for parents and oppose what they call
            the “destructive gender ideology” of the Equality Act.
          </p>

          <h2>
            <span className="orange-text">29</span> representatives signed{' '}
            <a
              href="https://promisetoamericaschildren.org/the-promise/"
              target="_blank"
              rel="noreferrer"
            >
              “The Promise”
            </a>
          </h2>

          <p>
            “The Promise” is a 10-part pledge created by Promise to America’s
            Children that uses language of protection and preservation while
            outlining regressive policies that hurt trans kids. Click “contact”
            for a model email that you can customize and send to them. It takes
            just a minute, and will show them their constituents support trans
            kids.
          </p>
          <div className="select-container" ref={selectRefPromise}>
            <SelectState
              selectedState={selectedStatePromise}
              setSelectedState={setSelectedStatePromise}
              selectRef={selectRefPromise}
            />
          </div>
          <div className="rep-flex-container">
            <PromiseRepsList
              reps={repsToDisplay}
              setSelectedRep={setSelectedRep}
              setSelectedRepEmail={setSelectedRepEmail}
              setSelectedRepImage={setSelectedRepImage}
              setModalIsOpen={setModalPromiseIsOpen}
            />
          </div>
          <PromiseModal
            modalIsOpen={modalPromiseIsOpen}
            closeModal={closeModalPromise}
            modalStyles={modalStyles}
            selectedRep={selectedRep}
            selectedRepEmail={selectedRepEmail}
            selectedRepImage={selectedRepImage}
            copy={copy}
            isCopied={isCopied}
          />
        </div>
      </div>
    </>
  );
}
